"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoorRequest = void 0;
var Time_1 = require("../../builtin_interfaces/msg/Time");
var DoorMode_1 = require("../../rmf_door_msgs/msg/DoorMode");
var DoorRequest = /** @class */ (function () {
    function DoorRequest(fields) {
        if (fields === void 0) { fields = {}; }
        this.request_time = fields.request_time || new Time_1.Time();
        this.requester_id = fields.requester_id || '';
        this.door_name = fields.door_name || '';
        this.requested_mode = fields.requested_mode || new DoorMode_1.DoorMode();
    }
    DoorRequest.validate = function (obj) {
        try {
            Time_1.Time.validate(obj['request_time']);
        }
        catch (e) {
            throw new Error('in "request_time":\n  ' + e.message);
        }
        if (typeof obj['requester_id'] !== 'string') {
            throw new Error('expected "requester_id" to be "string"');
        }
        if (typeof obj['door_name'] !== 'string') {
            throw new Error('expected "door_name" to be "string"');
        }
        try {
            DoorMode_1.DoorMode.validate(obj['requested_mode']);
        }
        catch (e) {
            throw new Error('in "requested_mode":\n  ' + e.message);
        }
    };
    DoorRequest.FullTypeName = 'rmf_door_msgs/msg/DoorRequest';
    return DoorRequest;
}());
exports.DoorRequest = DoorRequest;
/*
builtin_interfaces/Time request_time
string requester_id
string door_name
DoorMode requested_mode

*/
