import { DataGrid, getGridDateOperators, getGridStringOperators, } from '@mui/x-data-grid';
import { styled, TextField, Stack, Typography, Tooltip } from '@mui/material';
import * as React from 'react';
import { Status } from 'api-client';
import { InsertInvitation as ScheduleIcon, Person as UserIcon } from '@mui/icons-material/';
var classes = {
    taskActiveCell: 'MuiDataGrid-cell-active-cell',
    taskCancelledCell: 'MuiDataGrid-cell-cancelled-cell',
    taskCompletedCell: 'MuiDataGrid-cell-completed-cell',
    taskFailedCell: 'MuiDataGrid-cell-failed-cell',
    taskPendingCell: 'MuiDataGrid-cell-pending-cell',
    taskQueuedCell: 'MuiDataGrid-cell-queued-cell',
    taskUnknownCell: 'MuiDataGrid-cell-unknown-cell',
};
var StyledDataGrid = styled(DataGrid)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& ." + classes.taskActiveCell] = {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.getContrastText(theme.palette.success.light),
        },
        _b["& ." + classes.taskCancelledCell] = {
            backgroundColor: theme.palette.grey[500],
            color: theme.palette.getContrastText(theme.palette.grey[500]),
        },
        _b["& ." + classes.taskCompletedCell] = {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.getContrastText(theme.palette.info.light),
        },
        _b["& ." + classes.taskFailedCell] = {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.getContrastText(theme.palette.error.main),
        },
        _b["& ." + classes.taskQueuedCell] = {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.getContrastText(theme.palette.grey[300]),
        },
        _b["& ." + classes.taskUnknownCell] = {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.getContrastText(theme.palette.warning.main),
        },
        _b);
});
var TaskRequester = function (requester) {
    if (!requester) {
        return React.createElement(Typography, { variant: "body1" }, "unknown");
    }
    /** When a task is created as scheduled,
     we save the requester as USERNAME__scheduled.
     Therefore, we remove the __schedule because the different icon is enough indicator to know
     if the task was adhoc or scheduled.
    */
    return (React.createElement(Stack, { direction: "row", alignItems: "center", gap: 1 }, requester.includes('scheduled') ? (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: "User scheduled" },
            React.createElement(ScheduleIcon, null)),
        React.createElement(Typography, { variant: "body1" }, requester.split('__')[0]))) : (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: "User submitted" },
            React.createElement(UserIcon, null)),
        React.createElement(Typography, { variant: "body1" }, requester)))));
};
export function TaskDataGridTable(_a) {
    var tasks = _a.tasks, onTaskClick = _a.onTaskClick, onPageChange = _a.onPageChange, onPageSizeChange = _a.onPageSizeChange, setFilterFields = _a.setFilterFields, setSortFields = _a.setSortFields;
    var handleEvent = function (params, event) {
        if (onTaskClick) {
            onTaskClick(event, params.row);
        }
    };
    var getMinimalStringFilterOperators = getGridStringOperators().filter(
    // TODO: implement contains on the server end as well
    function (operator) { return operator.value === 'equals'; });
    var getMinimalDateOperators = getGridDateOperators(true).filter(function (operator) { return operator.value === 'onOrAfter' || operator.value === 'onOrBefore'; });
    var columns = [
        {
            field: 'unix_millis_request_time',
            headerName: 'Date',
            width: 150,
            editable: false,
            renderCell: function (cellValues) {
                return (React.createElement(TextField, { variant: "standard", value: cellValues.row.booking.unix_millis_request_time
                        ? "" + new Date(cellValues.row.booking.unix_millis_request_time).toLocaleDateString()
                        : 'unknown', InputProps: { disableUnderline: true }, multiline: true }));
            },
            flex: 1,
            filterOperators: getMinimalDateOperators,
            filterable: true,
        },
        {
            field: 'requester',
            headerName: 'Requester',
            minWidth: 160,
            editable: false,
            renderCell: function (cellValues) { return TaskRequester(cellValues.row.booking.requester); },
            flex: 2,
            filterOperators: getMinimalStringFilterOperators,
            filterable: true,
        },
        {
            field: 'id_',
            headerName: 'ID',
            width: 90,
            valueGetter: function (params) { return params.row.booking.id; },
            flex: 1,
            filterOperators: getMinimalStringFilterOperators,
            filterable: true,
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 150,
            editable: false,
            valueGetter: function (params) {
                return params.row.category ? params.row.category : 'unknown';
            },
            flex: 1,
            filterOperators: getMinimalStringFilterOperators,
            filterable: true,
        },
        {
            field: 'assigned_to',
            headerName: 'Assignee',
            width: 150,
            editable: false,
            valueGetter: function (params) {
                return params.row.assigned_to ? params.row.assigned_to.name : 'unknown';
            },
            flex: 1,
            filterOperators: getMinimalStringFilterOperators,
            filterable: true,
        },
        {
            field: 'unix_millis_start_time',
            headerName: 'Start Time',
            width: 150,
            editable: false,
            renderCell: function (cellValues) {
                return (React.createElement(TextField, { variant: "standard", value: cellValues.row.unix_millis_start_time
                        ? new Date(cellValues.row.unix_millis_start_time).toLocaleDateString() + " " + new Date(cellValues.row.unix_millis_start_time).toLocaleTimeString()
                        : 'unknown', InputProps: { disableUnderline: true }, multiline: true }));
            },
            flex: 1,
            filterOperators: getMinimalDateOperators,
            filterable: true,
        },
        {
            field: 'unix_millis_finish_time',
            headerName: 'End Time',
            width: 150,
            editable: false,
            renderCell: function (cellValues) {
                return (React.createElement(TextField, { variant: "standard", value: cellValues.row.unix_millis_finish_time
                        ? new Date(cellValues.row.unix_millis_finish_time).toLocaleDateString() + " " + new Date(cellValues.row.unix_millis_finish_time).toLocaleTimeString()
                        : 'unknown', InputProps: { disableUnderline: true }, multiline: true }));
            },
            flex: 1,
            filterOperators: getMinimalDateOperators,
            filterable: true,
        },
        {
            field: 'status',
            headerName: 'State',
            editable: false,
            valueGetter: function (params) {
                return params.row.status ? params.row.status : 'unknown';
            },
            flex: 1,
            filterOperators: getMinimalStringFilterOperators,
            filterable: true,
        },
    ];
    var handleFilterModelChange = React.useCallback(function (filterModel) {
        setFilterFields({ model: filterModel });
    }, [setFilterFields]);
    var handleSortModelChange = React.useCallback(function (sortModel) {
        setSortFields({ model: sortModel });
    }, [setSortFields]);
    return (React.createElement("div", { style: { height: '100%', width: '100%' } },
        React.createElement(StyledDataGrid, { autoHeight: true, getRowId: function (r) { return r.booking.id; }, rows: tasks.data, rowCount: tasks.total, loading: tasks.isLoading, pageSize: tasks.pageSize, rowsPerPageOptions: [10], pagination: true, paginationMode: "server", filterMode: "server", onFilterModelChange: handleFilterModelChange, sortingMode: "server", onSortModelChange: handleSortModelChange, page: tasks.page - 1, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, columns: columns, onRowClick: handleEvent, getCellClassName: function (params) {
                if (params.field === 'status') {
                    switch (params.value) {
                        case Status.Underway:
                            return classes.taskActiveCell;
                        case Status.Completed:
                            return classes.taskCompletedCell;
                        case Status.Canceled:
                            return classes.taskCancelledCell;
                        case Status.Failed:
                            return classes.taskFailedCell;
                        case Status.Queued:
                            return classes.taskQueuedCell;
                        default:
                            return classes.taskUnknownCell;
                    }
                }
                return '';
            } })));
}
