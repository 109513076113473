import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// TODO: some of these maps should be stored somewhere and used globally
// So they are all in sync
export var robot_list = {
    LeoVac: ['LeoVac-01', 'LeoVac-02'],
    S75: ['S75-A'],
    V40: ['V40-A', 'V40-B'],
};
var robotToCleanZoneMap = {
    V40: ['CIR 11-12 Carpet', 'CIR 9-10 Carpet', 'CIR 8 Carpet', 'CIR 7 Carpet'],
    LeoVac: [
        'Office',
        'GST',
        'STARBUCKS_1',
        'STARBUCKS_2',
        'DOOR 7',
        'DOOR 6',
        'DOOR 5',
        'DOOR 4',
        'CIR 10_1',
        'CIR 10_2',
        'CIR 9-10',
        'CIR 7-8_1',
        'CIR 7-8_2',
        'SHOP_DEPIMMI_1',
        'SHOP_DEPIMMI_2',
        'SHOP_DEPIMMI_3',
    ],
    S75: [
        'Office',
        'GST',
        'STARBUCKS_1',
        'STARBUCKS_2',
        'DOOR 7',
        'DOOR 6',
        'DOOR 5',
        'DOOR 4',
        'CIR 10_2',
        'CIR 10_1',
        'CIR 9-10',
        'CIR 7-8_1',
        'CIR 7-8_2',
        'SHOP_DEPIMMI_1',
        'SHOP_DEPIMMI_2',
        'SHOP_DEPIMMI_3',
    ],
};
export var cleanActionDescNameMap = {
    V40: 'clean_task_name',
    LeoVac: 'process',
    S75: 'clean_zone',
};
var robotHoldingPointMap = {
    LeoVac: { 'LeoVac-01': 'LeoVac-01-charger', 'LeoVac-02': 'LeoVac-02-charger' },
    S75: { 'S75-A': 'holding_point' },
    V40: { 'V40-A': 'V40-A-charger', 'V40-B': 'V40-B-charger' },
};
var robotDailyMaintenanceActionMap = {
    LeoVac: { 'LeoVac-01': 'manual', 'LeoVac-02': 'manual' },
    S75: { 'S75-A': 'manual' },
    V40: { 'V40-A': 'manual', 'V40-B': 'mannual' },
};
export var getCleanZonesFromRobot = function (robot) {
    if (robot in robotToCleanZoneMap) {
        return robotToCleanZoneMap[robot];
    }
    return Array();
};
var getHoldingPoint = function (fleet, robot) {
    if (fleet in robotHoldingPointMap) {
        if (robot in robotHoldingPointMap[fleet]) {
            return robotHoldingPointMap[fleet][robot];
        }
    }
    return null;
};
var getDailyMaintenaceAction = function (fleet, robot) {
    if (fleet in robotDailyMaintenanceActionMap) {
        if (robot in robotDailyMaintenanceActionMap[fleet]) {
            return robotDailyMaintenanceActionMap[fleet][robot];
        }
    }
    return null;
};
var isSeries = function (eventInfo) {
    if (!eventInfo.reccurrenceInfo) {
        return false;
    }
    return (eventInfo.reccurrenceInfo.dailyChecked ||
        eventInfo.reccurrenceInfo.weeklyChecked ||
        eventInfo.reccurrenceInfo.monthlyChecked);
};
var isDAG = function (eventInfo) {
    if (eventInfo.zoneSequence && eventInfo.eventCategory === 'Cleaning Task') {
        if (eventInfo.zoneSequence.length > 0) {
            return true;
        }
    }
    return false;
};
export var checkEventFormValid = function (eventInfo) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    // check if fleet exists
    var _j = useState(''), errorDialogMessage = _j[0], setErrorDialogMessage = _j[1];
    if (!robot_list[eventInfo.fleetName]) {
        console.log("Fleet " + eventInfo.fleetName + " does not exist!");
        return false;
    }
    // check if robot exists in the fleet
    if (!robot_list[eventInfo.fleetName].includes(eventInfo.robotName)) {
        console.log("Robot " + eventInfo.robotName + " does not exist in Fleet " + eventInfo.fleetName + "!");
        return false;
    }
    // start time smaller than end time
    if (eventInfo.endTime.getTime() <= eventInfo.startTime.getTime()) {
        console.log('End time is before start time');
        return false;
    }
    if (eventInfo.estimatedEndTime) {
        if (eventInfo.estimatedEndTime.getTime() <= eventInfo.startTime.getTime()) {
            console.log('End time is before or equal to start time');
            return false;
        }
    }
    // if there are conflicting selections for the series
    if ((((_a = eventInfo.reccurrenceInfo) === null || _a === void 0 ? void 0 : _a.dailyChecked) && ((_b = eventInfo.reccurrenceInfo) === null || _b === void 0 ? void 0 : _b.weeklyChecked)) ||
        (((_c = eventInfo.reccurrenceInfo) === null || _c === void 0 ? void 0 : _c.dailyChecked) && ((_d = eventInfo.reccurrenceInfo) === null || _d === void 0 ? void 0 : _d.monthlyChecked)) ||
        (((_e = eventInfo.reccurrenceInfo) === null || _e === void 0 ? void 0 : _e.weeklyChecked) && ((_f = eventInfo.reccurrenceInfo) === null || _f === void 0 ? void 0 : _f.monthlyChecked))) {
        console.log('Conflicting series selection');
        return false;
    }
    if (((_g = eventInfo.reccurrenceInfo) === null || _g === void 0 ? void 0 : _g.reccuranceEndDateChecked) &&
        !((_h = eventInfo.reccurrenceInfo) === null || _h === void 0 ? void 0 : _h.reuccuranceEndDate)) {
        console.log('Reccurance end date checked but no end date provided');
        return false;
    }
    return true;
};
export var checkEditFormValid = function (eventInfo) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    // check if fleet exists
    if (!robot_list[eventInfo.fleetName]) {
        console.log("Fleet " + eventInfo.fleetName + " does not exist!");
        return false;
    }
    // check if robot exists in the fleet
    if (!robot_list[eventInfo.fleetName].includes(eventInfo.robotName)) {
        console.log("Robot " + eventInfo.robotName + " does not exist in Fleet " + eventInfo.fleetName + "!");
        return false;
    }
    // start time smaller than end time
    if (eventInfo.endTime.getTime() <= eventInfo.startTime.getTime()) {
        console.log('End time is before or equal to start time');
        return false;
    }
    if (eventInfo.estimatedEndTime) {
        if (eventInfo.estimatedEndTime.getTime() <= eventInfo.startTime.getTime()) {
            console.log('End time is before or equal to start time');
            return false;
        }
    }
    // if there are conflicting selections for the series
    if ((((_a = eventInfo.reccurrenceInfo) === null || _a === void 0 ? void 0 : _a.dailyChecked) && ((_b = eventInfo.reccurrenceInfo) === null || _b === void 0 ? void 0 : _b.weeklyChecked)) ||
        (((_c = eventInfo.reccurrenceInfo) === null || _c === void 0 ? void 0 : _c.dailyChecked) && ((_d = eventInfo.reccurrenceInfo) === null || _d === void 0 ? void 0 : _d.monthlyChecked)) ||
        (((_e = eventInfo.reccurrenceInfo) === null || _e === void 0 ? void 0 : _e.weeklyChecked) && ((_f = eventInfo.reccurrenceInfo) === null || _f === void 0 ? void 0 : _f.monthlyChecked))) {
        console.log('Conflicting series selection');
        return false;
    }
    if (((_g = eventInfo.reccurrenceInfo) === null || _g === void 0 ? void 0 : _g.reccuranceEndDateChecked) &&
        !((_h = eventInfo.reccurrenceInfo) === null || _h === void 0 ? void 0 : _h.reuccuranceEndDate)) {
        console.log('Reccurance end date checked but no end date provided');
        return false;
    }
    return true;
};
var parser = require('cron-parser');
var convertToCron = function (startTime, endTime, reccuranceEndDate, dailyChecked, weeklyChecked, monthlyChecked, reccuranceEndDateChecked, daysCheckedArray) {
    var includeSeconds = true;
    if (dailyChecked) {
        var interval = parser.parseExpression('* * * * * *');
        var fields = JSON.parse(JSON.stringify(interval.fields));
        fields.second = [startTime.getSeconds()];
        fields.minute = [startTime.getMinutes()];
        fields.hour = [startTime.getHours()];
        return parser.fieldsToExpression(fields).stringify(includeSeconds);
    }
    if (weeklyChecked) {
        // Include current day
        var daysToRun = [];
        daysToRun.push(startTime.getDay());
        for (var i = 0; i < 7; i++) {
            // add all other unique days checked
            if (daysCheckedArray[i] && i != startTime.getDay()) {
                daysToRun.push(i);
            }
        }
        var interval = parser.parseExpression('* * * * * *');
        var fields = JSON.parse(JSON.stringify(interval.fields));
        fields.second = [startTime.getSeconds()];
        fields.minute = [startTime.getMinutes()];
        fields.hour = [startTime.getHours()];
        fields.dayOfWeek = daysToRun;
        return parser.fieldsToExpression(fields).stringify(includeSeconds);
    }
    if (monthlyChecked) {
        var interval = parser.parseExpression('* * * * * *');
        var fields = JSON.parse(JSON.stringify(interval.fields));
        fields.second = [startTime.getSeconds()];
        fields.minute = [startTime.getMinutes()];
        fields.hour = [startTime.getHours()];
        fields.dayOfMonth = [startTime.getDate()];
        return parser.fieldsToExpression(fields).stringify(includeSeconds);
    }
    console.debug('We should not be here');
    return '* * * * * *';
};
var convertToSeries = function (startTime, endTime, reccuranceEndDate, eventId, dailyChecked, weeklyChecked, monthlyChecked, reccuranceEndDateChecked, daysCheckedArray) {
    var cronString = convertToCron(startTime, endTime, reccuranceEndDate, dailyChecked, weeklyChecked, monthlyChecked, reccuranceEndDateChecked, daysCheckedArray);
    var occ = new Array(eventId);
    var timeZone = 'Asia/Singapore';
    var excepttionArray = new Array();
    var idPrefix = 'series-';
    // NOTE: We set a default duration for now
    // TODO: Convert the reccurence end date to the number of cycles
    var until = 0;
    return {
        cron: cronString,
        timezone: timeZone,
        id_prefix: idPrefix,
        occurrences: occ,
        // When you create a NEW series there shouldnt be any exceptions
        // exceptions: excepttionArray,
    };
};
var convertToDependency = function (cleanZones) {
    return cleanZones;
};
var convertToEvent = function (eventCategory, startTime, endTime, estimatedEndTime, eventId, fleetName, robotName, seriesId, dependencyId, zoneSequence) {
    if (seriesId === void 0) { seriesId = undefined; }
    if (dependencyId === void 0) { dependencyId = undefined; }
    if (zoneSequence === void 0) { zoneSequence = new Array(); }
    var eventDescString = createEventDescString(eventCategory, startTime, endTime, estimatedEndTime, eventId, fleetName, robotName, zoneSequence);
    var eventDetails = convertToEventDetails(eventCategory, startTime, endTime, estimatedEndTime, eventId, fleetName, robotName, zoneSequence);
    var unixStartTime = startTime.getTime() / 1000;
    // NOTE: if no duration we set a default duration
    var unixDuration = endTime ? endTime.getTime() / 1000 - startTime.getTime() / 1000 : 1000;
    var type = eventCategory;
    return {
        description: eventDescString,
        type: type,
        start_time: unixStartTime,
        duration: unixDuration,
        series_id: seriesId,
        dependency_id: dependencyId,
        event_details: eventDetails,
    };
};
var createEventDescString = function (eventCategory, startTime, endTime, estimatedEndTime, eventId, fleetName, robotName, zoneSequence) {
    if (zoneSequence === void 0) { zoneSequence = new Array(); }
    var description = robotName + " " + eventCategory + " ";
    if (zoneSequence && zoneSequence.length > 0) {
        description += "[" + zoneSequence.join(', ') + "]";
    }
    return description;
};
var convertToEventDetails = function (eventCategory, startTime, endTime, estimatedEndTime, eventId, fleetName, robotName, zoneSequence) {
    if (eventCategory === 'Cleaning Task') {
        return createCleanTaskRequest(startTime, endTime, estimatedEndTime, eventId, fleetName, robotName, zoneSequence);
    }
    else if (eventCategory === 'Daily Maintenance') {
        return createDailyMaintenanceTaskRequest(startTime, endTime, estimatedEndTime, eventId, fleetName, robotName);
    }
    else if (eventCategory == 'Vendor Maintenance') {
        return createVendorMaintenanceTaskRequest(startTime, endTime, estimatedEndTime, eventId, fleetName, robotName);
    }
    console.log("Event Category " + eventCategory + " does not exist!");
    return {};
};
var createVendorMaintenanceTaskRequest = function (startTime, endTime, estimatedEndTime, eventId, fleetName, robotName) {
    var request = {};
    return {
        type: 'robot_task_request',
        fleet: fleetName,
        robot: robotName,
        request: request,
    };
};
var createDailyMaintenanceTaskRequest = function (startTime, endTime, estimatedEndTime, eventId, fleetName, robotName) {
    var description = createDailyMaintenanceTaskRequestDesc(fleetName, robotName);
    var request = {
        category: 'compose',
        description: description,
    };
    return {
        type: 'robot_task_request',
        request: request,
        fleet: fleetName,
        robot: robotName,
    };
};
var createDailyMaintenanceTaskRequestDesc = function (fleetName, robotName) {
    var phases = [];
    var composeActivities = [];
    var holdingPoint = getHoldingPoint(fleetName, robotName);
    if (!holdingPoint) {
        return {};
    }
    // Go to holding point
    var goToStartActivity = createGoToPlaceActivity(holdingPoint);
    composeActivities.push(goToStartActivity);
    var maintenanceActionDesc = createDailyMaintenanceActionDesc(fleetName, robotName);
    var actionCategory = getDailyMaintenaceAction(fleetName, robotName);
    if (!actionCategory) {
        // Default value
        actionCategory = 'manual';
    }
    var estimatedDuration = 1000; // Place holder
    var dailyMaintenanceActivity = createPerformActionActivity(actionCategory, maintenanceActionDesc, estimatedDuration);
    // composeActivities.push(dailyMaintenanceActivity);
    var phase = createPhase(composeActivities);
    phases.push(phase);
    var description = {
        category: actionCategory,
        phases: phases,
    };
    return description;
};
var createDailyMaintenanceActionDesc = function (fleetName, robotName) {
    return {};
};
var createCleanTaskRequest = function (startTime, endTime, estimatedEndTime, eventId, fleetName, robotName, zoneSequence) {
    // let description = createCleanTaskRequestDesc(fleetName, robotName, zoneSequence);
    // let request = {
    //   category: 'compose',
    //   description: description,
    // };
    return {
        // type: 'robot_task_request',
        fleet: fleetName,
        robot: robotName,
        // request: request,
        // NOTE: This implementation assumes that there is
        // only one zone here
        zone: zoneSequence[0],
    };
};
var createPhase = function (activities) {
    return {
        activity: {
            category: 'sequence',
            description: { activities: activities },
        },
    };
};
var createPerformActionActivity = function (actionCategory, actionDescription, durationEstimate, useToolSink) {
    if (useToolSink === void 0) { useToolSink = true; }
    return {
        category: 'perform_action',
        description: {
            unix_millis_action_duration_estimate: durationEstimate,
            category: actionCategory,
            description: actionDescription,
            use_tool_sink: useToolSink,
        },
    };
};
var createGoToPlaceActivity = function (waypoint) {
    return {
        category: 'go_to_place',
        description: { waypoint: waypoint },
    };
};
export var convertEventFormInfoToSchedule = function (eventInfo) {
    var _a, _b, _c, _d;
    var _e, _f, _g;
    var series;
    var seriesUuid;
    var events = {};
    var dagId;
    var dag = {};
    // Stand alone event
    if (!isSeries(eventInfo) && !isDAG(eventInfo)) {
        var eventId = 'event-' + eventInfo.eventId;
        var event_1 = convertToEvent(eventInfo.eventCategory, eventInfo.startTime, eventInfo.endTime, eventInfo.estimatedEndTime ? eventInfo.estimatedEndTime : null, eventId, eventInfo.fleetName, eventInfo.robotName, undefined, // Not a series
        undefined, // Leave out dependencies for now
        eventInfo.zoneSequence);
        var event_id = eventInfo.eventId;
        events[event_id] = event_1;
    }
    else if (isSeries(eventInfo) && !isDAG(eventInfo)) {
        // Is series but has no DAG
        var eventId = 'event-' + eventInfo.eventId;
        seriesUuid = uuidv4();
        series = convertToSeries(eventInfo.startTime, eventInfo.endTime, ((_e = eventInfo.reccurrenceInfo) === null || _e === void 0 ? void 0 : _e.reuccuranceEndDate)
            ? eventInfo.reccurrenceInfo.reuccuranceEndDate
            : new Date(), eventId, eventInfo.reccurrenceInfo ? eventInfo.reccurrenceInfo.dailyChecked : false, eventInfo.reccurrenceInfo ? eventInfo.reccurrenceInfo.weeklyChecked : false, eventInfo.reccurrenceInfo ? eventInfo.reccurrenceInfo.monthlyChecked : false, eventInfo.reccurrenceInfo ? eventInfo.reccurrenceInfo.reccuranceEndDateChecked : false, eventInfo.reccurrenceInfo
            ? eventInfo.reccurrenceInfo.daysCheckedArray
            : Array(7).fill(false));
        var event_2 = convertToEvent(eventInfo.eventCategory, eventInfo.startTime, eventInfo.endTime, eventInfo.estimatedEndTime ? eventInfo.estimatedEndTime : null, eventInfo.eventId, eventInfo.fleetName, eventInfo.robotName, undefined, undefined, // Leave out dependencies for now
        eventInfo.zoneSequence);
        events[eventId] = event_2;
    }
    else if (!isSeries(eventInfo) && isDAG(eventInfo) && eventInfo.zoneSequence) {
        // clean event with multiple clean zones but not a series
        dagId = 'dag-' + uuidv4();
        var zoneSequenceEvents = [];
        var zoneSequenceEventsId = [];
        var dagInterval = (eventInfo.endTime.getTime() - eventInfo.startTime.getTime()) /
            (1000 * eventInfo.zoneSequence.length);
        // Create an event for each clean zone
        for (var i = 0; i < eventInfo.zoneSequence.length; i++) {
            var eventId = 'clean-' + uuidv4();
            var event_3 = convertToEvent(eventInfo.eventCategory, new Date(eventInfo.startTime.getTime() + dagInterval * 1000 * i), new Date(eventInfo.startTime.getTime() + dagInterval * 1000 * (i + 1)), eventInfo.estimatedEndTime ? eventInfo.estimatedEndTime : null, eventId, // Don't use event form id
            eventInfo.fleetName, eventInfo.robotName, undefined, undefined, [eventInfo.zoneSequence[i]]);
            event_3.dependency_id = eventId;
            zoneSequenceEvents.push(event_3);
            zoneSequenceEventsId.push(eventId);
        }
        // Populate DAG
        for (var i_1 = 0; i_1 < zoneSequenceEvents.length; i_1++) {
            // Add event to events
            events[zoneSequenceEventsId[i_1]] = zoneSequenceEvents[i_1];
            if (i_1 === 0) {
                // First event has no dependency
                dag[zoneSequenceEventsId[i_1]] = [];
                continue;
            }
            dag[zoneSequenceEventsId[i_1]] = [zoneSequenceEventsId[i_1 - 1]];
        }
    }
    else if (isSeries(eventInfo) && isDAG(eventInfo) && eventInfo.zoneSequence) {
        // Clean task with multiple zones which is a series
        dagId = 'dag-' + uuidv4();
        var zoneSequenceEvents = [];
        var zoneSequenceEventsId = [];
        var dagInterval = (eventInfo.endTime.getTime() - eventInfo.startTime.getTime()) /
            (1000 * eventInfo.zoneSequence.length);
        // Create an event for each clean zone
        for (var i = 0; i < eventInfo.zoneSequence.length; i++) {
            var eventId = 'clean-' + uuidv4();
            var event_4 = convertToEvent(eventInfo.eventCategory, new Date(eventInfo.startTime.getTime() + dagInterval * 1000 * i), new Date(eventInfo.startTime.getTime() + dagInterval * 1000 * (i + 1)), eventInfo.estimatedEndTime ? eventInfo.estimatedEndTime : null, eventId, // Don't use event form id
            eventInfo.fleetName, eventInfo.robotName, undefined, undefined, // Leave out dependencies for now
            [eventInfo.zoneSequence[i]]);
            event_4.dependency_id = eventId;
            zoneSequenceEvents.push(event_4);
            zoneSequenceEventsId.push(eventId);
        }
        // Populate DAG
        for (var i_2 = 0; i_2 < zoneSequenceEvents.length; i_2++) {
            // Add event to events
            events[zoneSequenceEventsId[i_2]] = zoneSequenceEvents[i_2];
            if (i_2 === 0) {
                // First event has no dependency
                dag[zoneSequenceEventsId[i_2]] = [];
                continue;
            }
            dag[zoneSequenceEventsId[i_2]] = [zoneSequenceEventsId[i_2 - 1]];
        }
        // Create series
        seriesUuid = uuidv4();
        series = convertToSeries(eventInfo.startTime, eventInfo.endTime, ((_f = eventInfo.reccurrenceInfo) === null || _f === void 0 ? void 0 : _f.reuccuranceEndDate)
            ? (_g = eventInfo.reccurrenceInfo) === null || _g === void 0 ? void 0 : _g.reuccuranceEndDate
            : new Date(), dagId, // occurance is a DAG instead of event id
        eventInfo.reccurrenceInfo ? eventInfo.reccurrenceInfo.dailyChecked : false, eventInfo.reccurrenceInfo ? eventInfo.reccurrenceInfo.weeklyChecked : false, eventInfo.reccurrenceInfo ? eventInfo.reccurrenceInfo.monthlyChecked : false, eventInfo.reccurrenceInfo ? eventInfo.reccurrenceInfo.reccuranceEndDateChecked : false, eventInfo.reccurrenceInfo
            ? eventInfo.reccurrenceInfo.daysCheckedArray
            : Array(7).fill(false));
    }
    else {
        return null;
    }
    if (!series && !seriesUuid && !dagId) {
        return {
            events: events,
        };
    }
    else if (series && seriesUuid && !dagId) {
        var series_id = series.id_prefix + seriesUuid;
        var seriesObj = (_a = {}, _a[series_id] = series, _a);
        return {
            events: events,
            series: seriesObj,
        };
    }
    else if (!series && !seriesUuid && dagId) {
        return {
            events: events,
            dependencies: (_b = {}, _b[dagId] = dag, _b),
        };
    }
    else if (series && seriesUuid && dagId) {
        var series_id = series.id_prefix + seriesUuid;
        var seriesObj = (_c = {}, _c[series_id] = series, _c);
        return {
            events: events,
            series: seriesObj,
            dependencies: (_d = {}, _d[dagId] = dag, _d),
        };
    }
    else {
        return null;
    }
};
////////////////////////////////////////// Converting to Edit Event Form //////////////////////////////////////////
var editableCategories = ['Cleaning Task', 'Daily Maintenance', 'Vendor Maintenance'];
export var convertEditFormIntoSchedule = function (editEventFormInfo, currentSchedule) {
    var _a, _b;
    var _c, _d;
    console.log("Current Schedule " + currentSchedule);
    var updateScheduleInfo = {};
    console.log("UDPATE SCHEDULE INFO " + JSON.stringify(updateScheduleInfo));
    var updateSchedule = {};
    updateSchedule['events'] = {};
    var deleteScheduleRequest = {};
    var addSchedule = {};
    addSchedule['events'] = {};
    if (!editEventFormInfo.options) {
        console.debug('Options not provided');
        return null;
    }
    deleteScheduleRequest.event_ids = [];
    if ((_c = editEventFormInfo.options) === null || _c === void 0 ? void 0 : _c.editSingleEvent) {
        if (!editEventFormInfo.dagId) {
            var event_5 = convertToEvent(editEventFormInfo.eventCategory, editEventFormInfo.startTime, editEventFormInfo.endTime, editEventFormInfo.estimatedEndTime, editEventFormInfo.eventId, // Don't use event form id
            editEventFormInfo.fleetName, editEventFormInfo.robotName, ((_d = editEventFormInfo.options) === null || _d === void 0 ? void 0 : _d.seriesId) ? editEventFormInfo.seriesId : undefined, undefined, // should not be part of a Dag
            undefined);
            updateSchedule['events'][editEventFormInfo.eventId] = event_5;
        }
        else if (editEventFormInfo.zoneSequence) {
            // search existing dag for clean zone.
            // store event id and zone sequence in a map.
            // identify added or removed zone sequence
            // create event for added zones
            // if there are deleted zones, add the event id for that zone to the delete schedule request
            var newDag = {};
            var cleanZoneEventMap = getZoneSequenceEventMap(editEventFormInfo.dagId, currentSchedule);
            cleanZoneEventMap = cleanZoneEventMap ? cleanZoneEventMap : {};
            // Delete events for clean zones no longer inside the clean zone sequence
            deleteScheduleRequest['event_ids'] = [];
            for (var _i = 0, _e = Object.entries(cleanZoneEventMap); _i < _e.length; _i++) {
                var zone = _e[_i];
                // This zone is no longer in the zone sequence we should delete it
                if (!editEventFormInfo.zoneSequence.includes(zone[0])) {
                    deleteScheduleRequest['event_ids'].push(zone[1]);
                }
            }
            console.log("Events to delete " + JSON.stringify(deleteScheduleRequest));
            updateScheduleInfo.deleteSchedule = deleteScheduleRequest;
            var dagStartTime = editEventFormInfo.startTime.getTime() / 1000;
            // NOTE: Current assumption is that the time interval for each is equally divided between each zone.
            // TODO: The interval times should should be taken from the estimated data from durationEstimateMap
            var dagInterval = (editEventFormInfo.endTime.getTime() - editEventFormInfo.startTime.getTime()) /
                (1000 * editEventFormInfo.zoneSequence.length);
            // Create new events if they do not exist
            // create new dag
            for (var i = 0; i < editEventFormInfo.zoneSequence.length; i++) {
                var eventId = void 0;
                // if current zone not in existing dag we create an event for it
                var addEvent = false;
                if (!(editEventFormInfo.zoneSequence[i] in cleanZoneEventMap)) {
                    eventId = 'clean-' + uuidv4();
                    // add event id to clean zone event map
                    cleanZoneEventMap[editEventFormInfo.zoneSequence[i]] = eventId;
                    var event_6 = convertToEvent(editEventFormInfo.eventCategory, new Date((dagStartTime + dagInterval * i) * 1000), new Date((dagStartTime + dagInterval * (i + 1)) * 1000), new Date((dagStartTime + dagInterval * (i + 1)) * 1000), eventId, // Don't use event form id
                    editEventFormInfo.fleetName, editEventFormInfo.robotName, undefined, undefined, // This event is being added to an existing dag
                    [editEventFormInfo.zoneSequence[i]]);
                    addSchedule['events'][eventId] = event_6;
                }
                else {
                    eventId = cleanZoneEventMap[editEventFormInfo.zoneSequence[i]];
                    var event_7 = convertToEvent(editEventFormInfo.eventCategory, new Date((dagStartTime + dagInterval * i) * 1000), new Date((dagStartTime + dagInterval * (i + 1)) * 1000), new Date((dagStartTime + dagInterval * (i + 1)) * 1000), eventId, // Don't use event form id
                    editEventFormInfo.fleetName, editEventFormInfo.robotName, editEventFormInfo.options.seriesId ? editEventFormInfo.seriesId : undefined, editEventFormInfo.dagId, [editEventFormInfo.zoneSequence[i]]);
                    updateSchedule['events'][eventId] = event_7;
                }
                console.log("Created event for clean zone " + JSON.stringify(event));
                // if not we just add the event id to the new dag without creating a new event
                if (i == 0) {
                    // this is the first event in the sequence we do not add any dependency
                    newDag[eventId] = [];
                    continue;
                }
                newDag[eventId] = [cleanZoneEventMap[editEventFormInfo.zoneSequence[i - 1]]];
                console.log("DAG " + JSON.stringify(newDag));
            }
            console.log("UPDATE " + JSON.stringify(updateScheduleInfo));
            updateSchedule['dependencies'] = (_a = {}, _a[editEventFormInfo.dagId] = newDag, _a);
            updateScheduleInfo['updateSchedule'] = updateSchedule;
            updateScheduleInfo['addSchedule'] = addSchedule;
        }
        else {
            console.log('Probably invalid options for task scheduler');
            return null;
        }
    }
    else {
        console.log('Updating entire series');
        if (!editEventFormInfo.reccurrenceInfo) {
            console.log('Cannot find reccurence information!');
            return null;
        }
        if (!editEventFormInfo.options.seriesId) {
            console.debug('No series ID provided in options');
            return null;
        }
        var oldSeries = currentSchedule.series
            ? currentSchedule.series[editEventFormInfo.options.seriesId]
            : null;
        if (!oldSeries) {
            console.debug('Old series not found in schedule');
        }
        // create dummy event
        var updateSeries = {
            cron: convertToCron(editEventFormInfo.startTime, editEventFormInfo.endTime, editEventFormInfo.reccurrenceInfo.reuccuranceEndDate
                ? editEventFormInfo.reccurrenceInfo.reuccuranceEndDate
                : new Date(), editEventFormInfo.reccurrenceInfo.dailyChecked, editEventFormInfo.reccurrenceInfo.weeklyChecked, editEventFormInfo.reccurrenceInfo.monthlyChecked, editEventFormInfo.reccurrenceInfo.reccuranceEndDateChecked, editEventFormInfo.reccurrenceInfo.daysCheckedArray),
            old_occ_time: currentSchedule.events[editEventFormInfo.options.eventId].start_time,
            new_occ_time: editEventFormInfo.startTime.getTime() / 1000,
            timezone: 'Asia/Singapore',
        };
        updateScheduleInfo['updateSeries'] = {
            updates: (_b = {},
                _b[editEventFormInfo.options.seriesId] = updateSeries,
                _b),
        };
    }
    updateScheduleInfo['updateSchedule'] = updateSchedule;
    return updateScheduleInfo;
};
export var convertToEditEventInfo = function (operation, options, currentSchedule) {
    var rmfEvent = options.eventId in currentSchedule.events ? currentSchedule.events[options.eventId] : null;
    if (!rmfEvent) {
        return null;
    }
    if (!editableCategories.includes(rmfEvent.type)) {
        return null;
    }
    var eventCategory;
    var startTime;
    var endTime;
    var estimatedEndTime;
    var fleetName;
    var robotName;
    // Common things to convert
    eventCategory = convertCategory(rmfEvent.type);
    startTime = convertToDateTime(rmfEvent.start_time);
    var durationUnixSec = rmfEvent.duration ? rmfEvent.duration : 1;
    endTime = convertToDateTime(rmfEvent.start_time + durationUnixSec);
    estimatedEndTime = endTime;
    fleetName = getFleetNameFromDetails(rmfEvent.event_details);
    robotName = getRobotNameFromDetails(rmfEvent.event_details);
    var editEventInfo = {
        operation: operation,
        options: options,
        eventCategory: eventCategory,
        startTime: startTime,
        endTime: endTime,
        estimatedEndTime: estimatedEndTime,
        fleetName: fleetName ? fleetName : '',
        robotName: robotName ? robotName : '',
        eventId: options.eventId,
    };
    if (options.dagId) {
        editEventInfo['dagId'] = options.dagId;
    }
    if (options.seriesId) {
        editEventInfo['seriesId'] = options.seriesId;
    }
    if (options.dagId && eventCategory == 'Cleaning Task') {
        var zoneSequence = convertToZoneSequence(options.dagId, currentSchedule);
        // In the case the zone sequence is not found we create an empty zone sequence
        zoneSequence = zoneSequence ? zoneSequence : Array();
        editEventInfo['zoneSequence'] = zoneSequence;
        var dagEndEvent = getDagEndEvent(options.dagId, currentSchedule);
        var dagStartEvent = getDagStartEvent(options.dagId, currentSchedule);
        if (dagStartEvent) {
            editEventInfo.startTime = convertToDateTime(dagStartEvent === null || dagStartEvent === void 0 ? void 0 : dagStartEvent.start_time);
        }
        if (dagEndEvent) {
            if (dagEndEvent.duration) {
                var eT = convertToDateTime(dagEndEvent.start_time + dagEndEvent.duration);
                editEventInfo.estimatedEndTime = eT;
                editEventInfo.endTime = eT;
            }
        }
    }
    // NOTE: We might want to consider validating the options
    if (options.editSingleEvent) {
        return editEventInfo;
    }
    else {
        // This event should be a series or a dag part of a series
        if (!options.seriesId) {
            return null;
        }
        if (!currentSchedule.series) {
            return null;
        }
        if (!(options.seriesId in currentSchedule.series)) {
            return null;
        }
        var reccurrenceInfo = convertCronToCheckReccurrenceInfo(currentSchedule.series[options.seriesId]);
        editEventInfo['reccurrenceInfo'] = reccurrenceInfo;
        return editEventInfo;
    }
};
var convertCronToCheckReccurrenceInfo = function (series) {
    var dailyChecked = false;
    var weeklyChecked = false;
    var monthlyChecked = false;
    var daysCheckedArray = Array(7).fill(false);
    // TODO: Create function to create reccurrence EndDate
    var reccuranceEndDateChecked = false;
    var interval = parser.parseExpression(series.cron);
    var fields = JSON.parse(JSON.stringify(interval.fields));
    if (fields.dayOfWeek.length >= 7 && fields.month.length === 12) {
        dailyChecked = true;
    }
    else if (fields.dayOfWeek.length < 7 && fields.month.length === 12) {
        weeklyChecked = true;
        for (var _i = 0, _a = fields.dayOfWeek; _i < _a.length; _i++) {
            var day = _a[_i];
            if (day === 7) {
                // 0 and 7 are equivalent to Sunday
                // But in the array Sunday is the first element
                // in the array
                day = 0;
            }
            daysCheckedArray[day] = true;
        }
    }
    else if (fields.dayOfMonth.length === 1) {
        monthlyChecked = true;
    }
    return {
        dailyChecked: dailyChecked,
        weeklyChecked: weeklyChecked,
        monthlyChecked: monthlyChecked,
        reccuranceEndDateChecked: reccuranceEndDateChecked,
        daysCheckedArray: daysCheckedArray,
    };
};
var convertToDateTime = function (unixTimeSecs) {
    return new Date(unixTimeSecs * 1000);
};
var convertCategory = function (schedulerCategory) {
    // In the case the event category in the front end does not match the backend
    // this function should map the event type on the backend to the front end category
    return schedulerCategory;
};
var getFleetNameFromDetails = function (details) {
    if (!('fleet' in details)) {
        return null;
    }
    return details['fleet'];
};
var getRobotNameFromDetails = function (details) {
    if (!('robot' in details)) {
        return null;
    }
    return details['robot'];
};
var getZoneFromDetails = function (details) {
    if (!('zone' in details)) {
        return null;
    }
    return details['zone'];
};
var convertToZoneSequence = function (dagId, currentSchedule) {
    var zoneSequenceEventIds = getDagEvents(dagId, currentSchedule);
    var zoneSequence = Array();
    if (!zoneSequenceEventIds) {
        return null;
    }
    for (var _i = 0, zoneSequenceEventIds_1 = zoneSequenceEventIds; _i < zoneSequenceEventIds_1.length; _i++) {
        var eventId = zoneSequenceEventIds_1[_i];
        if (!(eventId in currentSchedule.events)) {
            return null;
        }
        var eventDetails = currentSchedule.events[eventId].event_details;
        var cleanZone = getZoneFromDetails(eventDetails);
        if (!cleanZone) {
            return null;
        }
        zoneSequence.push(cleanZone);
    }
    return zoneSequence;
};
var getZoneSequenceEventMap = function (dagId, currentSchedule) {
    var zoneSequenceEventIds = getDagEvents(dagId, currentSchedule);
    var zoneSequenceEventMap = {};
    if (!zoneSequenceEventIds) {
        return null;
    }
    for (var _i = 0, zoneSequenceEventIds_2 = zoneSequenceEventIds; _i < zoneSequenceEventIds_2.length; _i++) {
        var eventId = zoneSequenceEventIds_2[_i];
        if (!(eventId in currentSchedule.events)) {
            return null;
        }
        var eventDetails = currentSchedule.events[eventId].event_details;
        var cleanZone = getZoneFromDetails(eventDetails);
        if (!cleanZone) {
            return null;
        }
        zoneSequenceEventMap[cleanZone] = eventId;
    }
    return zoneSequenceEventMap;
};
var getDagStartNodeId = function (dagId, currentSchedule) {
    if (!currentSchedule.dependencies) {
        return null;
    }
    if (!(dagId in currentSchedule.dependencies)) {
        return null;
    }
    var entryNode;
    var dag = currentSchedule.dependencies[dagId];
    for (var eventId in dag) {
        if (!Array.isArray(dag[eventId])) {
            return null;
        }
        if (dag[eventId].length == 0) {
            entryNode = eventId;
            // NOTE: This assumes that there is only one entry node.
            break;
        }
    }
    if (!entryNode) {
        console.debug('No entry node found');
        return null;
    }
    return entryNode;
};
var getDagStartEvent = function (dagId, currentSchedule) {
    if (!currentSchedule.dependencies) {
        return null;
    }
    if (!(dagId in currentSchedule.dependencies)) {
        return null;
    }
    var nodeEvents = Array();
    // add all the events in the dag in the node Event list in order
    var entryNode = getDagStartNodeId(dagId, currentSchedule);
    if (!entryNode) {
        console.debug('No entry node found');
        return null;
    }
    return currentSchedule.events[entryNode];
};
var getDagEndNode = function (dagId, currentSchedule) {
    var entryNode = getDagStartNodeId(dagId, currentSchedule);
    if (!entryNode) {
        return null;
    }
    var nodeOfInterest = entryNode;
    var dag = currentSchedule.dependencies ? currentSchedule.dependencies[dagId] : null;
    if (!dag) {
        return null;
    }
    var end = false;
    while (!end) {
        end = true;
        // if nothing is found in this for loop it will exit
        for (var node in dag) {
            console.log(node);
            if (nodeOfInterest === node) {
                continue;
            }
            if (dag[node].includes(nodeOfInterest)) {
                nodeOfInterest = node;
                end = false;
                break;
            }
        }
    }
    return nodeOfInterest;
};
var getDagEndEvent = function (dagId, currentSchedule) {
    var endNode = getDagEndNode(dagId, currentSchedule);
    if (!endNode) {
        return null;
    }
    return currentSchedule.events[endNode];
};
var getDagEvents = function (dagId, currentSchedule) {
    if (!currentSchedule.dependencies) {
        return null;
    }
    if (!(dagId in currentSchedule.dependencies)) {
        return null;
    }
    var nodeEvents = Array();
    // add all the events in the dag in the node Event list in order
    var entryNode;
    // NOTE: We are making change to the dag here so we need to make a copy
    var dag = JSON.parse(JSON.stringify(currentSchedule.dependencies[dagId]));
    for (var eventId in dag) {
        if (!Array.isArray(dag[eventId])) {
            return null;
        }
        if (dag[eventId].length == 0) {
            entryNode = eventId;
            // NOTE: This assumes that there is only one entry node.
            break;
        }
    }
    if (!entryNode) {
        console.debug('No entry node found');
        return null;
    }
    var nodeOfInterest = entryNode;
    nodeEvents.push(nodeOfInterest);
    // Assumes that the each non-entry node has only one dependency
    // the remaining item should be the entry node
    var run = true;
    while (run) {
        run = false;
        for (var eventId in dag) {
            if (dag[eventId].includes(nodeOfInterest)) {
                nodeOfInterest = eventId;
                nodeEvents.push(nodeOfInterest);
                // We found something so we continue running
                delete dag[eventId];
                run = true;
                break;
            }
        }
    }
    console.log("NODE EVENTS: " + JSON.stringify(nodeEvents));
    return nodeEvents.length > 0 ? nodeEvents : null;
};
