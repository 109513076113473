import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
} from '@mui/material';

interface FormState {
  cleanFilterBag: boolean;
  cleanPads: boolean;
  cleanSqueegee: boolean;
  emptyDirtyWater: boolean;
  rinseWaterTank: boolean;
  topUpCleanWater: boolean;
  wipeSensors: boolean;
  wipeBot: boolean;
}

interface CenobotL50MaintenanceFormProps {
  onSubmit: () => void;
}

export function CenobotL50MaintenanceForm({ onSubmit }: CenobotL50MaintenanceFormProps) {
  const [formState, setFormState] = useState<FormState>({
    cleanFilterBag: false,
    cleanPads: false,
    cleanSqueegee: false,
    emptyDirtyWater: false,
    rinseWaterTank: false,
    topUpCleanWater: false,
    wipeSensors: false,
    wipeBot: false,
  });

  const [formValid, setFormValid] = useState<boolean>(false);

  // Validation Logic
  useEffect(() => {
    // Check if all checkboxes are checked
    const isFormValid = Object.values(formState).every((value) => value);
    setFormValid(isFormValid);
  }, [formState]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <div>
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="cleanFilterBag"
                checked={formState.cleanFilterBag}
                onChange={handleCheckboxChange}
              />
            }
            label="Clean Filter Bag"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="cleanPads"
                checked={formState.cleanPads}
                onChange={handleCheckboxChange}
              />
            }
            label="Clean Disc Pads"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="cleanSqueegee"
                checked={formState.cleanSqueegee}
                onChange={handleCheckboxChange}
              />
            }
            label="Clean Squeegee"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="emptyDirtyWater"
                checked={formState.emptyDirtyWater}
                onChange={handleCheckboxChange}
              />
            }
            label="Empty Dirty Water Tank"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="rinseWaterTank"
                checked={formState.rinseWaterTank}
                onChange={handleCheckboxChange}
              />
            }
            label="Rinse Water Tank"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="topUpCleanWater"
                checked={formState.topUpCleanWater}
                onChange={handleCheckboxChange}
              />
            }
            label="Top Up Clean Water"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="wipeSensors"
                checked={formState.wipeSensors}
                onChange={handleCheckboxChange}
              />
            }
            label="Wipe Down Sensors (With Dry and Clean Glass Cleaning Cloth)"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="wipeBot"
                checked={formState.wipeBot}
                onChange={handleCheckboxChange}
              />
            }
            label="Wipe Down Exterior of Cenobot L50"
          />
          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            onClick={onSubmit}
            disabled={!formValid} // Disable the button if the form is not valid
          >
            Submit
          </Button>
        </FormGroup>
        {!formValid && (
          <FormHelperText error>You must complete all steps before submission!</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}
