"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Time = void 0;
var Time = /** @class */ (function () {
    function Time(fields) {
        if (fields === void 0) { fields = {}; }
        this.sec = fields.sec || 0;
        this.nanosec = fields.nanosec || 0;
    }
    Time.validate = function (obj) {
        if (typeof obj['sec'] !== 'number') {
            throw new Error('expected "sec" to be "number"');
        }
        if (typeof obj['nanosec'] !== 'number') {
            throw new Error('expected "nanosec" to be "number"');
        }
    };
    Time.FullTypeName = 'builtin_interfaces/msg/Time';
    return Time;
}());
exports.Time = Time;
/*
# Time indicates a specific point in time, relative to a clock's 0 point.

# The seconds component, valid over all int32 values.
int32 sec

# The nanoseconds component, valid in the range [0, 10e9).
uint32 nanosec

*/
