var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AppBar, styled } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
var classes = {
    root: 'header-bar-root',
};
var StyledAppBar = styled(function (props) { return React.createElement(AppBar, __assign({}, props)); })(function () {
    var _a;
    return (_a = {},
        _a["&." + classes.root] = {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        _a);
});
export var HeaderBar = function (_a) {
    var _b = _a.id, id = _b === void 0 ? 'appbar' : _b, _c = _a.position, position = _c === void 0 ? 'static' : _c, className = _a.className, children = _a.children;
    return (React.createElement(StyledAppBar, { id: id, position: position, className: clsx(classes.root, className) }, children));
};
