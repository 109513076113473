import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
} from '@mui/material';

interface FormState {
  cleanGutterBrushes: boolean;
  cleanRollerBrushes: boolean;
  clearHopper: boolean;
  clearDustBins: boolean;
  wipeSensors: boolean;
  wipeBot: boolean;
}

interface GlobotixFlexaMaintenanceFormProps {
  onSubmit: () => void;
}

export function GlobotixFlexaMaintenanceForm({ onSubmit }: GlobotixFlexaMaintenanceFormProps) {
  const [formState, setFormState] = useState<FormState>({
    cleanGutterBrushes: false,
    cleanRollerBrushes: false,
    clearHopper: false,
    clearDustBins: false,
    wipeSensors: false,
    wipeBot: false,
  });

  const [formValid, setFormValid] = useState<boolean>(false);

  // Validation Logic
  useEffect(() => {
    // Check if all checkboxes are checked
    const isFormValid = Object.values(formState).every((value) => value);
    setFormValid(isFormValid);
  }, [formState]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <div>
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="cleanGutterBrushes"
                checked={formState.cleanGutterBrushes}
                onChange={handleCheckboxChange}
              />
            }
            label="Clean Gutter Brushes"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="cleanRollerBrushes"
                checked={formState.cleanRollerBrushes}
                onChange={handleCheckboxChange}
              />
            }
            label="Clean Roller Brushes"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="clearHopper"
                checked={formState.clearHopper}
                onChange={handleCheckboxChange}
              />
            }
            label="Clear Hopper"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="clearDustBins"
                checked={formState.clearDustBins}
                onChange={handleCheckboxChange}
              />
            }
            label="Clear Dust Bins"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="wipeSensors"
                checked={formState.wipeSensors}
                onChange={handleCheckboxChange}
              />
            }
            label="Wipe Down Sensors (With Dry and Clean Glass Cleaning Cloth)"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="wipeBot"
                checked={formState.wipeBot}
                onChange={handleCheckboxChange}
              />
            }
            label="Wipe Down Exterior of FLEXA"
          />
          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            onClick={onSubmit}
            disabled={!formValid} // Disable the button if the form is not valid
          >
            Submit
          </Button>
        </FormGroup>
        {!formValid && (
          <FormHelperText error>You must complete all steps before submission!</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}
