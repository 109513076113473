var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Typography, styled } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
var classes = {
    status: 'status-label-root',
    unknown: 'status-label-unknown',
};
var StyledDiv = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&." + classes.status] = {
            borderColor: theme.palette.primary.main,
            borderRadius: theme.shape.borderRadius,
            borderStyle: 'solid',
            borderWidth: '2px',
            padding: '5px',
            width: '4rem',
            textAlign: 'center',
            flexShrink: 0,
        },
        _b["&." + classes.unknown] = {
            borderColor: theme.palette.grey[500],
        },
        _b);
});
export var StatusLabel = function (props) {
    var _a = props.text, text = _a === void 0 ? '' : _a, className = props.className, _b = props.variant, variant = _b === void 0 ? 'normal' : _b, otherProps = __rest(props, ["text", "className", "variant"]);
    return (React.createElement(StyledDiv, __assign({ className: clsx(classes.status, className, variant === 'unknown' ? classes.unknown : undefined) }, otherProps),
        React.createElement(Typography, { variant: "button", role: "status" }, variant === 'unknown' ? 'N/A' : text)));
};
