var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Box, Button, LinearProgress, TextField, Typography, Divider, } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles, createStyles } from '@mui/styles';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        textField: {
            background: theme.palette.background.default,
            pointerEvents: 'none',
        },
    });
});
export var CloseAlertDialog = React.memo(function (props) {
    var title = props.title;
    return React.createElement(Dialog, { key: title, open: false });
});
export var AlertDialog = React.memo(function (props) {
    var LinearProgressWithLabel = function (props) {
        return (React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
            React.createElement(Box, { sx: { width: '100%', mr: 1 } },
                React.createElement(LinearProgress, __assign({ variant: "determinate" }, props, { value: props.value * 100 }))),
            React.createElement(Box, { sx: { minWidth: 35 } },
                React.createElement(Typography, { variant: "body2", color: "text.secondary" }, Math.round(props.value * 100) + "%"))));
    };
    var returnDialogContent = function (alertContents) {
        return (React.createElement(React.Fragment, null, alertContents.map(function (message, index) { return (React.createElement("div", { key: index },
            React.createElement(TextField, { label: message.title, id: "standard-size-small", size: "small", variant: "filled", InputProps: { readOnly: true, className: classes.textField }, fullWidth: true, multiline: true, maxRows: 4, margin: "dense", value: message.value }))); })));
    };
    var onDismiss = props.onDismiss, onAcknowledge = props.onAcknowledge, onInspect = props.onInspect, acknowledgedBy = props.acknowledgedBy, title = props.title, progress = props.progress, alertContents = props.alertContents, backgroundColor = props.backgroundColor;
    var classes = useStyles();
    var _a = React.useState(true), isOpen = _a[0], setIsOpen = _a[1];
    var _b = React.useState(acknowledgedBy !== undefined), acknowledged = _b[0], setAcknowledged = _b[1];
    return (React.createElement(Dialog, { PaperProps: {
            style: {
                backgroundColor: backgroundColor,
                boxShadow: 'none',
            },
        }, maxWidth: "sm", fullWidth: true, open: isOpen, key: title },
        React.createElement(DialogTitle, { align: "center" }, title),
        React.createElement(Divider, null),
        progress ? (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "body2", fontWeight: "bold", ml: 3, mt: 1 }, "Task progress"),
            React.createElement(Box, { width: 0.95, ml: 3 },
                React.createElement(LinearProgressWithLabel, { value: progress })))) : null,
        React.createElement(DialogContent, null, returnDialogContent(alertContents)),
        React.createElement(DialogActions, null,
            onInspect ? (React.createElement(Button, { size: "small", variant: "contained", onClick: onInspect, disabled: false, autoFocus: true }, "Inspect")) : null,
            acknowledged || onAcknowledge === undefined ? (React.createElement(Button, { size: "small", variant: "contained", disabled: true, autoFocus: true }, acknowledgedBy ? "Acknowledged by " + acknowledgedBy : 'Acknowledged')) : (React.createElement(Button, { size: "small", variant: "contained", onClick: function () {
                    setAcknowledged(true);
                    onAcknowledge();
                }, disabled: false, autoFocus: true }, "Acknowledge")),
            React.createElement(Button, { size: "small", variant: "contained", onClick: function () {
                    setIsOpen(false);
                    onDismiss();
                }, autoFocus: true }, acknowledged ? 'Close' : 'Dismiss'))));
});
