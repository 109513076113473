var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { default as GridLayout_, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { WindowManagerStateContext } from './context';
var GridLayout = WidthProvider(GridLayout_);
// TODO: mui 5 switched theme spacing to string, instead of converting css units to px, just use
// a fixed margin for now.
var MARGIN = 10;
/**
 * A resizable and draggable grid layout based on react-grid-layout. By default, the layout
 * follows material-ui convention of 12 columns and rows.
 */
export var WindowContainer = function (_a) {
    var layout = _a.layout, _b = _a.cols, cols = _b === void 0 ? 12 : _b, _c = _a.rows, rows = _c === void 0 ? 12 : _c, _d = _a.designMode, designMode = _d === void 0 ? false : _d, onLayoutChange = _a.onLayoutChange, children = _a.children, style = _a.style, otherProps = __rest(_a, ["layout", "cols", "rows", "designMode", "onLayoutChange", "children", "style"]);
    var windowManagerState = React.useMemo(function () { return ({
        designMode: designMode !== null && designMode !== void 0 ? designMode : false,
    }); }, [designMode]);
    var containerRef = React.useRef(null);
    var _e = React.useState(null), rowHeight = _e[0], setRowHeight = _e[1];
    React.useEffect(function () {
        if (!containerRef.current)
            return;
        var resizeObserver = new ResizeObserver(function (entries) {
            var contentRect = entries[0].contentRect;
            setRowHeight((contentRect.height - MARGIN * 13) / rows);
        });
        resizeObserver.observe(containerRef.current);
        return function () {
            resizeObserver.disconnect();
        };
    }, [rows]);
    return (React.createElement(WindowManagerStateContext.Provider, { value: windowManagerState },
        React.createElement("div", __assign({ ref: containerRef, style: __assign({ overflow: 'auto', height: '100%', maxHeight: '100%' }, style) }, otherProps), rowHeight !== null && (React.createElement(GridLayout, { layout: layout, margin: [MARGIN, MARGIN], cols: cols, compactType: null, preventCollision: true, autoSize: false, maxRows: rows, rowHeight: rowHeight, isResizable: designMode, isDraggable: designMode, useCSSTransforms: false, draggableHandle: ".rgl-draggable", onLayoutChange: onLayoutChange }, children)))));
};
