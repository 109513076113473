import { Button, Divider, Grid, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CircularProgressBar } from './circular-progress-bar';
import { LinearProgressBar } from './linear-progress-bar';
function getTaskStatusDisplay(assignedTask, taskStatus) {
    if (assignedTask && !taskStatus) {
        return 'Unknown';
    }
    if (assignedTask && taskStatus) {
        return taskStatus;
    }
    else {
        return 'No Task';
    }
}
var classes = {
    button: 'robot-info-button',
};
var StyledDiv = styled('div')(function () {
    var _a;
    return (_a = {},
        _a["& ." + classes.button] = {
            '&:hover': {
                background: 'none',
                cursor: 'default',
            },
        },
        _a);
});
var finishedStatus = ['failed', 'completed', 'skipped', 'killed', 'canceled'];
export function RobotInfo(_a) {
    var robotName = _a.robotName, battery = _a.battery, assignedTask = _a.assignedTask, taskStatus = _a.taskStatus, taskProgress = _a.taskProgress, estFinishTime = _a.estFinishTime;
    var theme = useTheme();
    var hasConcreteEndTime = taskStatus && taskStatus in finishedStatus;
    return (React.createElement(StyledDiv, null,
        React.createElement(Typography, { variant: "h6", style: { textAlign: 'center' }, gutterBottom: true }, robotName),
        React.createElement(Divider, null),
        React.createElement("div", { style: { marginBottom: theme.spacing(1) } }),
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center" },
                React.createElement(Typography, { variant: "h6", gutterBottom: true, sx: { textTransform: 'capitalize' } }, "Task Progress - " + getTaskStatusDisplay(assignedTask, taskStatus))),
            React.createElement(Grid, { item: true, xs: 12 }, taskProgress && React.createElement(LinearProgressBar, { value: taskProgress * 100 })),
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center" },
                React.createElement(Typography, { variant: "h6", gutterBottom: true }, "Assigned Tasks")),
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center" },
                React.createElement(Button, { disableElevation: true, variant: "outlined", className: classes.button, disableRipple: true, component: "div" }, assignedTask || '-')),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "h6", align: "left" }, "Battery")),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "h6", align: "left" },
                    React.createElement("span", null,
                        !hasConcreteEndTime && 'Est. ',
                        "Finish Time"))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(CircularProgressBar, { progress: battery ? +(battery * 100).toFixed(2) : 0, strokeColor: "#20a39e" },
                    React.createElement(Typography, { variant: "h6" }, (battery ? (battery * 100).toFixed(2) : 0) + "%"))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Button, { size: "small", disableElevation: true, variant: "outlined", className: classes.button, disableRipple: true }, estFinishTime !== undefined ? "" + new Date(estFinishTime).toLocaleString() : '-')))));
}
