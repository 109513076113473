"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DispatchAck = void 0;
var DispatchRequest_1 = require("../../rmf_task_msgs/msg/DispatchRequest");
var DispatchAck = /** @class */ (function () {
    function DispatchAck(fields) {
        if (fields === void 0) { fields = {}; }
        this.dispatch_request = fields.dispatch_request || new DispatchRequest_1.DispatchRequest();
        this.success = fields.success || false;
    }
    DispatchAck.validate = function (obj) {
        try {
            DispatchRequest_1.DispatchRequest.validate(obj['dispatch_request']);
        }
        catch (e) {
            throw new Error('in "dispatch_request":\n  ' + e.message);
        }
        if (typeof obj['success'] !== 'boolean') {
            throw new Error('expected "success" to be "boolean"');
        }
    };
    DispatchAck.FullTypeName = 'rmf_task_msgs/msg/DispatchAck';
    return DispatchAck;
}());
exports.DispatchAck = DispatchAck;
/*
# This message is published by the fleet adapter in response to a
# DispatchRequest message. It indicates whether the requested task addition or
# cancellation was successful.

# The DispatchRequest message received by the Fleet Adapter
DispatchRequest dispatch_request

# True if the addition or cancellation operation was successful
bool success

*/
