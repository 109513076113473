var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled, useTheme } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { EntityManagerContext } from './entity-manager';
import { useAutoScale } from './hooks';
var DefaultRepositionThreshold = 200;
export function LabelContainer(props) {
    var theme = useTheme();
    var sourceX = props.sourceX, sourceY = props.sourceY, sourceRadius = props.sourceRadius, contentWidth = props.contentWidth, contentHeight = props.contentHeight, 
    // theme.spacing default value = 8
    _a = props.contentPadding, 
    // theme.spacing default value = 8
    contentPadding = _a === void 0 ? 8 : _a, 
    // theme.shape.borderRadius default = 4
    _b = props.contentBorderRadius, 
    // theme.shape.borderRadius default = 4
    contentBorderRadius = _b === void 0 ? 2 : _b, _c = props.arrowLength, preferredArrowLength = _c === void 0 ? 8 : _c, _d = props.angle, angle = _d === void 0 ? -30 : _d, _e = props.repositionThreshold, repositionThreshold = _e === void 0 ? DefaultRepositionThreshold : _e, children = props.children, otherProps = __rest(props, ["sourceX", "sourceY", "sourceRadius", "contentWidth", "contentHeight", "contentPadding", "contentBorderRadius", "arrowLength", "angle", "repositionThreshold", "children"]);
    var entityManager = React.useContext(EntityManagerContext);
    var contentWidthOuter = contentWidth + contentPadding * 2;
    var contentHeightOuter = contentHeight + contentPadding * 2;
    var preferredLocation = React.useMemo(function () {
        var normalizedAngle = (function () {
            var cur = angle;
            while (cur > 180 || cur < -180) {
                if (cur > 180) {
                    cur -= 180;
                }
                else {
                    cur += 180;
                }
            }
            return cur;
        })();
        var theta = (normalizedAngle * Math.PI) / 180;
        var anchorX1 = sourceX + Math.cos(theta) * sourceRadius;
        var anchorY1 = sourceY + Math.sin(theta) * sourceRadius;
        var anchorX2 = anchorX1 + Math.cos(theta) * preferredArrowLength;
        var anchorY2 = anchorY1 + Math.sin(theta) * preferredArrowLength;
        var rectStart = (function () {
            if (theta >= 0 && theta < Math.PI / 2) {
                return [anchorX2 - contentBorderRadius, anchorY2];
            }
            else if (theta >= Math.PI / 2 && theta < Math.PI) {
                return [anchorX2 - contentWidthOuter + contentBorderRadius, anchorY2];
            }
            else if (theta >= -Math.PI && theta < -Math.PI / 2) {
                return [anchorX2 - contentWidthOuter + contentBorderRadius, anchorY2 - contentHeightOuter];
            }
            else {
                return [anchorX2 - contentBorderRadius, anchorY2 - contentHeightOuter];
            }
        })();
        return {
            anchorX1: anchorX1,
            anchorX2: anchorX2,
            anchorY1: anchorY1,
            anchorY2: anchorY2,
            borderBBox: {
                minX: rectStart[0],
                minY: rectStart[1],
                maxX: rectStart[0] + contentWidthOuter,
                maxY: rectStart[1] + contentHeightOuter,
            },
        };
    }, [
        angle,
        contentBorderRadius,
        contentWidthOuter,
        contentHeightOuter,
        preferredArrowLength,
        sourceRadius,
        sourceX,
        sourceY,
    ]);
    var _f = React.useState(null), labelLocation = _f[0], setLabelLocation = _f[1];
    React.useLayoutEffect(function () {
        var nonColliding = entityManager.getNonColliding(preferredLocation.borderBBox, {
            distLimit: repositionThreshold,
        });
        if (!nonColliding)
            return;
        var entity = entityManager.add({ bbox: nonColliding });
        if (nonColliding !== preferredLocation.borderBBox) {
            var width = nonColliding.maxX - nonColliding.minX;
            var height = nonColliding.maxY - nonColliding.minY;
            var centerX = nonColliding.minX + width / 2;
            var centerY = nonColliding.minY + height / 2;
            var theta_1 = Math.atan2(centerY - sourceY, centerX - sourceX);
            var anchorX1 = sourceX + Math.cos(theta_1) * sourceRadius;
            var anchorY1 = sourceY + Math.sin(theta_1) * sourceRadius;
            var anchorX2 = (function () {
                if (theta_1 >= -Math.PI / 2 && theta_1 < Math.PI / 2) {
                    return nonColliding.minX + contentBorderRadius;
                }
                else {
                    return nonColliding.maxX - contentBorderRadius;
                }
            })();
            var anchorY2 = (function () {
                if (theta_1 >= 0 && theta_1 < Math.PI) {
                    return nonColliding.minY;
                }
                else {
                    return nonColliding.maxY;
                }
            })();
            setLabelLocation({
                anchorX1: anchorX1,
                anchorY1: anchorY1,
                anchorX2: anchorX2,
                anchorY2: anchorY2,
                borderBBox: nonColliding,
            });
        }
        else {
            setLabelLocation(preferredLocation);
        }
        return function () {
            entityManager.remove(entity);
        };
    }, [
        entityManager,
        preferredLocation,
        contentBorderRadius,
        sourceRadius,
        sourceX,
        sourceY,
        repositionThreshold,
    ]);
    return (labelLocation && (React.createElement("g", __assign({}, otherProps),
        React.createElement("line", { x1: labelLocation.anchorX1, y1: labelLocation.anchorY1, x2: labelLocation.anchorX2, y2: labelLocation.anchorY2 }),
        React.createElement("rect", { x: labelLocation.borderBBox.minX, y: labelLocation.borderBBox.minY, width: labelLocation.borderBBox.maxX - labelLocation.borderBBox.minX, height: labelLocation.borderBBox.maxY - labelLocation.borderBBox.minY, rx: contentBorderRadius, ry: contentBorderRadius, fill: theme.palette.background.paper }),
        React.createElement("g", { transform: "translate(" + (labelLocation.borderBBox.minX + contentPadding) + " " + (labelLocation.borderBBox.minY + contentPadding) + ")" }, children))));
}
var classes = {
    container: 'name-label-container',
};
var StyledLabelContainer = styled(function (props) { return React.createElement(LabelContainer, __assign({}, props)); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&." + classes.container] = {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily,
            userSelect: 'none',
        },
        _b);
});
var Text = React.forwardRef(function (_a, ref) {
    var children = _a.children, otherProps = __rest(_a, ["children"]);
    return (React.createElement("text", __assign({ ref: ref, x: 0, y: 0, strokeWidth: 0, dominantBaseline: "middle", textAnchor: "middle" }, otherProps), children));
});
export function NameLabel(props) {
    var theme = useTheme();
    var text = props.text, _a = props.contentPadding, contentPadding = _a === void 0 ? 4 : _a, className = props.className, otherProps = __rest(props, ["text", "contentPadding", "className"]);
    var _b = React.useState(0), contentWidth = _b[0], setContentWidth = _b[1];
    var _c = React.useState(0), contentHeight = _c[0], setContentHeight = _c[1];
    var _d = React.useState(false), show = _d[0], setShow = _d[1];
    var textRef = React.useRef(null);
    React.useEffect(function () {
        var updateContentSize = function () {
            if (!textRef.current)
                return;
            var bbox = textRef.current.getBBox();
            // sometimes the element is not rendered yet, and the bbox is not available.
            if (bbox.width === 0) {
                requestAnimationFrame(function () {
                    updateContentSize();
                });
            }
            else {
                setContentWidth(bbox.width + 16);
                setContentHeight(bbox.height);
                setShow(true);
            }
        };
        updateContentSize();
    }, [theme]);
    return (React.createElement(React.Fragment, null,
        !show && (React.createElement(Text, { ref: textRef, style: { visibility: 'hidden' } }, text)),
        show && (React.createElement(StyledLabelContainer, __assign({ contentWidth: contentWidth, contentHeight: contentHeight, contentPadding: contentPadding, className: clsx(classes.container, className), stroke: theme.palette.info.main, strokeWidth: 1 }, otherProps),
            React.createElement(Text, { fill: theme.palette.info.main, transform: "translate(" + contentWidth / 2 + "," + contentHeight / 2 + ")" }, text)))));
}
export function withAutoScaling(LabelComponent) {
    return function (_a) {
        var sourceX = _a.sourceX, sourceY = _a.sourceY, sourceRadius = _a.sourceRadius, arrowLength = _a.arrowLength, _b = _a.repositionThreshold, repositionThreshold = _b === void 0 ? 5 : _b, transform = _a.transform, otherProps = __rest(_a, ["sourceX", "sourceY", "sourceRadius", "arrowLength", "repositionThreshold", "transform"]);
        var scale = useAutoScale(1.2, Infinity);
        // getBBox returns the bbox BEFORE transform. Not pre-scaling the source x, y will cause the
        // collision detection to think the bbox is different size than what it actually is on screen.
        return (React.createElement(LabelComponent, __assign({}, otherProps, { sourceX: sourceX / scale, sourceY: sourceY / scale, sourceRadius: sourceRadius / scale, arrowLength: arrowLength ? arrowLength / scale : undefined, repositionThreshold: repositionThreshold / scale, transform: clsx(transform, "scale(" + scale + ")") })));
    };
}
export var ScaledNameLabel = withAutoScaling(NameLabel);
