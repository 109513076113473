var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { styled, TableCell } from '@mui/material';
var commonStyles = {
    overflow: 'hidden',
    height: 31,
};
var prefix = 'item-table-cell';
export var useFixedTableCellStylesClasses = {
    fixedTableCell: prefix + "-fix-cell",
    fixedLastTableCell: prefix + "-last-fix-cell",
};
export var ItemTableCell = styled(function (props) { return React.createElement(TableCell, __assign({}, props)); })(function () {
    var _a;
    return (_a = {},
        _a["&." + useFixedTableCellStylesClasses.fixedTableCell] = __assign({ flex: '1 0 0' }, commonStyles),
        _a["&." + useFixedTableCellStylesClasses.fixedLastTableCell] = __assign({ flex: '1.5 0 0' }, commonStyles),
        _a);
});
