import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Typography, Grid, styled } from '@mui/material';
var classes = {
    errorIcon: 'erroroverlay-error-icon',
    errorMsg: 'erroroverlay-error-msg',
    errorDisabled: 'erroroverlay-error-disabled',
    overlay: 'erroroverlay-overlay',
    container: 'erroroverlay-container',
    disableSelect: 'erroroverlay-disable-select',
};
var StyledDiv = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& ." + classes.errorIcon] = {
            color: theme.palette.error.main,
            fontSize: '2rem',
        },
        _b["& ." + classes.errorMsg] = {
            margin: '0.5rem',
        },
        _b["& ." + classes.errorDisabled] = {
            pointerEvents: 'none',
            filter: 'blur(.25rem)',
            gridArea: '1 / 1',
            opacity: 0.6,
        },
        _b["& ." + classes.overlay] = {
            gridArea: '1 / 1',
            backdropFilter: 'blur(.5rem)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        _b["&." + classes.container] = {
            display: 'grid',
        },
        _b["& ." + classes.disableSelect] = {
            userSelect: 'none',
        },
        _b);
});
export var ErrorOverlay = React.memo(function (props) {
    var errorMsg = props.errorMsg, children = props.children, overrideErrorStyle = props.overrideErrorStyle;
    return errorMsg ? (React.createElement(StyledDiv, { className: classes.container },
        React.createElement("div", { className: classes.errorDisabled }, children),
        React.createElement("div", { className: children ? classes.overlay + " " + classes.disableSelect : classes.disableSelect },
            React.createElement("div", null,
                React.createElement(Grid, { container: true, direction: "row", justifyContent: "center", alignItems: "center", spacing: 2 },
                    React.createElement(Grid, { item: true },
                        React.createElement(ErrorIcon, { className: classes.errorIcon })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { color: "error", variant: "h4", align: "center" }, "Error"))),
                React.createElement(Typography, { className: overrideErrorStyle ? overrideErrorStyle : classes.errorMsg, color: "error", variant: "h6", align: "center" }, errorMsg))))) : (React.createElement(React.Fragment, null, children));
});
