"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviveTask = void 0;
var ReviveTask_Request_1 = require("./ReviveTask_Request");
var ReviveTask_Response_1 = require("./ReviveTask_Response");
var ReviveTask = /** @class */ (function () {
    function ReviveTask() {
    }
    ReviveTask.FullServiceName = 'rmf_task_msgs/srv/ReviveTask';
    ReviveTask.Request = ReviveTask_Request_1.ReviveTask_Request;
    ReviveTask.Response = ReviveTask_Response_1.ReviveTask_Response;
    return ReviveTask;
}());
exports.ReviveTask = ReviveTask;
