import { Door as RmfDoor, DoorMode as RmfDoorMode } from 'rmf-models';
export var DoorType;
(function (DoorType) {
    DoorType[DoorType["SingleSwing"] = RmfDoor.DOOR_TYPE_SINGLE_SWING] = "SingleSwing";
    DoorType[DoorType["SingleSliding"] = RmfDoor.DOOR_TYPE_SINGLE_SLIDING] = "SingleSliding";
    DoorType[DoorType["SingleTelescope"] = RmfDoor.DOOR_TYPE_SINGLE_TELESCOPE] = "SingleTelescope";
    DoorType[DoorType["DoubleSwing"] = RmfDoor.DOOR_TYPE_DOUBLE_SWING] = "DoubleSwing";
    DoorType[DoorType["DoubleSliding"] = RmfDoor.DOOR_TYPE_DOUBLE_SLIDING] = "DoubleSliding";
    DoorType[DoorType["DoubleTelescope"] = RmfDoor.DOOR_TYPE_DOUBLE_TELESCOPE] = "DoubleTelescope";
})(DoorType || (DoorType = {}));
export var DoorMotion;
(function (DoorMotion) {
    DoorMotion[DoorMotion["Clockwise"] = 1] = "Clockwise";
    DoorMotion[DoorMotion["AntiClockwise"] = -1] = "AntiClockwise";
})(DoorMotion || (DoorMotion = {}));
export var DoorMode;
(function (DoorMode) {
    DoorMode[DoorMode["Open"] = RmfDoorMode.MODE_OPEN] = "Open";
    DoorMode[DoorMode["Closed"] = RmfDoorMode.MODE_CLOSED] = "Closed";
    DoorMode[DoorMode["Moving"] = RmfDoorMode.MODE_MOVING] = "Moving";
})(DoorMode || (DoorMode = {}));
export function doorModeToString(doorMode) {
    if (doorMode === undefined) {
        return 'N/A';
    }
    switch (doorMode) {
        case RmfDoorMode.MODE_OPEN:
            return 'OPEN';
        case RmfDoorMode.MODE_CLOSED:
            return 'CLOSED';
        case RmfDoorMode.MODE_MOVING:
            return 'MOVING';
        default:
            return 'UNKNOWN';
    }
}
export function doorTypeToString(doorType) {
    switch (doorType) {
        case RmfDoor.DOOR_TYPE_DOUBLE_SLIDING:
            return 'Double Sliding';
        case RmfDoor.DOOR_TYPE_DOUBLE_SWING:
            return 'Double Swing';
        case RmfDoor.DOOR_TYPE_DOUBLE_TELESCOPE:
            return 'Double Telescope';
        case RmfDoor.DOOR_TYPE_SINGLE_SLIDING:
            return 'Single Sliding';
        case RmfDoor.DOOR_TYPE_SINGLE_SWING:
            return 'Single Swing';
        case RmfDoor.DOOR_TYPE_SINGLE_TELESCOPE:
            return 'Single Telescope';
        default:
            return "Unknown (" + doorType + ")";
    }
}
