var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Table, TableBody, TableCell, TableHead, TableRow, useTheme, } from '@mui/material';
import React from 'react';
var RobotRow = React.memo(function (_a) {
    var fleet = _a.fleet, name = _a.name, status = _a.status, _b = _a.battery, battery = _b === void 0 ? 0 : _b, estFinishTime = _a.estFinishTime, lastUpdateTime = _a.lastUpdateTime, onClick = _a.onClick;
    var theme = useTheme();
    var robotStatusClass = React.useMemo(function () {
        if (!status) {
            return {};
        }
        switch (status) {
            case 'error':
                return {
                    backgroundColor: theme.palette.error.main,
                };
            case 'charging':
                return {
                    backgroundColor: theme.palette.info.main,
                };
            case 'working':
                return {
                    backgroundColor: theme.palette.success.main,
                };
            case 'idle':
            case 'offline':
            case 'shutdown':
            case 'uninitialized':
                return {
                    backgroundColor: theme.palette.warning.main,
                };
        }
    }, [status, theme]);
    return (React.createElement(TableRow, { onClick: onClick, sx: {
            cursor: 'pointer',
            backgroundColor: theme.palette.action.hover,
        } },
        React.createElement(TableCell, null, fleet),
        React.createElement(TableCell, null, name),
        React.createElement(TableCell, null, estFinishTime ? new Date(estFinishTime).toLocaleString() : '-'),
        React.createElement(TableCell, null,
            (battery * 100).toFixed(2),
            "%"),
        React.createElement(TableCell, null, lastUpdateTime ? new Date(lastUpdateTime).toLocaleString() : '-'),
        React.createElement(TableCell, { sx: robotStatusClass }, status)));
});
export function RobotTable(_a) {
    var robots = _a.robots, onRobotClick = _a.onRobotClick, otherProps = __rest(_a, ["robots", "onRobotClick"]);
    return (React.createElement(Table, __assign({ stickyHeader: true, size: "small", style: { tableLayout: 'fixed' } }, otherProps),
        React.createElement(TableHead, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Fleet"),
                React.createElement(TableCell, null, "Robot Name"),
                React.createElement(TableCell, null, "Est. Task Finish Time"),
                React.createElement(TableCell, null, "Battery"),
                React.createElement(TableCell, null, "Last Updated"),
                React.createElement(TableCell, null, "Status"))),
        React.createElement(TableBody, null, robots.map(function (robot, robot_id) { return (React.createElement(RobotRow, __assign({ key: robot_id }, robot, { onClick: function (ev) { return onRobotClick && onRobotClick(ev, robot); } }))); }))));
}
