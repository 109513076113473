export function getPlaces(buildingMap) {
    var places = [];
    for (var _i = 0, _a = buildingMap.levels; _i < _a.length; _i++) {
        var level = _a[_i];
        for (var _b = 0, _c = level.nav_graphs; _b < _c.length; _b++) {
            var graphs = _c[_b];
            for (var _d = 0, _e = graphs.vertices; _d < _e.length; _d++) {
                var vertex = _e[_d];
                if (vertex.name) {
                    places.push({ level: level.name, vertex: vertex });
                }
            }
        }
    }
    return places;
}
