var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled, Table, TableBody, TableHead, TableRow } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { DispenserState as RmfDispenserState } from 'rmf-models';
import { ItemTableCell, useFixedTableCellStylesClasses } from '../utils';
import { dispenserModeToString } from './utils';
var classes = {
    dispenserLabelIdle: 'workcell-dispenser-label-idle',
    dispenserLabelBusy: 'workcell-dispenser-label-busy',
    dispenserLabelOffline: 'workcell-offline-label',
    tableContainer: 'workcell-table-container',
    firstCell: 'workcell-table-first-cell',
    tableRow: 'workcell-table-row',
    tableCell: 'workcell-table-cell',
};
var StyledAutosizer = styled(function (props) { return React.createElement(AutoSizer, __assign({}, props)); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& ." + classes.dispenserLabelIdle] = {
            color: theme.palette.success.main,
        },
        _b["& ." + classes.dispenserLabelBusy] = {
            color: theme.palette.error.main,
        },
        _b["& ." + classes.dispenserLabelOffline] = {
            color: theme.palette.warning.main,
        },
        _b["& ." + classes.tableContainer] = {
            maxHeight: '25vh',
        },
        _b["& ." + classes.firstCell] = {
            width: theme.spacing(16),
            maxWidth: theme.spacing(16),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        _b["& ." + classes.tableRow] = {
            display: 'flex',
            flexDirection: 'row',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.action.hover,
            },
        },
        _b["& ." + classes.tableCell] = {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        _b);
});
var WorkcellRow = React.memo(function (_a) {
    var workcell = _a.workcell, mode = _a.mode, requestGuidQueue = _a.requestGuidQueue, secondsRemaining = _a.secondsRemaining;
    var fixedTableCell = useFixedTableCellStylesClasses.fixedTableCell;
    var dispenserModeLabelClasses = React.useCallback(function (mode) {
        switch (mode) {
            case RmfDispenserState.IDLE:
                return "" + classes.dispenserLabelIdle;
            case RmfDispenserState.BUSY:
                return "" + classes.dispenserLabelBusy;
            case RmfDispenserState.OFFLINE:
                return "" + classes.dispenserLabelOffline;
            default:
                return '';
        }
    }, []);
    return (React.createElement(TableRow, { "aria-label": "" + workcell.guid, className: classes.tableRow, component: "div" }, mode !== undefined && requestGuidQueue !== undefined && secondsRemaining !== undefined ? (React.createElement(React.Fragment, null,
        React.createElement(ItemTableCell, { component: "div", variant: "body", className: clsx(classes.tableCell, fixedTableCell), title: workcell.guid }, workcell.guid),
        React.createElement(ItemTableCell, { component: "div", variant: "body", className: clsx(dispenserModeLabelClasses(mode), classes.tableCell, fixedTableCell) }, dispenserModeToString(mode)),
        React.createElement(ItemTableCell, { component: "div", variant: "body", className: clsx(classes.tableCell, fixedTableCell) }, requestGuidQueue.length),
        React.createElement(ItemTableCell, { component: "div", variant: "body", className: clsx(classes.tableCell, fixedTableCell) }, requestGuidQueue),
        React.createElement(ItemTableCell, { component: "div", variant: "body", className: clsx(classes.tableCell, fixedTableCell) }, secondsRemaining))) : (React.createElement(React.Fragment, null,
        React.createElement(ItemTableCell, { component: "div", variant: "body", className: clsx(classes.tableCell, fixedTableCell), title: workcell.guid }, workcell.guid),
        React.createElement(ItemTableCell, { component: "div", variant: "body", className: clsx(classes.tableCell, fixedTableCell) }, 'NA'),
        React.createElement(ItemTableCell, { component: "div", variant: "body", className: clsx(classes.tableCell, fixedTableCell) }, 'NA'),
        React.createElement(ItemTableCell, { component: "div", variant: "body", className: clsx(classes.tableCell, fixedTableCell) }, 'NA'),
        React.createElement(ItemTableCell, { component: "div", variant: "body", className: clsx(classes.tableCell, fixedTableCell) }, 'NA')))));
});
var WorkcellListRenderer = function (_a) {
    var data = _a.data, index = _a.index, style = _a.style;
    var workcell = data.workcells[index];
    var workcellState = data.workcellStates[workcell.guid];
    return (React.createElement("div", { style: style },
        React.createElement(WorkcellRow, { workcell: workcell, mode: workcellState === null || workcellState === void 0 ? void 0 : workcellState.mode, requestGuidQueue: workcellState === null || workcellState === void 0 ? void 0 : workcellState.request_guid_queue, secondsRemaining: workcellState === null || workcellState === void 0 ? void 0 : workcellState.seconds_remaining })));
};
export var WorkcellTable = function (_a) {
    var workcells = _a.workcells, workcellStates = _a.workcellStates;
    var fixedTableCell = useFixedTableCellStylesClasses.fixedTableCell;
    return (React.createElement(StyledAutosizer, { disableHeight: true }, function (_a) {
        var width = _a.width;
        return (React.createElement(Table, { component: "div", size: "small", "aria-label": "workcell-table" },
            React.createElement(TableHead, { component: "div" },
                React.createElement(TableRow, { component: "div", className: classes.tableRow },
                    React.createElement(ItemTableCell, { component: "div", variant: "head", className: clsx(classes.tableCell, fixedTableCell) }, "Dispenser Name"),
                    React.createElement(ItemTableCell, { component: "div", variant: "head", className: clsx(classes.tableCell, fixedTableCell) }, "Op. Mode"),
                    React.createElement(ItemTableCell, { component: "div", variant: "head", className: clsx(classes.tableCell, fixedTableCell) }, "No. Queued Requests"),
                    React.createElement(ItemTableCell, { component: "div", variant: "head", className: clsx(classes.tableCell, fixedTableCell) }, "Request Queue ID"),
                    React.createElement(ItemTableCell, { component: "div", variant: "head", className: clsx(classes.tableCell, fixedTableCell) }, "Seconds Remaining"))),
            React.createElement(TableBody, { component: "div" },
                React.createElement(FixedSizeList, { itemSize: 43, itemCount: workcells.length, height: 200, width: width, itemData: {
                        workcells: workcells,
                        workcellStates: workcellStates,
                    } }, WorkcellListRenderer))));
    }));
};
