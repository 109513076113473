var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { ColorContext } from '../color-manager';
import { uniqueId } from '../utils';
/**
 *
 * @param color MUST be in hex notation without alpha channel. e.g. #123456
 */
function makeGradientShadow(color) {
    return function (props) { return (React.createElement("radialGradient", __assign({}, props),
        React.createElement("stop", { offset: "0%", stopColor: color + "80" }),
        React.createElement("stop", { offset: "70%", stopColor: color + "40" }),
        React.createElement("stop", { offset: "90%", stopColor: color + "10" }),
        React.createElement("stop", { offset: "100%", stopColor: color + "00" }))); };
}
// TODO: Support rectangle markers?
/**
 * Image should be 1x1 aspect ratio.
 */
export var ImageMarker = function (_a) {
    var cx = _a.cx, cy = _a.cy, r = _a.r, iconPath = _a.iconPath, _b = _a.inConflict, inConflict = _b === void 0 ? false : _b, onError = _a.onError;
    var colorManager = React.useContext(ColorContext);
    var componentId = React.useMemo(uniqueId, []);
    var shadowId = React.useMemo(function () { return "RobotImageIcon-" + componentId + "-shadow"; }, [componentId]);
    var conflictShadowId = React.useMemo(function () { return "RobotImageIcon-" + componentId + "-shadow-conflict"; }, [
        componentId,
    ]);
    var Shadow = React.useMemo(function () { return makeGradientShadow('#000000'); }, []);
    var ShadowConflict = React.useMemo(function () { return makeGradientShadow(colorManager.conflictHighlight); }, [
        colorManager.conflictHighlight,
    ]);
    return iconPath ? (React.createElement("g", null,
        React.createElement("defs", null,
            React.createElement(Shadow, { id: shadowId }),
            React.createElement(ShadowConflict, { id: conflictShadowId })),
        React.createElement("circle", { r: r * 1.3, cx: cx, cy: cy, fill: inConflict ? "url(#" + conflictShadowId + ")" : "url(#" + shadowId + ")" }),
        React.createElement("image", { href: iconPath, width: r * 2, height: r * 2, x: cx - r, y: cy - r, onError: onError }))) : null;
};
export default ImageMarker;
