import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
} from '@mui/material';

interface FormState {
  generalMaintenance: boolean;
}

interface DefaultMaintenanceFormProps {
  onSubmit: () => void;
}

export function DefaultMaintenanceForm({ onSubmit }: DefaultMaintenanceFormProps) {
  const [formState, setFormState] = useState<FormState>({
    generalMaintenance: false,
  });

  const [formValid, setFormValid] = useState<boolean>(false);

  // Validation Logic
  useEffect(() => {
    // Check if all checkboxes are checked
    const isFormValid = Object.values(formState).every((value) => value);
    setFormValid(isFormValid);
  }, [formState]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <div>
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="generalMaintenance"
                checked={formState.generalMaintenance}
                onChange={handleCheckboxChange}
              />
            }
            label="General Maintenance"
          />
          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            onClick={onSubmit}
            disabled={!formValid} // Disable the button if the form is not valid
          >
            Submit
          </Button>
        </FormGroup>
        {!formValid && (
          <FormHelperText error>You must complete all steps before submission!</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}
