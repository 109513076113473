"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Place = void 0;
var Place = /** @class */ (function () {
    function Place(fields) {
        if (fields === void 0) { fields = {}; }
        this.name = fields.name || '';
        this.x = fields.x || 0;
        this.y = fields.y || 0;
        this.yaw = fields.yaw || 0;
        this.position_tolerance = fields.position_tolerance || 0;
        this.yaw_tolerance = fields.yaw_tolerance || 0;
    }
    Place.validate = function (obj) {
        if (typeof obj['name'] !== 'string') {
            throw new Error('expected "name" to be "string"');
        }
        if (typeof obj['x'] !== 'number') {
            throw new Error('expected "x" to be "number"');
        }
        if (typeof obj['y'] !== 'number') {
            throw new Error('expected "y" to be "number"');
        }
        if (typeof obj['yaw'] !== 'number') {
            throw new Error('expected "yaw" to be "number"');
        }
        if (typeof obj['position_tolerance'] !== 'number') {
            throw new Error('expected "position_tolerance" to be "number"');
        }
        if (typeof obj['yaw_tolerance'] !== 'number') {
            throw new Error('expected "yaw_tolerance" to be "number"');
        }
    };
    Place.FullTypeName = 'rmf_building_map_msgs/msg/Place';
    return Place;
}());
exports.Place = Place;
/*
string name
float32 x
float32 y
float32 yaw
float32 position_tolerance
float32 yaw_tolerance

*/
