import React from 'react';
import { useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Button, IconButton, List, ListItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export function FilterSelector(props) {
  const { robotsCalendarArray, calendarRef, filterStates, setFilterStates } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);

  console.log(`FILTER STATES ${filterStates}`);

  const getCalendarCurrentInstance = () => {
    if (calendarRef.current) return calendarRef.current.getInstance();

    return null;
  };

  const hideCalendar = (index, isChecked) => {
    let calendars = [];
    Object.values(robotsCalendarArray[index][1].calendars).forEach((value) => {
      calendars.push(value);
    });
    getCalendarCurrentInstance()?.setCalendarVisibility(calendars, isChecked);
  };

  const onChange = (isChecked, index) => {
    console.log('CHECK BOX CHANGE');
    let newStates = filterStates.slice();
    newStates[index] = isChecked;
    setFilterStates(newStates);
    hideCalendar(index, isChecked);
    // set calender filter states;
  };

  const renderList = () => {
    console.log(`IS COLLAPSED ${isCollapsed}`);
    if (isCollapsed) return;

    if (!robotsCalendarArray) return;

    return (
      <FormGroup>
        {filterStates.map((show, index) => {
          const robot = robotsCalendarArray[index][1].ui_robot_name;
          return (
            <FormControlLabel
              key={robot}
              checked={show}
              onChange={(e) => onChange(e.target.checked, index)}
              label={robot}
              control={<Checkbox key={robot} />}
            ></FormControlLabel>
          );
        })}
      </FormGroup>
    );
  };

  return (
    <Sidebar collapsed={isCollapsed}>
      <Menu>
        <MenuItem
          onClick={() => setIsCollapsed(!isCollapsed)}
          icon={<MenuOutlinedIcon />}
        ></MenuItem>
      </Menu>
      {renderList()}
    </Sidebar>
  );
}
