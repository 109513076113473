var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Autocomplete, styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import { ConfirmationDialog } from '../confirmation-dialog';
import { requestDoorModeToString, requestModeToString } from './lift-utils';
var classes = {
    closeButton: 'lift-request-close-button',
    form: 'lift-request-form',
    divForm: 'lift-request-divform',
    error: 'lift-request-error',
    input: 'lift-request-input',
    button: 'lift-request-button',
    buttonContainer: 'lift-request-button-container',
    dialogContent: 'lift-request-dialog-content',
};
var StyledConfirmationDialog = styled(function (props) { return (React.createElement(ConfirmationDialog, __assign({}, props))); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& ." + classes.closeButton] = {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.error.main,
        },
        _b["& ." + classes.form] = {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '0.5rem',
        },
        _b["& ." + classes.divForm] = {
            padding: '0.5rem',
            width: '100%',
        },
        _b["& ." + classes.error] = {
            color: theme.palette.error.main,
        },
        _b["& ." + classes.input] = {
            width: '100%',
        },
        _b["& ." + classes.button] = {
            width: '100%',
        },
        _b["& ." + classes.buttonContainer] = {
            paddingTop: '0.5rem',
            width: '100%',
        },
        _b["& ." + classes.dialogContent] = {
            padding: theme.spacing(5),
        },
        _b);
});
export var LiftRequestDialog = function (_a) {
    var currentLevel = _a.currentLevel, availableLevels = _a.availableLevels, availableRequestTypes = _a.availableRequestTypes, availableDoorModes = _a.availableDoorModes, showFormDialog = _a.showFormDialog, onRequestSubmit = _a.onRequestSubmit, onClose = _a.onClose;
    var _b = React.useState(availableDoorModes[0]), doorState = _b[0], setDoorState = _b[1];
    var _c = React.useState(availableRequestTypes[0]), requestType = _c[0], setRequestType = _c[1];
    var _d = React.useState(currentLevel), destination = _d[0], setDestination = _d[1];
    // Error states
    var _e = React.useState(''), doorStateError = _e[0], setDoorStateError = _e[1];
    var _f = React.useState(''), requestTypeError = _f[0], setRequestTypeError = _f[1];
    var _g = React.useState(''), destinationError = _g[0], setDestinationError = _g[1];
    var cleanUpError = function () {
        setDoorStateError('');
        setRequestTypeError('');
        setDestinationError('');
    };
    var isFormValid = function () {
        var isValid = true;
        cleanUpError();
        if (!destination) {
            setDestinationError('Destination cannot be empty');
            isValid = false;
        }
        return isValid;
    };
    var handleLiftRequest = function (event) {
        event.preventDefault();
        if (isFormValid()) {
            onRequestSubmit && onRequestSubmit(event, doorState, requestType, destination);
            onClose();
        }
    };
    return (React.createElement(StyledConfirmationDialog, { open: showFormDialog, onClose: function () { return onClose(); }, fullWidth: true, maxWidth: 'md', onSubmit: handleLiftRequest, title: 'Lift Request Form', confirmText: 'Request', cancelText: 'Close' },
        React.createElement("div", { className: classes.divForm },
            React.createElement(Autocomplete, { getOptionLabel: function (option) { return option; }, onChange: function (_, value) { return setDestination(value || ''); }, options: availableLevels, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { label: "Pick a Destination", placeholder: "Pick a Destination", variant: "outlined", error: !!destinationError, helperText: destinationError }))); }, value: destination })),
        React.createElement("div", { className: classes.divForm },
            React.createElement(Autocomplete, { getOptionLabel: function (option) { return requestDoorModeToString(option); }, onChange: function (_, value) { return setDoorState(value); }, options: availableDoorModes, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { label: "Pick a Door State", placeholder: "Pick a Door State", variant: "outlined", error: !!doorStateError, helperText: doorStateError }))); }, value: doorState })),
        React.createElement("div", { className: classes.divForm },
            React.createElement(Autocomplete, { getOptionLabel: function (option) { return requestModeToString(option); }, onChange: function (_, value) { return setRequestType(value || availableRequestTypes[0]); }, options: availableRequestTypes, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { label: "Pick Request Type", placeholder: "Pick Request Type", variant: "outlined", error: !!requestTypeError, helperText: requestTypeError }))); }, value: requestType }))));
};
export default LiftRequestDialog;
