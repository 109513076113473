"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Param = void 0;
var Param = /** @class */ (function () {
    function Param(fields) {
        if (fields === void 0) { fields = {}; }
        this.name = fields.name || '';
        this.type = fields.type || 0;
        this.value_int = fields.value_int || 0;
        this.value_float = fields.value_float || 0;
        this.value_string = fields.value_string || '';
        this.value_bool = fields.value_bool || false;
    }
    Param.validate = function (obj) {
        if (typeof obj['name'] !== 'string') {
            throw new Error('expected "name" to be "string"');
        }
        if (typeof obj['type'] !== 'number') {
            throw new Error('expected "type" to be "number"');
        }
        if (typeof obj['value_int'] !== 'number') {
            throw new Error('expected "value_int" to be "number"');
        }
        if (typeof obj['value_float'] !== 'number') {
            throw new Error('expected "value_float" to be "number"');
        }
        if (typeof obj['value_string'] !== 'string') {
            throw new Error('expected "value_string" to be "string"');
        }
        if (typeof obj['value_bool'] !== 'boolean') {
            throw new Error('expected "value_bool" to be "boolean"');
        }
    };
    Param.FullTypeName = 'rmf_building_map_msgs/msg/Param';
    Param.TYPE_UNDEFINED = 0;
    Param.TYPE_STRING = 1;
    Param.TYPE_INT = 2;
    Param.TYPE_DOUBLE = 3;
    Param.TYPE_BOOL = 4;
    return Param;
}());
exports.Param = Param;
/*
string name

uint32 type
uint32 TYPE_UNDEFINED=0
uint32 TYPE_STRING=1
uint32 TYPE_INT=2
uint32 TYPE_DOUBLE=3
uint32 TYPE_BOOL=4

int32 value_int
float32 value_float
string value_string
bool value_bool

*/
