"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildingMap = void 0;
var Level_1 = require("../../rmf_building_map_msgs/msg/Level");
var Lift_1 = require("../../rmf_building_map_msgs/msg/Lift");
var BuildingMap = /** @class */ (function () {
    function BuildingMap(fields) {
        if (fields === void 0) { fields = {}; }
        this.name = fields.name || '';
        this.levels = fields.levels || [];
        this.lifts = fields.lifts || [];
    }
    BuildingMap.validate = function (obj) {
        var e_1, _a, e_2, _b;
        if (typeof obj['name'] !== 'string') {
            throw new Error('expected "name" to be "string"');
        }
        if (!Array.isArray(obj['levels'])) {
            throw new Error('expected "levels" to be an array');
        }
        try {
            for (var _c = __values(obj['levels'].entries()), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), i = _e[0], v = _e[1];
                try {
                    Level_1.Level.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"levels\":\n  " + e.message);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (!Array.isArray(obj['lifts'])) {
            throw new Error('expected "lifts" to be an array');
        }
        try {
            for (var _f = __values(obj['lifts'].entries()), _g = _f.next(); !_g.done; _g = _f.next()) {
                var _h = __read(_g.value, 2), i = _h[0], v = _h[1];
                try {
                    Lift_1.Lift.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"lifts\":\n  " + e.message);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    BuildingMap.FullTypeName = 'rmf_building_map_msgs/msg/BuildingMap';
    return BuildingMap;
}());
exports.BuildingMap = BuildingMap;
/*
string name
Level[] levels
Lift[] lifts

*/
