var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from '@mui/material';
import clsx from 'clsx';
import Debug from 'debug';
import React from 'react';
import { DefaultMarker } from './default-robot-marker';
import { ImageMarker } from './image-marker';
var debug = Debug('Map:RobotMarker');
var classes = {
    clickable: 'robot-marker-clickable',
};
var StyledG = styled('g')(function () {
    var _a;
    return (_a = {},
        _a["& ." + classes.clickable] = {
            pointerEvents: 'auto',
            cursor: 'pointer',
        },
        _a);
});
// TODO: Support rectangle markers?
export var RobotMarker = React.forwardRef(function (_a, ref) {
    var cx = _a.cx, cy = _a.cy, r = _a.r, color = _a.color, inConflict = _a.inConflict, iconPath = _a.iconPath, otherProps = __rest(_a, ["cx", "cy", "r", "color", "inConflict", "iconPath"]);
    debug('render');
    var _b = React.useState(false), imageHasError = _b[0], setImageHasError = _b[1];
    var useImageMarker = !!iconPath && !imageHasError;
    var imageErrorHandler = React.useCallback(function () { return setImageHasError(true); }, []);
    return (React.createElement(StyledG, __assign({ ref: ref }, otherProps),
        React.createElement("g", { className: clsx(otherProps.onClick && classes.clickable) }, useImageMarker ? (React.createElement(ImageMarker, { cx: cx, cy: cy, r: r, 
            // iconPath should always be truthy if useImageMarker is truthy due to above check.
            iconPath: iconPath, onError: imageErrorHandler, inConflict: inConflict })) : (React.createElement(DefaultMarker, { cx: cx, cy: cy, r: r, color: color, inConflict: inConflict })))));
});
export default RobotMarker;
