var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
/* istanbul ignore file */
import { Configuration, DefaultApi } from 'api-client';
import React from 'react';
export var UserProfileContext = React.createContext(null);
export function UserProfileProvider(_a) {
    var _this = this;
    var authenticator = _a.authenticator, basePath = _a.basePath, children = _a.children;
    var _b = React.useState(authenticator.token), token = _b[0], setToken = _b[1];
    var _c = React.useState(null), userProfile = _c[0], setUserProfile = _c[1];
    // TODO: The axios swagger generator is bugged and does not use this option.
    // const apiClient = new DefaultApi({
    //   accessToken: async () => {
    //     await authenticator.refreshToken();
    //     return authenticator.token || '';
    //   },
    // });
    var apiClient = React.useMemo(function () {
        return new DefaultApi(new Configuration({
            basePath: basePath,
            baseOptions: {
                headers: {
                    Authorization: token && "Bearer " + token,
                },
            },
        }));
    }, [token, basePath]);
    React.useEffect(function () {
        var handler = function () { return setToken(authenticator.token); };
        authenticator.on('userChanged', handler);
        return function () {
            authenticator.off('userChanged', handler);
        };
    }, [authenticator]);
    React.useEffect(function () {
        var cancel = false;
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var user, perm;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, apiClient.getUserUserGet()];
                    case 1:
                        user = (_a.sent()).data;
                        return [4 /*yield*/, apiClient.getEffectivePermissionsPermissionsGet()];
                    case 2:
                        perm = (_a.sent()).data;
                        if (cancel)
                            return [2 /*return*/];
                        setUserProfile({ user: user, permissions: perm });
                        return [2 /*return*/];
                }
            });
        }); })();
        return function () {
            cancel = true;
        };
    }, [apiClient]);
    return _jsx(UserProfileContext.Provider, __assign({ value: userProfile }, { children: children }), void 0);
}
