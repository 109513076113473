var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import ReactDOM from 'react-dom';
import { ScaledNameLabel } from './label-marker';
import { LabelsPortalContext } from './labels-overlay';
/**
 * Wraps a component with a `ScaledNameLabel` that is rendered with a portal on
 * the element in `LabelsPortalContext`.
 */
export var withLabel = function (Component) {
    return function (_a) {
        var labelText = _a.labelText, labelSourceX = _a.labelSourceX, labelSourceY = _a.labelSourceY, labelSourceRadius = _a.labelSourceRadius, labelArrowLength = _a.labelArrowLength, _b = _a.hideLabel, hideLabel = _b === void 0 ? false : _b, componentProps = __rest(_a, ["labelText", "labelSourceX", "labelSourceY", "labelSourceRadius", "labelArrowLength", "hideLabel"]);
        var labelsPortal = React.useContext(LabelsPortalContext);
        return (React.createElement("g", null,
            React.createElement(React.Fragment, null,
                React.createElement(Component, __assign({}, componentProps)),
                !hideLabel &&
                    labelsPortal &&
                    ReactDOM.createPortal(React.createElement(ScaledNameLabel, { text: labelText, sourceX: labelSourceX, sourceY: labelSourceY, sourceRadius: labelSourceRadius, arrowLength: labelArrowLength }), labelsPortal))));
    };
};
