var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ProgressBar from 'react-customizable-progressbar';
import { styled } from '@mui/material';
var classes = {
    indicator: 'circular-progressbar-indicator',
};
var StyledCircularProgressBar = styled(function (props) { return (React.createElement(ProgressBar, __assign({}, props))); })(function () {
    var _a;
    return (_a = {},
        _a["& ." + classes.indicator] = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            margin: '0 auto',
        },
        _a);
});
export function CircularProgressBar(props) {
    var progress = props.progress, strokeColor = props.strokeColor, children = props.children;
    return (React.createElement(StyledCircularProgressBar, { radius: 60, progress: progress, cut: 120, rotate: -210, strokeColor: strokeColor, strokeWidth: 10, trackStrokeWidth: 10 },
        React.createElement("div", { className: classes.indicator }, children)));
}
