var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material';
import React from 'react';
var classes = {
    container: 'transfer-list-container',
    cardHeader: 'transfer-list-card-header',
    list: 'transfer-list-list',
    button: 'transfer-list-button',
    transferControls: 'transfer-list-controls',
    cardContainer: 'transfer-list-card-container',
};
var StyledGrid = styled(function (props) { return React.createElement(Grid, __assign({}, props)); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&." + classes.container] = {
            height: '100%',
            '& > *': {
                height: '100%',
                flex: '1 1 0',
            },
        },
        _b["& ." + classes.cardContainer] = {
            height: '100%',
            '& > *': {
                height: '100%',
                flex: '1 1 0',
            },
        },
        _b["& ." + classes.cardHeader] = {
            padding: theme.spacing(1, 2),
        },
        _b["& ." + classes.list] = {
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        _b["& ." + classes.button] = {
            margin: theme.spacing(0.5, 0),
        },
        _b["& ." + classes.transferControls] = {
            marginTop: 'auto',
            marginBottom: 'auto',
            height: 'auto',
            flex: '0 0 auto',
        },
        _b);
});
function CustomList(_a) {
    var title = _a.title, items = _a.items, checked = _a.checked, setChecked = _a.setChecked;
    var numberOfChecked = checked.size;
    var handleToggleAllClick = React.useCallback(function () {
        if (numberOfChecked < items.length) {
            setChecked(new Set(items.values()));
        }
        else {
            setChecked(new Set());
        }
    }, [items, numberOfChecked, setChecked]);
    return (React.createElement(Card, { variant: "outlined", className: classes.cardContainer },
        React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" },
            React.createElement(CardHeader, { className: classes.cardHeader, avatar: React.createElement(Checkbox, { onClick: handleToggleAllClick, checked: numberOfChecked > 0, indeterminate: numberOfChecked > 0 && numberOfChecked < items.length, disabled: items.length === 0, inputProps: { 'aria-label': 'all items selected' } }), title: title, subheader: numberOfChecked + "/" + items.length + " selected" }),
            React.createElement(Divider, null),
            React.createElement(List, { className: classes.list, dense: true, disablePadding: true, component: "div", role: "list" },
                items.map(function (item) {
                    var labelId = "transfer-list-all-item-" + item + "-label";
                    return (React.createElement(ListItem, { key: item, role: "listitem", button: true, onClick: function () {
                            return setChecked(function (prev) {
                                if (prev.has(item)) {
                                    prev.delete(item);
                                }
                                else {
                                    prev.add(item);
                                }
                                return new Set(prev);
                            });
                        } },
                        React.createElement(ListItemIcon, null,
                            React.createElement(Checkbox, { checked: checked.has(item), tabIndex: -1, disableRipple: true, inputProps: { 'aria-labelledby': labelId } })),
                        React.createElement(ListItemText, { id: labelId, primary: item })));
                }),
                React.createElement(ListItem, null)))));
}
export function TransferList(_a) {
    var leftItems = _a.leftItems, rightItems = _a.rightItems, _b = _a.leftTitle, leftTitle = _b === void 0 ? 'Choices' : _b, _c = _a.rightTitle, rightTitle = _c === void 0 ? 'Choices' : _c, onTransfer = _a.onTransfer;
    var _d = React.useState(new Set()), leftChecked = _d[0], setLeftChecked = _d[1];
    var _e = React.useState(new Set()), rightChecked = _e[0], setRightChecked = _e[1];
    return (React.createElement(StyledGrid, { container: true, spacing: 2, justifyContent: "center", alignItems: "stretch", className: classes.container },
        React.createElement(Grid, { item: true },
            React.createElement(CustomList, { title: leftTitle, items: leftItems, checked: leftChecked, setChecked: setLeftChecked })),
        React.createElement(Grid, { item: true, className: classes.transferControls },
            React.createElement(Grid, { container: true, direction: "column", justifyContent: "center" },
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { variant: "outlined", size: "small", className: classes.button, onClick: function () {
                            var newLeft = leftItems.filter(function (val) { return !leftChecked.has(val); });
                            var newRight = rightItems.concat(Array.from(leftChecked.values()));
                            onTransfer && onTransfer(newLeft, newRight);
                            setLeftChecked(new Set());
                        }, disabled: leftChecked.size === 0, "aria-label": "move selected right" }, ">")),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { variant: "outlined", size: "small", className: classes.button, onClick: function () {
                            var newRight = rightItems.filter(function (val) { return !rightChecked.has(val); });
                            var newLeft = leftItems.concat(Array.from(rightChecked.values()));
                            onTransfer && onTransfer(newLeft, newRight);
                            setRightChecked(new Set());
                        }, disabled: rightChecked.size === 0, "aria-label": "move selected left" }, "<")))),
        React.createElement(Grid, { item: true },
            React.createElement(CustomList, { title: rightTitle, items: rightItems, checked: rightChecked, setChecked: setRightChecked }))));
}
