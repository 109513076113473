"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Level = void 0;
var AffineImage_1 = require("../../rmf_building_map_msgs/msg/AffineImage");
var Place_1 = require("../../rmf_building_map_msgs/msg/Place");
var Door_1 = require("../../rmf_building_map_msgs/msg/Door");
var Graph_1 = require("../../rmf_building_map_msgs/msg/Graph");
var Level = /** @class */ (function () {
    function Level(fields) {
        if (fields === void 0) { fields = {}; }
        this.name = fields.name || '';
        this.elevation = fields.elevation || 0;
        this.images = fields.images || [];
        this.places = fields.places || [];
        this.doors = fields.doors || [];
        this.nav_graphs = fields.nav_graphs || [];
        this.wall_graph = fields.wall_graph || new Graph_1.Graph();
    }
    Level.validate = function (obj) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d;
        if (typeof obj['name'] !== 'string') {
            throw new Error('expected "name" to be "string"');
        }
        if (typeof obj['elevation'] !== 'number') {
            throw new Error('expected "elevation" to be "number"');
        }
        if (!Array.isArray(obj['images'])) {
            throw new Error('expected "images" to be an array');
        }
        try {
            for (var _e = __values(obj['images'].entries()), _f = _e.next(); !_f.done; _f = _e.next()) {
                var _g = __read(_f.value, 2), i = _g[0], v = _g[1];
                try {
                    AffineImage_1.AffineImage.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"images\":\n  " + e.message);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (!Array.isArray(obj['places'])) {
            throw new Error('expected "places" to be an array');
        }
        try {
            for (var _h = __values(obj['places'].entries()), _j = _h.next(); !_j.done; _j = _h.next()) {
                var _k = __read(_j.value, 2), i = _k[0], v = _k[1];
                try {
                    Place_1.Place.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"places\":\n  " + e.message);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_j && !_j.done && (_b = _h.return)) _b.call(_h);
            }
            finally { if (e_2) throw e_2.error; }
        }
        if (!Array.isArray(obj['doors'])) {
            throw new Error('expected "doors" to be an array');
        }
        try {
            for (var _l = __values(obj['doors'].entries()), _m = _l.next(); !_m.done; _m = _l.next()) {
                var _o = __read(_m.value, 2), i = _o[0], v = _o[1];
                try {
                    Door_1.Door.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"doors\":\n  " + e.message);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_m && !_m.done && (_c = _l.return)) _c.call(_l);
            }
            finally { if (e_3) throw e_3.error; }
        }
        if (!Array.isArray(obj['nav_graphs'])) {
            throw new Error('expected "nav_graphs" to be an array');
        }
        try {
            for (var _p = __values(obj['nav_graphs'].entries()), _q = _p.next(); !_q.done; _q = _p.next()) {
                var _r = __read(_q.value, 2), i = _r[0], v = _r[1];
                try {
                    Graph_1.Graph.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"nav_graphs\":\n  " + e.message);
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_q && !_q.done && (_d = _p.return)) _d.call(_p);
            }
            finally { if (e_4) throw e_4.error; }
        }
        try {
            Graph_1.Graph.validate(obj['wall_graph']);
        }
        catch (e) {
            throw new Error('in "wall_graph":\n  ' + e.message);
        }
    };
    Level.FullTypeName = 'rmf_building_map_msgs/msg/Level';
    return Level;
}());
exports.Level = Level;
/*
string name
float32 elevation
AffineImage[] images
Place[] places
Door[] doors
Graph[] nav_graphs
Graph wall_graph

*/
