var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useTheme, styled } from '@mui/material';
import Debug from 'debug';
import React from 'react';
var debug = Debug('Map:WorkcellMarker');
var DefaultIcon = function (_a) {
    var cx = _a.cx, cy = _a.cy, size = _a.size;
    var theme = useTheme();
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", 
        // The size of the svg is slightly smaller than the footprint.
        x: cx - size * 0.7, y: cy - size * 0.7, width: size * 1.4, height: size * 1.4, viewBox: "0 0 24 24" },
        React.createElement("path", { fill: theme.palette.success.main, d: "M19 3H4.99c-1.11 0-1.98.9-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10zm-3-5h-2V7h-4v3H8l4 4 4-4z" })));
};
var classes = {
    text: 'workcell-marker-text',
    clickable: 'workcell-marker-clickable',
};
var StyledG = styled('g')(function () {
    var _a;
    return (_a = {},
        _a["& ." + classes.text] = {
            dominantBaseline: 'central',
            textAnchor: 'middle',
            fontSize: '0.18px',
            fontWeight: 'bold',
            fill: 'white',
            /* 1 pixel black shadow to left, top, right and bottom */
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
            pointerEvents: 'none',
            userSelect: 'none',
        },
        _a["& ." + classes.clickable] = {
            pointerEvents: 'auto',
            cursor: 'pointer',
        },
        _a);
});
// TODO: Support rectangle marker?
export var WorkcellMarker = React.forwardRef(function (props, ref) {
    debug('render');
    var cx = props.cx, cy = props.cy, size = props.size, iconPath = props.iconPath, otherProps = __rest(props, ["cx", "cy", "size", "iconPath"]);
    var _a = React.useState(false), imageHasError = _a[0], setImageHasError = _a[1];
    var useImageIcon = !!iconPath && !imageHasError;
    return (React.createElement(StyledG, __assign({ ref: ref }, otherProps),
        React.createElement("g", { className: otherProps.onClick && classes.clickable },
            useImageIcon ? (React.createElement("image", { href: iconPath, x: cx - size / 2, y: cy - size / 2, width: size, height: size, onError: function () { return setImageHasError(true); } })) : (
            // the default marker's size is slightly smaller than the footprint
            React.createElement(DefaultIcon, __assign({}, props))),
            React.createElement("rect", { x: cx - size / 2, y: cy - size / 2, width: size, height: size, fill: "transparent" }))));
});
export default WorkcellMarker;
