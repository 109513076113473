import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { LiftState as LiftStateModel } from 'rmf-models';
import { doorStateToString, motionStateToString } from './lift-utils';
import { HealthStatus, healthStatusToOpMode } from '../utils';
import { LiftControls } from './lift-controls';
export function LiftDataGridTable(_a) {
    var lifts = _a.lifts;
    var theme = useTheme();
    var OpModeState = function (params) {
        var opModeStateLabelStyle = (function () {
            var unknown = {
                color: theme.palette.action.disabledBackground,
            };
            var online = {
                color: theme.palette.success.main,
            };
            var unstable = {
                color: theme.palette.warning.main,
            };
            var offline = {
                color: theme.palette.error.main,
            };
            switch (params.row.opMode) {
                case HealthStatus.Healthy:
                    return online;
                case HealthStatus.Unhealthy:
                    return unstable;
                case HealthStatus.Dead:
                    return offline;
                default:
                    return unknown;
            }
        })();
        return (React.createElement(Box, { sx: opModeStateLabelStyle },
            React.createElement(Typography, { "data-testid": "op-mode-state", component: "p", sx: {
                    fontWeight: 'bold',
                    fontSize: 14,
                } }, healthStatusToOpMode(params.row.opMode))));
    };
    var LiftState = function (params) {
        var _a, _b, _c;
        var currDoorMotion = doorStateToString((_a = params.row) === null || _a === void 0 ? void 0 : _a.doorState);
        var currMotion = motionStateToString((_b = params.row) === null || _b === void 0 ? void 0 : _b.motionState);
        var motionArrowActiveStyle = {
            color: theme.palette.primary.main,
        };
        var motionArrowIdleStyle = {
            color: theme.palette.action.disabled,
            opacity: theme.palette.action.disabledOpacity,
        };
        var doorStateLabelStyle = (function () {
            var _a;
            switch ((_a = params.row) === null || _a === void 0 ? void 0 : _a.doorState) {
                case LiftStateModel.DOOR_OPEN:
                    return {
                        color: theme.palette.success.main,
                    };
                case LiftStateModel.DOOR_CLOSED:
                    return {
                        color: theme.palette.error.main,
                    };
                case LiftStateModel.DOOR_MOVING:
                    return {
                        color: theme.palette.warning.main,
                    };
                default:
                    return {
                        color: theme.palette.action.disabledBackground,
                    };
            }
        })();
        return (React.createElement(Box, { sx: doorStateLabelStyle },
            React.createElement(Typography, { component: "p", sx: {
                    marginRight: ((_c = params.row) === null || _c === void 0 ? void 0 : _c.doorState) === LiftStateModel.DOOR_OPEN ? 4 : 2,
                    fontWeight: 'bold',
                    fontSize: 14,
                    display: 'inline-block',
                } }, currDoorMotion),
            React.createElement(ArrowUpwardIcon, { sx: currMotion === 'Up' ? motionArrowActiveStyle : motionArrowIdleStyle }),
            React.createElement(ArrowDownwardIcon, { sx: currMotion === 'Down' ? motionArrowActiveStyle : motionArrowIdleStyle })));
    };
    var LiftControl = function (params) {
        var _a, _b;
        return (React.createElement(LiftControls, { availableLevels: params.row.lift.levels, currentLevel: (_a = params.row) === null || _a === void 0 ? void 0 : _a.currentFloor, onRequestSubmit: (_b = params.row) === null || _b === void 0 ? void 0 : _b.onRequestSubmit }));
    };
    var columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 90,
            valueGetter: function (params) { return params.row.name; },
            flex: 1,
            filterable: true,
        },
        {
            field: 'opMode',
            headerName: 'Op. Mode',
            width: 90,
            flex: 1,
            renderCell: OpModeState,
            filterable: true,
        },
        {
            field: 'currentFloor',
            headerName: 'Current Floor',
            width: 150,
            editable: false,
            valueGetter: function (params) {
                return params.row.currentFloor ? params.row.currentFloor : 'N/A';
            },
            flex: 1,
            filterable: true,
        },
        {
            field: 'destinationFloor',
            headerName: 'Destination Floor',
            width: 150,
            editable: false,
            valueGetter: function (params) {
                return params.row.destinationFloor ? params.row.destinationFloor : 'N/A';
            },
            flex: 1,
            filterable: true,
        },
        {
            field: 'liftState',
            headerName: 'Lift State',
            width: 150,
            editable: false,
            flex: 1,
            renderCell: LiftState,
            filterable: true,
        },
        {
            field: '-',
            headerName: '',
            width: 150,
            editable: false,
            flex: 1,
            renderCell: LiftControl,
            filterable: true,
        },
    ];
    return (React.createElement("div", { style: { height: '100%', width: '100%' } },
        React.createElement(DataGrid, { autoHeight: true, getRowId: function (l) { return l.index; }, rows: lifts, pageSize: 5, rowHeight: 38, columns: columns, rowsPerPageOptions: [5], localeText: {
                noRowsLabel: 'No lifts available.',
            } })));
}
