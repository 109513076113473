var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { LiftState as RmfLiftState } from 'rmf-models';
// Gets the text to insert to the lift, the text depend on the current mode, motion state and the
// current and destination floor of the lift.
function getLiftModeText(liftState) {
    if (!liftState.current_mode) {
        return 'UNKNOWN';
    }
    switch (liftState.current_mode) {
        case RmfLiftState.MODE_FIRE:
            return 'FIRE!';
        case RmfLiftState.MODE_EMERGENCY:
            return 'EMERGENCY!';
        case RmfLiftState.MODE_OFFLINE:
            return 'OFFLINE';
        default:
            return 'NORMAL';
    }
}
function getLiftMotionText(liftState) {
    switch (liftState.motion_state) {
        case RmfLiftState.MOTION_UP:
            return '▲';
        case RmfLiftState.MOTION_DOWN:
            return '▼';
        case RmfLiftState.MOTION_STOPPED:
            return '⯀';
        default:
            return '?';
    }
}
export var liftMarkerClasses = {
    marker: 'lift-marker-root',
    lift: 'lift-marker-lift',
    text: 'lift-marker-text',
    onCurrentLevel: 'lift-marker-oncurrentlevel',
    moving: 'lift-marker-moving',
    unknown: 'lift-marker-unknown',
    emergency: 'lift-marker-emergency',
    fire: 'lift-marker-fire',
    offLine: 'lift-marker-offline',
    human: 'lift-marker-human',
};
var StyledG = styled('g')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&." + liftMarkerClasses.marker] = {
            cursor: 'pointer',
            pointerEvents: 'auto',
        },
        _b["& ." + liftMarkerClasses.lift] = {
            strokeWidth: '0.2',
        },
        _b["& ." + liftMarkerClasses.text] = {
            dominantBaseline: 'central',
            textAnchor: 'middle',
            fontSize: '0.16px',
            fontWeight: 'bold',
            cursor: 'inherit',
            userSelect: 'none',
        },
        _b["& ." + liftMarkerClasses.onCurrentLevel] = {
            fill: theme.palette.success.light,
            opacity: '70%',
        },
        _b["& ." + liftMarkerClasses.moving] = {
            fill: theme.palette.secondary.light,
            opacity: '70%',
        },
        _b["& ." + liftMarkerClasses.unknown] = {
            fill: theme.palette.warning.light,
            opacity: '80%',
        },
        _b["& ." + liftMarkerClasses.emergency] = {
            fill: theme.palette.error.light,
            opacity: '80%',
        },
        _b["& ." + liftMarkerClasses.fire] = {
            fill: theme.palette.error.main,
            opacity: '80%',
        },
        _b["& ." + liftMarkerClasses.offLine] = {
            fill: theme.palette.grey[400],
            opacity: '80%',
        },
        _b["& ." + liftMarkerClasses.human] = {
            fill: theme.palette.info.main,
            opacity: '80%',
        },
        _b);
});
export var LiftMarker = React.forwardRef(function (_a, ref) {
    var cx = _a.cx, cy = _a.cy, width = _a.width, height = _a.height, yaw = _a.yaw, liftState = _a.liftState, variant = _a.variant, otherProps = __rest(_a, ["cx", "cy", "width", "height", "yaw", "liftState", "variant"]);
    var markerClass = variant ? liftMarkerClasses[variant] : liftMarkerClasses.onCurrentLevel;
    var x = cx - width / 2;
    var y = cy - height / 2;
    var r = Math.max(width, height) * 0.04;
    /**
     * In order to keep consistent spacing, we render at a "unit box" scale it according to the
     * dimensionals of the lift.
     */
    var renderStatusText = function () {
        // QN: do we need to take into account rotation?
        var textScale = Math.min(width, height); // keep aspect ratio
        return liftState ? (React.createElement("text", { className: liftMarkerClasses.text, transform: "translate(" + cx + " " + cy + ") scale(" + textScale + ")" },
            React.createElement("tspan", { x: "0", dy: "-1.8em" }, liftState.current_floor),
            React.createElement("tspan", { x: "0", dy: "1.2em", fontSize: "0.7em" }, getLiftModeText(liftState)),
            React.createElement("tspan", { x: "0", dy: "0.6em", fontSize: "3em" }, getLiftMotionText(liftState)))) : (React.createElement("text", { className: liftMarkerClasses.text, transform: "translate(" + cx + " " + cy + ") scale(" + textScale + ")" },
            React.createElement("tspan", { x: "0", dy: "-0.5em" }, "Unknown"),
            React.createElement("tspan", { x: "0", dy: "1em" }, "State")));
    };
    return (React.createElement(StyledG, __assign({ ref: ref, className: clsx(otherProps.onClick ? liftMarkerClasses.marker : undefined, otherProps.className) }, otherProps),
        React.createElement("rect", { className: liftMarkerClasses.lift + " " + markerClass, x: x, y: y, width: width, height: height, rx: r, ry: r, style: { transform: "rotate(" + yaw + "deg)", transformOrigin: cx + "px " + cy + "px" } }),
        renderStatusText()));
});
export default LiftMarker;
