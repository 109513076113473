"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModeParameter = void 0;
var ModeParameter = /** @class */ (function () {
    function ModeParameter(fields) {
        if (fields === void 0) { fields = {}; }
        this.name = fields.name || '';
        this.value = fields.value || '';
    }
    ModeParameter.validate = function (obj) {
        if (typeof obj['name'] !== 'string') {
            throw new Error('expected "name" to be "string"');
        }
        if (typeof obj['value'] !== 'string') {
            throw new Error('expected "value" to be "string"');
        }
    };
    ModeParameter.FullTypeName = 'rmf_fleet_msgs/msg/ModeParameter';
    return ModeParameter;
}());
exports.ModeParameter = ModeParameter;
/*
string name
string value

*/
