"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitTask_Request = void 0;
var TaskDescription_1 = require("../../rmf_task_msgs/msg/TaskDescription");
var SubmitTask_Request = /** @class */ (function () {
    function SubmitTask_Request(fields) {
        if (fields === void 0) { fields = {}; }
        this.requester = fields.requester || '';
        this.description = fields.description || new TaskDescription_1.TaskDescription();
    }
    SubmitTask_Request.validate = function (obj) {
        if (typeof obj['requester'] !== 'string') {
            throw new Error('expected "requester" to be "string"');
        }
        try {
            TaskDescription_1.TaskDescription.validate(obj['description']);
        }
        catch (e) {
            throw new Error('in "description":\n  ' + e.message);
        }
    };
    SubmitTask_Request.FullTypeName = 'rmf_task_msgs/srv/SubmitTask_Request';
    return SubmitTask_Request;
}());
exports.SubmitTask_Request = SubmitTask_Request;
/*
# Submit Task | POST service call

# Identifier for who is requesting the service
string requester

# desciption of task
TaskDescription description


*/
