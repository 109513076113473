import { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { ListItem } from '@mui/material';
import { Grid, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import EditIcon from '@mui/icons-material/Edit';
import Popover from '@mui/material/Popover';
import { EventType, Operation } from './utils/event_form_utils';
import { DeleteCallback, TogglePauseCallback, CancelTaskCallback } from './utils/event_form_utils';
import { AppControllerContext } from '../app-contexts';
import { RmfAppContext } from '../rmf-app';

export function EventDialog(props) {
  const { open, onClose, eventDetails, onEditCallback, eventType, eventId, eventInfo } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);
  const [openEditPopOver, setOpenEditPopOver] = useState(false);
  const [openDeletePopOver, setOpenDeletePopOver] = useState(false);
  const rmf = useContext(RmfAppContext);
  const { showAlert } = useContext(AppControllerContext);

  const handleClose = () => {
    console.log('Closing Dialog Box');
    onClose();
  };

  console.log(`EVENT DIALOG EVENT TYPE  IS ${eventType}`);

  const renderEditButtion = () => {
    let onEditButtonClick;
    let onEditOccurrence;
    let onEditSeries;
    let onPopUpClose;
    let displayText;

    // Define callback functions
    switch (eventType) {
      case EventType.Event: {
        onEditButtonClick = () => {
          console.log('Edit Event');
          onEditCallback(Operation.EditEvent);
        };

        displayText = 'Edit Event';
        break;
      }
      case EventType.EventOfDAG: {
        onEditButtonClick = () => {
          console.log('Edit DAG Event');
          onEditCallback(Operation.EditEventOfDAG);
        };
        displayText = 'Edit DAG Event';
        break;
      }
      case EventType.OccurrenceOfEventSeries: {
        onEditButtonClick = (event) => {
          setAnchorEl(event.currentTarget);
          setOpenEditPopOver(true);
        };
        onPopUpClose = () => {
          setOpenEditPopOver(false);
          setAnchorEl(null);
        };
        onEditSeries = () => {
          onEditCallback(Operation.EditEventSeries);
        };
        onEditOccurrence = () => {
          onEditCallback(Operation.EditOccurrenceOfEventSeries);
        };
        displayText = 'Edit Event of Series';
        break;
      }
      case EventType.OccurrenceOfDAGSeries: {
        onEditButtonClick = (event) => {
          setAnchorEl(event.currentTarget);
          setOpenEditPopOver(true);
        };
        onPopUpClose = () => {
          setOpenEditPopOver(false);
          setAnchorEl(null);
        };
        onEditSeries = () => {
          onEditCallback(Operation.EditDAGSeries);
        };
        onEditOccurrence = () => {
          onEditCallback(Operation.EditOccurrenceOfDAGSeries);
        };
        displayText = 'Edit DAG of Series';
        break;
      }
    }

    const renderPopOvers = () => {
      if (!onPopUpClose) return;

      return (
        <Popover
          open={openEditPopOver}
          anchorEl={anchorEl}
          onClose={onPopUpClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Button
            sx={{ width: '100%' }}
            onClick={() => {
              onEditSeries();
            }}
          >
            Edit Series
          </Button>
          <Button
            sx={{ width: '100%' }}
            onClick={() => {
              onEditOccurrence();
            }}
          >
            Edit Occurrence
          </Button>
        </Popover>
      );
    };

    return (
      <>
        <Button
          onClick={(event) => {
            onEditButtonClick(event);
          }}
          variant="contained"
          startIcon={<EditIcon />}
          fullWidth={true}
        >
          {displayText}
        </Button>
        {renderPopOvers()}
      </>
    );
  };

  const renderDeleteButton = () => {
    let displayText = 'Delete';
    let onButtonClick;
    let onDeleteSeries;
    let onDeleteOccurrence;
    let onPopUpClose;

    // Consider moving this large chunk into utils
    switch (eventType) {
      case EventType.Event: {
        const request = {
          event_ids: new Array(1).fill(eventId),
          dependency_ids: new Array(),
          series_ids: new Array(),
        };
        console.log(`${JSON.stringify(request)}`);
        onButtonClick = () => {
          if (rmf)
            DeleteCallback({
              rmf: rmf,
              showAlert: showAlert,
              messages: {
                successMsg: 'Successfully deleted event',
                errorMsg: 'Error deleting event: ',
              },
              request: request,
            });
        };
        break;
      }
      case EventType.EventOfDAG: {
        const request = {
          event_ids: [eventId],
          dependency_ids: [],
          series_ids: [],
        };
        onButtonClick = () => {
          if (rmf)
            DeleteCallback({
              rmf: rmf,
              showAlert: showAlert,
              messages: {
                successMsg: 'Successfully deleted DAG',
                errorMsg: 'Error deleting DAG: ',
              },
              request: request,
            });
        };
        break;
      }
      case EventType.OccurrenceOfEventSeries: {
        onButtonClick = (event) => {
          setDeleteAnchorEl(event.currentTarget);
          setOpenDeletePopOver(true);
        };
        onDeleteSeries = () => {
          const request = {
            event_ids: [],
            dependency_ids: [],
            series_ids: [eventInfo.series_id],
          };
          if (rmf)
            DeleteCallback({
              rmf: rmf,
              showAlert: showAlert,
              messages: {
                successMsg: 'Successfully deleted Event Series',
                errorMsg: 'Error deleting Event Series: ',
              },
              request: request,
            });
        };
        onDeleteOccurrence = () => {
          const request = {
            event_ids: [eventId],
            dependency_ids: [],
            series_ids: [],
          };
          if (rmf)
            DeleteCallback({
              rmf: rmf,
              showAlert: showAlert,
              messages: {
                successMsg: 'Successfully deleted occurrence of Event Series',
                errorMsg: 'Error deleting occurrence of Event Series: ',
              },
              request: request,
            });
        };
        onPopUpClose = () => {
          setDeleteAnchorEl(null);
          setOpenDeletePopOver(false);
        };
        break;
      }
      case EventType.OccurrenceOfDAGSeries: {
        onButtonClick = (event) => {
          setDeleteAnchorEl(event.currentTarget);
          setOpenDeletePopOver(true);
        };
        onDeleteSeries = () => {
          let request = {
            event_ids: [],
            dependency_ids: [],
            series_ids: [eventInfo.series_id],
          };
          if (rmf)
            DeleteCallback({
              rmf: rmf,
              showAlert: showAlert,
              messages: {
                successMsg: 'Successfully deleted DAG Series',
                errorMsg: 'Error deleting DAG Series: ',
              },
              request: request,
            });
        };
        onDeleteOccurrence = () => {
          let request = {
            event_ids: [eventId],
            dependency_ids: [],
            series_ids: [],
          };
          if (rmf)
            DeleteCallback({
              rmf: rmf,
              showAlert: showAlert,
              messages: {
                successMsg: 'Successfully deleted occurrence of DAG Series',
                errorMsg: 'Error deleting occurrence of DAG Series: ',
              },
              request: request,
            });
        };
        onPopUpClose = () => {
          setDeleteAnchorEl(null);
          setOpenDeletePopOver(false);
        };
        break;
      }
    }

    // Function to render the pop overs
    const renderDeletePopOvers = () => {
      if (!onPopUpClose) return;

      return (
        <Popover
          open={openDeletePopOver}
          anchorEl={deleteAnchorEl}
          onClose={onPopUpClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Button
            sx={{ width: '100%' }}
            onClick={() => {
              onDeleteSeries();
            }}
          >
            Delete Series
          </Button>
          <Button
            sx={{ width: '100%' }}
            onClick={() => {
              onDeleteOccurrence();
            }}
          >
            Delete Occurrence
          </Button>
        </Popover>
      );
    };
    return (
      <>
        <Button
          onClick={(e) => {
            onButtonClick(e);
          }}
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          fullWidth={true}
        >
          {displayText}
        </Button>
        {renderDeletePopOvers()}
      </>
    );
  };

  const renderTogglePauseButton = () => {
    const togglePauseCallback = () => {
      TogglePauseCallback({ rmf: rmf, eventId: eventId });
    };

    return (
      <Button
        variant="contained"
        startIcon={<PauseCircleIcon />}
        fullWidth={true}
        onClick={togglePauseCallback}
      >
        {'Toggle Pause'}
      </Button>
    );
  };

  const renderCancelTaskButton = () => {
    const cancelTask = () => {
      CancelTaskCallback({ rmf: rmf, eventId: eventId });
    };
    return (
      <Button
        variant="contained"
        color="error"
        startIcon={<StopCircleIcon />}
        fullWidth={true}
        onClick={cancelTask}
      >
        {'Cancel Task'}
      </Button>
    );
  };

  const renderEditOptions = () => {
    const nowSeconds = new Date().valueOf() / 1000;
    if (eventInfo.start_time < nowSeconds) {
      // If event is over disable all edit buttons
      if (nowSeconds - eventInfo.start_time > eventInfo.duration) {
        return;
      }
      // return pause/resume and cancel buttons
      return (
        <>
          <Grid container>
            <Grid item xs={6} style={{ padding: 20 }}>
              {renderTogglePauseButton()}
            </Grid>
            <Grid item xs={6} style={{ padding: 20 }}>
              {renderCancelTaskButton()}
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid container>
          <Grid item xs={6} style={{ padding: 20 }}>
            {renderDeleteButton()}
          </Grid>
          <Grid item xs={6} style={{ padding: 20 }}>
            {renderEditButtion()}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true}>
      <DialogTitle style={{ color: '#FFFFFF', backgroundColor: '#1976D2' }}>Event</DialogTitle>
      <List>
        {Object.entries(eventDetails).map((pair) => {
          const value = pair[1] ? pair[1].toString() : '';
          const key = pair[0];
          return (
            <ListItem disableGutters key={key}>
              <Box sx={{ mx: 2, width: '30%', fontWeight: 'bold' }}>{key}:</Box>
              <Box sx={{ p: 1, border: '1px solid grey', mx: 2, width: '60%' }}>{value}</Box>
            </ListItem>
          );
        })}
      </List>
      {renderEditOptions()}
    </Dialog>
  );
}
