var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TextField } from '@mui/material';
import React from 'react';
export function PositiveIntField(_a) {
    var _b = _a.value, value = _b === void 0 ? 0 : _b, onChange = _a.onChange, props = __rest(_a, ["value", "onChange"]);
    var _c = React.useState(value.toString()), valueInput = _c[0], setValueInput = _c[1];
    React.useEffect(function () {
        setValueInput(value.toString());
    }, [value]);
    return (React.createElement(TextField, __assign({}, props, { type: "number", value: valueInput, inputProps: { min: 0 }, onKeyDown: function (ev) {
            if ('-+.'.indexOf(ev.key) >= 0) {
                ev.preventDefault();
            }
            props.onKeyDown && props.onKeyDown(ev);
        }, onChange: function (ev) {
            var int = parseInt(ev.target.value);
            if (int > 0) {
                onChange && onChange(ev, int);
            }
            setValueInput(ev.target.value);
        } })));
}
