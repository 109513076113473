import { Divider, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material';
import React from 'react';
import { TaskTimeline } from './task-timeline';
var classes = {
    infoValue: 'task-info-info-value',
};
var StyledDiv = styled('div')(function () {
    var _a;
    return (_a = {},
        _a["& ." + classes.infoValue] = {
            float: 'right',
            textAlign: 'right',
        },
        _a);
});
function InfoLine(_a) {
    var children = _a.children;
    return (React.createElement(Typography, { variant: "body1", gutterBottom: true }, children));
}
function InfoValue(_a) {
    var children = _a.children;
    return React.createElement("span", { className: classes.infoValue }, children);
}
export function TaskInfo(_a) {
    var task = _a.task, title = _a.title;
    var theme = useTheme();
    var _b = React.useState(''), startWaypoint = _b[0], setStartWaypoint = _b[1];
    var _c = React.useState(''), finishWaypoint = _c[0], setFinishWaypoint = _c[1];
    React.useEffect(function () {
        var _a, _b;
        if (task.phases) {
            /**
             * We assumed that phases[index].detail always return
             * "Go to " + goal_name_,
                "Moving the robot from " + start_name + " to " + goal_name_,
                *estimate);
      
                According with this code
             * https://github.com/open-rmf/rmf_task/blob/main/rmf_task_sequence/src/rmf_task_sequence/events/GoToPlace.cpp#L252
             */
            var firstPhase = (_a = Object.values(task.phases)[0]) === null || _a === void 0 ? void 0 : _a.detail;
            if (!firstPhase) {
                setStartWaypoint('');
                return;
            }
            var colonIndex = firstPhase.toString().indexOf(':');
            var closingBracketIndex = firstPhase.toString().indexOf(']');
            setStartWaypoint(firstPhase.toString().substring(colonIndex + 1, closingBracketIndex));
            var lastPhase = (_b = Object.values(task.phases).pop()) === null || _b === void 0 ? void 0 : _b.detail;
            if (lastPhase) {
                setFinishWaypoint(lastPhase.toString().replace(/^.+:/, '').replace(/.$/, ''));
            }
        }
    }, [task]);
    return (React.createElement(StyledDiv, null,
        React.createElement(Typography, { variant: "h6", style: { textAlign: 'center' }, gutterBottom: true }, title ? title : task.booking.id),
        React.createElement(Divider, null),
        React.createElement("div", { style: { marginBottom: theme.spacing(1) } }),
        React.createElement(InfoLine, null,
            React.createElement("span", null, "Status:"),
            React.createElement(InfoValue, null, task.status || 'unknown')),
        React.createElement(InfoLine, null,
            React.createElement("span", null, "Start Waypoint:"),
            React.createElement(InfoValue, null, startWaypoint)),
        React.createElement(InfoLine, null,
            React.createElement("span", null, "Finish Waypoint"),
            React.createElement(InfoValue, null, finishWaypoint)),
        React.createElement("div", { style: { padding: '4px' } },
            React.createElement(TaskTimeline, { taskState: task }))));
}
