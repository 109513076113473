var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, styled, Table, TableBody, TableCell, TableHead, TableRow, } from '@mui/material';
import { ArrowCircleDown, ArrowCircleUp } from '@mui/icons-material';
import { Status } from 'api-client';
import clsx from 'clsx';
import React from 'react';
var classes = {
    taskRowHover: 'task-table-taskrow-hover',
    infoRow: 'task-table-info-row',
    phasesCell: 'task-table-phase-cell',
    phasesRow: 'task-table-phase-row',
    taskActiveCell: 'task-table-active-cell',
    taskCancelledCell: 'task-table-cancelled-cell',
    taskCompletedCell: 'task-table-completed-cell',
    taskFailedCell: 'task-table-failed-cell',
    taskPendingCell: 'task-table-pending-cell',
    taskQueuedCell: 'task-table-queued-cell',
    taskUnknownCell: 'task-table-unknown-cell',
};
var StyledTable = styled(function (props) { return React.createElement(Table, __assign({}, props)); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& ." + classes.taskRowHover] = {
            background: theme.palette.action.hover,
            cursor: 'pointer',
        },
        _b["& ." + classes.infoRow] = {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        _b["& ." + classes.phasesCell] = {
            padding: "0 " + theme.spacing(1) + "px 0 " + theme.spacing(1) + "px",
            boxShadow: "" + theme.shadows[1],
            '&:last-child': {
                paddingRight: theme.spacing(1) + "px",
            },
        },
        _b["& ." + classes.phasesRow] = {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        _b["& ." + classes.taskActiveCell] = {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.getContrastText(theme.palette.success.light),
        },
        _b["& ." + classes.taskCancelledCell] = {
            backgroundColor: theme.palette.grey[500],
            color: theme.palette.getContrastText(theme.palette.grey[500]),
        },
        _b["& ." + classes.taskCompletedCell] = {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.getContrastText(theme.palette.info.light),
        },
        _b["& ." + classes.taskFailedCell] = {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.getContrastText(theme.palette.error.main),
        },
        _b["& ." + classes.taskQueuedCell] = {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.getContrastText(theme.palette.grey[300]),
        },
        _b["& ." + classes.taskUnknownCell] = {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.getContrastText(theme.palette.warning.main),
        },
        _b);
});
function TaskRow(_a) {
    var task = _a.task, onClick = _a.onClick;
    // replace all temp info
    var _b = React.useState(false), hover = _b[0], setHover = _b[1];
    var getTaskStateCellClass = function (task) {
        switch (task.status) {
            case Status.Underway:
                return classes.taskActiveCell;
            case Status.Completed:
                return classes.taskCompletedCell;
            case Status.Canceled:
                return classes.taskCancelledCell;
            case Status.Failed:
                return classes.taskFailedCell;
            case Status.Queued:
                return classes.taskQueuedCell;
            default:
                return classes.taskUnknownCell;
        }
    };
    var taskStateCellClass = getTaskStateCellClass(task);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { className: clsx(hover && classes.taskRowHover), onClick: onClick, onMouseOver: function () { return setHover(true); }, onMouseOut: function () { return setHover(false); } },
            React.createElement(TableCell, null, task.unix_millis_start_time
                ? new Date(task.unix_millis_start_time).toLocaleDateString()
                : 'unknown'),
            React.createElement(TableCell, null, task.booking.id),
            React.createElement(TableCell, null, task.category),
            React.createElement(TableCell, null, task.assigned_to ? task.assigned_to.name : 'unknown'),
            React.createElement(TableCell, null, task.unix_millis_start_time
                ? new Date(task.unix_millis_start_time).toLocaleTimeString()
                : '-'),
            React.createElement(TableCell, null, task.unix_millis_finish_time
                ? new Date(task.unix_millis_finish_time).toLocaleTimeString()
                : '-'),
            React.createElement(TableCell, { className: taskStateCellClass }, task.status || 'unknown'))));
}
export function TaskTable(_a) {
    var tasks = _a.tasks, onTaskClick = _a.onTaskClick, onDateTitleClick = _a.onDateTitleClick, chronologicalOrder = _a.chronologicalOrder;
    return (React.createElement(StyledTable, { stickyHeader: true, size: "small" },
        React.createElement(TableHead, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, { sx: {
                        padding: 0,
                    } },
                    React.createElement(Button, { variant: "text", onClick: function (ev) { return onDateTitleClick && onDateTitleClick(ev); }, sx: {
                            color: 'inherit',
                            textTransform: 'none',
                            width: '100%',
                        } },
                        "Date \u00A0",
                        chronologicalOrder ? React.createElement(ArrowCircleDown, null) : React.createElement(ArrowCircleUp, null))),
                React.createElement(TableCell, null, "Task Id"),
                React.createElement(TableCell, null, "Category"),
                React.createElement(TableCell, null, "Assignee"),
                React.createElement(TableCell, null, "Start Time"),
                React.createElement(TableCell, null, "End Time"),
                React.createElement(TableCell, null, "State"))),
        React.createElement(TableBody, null, tasks.map(function (task) { return (React.createElement(TaskRow, { key: task.booking.id, task: task, onClick: function (ev) { return onTaskClick && onTaskClick(ev, task); } })); }))));
}
