"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChargerCancel = void 0;
var ChargerCancel = /** @class */ (function () {
    function ChargerCancel(fields) {
        if (fields === void 0) { fields = {}; }
        this.charger_name = fields.charger_name || '';
        this.request_id = fields.request_id || '';
    }
    ChargerCancel.validate = function (obj) {
        if (typeof obj['charger_name'] !== 'string') {
            throw new Error('expected "charger_name" to be "string"');
        }
        if (typeof obj['request_id'] !== 'string') {
            throw new Error('expected "request_id" to be "string"');
        }
    };
    ChargerCancel.FullTypeName = 'rmf_charger_msgs/msg/ChargerCancel';
    return ChargerCancel;
}());
exports.ChargerCancel = ChargerCancel;
/*
string charger_name  # the charger that should process this message

# A unique ID for each request. It is advised that you prefix this
# with the sender's node name. This is used for error tracking
# later on
string request_id

*/
