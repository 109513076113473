"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitTask = void 0;
var SubmitTask_Request_1 = require("./SubmitTask_Request");
var SubmitTask_Response_1 = require("./SubmitTask_Response");
var SubmitTask = /** @class */ (function () {
    function SubmitTask() {
    }
    SubmitTask.FullServiceName = 'rmf_task_msgs/srv/SubmitTask';
    SubmitTask.Request = SubmitTask_Request_1.SubmitTask_Request;
    SubmitTask.Response = SubmitTask_Response_1.SubmitTask_Response;
    return SubmitTask;
}());
exports.SubmitTask = SubmitTask;
