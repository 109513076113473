"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBuildingMap_Request = void 0;
var GetBuildingMap_Request = /** @class */ (function () {
    function GetBuildingMap_Request(fields) {
        if (fields === void 0) { fields = {}; }
    }
    GetBuildingMap_Request.validate = function (obj) { };
    GetBuildingMap_Request.FullTypeName = 'rmf_building_map_msgs/srv/GetBuildingMap_Request';
    return GetBuildingMap_Request;
}());
exports.GetBuildingMap_Request = GetBuildingMap_Request;
/*

*/
