"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTaskList = void 0;
var GetTaskList_Request_1 = require("./GetTaskList_Request");
var GetTaskList_Response_1 = require("./GetTaskList_Response");
var GetTaskList = /** @class */ (function () {
    function GetTaskList() {
    }
    GetTaskList.FullServiceName = 'rmf_task_msgs/srv/GetTaskList';
    GetTaskList.Request = GetTaskList_Request_1.GetTaskList_Request;
    GetTaskList.Response = GetTaskList_Response_1.GetTaskList_Response;
    return GetTaskList;
}());
exports.GetTaskList = GetTaskList;
