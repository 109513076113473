"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BidNotice = void 0;
var TaskProfile_1 = require("../../rmf_task_msgs/msg/TaskProfile");
var Duration_1 = require("../../builtin_interfaces/msg/Duration");
var BidNotice = /** @class */ (function () {
    function BidNotice(fields) {
        if (fields === void 0) { fields = {}; }
        this.task_profile = fields.task_profile || new TaskProfile_1.TaskProfile();
        this.time_window = fields.time_window || new Duration_1.Duration();
    }
    BidNotice.validate = function (obj) {
        try {
            TaskProfile_1.TaskProfile.validate(obj['task_profile']);
        }
        catch (e) {
            throw new Error('in "task_profile":\n  ' + e.message);
        }
        try {
            Duration_1.Duration.validate(obj['time_window']);
        }
        catch (e) {
            throw new Error('in "time_window":\n  ' + e.message);
        }
    };
    BidNotice.FullTypeName = 'rmf_task_msgs/msg/BidNotice';
    return BidNotice;
}());
exports.BidNotice = BidNotice;
/*
# This message is published by the Task Dispatcher node to notify all
# Fleet Adapters to participate in a bidding process for a new task.
# Fleet Adapters may then submit a BidProposal message with their best proposal
# to accommodate the new task.

# Details of the new task
TaskProfile task_profile

# Duration for which the bidding is open
builtin_interfaces/Duration time_window

*/
