/* CommonJS in Node.js environment */
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { useQuery } from '@tanstack/react-query';
import { useState, useContext } from 'react';
import { convertToDisplay, eventToToastEvent, getCalendarIdAndColor } from './utils/utils';
import { useEffect } from 'react';
import { EventDialog } from './EventDialog';
import { getEventType } from './utils/event_form_utils';
import { RmfAppContext } from '../rmf-app';

export function TUICalendar(props) {
  const { calendarRef, robotsCalendarArray, filterStates, onEditCallback } = props;
  const [showEvent, setShowEvent] = useState(false);
  const [eventIdToShow, setEventIdToShow] = useState(null);

  const rmf = useContext(RmfAppContext);

  console.log('Calendar');
  console.debug(`Event ID to show is ${eventIdToShow}`);

  const getCalendarCurrentInstance = () => {
    if (!calendarRef.current) return null;
    return calendarRef.current.getInstance();
  };

  const { isPending, error, data } = useQuery({
    queryKey: ['scheduleData'],
    queryFn: () => rmf.schedulerApi.getScheduleSchedulerScheduleGet().then((res) => res.data),
    staleTime: 1000,
    refetchInterval: 1000,
  });

  useEffect(() => {
    console.log('USE EFFECT IN CALENDAR');
    filterStates.forEach((toShow, index) => {
      for (let calendar of Object.values(robotsCalendarArray[index][1].calendars)) {
        getCalendarCurrentInstance()?.setCalendarVisibility([calendar], toShow);
      }
    });
  }, [data]);

  if (isPending) {
    return <Calendar usageStatistics={false} ref={calendarRef} isReadOnly={true} />;
    // ADD WARNING PENDING
  }

  if (error) {
    console.log(`${error}`);
    return `Error getting rmf web api server is up! ${error}`;
  }

  if (!data) {
    return;
  }

  if (!'detail' in data) {
    return 'Invalid JSON response!';
  }

  if (data.detail == 'rmf service timed out') {
    return 'Unable to connect  CAG RMF Scheduler';
  }

  // When true the clicked event will be rendered
  const renderEventDialog = () => {
    if (!showEvent || !eventIdToShow) return;
    const onClose = () => {
      setEventIdToShow(null);
      setShowEvent(false);
    };

    if (!data.schedule.events.hasOwnProperty(eventIdToShow)) {
      console.log(`Event ${eventIdToShow} might have been deleted! Closing dialogue box`);
      return;
    }
    const calendarInstance = getCalendarCurrentInstance();

    if (!calendarInstance) return;

    // Refactor to just use event information
    const eventDetails = convertToDisplay(eventIdToShow, data.schedule);
    const eventType = getEventType(eventIdToShow, data.schedule);
    console.log(`EVENT TYPE IS ${eventType}`);

    return (
      <EventDialog
        onClose={onClose}
        eventDetails={eventDetails}
        open={showEvent}
        eventType={eventType}
        eventInfo={data.schedule.events[eventIdToShow]}
        eventId={eventIdToShow}
        onEditCallback={(editOptions) => onEditCallback(eventIdToShow, data.schedule, editOptions)}
      ></EventDialog>
    );
  };

  const onClickEvent = (e) => {
    if (!e.event) return;
    console.log(`EVENT ${e.event.id} WAS CLICKED!!!!!!!!!!!!!!!!!!`);
    setShowEvent(true);
    setEventIdToShow(e.event.id);
  };

  // Render events if there are any
  let toast_events = [];
  for (const event_id in data.schedule.events) {
    // Get the calendar id according to the event type and type of task
    const [calendarId, colour] = getCalendarIdAndColor(
      data.schedule.events[event_id],
      robotsCalendarArray,
    );
    let toast_event = eventToToastEvent(
      event_id,
      data.schedule.events[event_id],
      calendarId,
      colour,
    );
    if (!toast_event) {
      continue;
    }
    toast_events.push(toast_event);
  }

  console.log('Returning calendar');

  // Callback after calender is rendered as instance may not be valid
  // before it is mounted
  const calendarRenderCallback = (event) => {
    // console.debug(`CALENDAR RENDER CALLBACK ${JSON.stringify(event)}`);
  };

  return (
    <div>
      <Calendar
        usageStatistics={false}
        ref={calendarRef}
        events={toast_events}
        isReadOnly={true}
        onAfterRenderEvent={calendarRenderCallback}
        onClickEvent={onClickEvent}
        view="day"
        week={{ taskView: false }}
      />
      {renderEventDialog()}
    </div>
  );
}
