"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoorState = void 0;
var Time_1 = require("../../builtin_interfaces/msg/Time");
var DoorMode_1 = require("../../rmf_door_msgs/msg/DoorMode");
var DoorState = /** @class */ (function () {
    function DoorState(fields) {
        if (fields === void 0) { fields = {}; }
        this.door_time = fields.door_time || new Time_1.Time();
        this.door_name = fields.door_name || '';
        this.current_mode = fields.current_mode || new DoorMode_1.DoorMode();
    }
    DoorState.validate = function (obj) {
        try {
            Time_1.Time.validate(obj['door_time']);
        }
        catch (e) {
            throw new Error('in "door_time":\n  ' + e.message);
        }
        if (typeof obj['door_name'] !== 'string') {
            throw new Error('expected "door_name" to be "string"');
        }
        try {
            DoorMode_1.DoorMode.validate(obj['current_mode']);
        }
        catch (e) {
            throw new Error('in "current_mode":\n  ' + e.message);
        }
    };
    DoorState.FullTypeName = 'rmf_door_msgs/msg/DoorState';
    return DoorState;
}());
exports.DoorState = DoorState;
/*
builtin_interfaces/Time door_time
string door_name
DoorMode current_mode

*/
