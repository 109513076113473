var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, TreeItem, TreeView, } from '@mui/lab';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Status } from 'api-client';
import React from 'react';
var classes = {
    paper: 'timeline-paper',
    secondaryTail: 'timeline-secondary-tail',
    timelineRoot: 'timeline-root',
};
var StyledTimeLine = styled(function (props) { return React.createElement(Timeline, __assign({}, props)); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& ." + classes.paper] = {
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
            width: '200px',
            overflow: 'auto',
            display: 'inline-block',
            maxHeight: '95%',
        },
        _b["& ." + classes.secondaryTail] = {
            backgroundColor: theme.palette.secondary.main,
        },
        _b["&." + classes.timelineRoot] = {
            padding: '6px 0px',
        },
        _b);
});
function NestedEvents(eventStates, eventId) {
    if (eventStates !== undefined && eventStates !== null && eventId !== undefined) {
        var event_1 = eventStates[eventId];
        if (event_1 !== undefined) {
            return (React.createElement(TreeItem, { nodeId: "event-" + event_1.id, key: "event-" + event_1.id, label: event_1.name || 'undefined' }, event_1.deps
                ? event_1.deps.map(function (childId) {
                    return NestedEvents(eventStates, childId);
                })
                : null));
        }
    }
    return null;
}
function colorDot(phase) {
    if (phase == null)
        return 'error';
    if (phase.final_event_id == null || phase.events == null)
        return 'grey';
    var root_event = phase.events[phase.final_event_id];
    if (root_event == null)
        return 'error';
    if (root_event.status == null)
        return 'error';
    switch (root_event.status) {
        case Status.Uninitialized:
        case Status.Blocked:
        case Status.Error:
        case Status.Failed:
            return 'error';
        case Status.Queued:
        case Status.Standby:
            return 'grey';
        case Status.Underway:
            return 'success';
        case Status.Skipped:
        case Status.Canceled:
        case Status.Killed:
            return 'secondary';
        case Status.Delayed:
            return 'warning';
        case Status.Completed:
            return 'primary';
        default:
            return 'error';
    }
}
function RenderPhase(phase) {
    return (React.createElement(TimelineItem, { key: phase.id, sx: { width: 0 } },
        React.createElement(TimelineOppositeContent, { color: "text.secondary" },
            React.createElement(Typography, { variant: "overline", color: "textSecondary", style: { textAlign: 'justify' } }, phase.unix_millis_start_time != null
                ? new Date(phase.unix_millis_start_time).toLocaleTimeString()
                : null)),
        React.createElement(TimelineSeparator, null,
            React.createElement(TimelineDot, { color: colorDot(phase) }),
            React.createElement(TimelineConnector, null)),
        React.createElement(TimelineContent, null,
            React.createElement(Typography, { variant: "overline", color: "textSecondary", style: { textAlign: 'justify' } },
                phase.id,
                ". ",
                phase.category),
            React.createElement(TreeView, { defaultCollapseIcon: React.createElement(ExpandMoreIcon, null), defaultExpandIcon: React.createElement(ChevronRightIcon, null) }, NestedEvents(phase.events, phase.final_event_id)))));
}
export function TaskTimeline(_a) {
    var taskState = _a.taskState;
    var phases = taskState.phases ? Object.values(taskState.phases) : [];
    return (React.createElement(StyledTimeLine, { className: classes.timelineRoot }, phases.map(function (phase) { return RenderPhase(phase); })));
}
