var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Grid, Slider, Typography, styled } from '@mui/material';
import GestureIcon from '@mui/icons-material/Gesture';
import Leaflet from 'leaflet';
import React from 'react';
import ReactDOM from 'react-dom';
import { MapControl, withLeaflet } from 'react-leaflet';
import { PositiveIntField } from '../form-inputs';
var classes = {
    root: 'traj-time-control-root',
    container: 'traj-time-control-container',
    slider: 'traj-time-control-slider',
    textField: 'traj-time-control-textfield',
};
var StyledDiv = styled('div')(function () {
    var _a;
    return (_a = {},
        _a["&." + classes.root] = {
            width: '100%',
            height: '100%',
        },
        _a["& ." + classes.container] = {
            padding: 16,
        },
        _a["& ." + classes.slider] = {
            width: 200,
            verticalAlign: 'middle',
        },
        _a["& ." + classes.textField] = {
            width: '3em',
        },
        _a);
});
function Component(_a) {
    var value = _a.value, min = _a.min, max = _a.max, onChange = _a.onChange;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var getValueFormat = function (value) { return "" + value / 60000; };
    return (React.createElement(StyledDiv, { className: classes.root, onMouseEnter: function () { return setOpen(true); }, onMouseLeave: function () { return setOpen(false); }, "aria-label": "trajectory time control", "aria-haspopup": true },
        React.createElement("div", { className: classes.container, style: { display: open ? 'block' : 'none' } },
            React.createElement(Typography, { id: "trajectory-time" }, "Trajectory Time (min)"),
            React.createElement(Grid, { container: true, spacing: 2, alignItems: "center" },
                React.createElement(Grid, { item: true },
                    React.createElement(Slider, { value: value, min: min, step: 60000, max: max, getAriaValueText: getValueFormat, valueLabelFormat: getValueFormat, valueLabelDisplay: "auto", "aria-labelledby": "trajectory-time", className: classes.slider, onChange: onChange })),
                React.createElement(Grid, { item: true },
                    React.createElement(PositiveIntField, { value: value / 60000, margin: "dense", className: classes.textField, onChange: function (ev, newValue) { return onChange && onChange(ev, newValue * 60000); } })))),
        React.createElement(GestureIcon, { style: { verticalAlign: 'middle', display: open ? 'none' : 'block' }, fontSize: "large" })));
}
var BaseTrajectoryTimeControl = /** @class */ (function (_super) {
    __extends(BaseTrajectoryTimeControl, _super);
    function BaseTrajectoryTimeControl(props) {
        var _this = _super.call(this, props) || this;
        _this._container = Leaflet.DomUtil.create('div');
        _this._container.className = 'leaflet-control-layers';
        return _this;
    }
    BaseTrajectoryTimeControl.prototype.createLeafletElement = function (props) {
        var _this = this;
        var LeafletControl = Leaflet.Control.extend({
            onAdd: function () {
                ReactDOM.createPortal(React.createElement(Component, __assign({}, props)), _this._container);
                // FIXME: react <= 16 installs event handlers on the root document. Stopping propagation
                // of the events on the container will stop react from receiving these events, but not
                // stopping them causes them to propagate to leaflet, causing weird behavior when
                // interacting with the widget.
                // Leaflet.DomEvent.disableClickPropagation(this._container);
                return _this._container;
            },
        });
        return new LeafletControl(props);
    };
    BaseTrajectoryTimeControl.prototype.updateLeafletElement = function (_fromProps, toProps) {
        ReactDOM.createPortal(React.createElement(Component, __assign({}, toProps)), this._container);
    };
    return BaseTrajectoryTimeControl;
}(MapControl));
export { BaseTrajectoryTimeControl };
export var TrajectoryTimeControl = withLeaflet(BaseTrajectoryTimeControl);
export default TrajectoryTimeControl;
