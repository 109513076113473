import React from 'react';
import { useLeaflet } from 'react-leaflet';
import { DefaultMarkerActualSizeMinZoom } from './constants';
/**
 * Get a scaling factor based on the current zoom level.
 * @param factor
 * @param threshold minimal zoom level for the scale to be applied.
 * @returns
 */
export function useAutoScale(factor, threshold) {
    if (threshold === void 0) { threshold = DefaultMarkerActualSizeMinZoom; }
    var leaflet = useLeaflet();
    var getScale = React.useCallback(function () {
        if (!leaflet.map || leaflet.map.getZoom() >= threshold)
            return 1;
        return factor / Math.pow(2, leaflet.map.getZoom());
    }, [leaflet.map, threshold, factor]);
    var _a = React.useState(getScale), scale = _a[0], setScale = _a[1];
    React.useEffect(function () {
        if (!leaflet.map)
            return;
        leaflet.map.on('zoom', function () { return setScale(getScale()); });
    }, [leaflet.map, getScale]);
    return scale;
}
