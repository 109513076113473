"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lift = void 0;
var Door_1 = require("../../rmf_building_map_msgs/msg/Door");
var Graph_1 = require("../../rmf_building_map_msgs/msg/Graph");
var Lift = /** @class */ (function () {
    function Lift(fields) {
        if (fields === void 0) { fields = {}; }
        this.name = fields.name || '';
        this.levels = fields.levels || [];
        this.doors = fields.doors || [];
        this.wall_graph = fields.wall_graph || new Graph_1.Graph();
        this.ref_x = fields.ref_x || 0;
        this.ref_y = fields.ref_y || 0;
        this.ref_yaw = fields.ref_yaw || 0;
        this.width = fields.width || 0;
        this.depth = fields.depth || 0;
    }
    Lift.validate = function (obj) {
        var e_1, _a, e_2, _b;
        if (typeof obj['name'] !== 'string') {
            throw new Error('expected "name" to be "string"');
        }
        if (!Array.isArray(obj['levels'])) {
            throw new Error('expected "levels" to be an array');
        }
        try {
            for (var _c = __values(obj['levels'].entries()), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), i = _e[0], v = _e[1];
                if (typeof v !== 'string') {
                    throw new Error("expected index " + i + " of \"levels\" to be \"string\"");
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (!Array.isArray(obj['doors'])) {
            throw new Error('expected "doors" to be an array');
        }
        try {
            for (var _f = __values(obj['doors'].entries()), _g = _f.next(); !_g.done; _g = _f.next()) {
                var _h = __read(_g.value, 2), i = _h[0], v = _h[1];
                try {
                    Door_1.Door.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"doors\":\n  " + e.message);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_2) throw e_2.error; }
        }
        try {
            Graph_1.Graph.validate(obj['wall_graph']);
        }
        catch (e) {
            throw new Error('in "wall_graph":\n  ' + e.message);
        }
        if (typeof obj['ref_x'] !== 'number') {
            throw new Error('expected "ref_x" to be "number"');
        }
        if (typeof obj['ref_y'] !== 'number') {
            throw new Error('expected "ref_y" to be "number"');
        }
        if (typeof obj['ref_yaw'] !== 'number') {
            throw new Error('expected "ref_yaw" to be "number"');
        }
        if (typeof obj['width'] !== 'number') {
            throw new Error('expected "width" to be "number"');
        }
        if (typeof obj['depth'] !== 'number') {
            throw new Error('expected "depth" to be "number"');
        }
    };
    Lift.FullTypeName = 'rmf_building_map_msgs/msg/Lift';
    return Lift;
}());
exports.Lift = Lift;
/*
string name
string[] levels
Door[] doors
Graph wall_graph

# (ref_x, ref_y, ref_yaw) is a "reference orientation" of the lift cabin
# which can be used to align floors.
float32 ref_x
float32 ref_y
float32 ref_yaw

# width and depth of the cabin
float32 width
float32 depth
*/
