import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Grid,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import KeyIcon from '@mui/icons-material/Key';
import Grow from '@mui/material/Grow';

export const TelegramOTPCard: React.FC<{ otp: number; timeLeftToExpire: number }> = (data) => {
  const [timeLeftToExpireCopy, setTimeLeftToExpireCopy] = useState<number>(
    data.timeLeftToExpire < 0 ? 0 : data.timeLeftToExpire,
  );
  const [timeColor, setTimeColor] = useState<string>('#4CAF50');

  useEffect(() => {
    const id = setInterval(() => {
      if (timeLeftToExpireCopy > 0) {
        setTimeLeftToExpireCopy(timeLeftToExpireCopy - 1);
      }

      if (timeLeftToExpireCopy <= 10) {
        setTimeColor('#FF0000');
      }
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [timeLeftToExpireCopy]);

  return (
    <Grow in={true}>
      <Card sx={{ minWidth: 275 }}>
        <CardHeader
          sx={{ background: '#1976D2', color: '#FFFFFF' }}
          action={
            <IconButton sx={{ color: '#FFFFFF' }}>
              <KeyIcon />
            </IconButton>
          }
          titleTypographyProps={{ variant: 'h4', fontWeight: 'bold' }}
          title="OTP"
        />
        <CardContent>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item>
              <Typography gutterBottom variant="h3" component="div" sx={{ textAlign: 'center' }}>
                {data.otp}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'right' }}>
          <Avatar sx={{ background: timeColor, color: '#FFFFFF' }}>{timeLeftToExpireCopy}</Avatar>
        </CardActions>
      </Card>
    </Grow>
  );
};

export default TelegramOTPCard;
