var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Paper, styled, useTheme } from '@mui/material';
import React from 'react';
import 'react-grid-layout/css/styles.css';
import { WindowManagerStateContext } from './context';
import { WindowToolbar } from './window-toolbar';
export var Window = styled(React.forwardRef(function (_a, ref) {
    var title = _a.title, toolbar = _a.toolbar, onClose = _a.onClose, sx = _a.sx, children = _a.children, otherProps = __rest(_a, ["title", "toolbar", "onClose", "sx", "children"]);
    var theme = useTheme();
    // The resize marker injected by `react-grid-layout` must be a direct children, but we
    // want to wrap the window components in a div so it shows a scrollbar. So we assume that
    // the injected resize marker is always the last component and render it separately.
    var childrenArr = React.Children.toArray(children);
    var childComponents = childrenArr.slice(0, childrenArr.length - 1);
    var resizeComponent = childrenArr[childrenArr.length - 1];
    var windowManagerState = React.useContext(WindowManagerStateContext);
    return (React.createElement(Paper, __assign({ ref: ref, variant: "outlined", sx: __assign({ cursor: windowManagerState.designMode ? 'move' : undefined, borderRadius: theme.shape.borderRadius }, sx) }, otherProps),
        React.createElement(Grid, { item: true, className: "rgl-draggable" },
            React.createElement(WindowToolbar, { title: title },
                toolbar,
                windowManagerState.designMode && (React.createElement(IconButton, { color: "inherit", onClick: function () { return onClose && onClose(); } },
                    React.createElement(CloseIcon, null))))),
        React.createElement("div", { style: { overflow: 'auto', width: '100%', height: '100%', cursor: 'auto' } }, childComponents),
        resizeComponent));
}))({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'auto',
});
