"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModeRequest = void 0;
var RobotMode_1 = require("../../rmf_fleet_msgs/msg/RobotMode");
var ModeParameter_1 = require("../../rmf_fleet_msgs/msg/ModeParameter");
var ModeRequest = /** @class */ (function () {
    function ModeRequest(fields) {
        if (fields === void 0) { fields = {}; }
        this.fleet_name = fields.fleet_name || '';
        this.robot_name = fields.robot_name || '';
        this.mode = fields.mode || new RobotMode_1.RobotMode();
        this.task_id = fields.task_id || '';
        this.parameters = fields.parameters || [];
    }
    ModeRequest.validate = function (obj) {
        var e_1, _a;
        if (typeof obj['fleet_name'] !== 'string') {
            throw new Error('expected "fleet_name" to be "string"');
        }
        if (typeof obj['robot_name'] !== 'string') {
            throw new Error('expected "robot_name" to be "string"');
        }
        try {
            RobotMode_1.RobotMode.validate(obj['mode']);
        }
        catch (e) {
            throw new Error('in "mode":\n  ' + e.message);
        }
        if (typeof obj['task_id'] !== 'string') {
            throw new Error('expected "task_id" to be "string"');
        }
        if (!Array.isArray(obj['parameters'])) {
            throw new Error('expected "parameters" to be an array');
        }
        try {
            for (var _b = __values(obj['parameters'].entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), i = _d[0], v = _d[1];
                try {
                    ModeParameter_1.ModeParameter.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"parameters\":\n  " + e.message);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    ModeRequest.FullTypeName = 'rmf_fleet_msgs/msg/ModeRequest';
    return ModeRequest;
}());
exports.ModeRequest = ModeRequest;
/*
string fleet_name
string robot_name
RobotMode mode

# task_id must be copied into future RobotState messages
string task_id

# Some mode changes require parameters. For example, the name of a dock.
ModeParameter[] parameters

*/
