"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Graph = void 0;
var GraphNode_1 = require("../../rmf_building_map_msgs/msg/GraphNode");
var GraphEdge_1 = require("../../rmf_building_map_msgs/msg/GraphEdge");
var Param_1 = require("../../rmf_building_map_msgs/msg/Param");
var Graph = /** @class */ (function () {
    function Graph(fields) {
        if (fields === void 0) { fields = {}; }
        this.name = fields.name || '';
        this.vertices = fields.vertices || [];
        this.edges = fields.edges || [];
        this.params = fields.params || [];
    }
    Graph.validate = function (obj) {
        var e_1, _a, e_2, _b, e_3, _c;
        if (typeof obj['name'] !== 'string') {
            throw new Error('expected "name" to be "string"');
        }
        if (!Array.isArray(obj['vertices'])) {
            throw new Error('expected "vertices" to be an array');
        }
        try {
            for (var _d = __values(obj['vertices'].entries()), _e = _d.next(); !_e.done; _e = _d.next()) {
                var _f = __read(_e.value, 2), i = _f[0], v = _f[1];
                try {
                    GraphNode_1.GraphNode.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"vertices\":\n  " + e.message);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (!Array.isArray(obj['edges'])) {
            throw new Error('expected "edges" to be an array');
        }
        try {
            for (var _g = __values(obj['edges'].entries()), _h = _g.next(); !_h.done; _h = _g.next()) {
                var _j = __read(_h.value, 2), i = _j[0], v = _j[1];
                try {
                    GraphEdge_1.GraphEdge.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"edges\":\n  " + e.message);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
            }
            finally { if (e_2) throw e_2.error; }
        }
        if (!Array.isArray(obj['params'])) {
            throw new Error('expected "params" to be an array');
        }
        try {
            for (var _k = __values(obj['params'].entries()), _l = _k.next(); !_l.done; _l = _k.next()) {
                var _m = __read(_l.value, 2), i = _m[0], v = _m[1];
                try {
                    Param_1.Param.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"params\":\n  " + e.message);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_l && !_l.done && (_c = _k.return)) _c.call(_k);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    Graph.FullTypeName = 'rmf_building_map_msgs/msg/Graph';
    return Graph;
}());
exports.Graph = Graph;
/*
string name
GraphNode[] vertices
GraphEdge[] edges
Param[] params

*/
