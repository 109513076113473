"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorParameter = void 0;
var BehaviorParameter = /** @class */ (function () {
    function BehaviorParameter(fields) {
        if (fields === void 0) { fields = {}; }
        this.name = fields.name || '';
        this.value = fields.value || '';
    }
    BehaviorParameter.validate = function (obj) {
        if (typeof obj['name'] !== 'string') {
            throw new Error('expected "name" to be "string"');
        }
        if (typeof obj['value'] !== 'string') {
            throw new Error('expected "value" to be "string"');
        }
    };
    BehaviorParameter.FullTypeName = 'rmf_task_msgs/msg/BehaviorParameter';
    return BehaviorParameter;
}());
exports.BehaviorParameter = BehaviorParameter;
/*
string name
string value

*/
