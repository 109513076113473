/**
 * FIXME(kp): Make the whole task request system task agnostic.
 * For that RMF needs to support task discovery and UI schemas https://github.com/open-rmf/rmf_api_msgs/issues/32.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import UpdateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceOutlined from '@mui/icons-material/PlaceOutlined';
import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Radio, RadioGroup, styled, TextField, Typography, useTheme, } from '@mui/material';
import { DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers';
import React from 'react';
import { Loading } from '..';
import { ConfirmationDialog } from '../confirmation-dialog';
import { PositiveIntField } from '../form-inputs';
var classes = {
    title: 'dialogue-info-value',
    selectFileBtn: 'create-task-selected-file-btn',
    taskList: 'create-task-task-list',
    selectedTask: 'create-task-selected-task',
    actionBtn: 'dialogue-action-button',
};
var StyledDialog = styled(function (props) { return React.createElement(Dialog, __assign({}, props)); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& ." + classes.selectFileBtn] = {
            marginBottom: theme.spacing(1),
        },
        _b["& ." + classes.taskList] = {
            flex: '1 1 auto',
            minHeight: 400,
            maxHeight: '50vh',
            overflow: 'auto',
        },
        _b["& ." + classes.selectedTask] = {
            background: theme.palette.action.focus,
        },
        _b["& ." + classes.title] = {
            flex: '1 1 auto',
        },
        _b["& ." + classes.actionBtn] = {
            minWidth: 80,
        },
        _b);
});
function getShortDescription(taskRequest) {
    switch (taskRequest.category) {
        case 'clean': {
            return "[Clean] zone [" + taskRequest.description.zone + "]";
        }
        case 'delivery': {
            return "[Delivery] from [" + taskRequest.description.pickup.place + "] to [" + taskRequest.description.dropoff.place + "]";
        }
        case 'patrol': {
            return "[Patrol] [" + taskRequest.description.places[0] + "] to [" + taskRequest.description.places[1] + "]";
        }
        default:
            return "[Unknown] type \"" + taskRequest.category + "\"";
    }
}
function FormToolbar(_a) {
    var onSelectFileClick = _a.onSelectFileClick;
    return (React.createElement(Button, { "aria-label": "Select File", className: classes.selectFileBtn, variant: "contained", color: "primary", onClick: onSelectFileClick }, "Select File"));
}
function DeliveryTaskForm(_a) {
    var taskDesc = _a.taskDesc, deliveryWaypoints = _a.deliveryWaypoints, dispensers = _a.dispensers, ingestors = _a.ingestors, onChange = _a.onChange;
    var theme = useTheme();
    return (React.createElement(Grid, { container: true, spacing: theme.spacing(2), justifyContent: "center", alignItems: "center" },
        React.createElement(Grid, { item: true, xs: 8 },
            React.createElement(Autocomplete, { id: "pickup-location", freeSolo: true, fullWidth: true, options: deliveryWaypoints, value: taskDesc.pickup.place, onChange: function (_ev, newValue) {
                    return newValue !== null &&
                        onChange(__assign(__assign({}, taskDesc), { pickup: __assign(__assign({}, taskDesc.pickup), { place: newValue }) }));
                }, onBlur: function (ev) {
                    return onChange(__assign(__assign({}, taskDesc), { pickup: __assign(__assign({}, taskDesc.pickup), { place: ev.target.value }) }));
                }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: "Pickup Location" })); } })),
        React.createElement(Grid, { item: true, xs: 4 },
            React.createElement(Autocomplete, { id: "dispenser", freeSolo: true, fullWidth: true, options: dispensers, value: taskDesc.pickup.handler, onChange: function (_ev, newValue) {
                    return newValue !== null &&
                        onChange(__assign(__assign({}, taskDesc), { pickup: __assign(__assign({}, taskDesc.pickup), { handler: newValue }) }));
                }, onBlur: function (ev) {
                    return onChange(__assign(__assign({}, taskDesc), { pickup: __assign(__assign({}, taskDesc.pickup), { handler: ev.target.value }) }));
                }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: "Dispenser" })); } })),
        React.createElement(Grid, { item: true, xs: 8 },
            React.createElement(Autocomplete, { id: "dropoff-location", freeSolo: true, fullWidth: true, options: deliveryWaypoints, value: taskDesc.dropoff.place, onChange: function (_ev, newValue) {
                    return newValue !== null &&
                        onChange(__assign(__assign({}, taskDesc), { dropoff: __assign(__assign({}, taskDesc.dropoff), { place: newValue }) }));
                }, onBlur: function (ev) {
                    return onChange(__assign(__assign({}, taskDesc), { dropoff: __assign(__assign({}, taskDesc.dropoff), { place: ev.target.value }) }));
                }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: "Dropoff Location" })); } })),
        React.createElement(Grid, { item: true, xs: 4 },
            React.createElement(Autocomplete, { id: "ingestor", freeSolo: true, fullWidth: true, options: ingestors, value: taskDesc.dropoff.handler, onChange: function (_ev, newValue) {
                    return newValue !== null &&
                        onChange(__assign(__assign({}, taskDesc), { dropoff: __assign(__assign({}, taskDesc.dropoff), { handler: newValue }) }));
                }, onBlur: function (ev) {
                    return onChange(__assign(__assign({}, taskDesc), { dropoff: __assign(__assign({}, taskDesc.dropoff), { handler: ev.target.value }) }));
                }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: "Ingestor" })); } })),
        React.createElement(Grid, { item: true, xs: 4 },
            React.createElement(Autocomplete, { id: "pickup_sku", freeSolo: true, fullWidth: true, value: taskDesc.pickup.payload.sku, options: [], onChange: function (_ev, newValue) {
                    return newValue !== null &&
                        onChange(__assign(__assign({}, taskDesc), { pickup: __assign(__assign({}, taskDesc.pickup), { payload: __assign(__assign({}, taskDesc.pickup.payload), { sku: newValue }) }) }));
                }, onBlur: function (ev) {
                    return onChange(__assign(__assign({}, taskDesc), { pickup: __assign(__assign({}, taskDesc.dropoff), { payload: __assign(__assign({}, taskDesc.pickup.payload), { sku: ev.target.value }) }) }));
                }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: "Pickup SKU" })); } })),
        React.createElement(Grid, { item: true, xs: 2 },
            React.createElement(Autocomplete, { id: "pickup_quantity", freeSolo: true, fullWidth: true, value: taskDesc.pickup.payload.quantity, options: [], onChange: function (_ev, newValue) {
                    return newValue !== null &&
                        onChange(__assign(__assign({}, taskDesc), { pickup: __assign(__assign({}, taskDesc.pickup), { payload: __assign(__assign({}, taskDesc.pickup.payload), { quantity: typeof newValue == 'string' ? parseInt(newValue) : newValue }) }) }));
                }, onBlur: function (ev) {
                    return onChange(__assign(__assign({}, taskDesc), { pickup: __assign(__assign({}, taskDesc.pickup), { payload: __assign(__assign({}, taskDesc.pickup.payload), { quantity: parseInt(ev.target.value) }) }) }));
                }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: "Pickup Quantity" })); } })),
        React.createElement(Grid, { item: true, xs: 4 },
            React.createElement(Autocomplete, { id: "dropoff_sku", freeSolo: true, fullWidth: true, value: taskDesc.dropoff.payload.sku, options: [], onChange: function (_ev, newValue) {
                    return newValue !== null &&
                        onChange(__assign(__assign({}, taskDesc), { dropoff: __assign(__assign({}, taskDesc.dropoff), { payload: __assign(__assign({}, taskDesc.dropoff.payload), { sku: newValue }) }) }));
                }, onBlur: function (ev) {
                    return onChange(__assign(__assign({}, taskDesc), { dropoff: __assign(__assign({}, taskDesc.dropoff), { payload: __assign(__assign({}, taskDesc.dropoff.payload), { sku: ev.target.value }) }) }));
                }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: "Dropoff SKU" })); } })),
        React.createElement(Grid, { item: true, xs: 2 },
            React.createElement(Autocomplete, { id: "dropoff_quantity", freeSolo: true, fullWidth: true, value: taskDesc.dropoff.payload.quantity, options: [], onChange: function (_ev, newValue) {
                    return newValue !== null &&
                        onChange(__assign(__assign({}, taskDesc), { dropoff: __assign(__assign({}, taskDesc.dropoff), { payload: __assign(__assign({}, taskDesc.dropoff.payload), { quantity: typeof newValue == 'string' ? parseInt(newValue) : newValue }) }) }));
                }, onBlur: function (ev) {
                    return onChange(__assign(__assign({}, taskDesc), { dropoff: __assign(__assign({}, taskDesc.dropoff), { payload: __assign(__assign({}, taskDesc.dropoff.payload), { quantity: parseInt(ev.target.value) }) }) }));
                }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: "Dropoff Quantity" })); } }))));
}
function PlaceList(_a) {
    var places = _a.places, onClick = _a.onClick;
    var theme = useTheme();
    return (React.createElement(List, { dense: true, sx: {
            bgcolor: 'background.paper',
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        } }, places.map(function (value, index) { return (React.createElement(ListItem, { key: value + "-" + index, secondaryAction: React.createElement(IconButton, { edge: "end", "aria-label": "delete", onClick: function () { return onClick(index); } },
            React.createElement(DeleteIcon, null)) },
        React.createElement(ListItemIcon, null,
            React.createElement(PlaceOutlined, null)),
        React.createElement(ListItemText, { primary: "Place Name:   " + value }))); })));
}
function LoopTaskForm(_a) {
    var taskDesc = _a.taskDesc, loopWaypoints = _a.loopWaypoints, onChange = _a.onChange;
    var theme = useTheme();
    return (React.createElement(Grid, { container: true, spacing: theme.spacing(2), justifyContent: "center", alignItems: "center" },
        React.createElement(Grid, { item: true, xs: 10 },
            React.createElement(Autocomplete, { id: "place-input", freeSolo: true, fullWidth: true, options: loopWaypoints, onChange: function (_ev, newValue) {
                    return newValue !== null &&
                        onChange(__assign(__assign({}, taskDesc), { places: taskDesc.places.concat(newValue).filter(function (el) { return el; }) }));
                }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: "Place Name" })); } })),
        React.createElement(Grid, { item: true, xs: 2 },
            React.createElement(PositiveIntField, { id: "loops", label: "Loops", value: taskDesc.rounds, onChange: function (_ev, val) {
                    onChange(__assign(__assign({}, taskDesc), { rounds: val }));
                } })),
        React.createElement(Grid, { item: true, xs: 10 },
            React.createElement(PlaceList, { places: taskDesc && taskDesc.places ? taskDesc.places : [], onClick: function (places_index) {
                    return taskDesc.places.splice(places_index, 1) &&
                        onChange(__assign({}, taskDesc));
                } }))));
}
function CleanTaskForm(_a) {
    var taskDesc = _a.taskDesc, cleaningZones = _a.cleaningZones, onChange = _a.onChange;
    return (React.createElement(Autocomplete, { id: "cleaning-zone", freeSolo: true, fullWidth: true, options: cleaningZones, value: taskDesc.zone, onChange: function (_ev, newValue) {
            return newValue !== null &&
                onChange(__assign(__assign({}, taskDesc), { zone: newValue }));
        }, onBlur: function (ev) { return onChange(__assign(__assign({}, taskDesc), { zone: ev.target.value })); }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: "Cleaning Zone" })); } }));
}
function FavoriteTask(_a) {
    var listItemText = _a.listItemText, listItemClick = _a.listItemClick, favoriteTask = _a.favoriteTask, setFavoriteTask = _a.setFavoriteTask, setOpenDialog = _a.setOpenDialog, setCallToDelete = _a.setCallToDelete, setCallToUpdate = _a.setCallToUpdate;
    var theme = useTheme();
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { sx: { width: theme.spacing(30) }, onClick: function () {
                listItemClick();
                setCallToUpdate(false);
            }, role: "listitem button", button: true, divider: true },
            React.createElement(ListItemText, { primary: listItemText }),
            React.createElement(ListItemSecondaryAction, null,
                React.createElement(IconButton, { edge: "end", "aria-label": "update", onClick: function () {
                        setCallToUpdate(true);
                        listItemClick();
                    } },
                    React.createElement(UpdateIcon, null)),
                React.createElement(IconButton, { edge: "end", "aria-label": "delete", onClick: function () {
                        setOpenDialog(true);
                        setFavoriteTask(favoriteTask);
                        setCallToDelete(true);
                    } },
                    React.createElement(DeleteIcon, null))))));
}
function defaultCleanTask() {
    return {
        zone: '',
    };
}
function defaultLoopTask() {
    return {
        places: [],
        rounds: 1,
    };
}
function defaultDeliveryTask() {
    return {
        pickup: {
            place: '',
            handler: '',
            payload: {
                sku: '',
                quantity: 1,
            },
        },
        dropoff: {
            place: '',
            handler: '',
            payload: {
                sku: '',
                quantity: 1,
            },
        },
    };
}
function defaultTaskDescription(taskCategory) {
    switch (taskCategory) {
        case 'clean':
            return defaultCleanTask();
        case 'patrol':
            return defaultLoopTask();
        case 'delivery':
            return defaultDeliveryTask();
        default:
            return undefined;
    }
}
function defaultTask() {
    return {
        category: 'patrol',
        description: defaultLoopTask(),
        unix_millis_earliest_start_time: 0,
        unix_millis_request_time: Date.now(),
        priority: { type: 'binary', value: 0 },
        requester: '',
    };
}
var ScheduleUntilValue;
(function (ScheduleUntilValue) {
    ScheduleUntilValue["NEVER"] = "never";
    ScheduleUntilValue["ON"] = "on";
})(ScheduleUntilValue || (ScheduleUntilValue = {}));
var DaySelectorSwitch = function (_a) {
    var disabled = _a.disabled, onChange = _a.onChange, value = _a.value;
    var theme = useTheme();
    var renderChip = function (idx, text) { return (React.createElement(Chip, { key: idx, label: text, color: "primary", sx: { '&:hover': {}, margin: theme.spacing(0.25) }, variant: value[idx] && !disabled ? 'filled' : 'outlined', disabled: disabled, onClick: function () {
            value[idx] = !value[idx];
            onChange(__spreadArray([], value, true));
        } })); };
    return (React.createElement("div", null,
        React.createElement(TextField, { label: "Recurring Every", color: "primary", InputProps: {
                disabled: true,
                startAdornment: [
                    renderChip(0, 'Mon'),
                    renderChip(1, 'Tue'),
                    renderChip(2, 'Wed'),
                    renderChip(3, 'Thu'),
                    renderChip(4, 'Fri'),
                    renderChip(5, 'Sat'),
                    renderChip(6, 'Sun'),
                ],
            } })));
};
var defaultFavoriteTask = function () {
    return {
        id: '',
        name: '',
        category: 'patrol',
        description: defaultLoopTask(),
        unix_millis_earliest_start_time: 0,
        priority: { type: 'binary', value: 0 },
        user: '',
    };
};
export function CreateTaskForm(_a) {
    var _this = this;
    var _b;
    var user = _a.user, _c = _a.cleaningZones, cleaningZones = _c === void 0 ? [] : _c, _d = _a.loopWaypoints, loopWaypoints = _d === void 0 ? [] : _d, _e = _a.deliveryWaypoints, deliveryWaypoints = _e === void 0 ? [] : _e, _f = _a.dispensers, dispensers = _f === void 0 ? [] : _f, _g = _a.ingestors, ingestors = _g === void 0 ? [] : _g, _h = _a.favoritesTasks, favoritesTasks = _h === void 0 ? [] : _h, submitTasks = _a.submitTasks, tasksFromFile = _a.tasksFromFile, onClose = _a.onClose, onSuccess = _a.onSuccess, onFail = _a.onFail, onSuccessFavoriteTask = _a.onSuccessFavoriteTask, onFailFavoriteTask = _a.onFailFavoriteTask, submitFavoriteTask = _a.submitFavoriteTask, deleteFavoriteTask = _a.deleteFavoriteTask, onSuccessScheduling = _a.onSuccessScheduling, onFailScheduling = _a.onFailScheduling, otherProps = __rest(_a, ["user", "cleaningZones", "loopWaypoints", "deliveryWaypoints", "dispensers", "ingestors", "favoritesTasks", "submitTasks", "tasksFromFile", "onClose", "onSuccess", "onFail", "onSuccessFavoriteTask", "onFailFavoriteTask", "submitFavoriteTask", "deleteFavoriteTask", "onSuccessScheduling", "onFailScheduling"]);
    var theme = useTheme();
    var _j = React.useState(false), openFavoriteDialog = _j[0], setOpenFavoriteDialog = _j[1];
    var _k = React.useState(false), callToDeleteFavoriteTask = _k[0], setCallToDeleteFavoriteTask = _k[1];
    var _l = React.useState(false), callToUpdateFavoriteTask = _l[0], setCallToUpdateFavoriteTask = _l[1];
    var _m = React.useState(false), deletingFavoriteTask = _m[0], setDeletingFavoriteTask = _m[1];
    var _o = React.useState(defaultFavoriteTask()), favoriteTaskBuffer = _o[0], setFavoriteTaskBuffer = _o[1];
    var _p = React.useState(false), favoriteTaskTitleError = _p[0], setFavoriteTaskTitleError = _p[1];
    var _q = React.useState(false), savingFavoriteTask = _q[0], setSavingFavoriteTask = _q[1];
    var _r = React.useState(function () { return [defaultTask()]; }), taskRequests = _r[0], setTaskRequests = _r[1];
    var _s = React.useState(0), selectedTaskIdx = _s[0], setSelectedTaskIdx = _s[1];
    var taskTitles = React.useMemo(function () { return taskRequests && taskRequests.map(function (t, i) { return i + 1 + ": " + getShortDescription(t); }); }, [taskRequests]);
    var _t = React.useState(false), submitting = _t[0], setSubmitting = _t[1];
    var taskRequest = taskRequests[selectedTaskIdx];
    var _u = React.useState(false), openSchedulingDialog = _u[0], setOpenSchedulingDialog = _u[1];
    var _v = React.useState({
        startOn: new Date(),
        days: [true, true, true, true, true, true, true],
        until: undefined,
    }), schedule = _v[0], setSchedule = _v[1];
    var _w = React.useState(new Date()), atTime = _w[0], setAtTime = _w[1];
    var _x = React.useState(ScheduleUntilValue.NEVER), scheduleUntilValue = _x[0], setScheduleUntilValue = _x[1];
    var handleScheduleUntilValue = function (event) {
        if (event.target.value === ScheduleUntilValue.ON) {
            /**
             * Since the time change is done in the onchange of the Datepicker,
             * we need a defined time if the user saves the value without calling the onChange event of the datepicker
             */
            var date_1 = new Date();
            date_1.setHours(23);
            date_1.setMinutes(59);
            setSchedule(function (prev) { return (__assign(__assign({}, prev), { until: date_1 })); });
        }
        else {
            setSchedule(function (prev) { return (__assign(__assign({}, prev), { until: undefined })); });
        }
        setScheduleUntilValue(event.target.value);
    };
    // schedule is not supported with batch upload
    var scheduleEnabled = taskRequests.length === 1;
    var updateTasks = function () {
        setTaskRequests(function (prev) {
            prev.splice(selectedTaskIdx, 1, taskRequest);
            return __spreadArray([], prev, true);
        });
    };
    var handleTaskDescriptionChange = function (newCategory, newDesc) {
        taskRequest.category = newCategory;
        taskRequest.description = newDesc;
        setFavoriteTaskBuffer(__assign(__assign({}, favoriteTaskBuffer), { description: newDesc, category: newCategory }));
        updateTasks();
    };
    var renderTaskDescriptionForm = function () {
        switch (taskRequest.category) {
            case 'clean':
                return (React.createElement(CleanTaskForm, { taskDesc: taskRequest.description, cleaningZones: cleaningZones, onChange: function (desc) { return handleTaskDescriptionChange('clean', desc); } }));
            case 'patrol':
                return (React.createElement(LoopTaskForm, { taskDesc: taskRequest.description, loopWaypoints: loopWaypoints, onChange: function (desc) { return handleTaskDescriptionChange('patrol', desc); } }));
            case 'delivery':
                return (React.createElement(DeliveryTaskForm, { taskDesc: taskRequest.description, deliveryWaypoints: deliveryWaypoints, dispensers: dispensers, ingestors: ingestors, onChange: function (desc) { return handleTaskDescriptionChange('delivery', desc); } }));
            default:
                return null;
        }
    };
    var handleTaskTypeChange = function (ev) {
        var newCategory = ev.target.value;
        var newDesc = defaultTaskDescription(newCategory);
        if (newDesc === undefined) {
            return;
        }
        taskRequest.description = newDesc;
        taskRequest.category = newCategory;
        setFavoriteTaskBuffer(__assign(__assign({}, favoriteTaskBuffer), { category: newCategory, description: newDesc }));
        updateTasks();
    };
    // no memo because deps would likely change
    var handleSubmit = function (scheduling) { return __awaiter(_this, void 0, void 0, function () {
        var requester, _i, taskRequests_1, t, submittingSchedule, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!submitTasks) {
                        onSuccess && onSuccess(taskRequests);
                        return [2 /*return*/];
                    }
                    requester = scheduling ? user + "__scheduled" : user;
                    for (_i = 0, taskRequests_1 = taskRequests; _i < taskRequests_1.length; _i++) {
                        t = taskRequests_1[_i];
                        t.requester = requester;
                        t.unix_millis_request_time = Date.now();
                    }
                    submittingSchedule = scheduling && scheduleEnabled;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setSubmitting(true);
                    return [4 /*yield*/, submitTasks(taskRequests, submittingSchedule ? schedule : null)];
                case 2:
                    _a.sent();
                    setSubmitting(false);
                    if (submittingSchedule) {
                        onSuccessScheduling && onSuccessScheduling();
                    }
                    else {
                        onSuccess && onSuccess(taskRequests);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    setSubmitting(false);
                    if (submittingSchedule) {
                        onFailScheduling && onFailScheduling(e_1);
                    }
                    else {
                        onFail && onFail(e_1, taskRequests);
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmitNow = function (ev) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ev.preventDefault();
                    return [4 /*yield*/, handleSubmit(false)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSubmitSchedule = function (ev) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ev.preventDefault();
                    return [4 /*yield*/, handleSubmit(true)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSubmitFavoriteTask = function (ev) { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ev.preventDefault();
                    if (!favoriteTaskBuffer.name) {
                        setFavoriteTaskTitleError(true);
                        return [2 /*return*/];
                    }
                    setFavoriteTaskTitleError(false);
                    if (!submitFavoriteTask) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setSavingFavoriteTask(true);
                    return [4 /*yield*/, submitFavoriteTask(favoriteTaskBuffer)];
                case 2:
                    _a.sent();
                    setSavingFavoriteTask(false);
                    onSuccessFavoriteTask &&
                        onSuccessFavoriteTask((!favoriteTaskBuffer.id ? "Created" : "Edited") + "  favorite task successfully", favoriteTaskBuffer);
                    setOpenFavoriteDialog(false);
                    setCallToUpdateFavoriteTask(false);
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    setSavingFavoriteTask(false);
                    onFailFavoriteTask && onFailFavoriteTask(e_2, favoriteTaskBuffer);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteFavoriteTask = function (ev) { return __awaiter(_this, void 0, void 0, function () {
        var e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ev.preventDefault();
                    if (!deleteFavoriteTask) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setDeletingFavoriteTask(true);
                    return [4 /*yield*/, deleteFavoriteTask(favoriteTaskBuffer)];
                case 2:
                    _a.sent();
                    setDeletingFavoriteTask(false);
                    onSuccessFavoriteTask &&
                        onSuccessFavoriteTask('Deleted favorite task successfully', favoriteTaskBuffer);
                    setTaskRequests([defaultTask()]);
                    setOpenFavoriteDialog(false);
                    setCallToDeleteFavoriteTask(false);
                    setCallToUpdateFavoriteTask(false);
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    setDeletingFavoriteTask(false);
                    onFailFavoriteTask && onFailFavoriteTask(e_3, favoriteTaskBuffer);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSelectFileClick = function () {
        if (!tasksFromFile) {
            return;
        }
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var newTasks;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, tasksFromFile()];
                    case 1:
                        newTasks = _a.sent();
                        if (newTasks.length === 0) {
                            return [2 /*return*/];
                        }
                        setTaskRequests(newTasks);
                        setSelectedTaskIdx(0);
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    var submitText = taskRequests.length > 1 ? 'Submit All Now' : 'Submit Now';
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledDialog, __assign({ title: "Create Task", maxWidth: "lg", fullWidth: taskRequests.length > 1, disableEnforceFocus: true }, otherProps),
            React.createElement("form", { "aria-label": "create-task" },
                React.createElement(DialogTitle, null,
                    React.createElement(Grid, { container: true, wrap: "nowrap" },
                        React.createElement(Grid, { item: true, className: classes.title }, "Create Task"),
                        React.createElement(Grid, { item: true },
                            React.createElement(FormToolbar, { onSelectFileClick: handleSelectFileClick })))),
                React.createElement(DialogContent, null,
                    React.createElement(Grid, { container: true, direction: "row", wrap: "nowrap" },
                        React.createElement(List, { dense: true, className: classes.taskList, "aria-label": "Favorites Tasks" },
                            React.createElement(Typography, { variant: "h6", component: "div" }, "Favorite tasks"),
                            favoritesTasks.map(function (favoriteTask, index) {
                                return (React.createElement(FavoriteTask, { listItemText: favoriteTask.name, key: index, setFavoriteTask: setFavoriteTaskBuffer, favoriteTask: favoriteTask, setCallToDelete: setCallToDeleteFavoriteTask, setCallToUpdate: setCallToUpdateFavoriteTask, setOpenDialog: setOpenFavoriteDialog, listItemClick: function () {
                                        setFavoriteTaskBuffer(favoriteTask);
                                        setTaskRequests(function (prev) {
                                            return __spreadArray(__spreadArray([], prev, true), [
                                                {
                                                    category: favoriteTask.category,
                                                    description: favoriteTask.description,
                                                    unix_millis_earliest_start_time: Date.now(),
                                                    priority: favoriteTask.priority,
                                                },
                                            ], false);
                                        });
                                    } }));
                            })),
                        React.createElement(Divider, { orientation: "vertical", flexItem: true, style: { marginLeft: theme.spacing(2), marginRight: theme.spacing(2) } }),
                        React.createElement(Grid, null,
                            React.createElement(Grid, { container: true, spacing: theme.spacing(2) },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(TextField, { select: true, id: "task-type", label: "Task Category", variant: "outlined", fullWidth: true, margin: "normal", value: taskRequest.category, onChange: handleTaskTypeChange },
                                        React.createElement(MenuItem, { value: "clean" }, "Clean"),
                                        React.createElement(MenuItem, { value: "patrol" }, "Loop"),
                                        React.createElement(MenuItem, { value: "delivery" }, "Delivery"))),
                                React.createElement(Grid, { item: true, xs: 10 },
                                    React.createElement(DateTimePicker, { inputFormat: 'MM/dd/yyyy HH:mm', value: taskRequest.unix_millis_earliest_start_time
                                            ? new Date(taskRequest.unix_millis_earliest_start_time)
                                            : new Date(), onChange: function (date) {
                                            if (!date) {
                                                return;
                                            }
                                            taskRequest.unix_millis_earliest_start_time = date.valueOf();
                                            setFavoriteTaskBuffer(__assign(__assign({}, favoriteTaskBuffer), { unix_millis_earliest_start_time: date.valueOf() }));
                                            updateTasks();
                                        }, label: "Start Time", renderInput: function (props) { return React.createElement(TextField, __assign({}, props)); } })),
                                React.createElement(Grid, { item: true, xs: 2 },
                                    React.createElement(PositiveIntField, { id: "priority", label: "Priority", 
                                        // FIXME(AA): The priority object is currently undefined.
                                        value: ((_b = taskRequest.priority) === null || _b === void 0 ? void 0 : _b.value) || 0, onChange: function (_ev, val) {
                                            taskRequest.priority = { type: 'binary', value: val };
                                            setFavoriteTaskBuffer(__assign(__assign({}, favoriteTaskBuffer), { priority: { type: 'binary', value: val } }));
                                            updateTasks();
                                        } }))),
                            React.createElement(Divider, { orientation: "horizontal", flexItem: true, style: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) } }),
                            renderTaskDescriptionForm(),
                            React.createElement(Grid, { container: true, justifyContent: "center" },
                                React.createElement(Button, { "aria-label": "Save as a favorite task", variant: "contained", color: "primary", onClick: function () {
                                        !callToUpdateFavoriteTask &&
                                            setFavoriteTaskBuffer(__assign(__assign({}, favoriteTaskBuffer), { name: '', id: '' }));
                                        setOpenFavoriteDialog(true);
                                    }, style: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) } }, callToUpdateFavoriteTask ? "Confirm edits" : 'Save as a favorite task'))),
                        taskTitles.length > 1 && (React.createElement(React.Fragment, null,
                            React.createElement(Divider, { orientation: "vertical", flexItem: true, style: { marginLeft: theme.spacing(2), marginRight: theme.spacing(2) } }),
                            React.createElement(List, { dense: true, className: classes.taskList, "aria-label": "Tasks List" }, taskTitles.map(function (title, idx) { return (React.createElement(ListItem, { key: idx, button: true, onClick: function () { return setSelectedTaskIdx(idx); }, className: selectedTaskIdx === idx ? classes.selectedTask : undefined, role: "listitem button" },
                                React.createElement(ListItemText, { primary: title }))); })))))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { variant: "outlined", disabled: submitting, className: classes.actionBtn, onClick: function (ev) { return onClose && onClose(ev, 'escapeKeyDown'); } }, "Cancel"),
                    React.createElement(Button, { variant: "contained", color: "primary", disabled: submitting, className: classes.actionBtn, onClick: function () { return setOpenSchedulingDialog(true); } }, "Add to Schedule"),
                    React.createElement(Button, { variant: "contained", type: "submit", color: "primary", disabled: submitting, className: classes.actionBtn, "aria-label": submitText, onClick: handleSubmitNow },
                        React.createElement(Loading, { hideChildren: true, loading: submitting, size: "1.5em", color: "inherit" }, submitText))))),
        openFavoriteDialog && (React.createElement(ConfirmationDialog, { confirmText: callToDeleteFavoriteTask ? 'Delete' : 'Save', cancelText: "Back", open: openFavoriteDialog, title: callToDeleteFavoriteTask ? 'Confirm Delete' : 'Favorite Task', submitting: callToDeleteFavoriteTask ? deletingFavoriteTask : savingFavoriteTask, onClose: function () {
                setOpenFavoriteDialog(false);
                setCallToDeleteFavoriteTask(false);
            }, onSubmit: callToDeleteFavoriteTask ? handleDeleteFavoriteTask : handleSubmitFavoriteTask },
            !callToDeleteFavoriteTask && (React.createElement(TextField, { size: "small", value: favoriteTaskBuffer.name, onChange: function (e) {
                    return setFavoriteTaskBuffer(__assign(__assign({}, favoriteTaskBuffer), { name: e.target.value }));
                }, helperText: "Required", error: favoriteTaskTitleError })),
            callToDeleteFavoriteTask && (React.createElement(Typography, null, "Are you sure you want to delete \"" + favoriteTaskBuffer.name + "\"?")))),
        openSchedulingDialog && (React.createElement(ConfirmationDialog, { confirmText: "Schedule", cancelText: "Cancel", open: openSchedulingDialog, title: "Schedule Task", submitting: false, onClose: function () { return setOpenSchedulingDialog(false); }, onSubmit: function (ev) {
                handleSubmitSchedule(ev);
                setOpenSchedulingDialog(false);
            } },
            React.createElement(Grid, { container: true, spacing: theme.spacing(2), marginTop: theme.spacing(1) },
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(DatePicker, { value: schedule.startOn, onChange: function (date) {
                            return date &&
                                setSchedule(function (prev) {
                                    date.setHours(atTime.getHours());
                                    date.setMinutes(atTime.getMinutes());
                                    return __assign(__assign({}, prev), { startOn: date });
                                });
                        }, label: "Start On", disabled: !scheduleEnabled, renderInput: function (props) { return React.createElement(TextField, __assign({}, props, { fullWidth: true })); } })),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TimePicker, { value: atTime, onChange: function (date) {
                            if (!date) {
                                return;
                            }
                            setAtTime(date);
                            if (!isNaN(date.valueOf())) {
                                setSchedule(function (prev) {
                                    var startOn = prev.startOn;
                                    startOn.setHours(date.getHours());
                                    startOn.setMinutes(date.getMinutes());
                                    return __assign(__assign({}, prev), { startOn: startOn });
                                });
                            }
                        }, label: "At", disabled: !scheduleEnabled, renderInput: function (props) { return React.createElement(TextField, __assign({}, props, { fullWidth: true })); } })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(DaySelectorSwitch, { value: schedule.days, disabled: !scheduleEnabled, onChange: function (days) { return setSchedule(function (prev) { return (__assign(__assign({}, prev), { days: days })); }); } }))),
            React.createElement(Grid, { container: true, marginTop: theme.spacing(1), marginLeft: theme.spacing(0) },
                React.createElement(FormControl, { fullWidth: true },
                    React.createElement(FormHelperText, null, "Ends"),
                    React.createElement(RadioGroup, { "aria-labelledby": "controlled-radio-buttons-group", name: "controlled-radio-buttons-group", value: scheduleUntilValue, onChange: handleScheduleUntilValue, row: true },
                        React.createElement(Grid, { item: true, xs: 6, paddingLeft: theme.spacing(1) },
                            React.createElement(FormControlLabel, { value: ScheduleUntilValue.NEVER, control: React.createElement(Radio, null), label: "Never" })),
                        React.createElement(Grid, { item: true, xs: 2, paddingLeft: theme.spacing(1) },
                            React.createElement(FormControlLabel, { value: ScheduleUntilValue.ON, control: React.createElement(Radio, null), label: "On" })),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(DatePicker, { value: scheduleUntilValue === ScheduleUntilValue.NEVER ? new Date() : schedule.until, onChange: function (date) {
                                    return date &&
                                        setSchedule(function (prev) {
                                            date.setHours(23);
                                            date.setMinutes(59);
                                            return __assign(__assign({}, prev), { until: date });
                                        });
                                }, disabled: scheduleUntilValue !== ScheduleUntilValue.ON, renderInput: function (props) { return React.createElement(TextField, __assign({}, props, { fullWidth: true })); } })))))))));
}
