import { Tooltip as MuiTooltip, styled } from '@mui/material';
import React from 'react';
var classes = {
    tooltipWidth: 'tooltip-width',
};
var StyledDiv = styled('div')(function () {
    var _a;
    return (_a = {},
        _a["& ." + classes.tooltipWidth] = {
            maxWidth: 200,
        },
        _a);
});
export var Tooltip = function (props) {
    var title = props.title, id = props.id, enabled = props.enabled;
    return (React.createElement(StyledDiv, null,
        enabled && (React.createElement(MuiTooltip, { title: title, arrow: true, id: id, className: classes.tooltipWidth, "data-testid": id + '-tooltip' }, props.children)),
        !enabled && props.children));
};
export default Tooltip;
