import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
export function BeaconDataGridTable(_a) {
    var beacons = _a.beacons;
    var theme = useTheme();
    var OpModeState = function (params) {
        var opModeStateLabelStyle = (function () {
            var online = {
                color: theme.palette.success.main,
            };
            var offline = {
                color: theme.palette.error.main,
            };
            return params.row.online ? online : offline;
        })();
        return (React.createElement(Box, { sx: opModeStateLabelStyle },
            React.createElement(Typography, { "data-testid": "op-mode-state", component: "p", sx: {
                    fontWeight: 'bold',
                    fontSize: 14,
                } }, params.row.online ? 'ONLINE' : 'OFFLINE')));
    };
    var ActivatedState = function (params) {
        var activatedStateLabelStyle = (function () {
            var on = {
                color: theme.palette.success.main,
            };
            var off = {
                color: theme.palette.error.main,
            };
            return params.row.activated ? on : off;
        })();
        return (React.createElement(Box, { sx: activatedStateLabelStyle },
            React.createElement(Typography, { "data-testid": "activated-state", component: "p", sx: {
                    fontWeight: 'bold',
                    fontSize: 14,
                } }, params.row.activated ? 'ON' : 'OFF')));
    };
    var columns = [
        {
            field: 'beaconName',
            headerName: 'Name',
            width: 90,
            valueGetter: function (params) { return params.row.id; },
            flex: 1,
            filterable: true,
        },
        {
            field: 'opMode',
            headerName: 'Op. Mode',
            width: 150,
            editable: false,
            flex: 1,
            renderCell: OpModeState,
            filterable: true,
        },
        {
            field: 'levelName',
            headerName: 'Level',
            width: 150,
            editable: false,
            valueGetter: function (params) { var _a; return (_a = params.row.level) !== null && _a !== void 0 ? _a : 'N/A'; },
            flex: 1,
            filterable: true,
        },
        {
            field: 'beaconCategory',
            headerName: 'Type',
            width: 150,
            editable: false,
            valueGetter: function (params) { var _a; return (_a = params.row.category) !== null && _a !== void 0 ? _a : 'N/A'; },
            flex: 1,
            filterable: true,
        },
        {
            field: 'beaconState',
            headerName: 'Beacon State',
            width: 150,
            editable: false,
            flex: 1,
            renderCell: ActivatedState,
            filterable: true,
        },
    ];
    return (React.createElement("div", { style: { height: '100%', width: '100%' } },
        React.createElement(DataGrid, { autoHeight: true, getRowId: function (l) { return l.id; }, rows: beacons, pageSize: 5, rowHeight: 38, columns: columns, rowsPerPageOptions: [5], localeText: {
                noRowsLabel: 'No beacons available.',
            } })));
}
