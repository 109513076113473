// @ts-nocheck
import React from 'react';
import { useState } from 'react';
import { EventFormSelection } from './EventFormSelections';
import { StyledConfirmationDialog } from './StyledConfirmationDialog';
import { AppControllerContext } from '../app-contexts';
import {
  EventFormData,
  EventFormDataErrors,
  getSubmitCallback,
  validateEventFormData,
} from './utils/event_form_utils';
import { RmfAppContext } from '../rmf-app';

const dialogClasses = {
  title: 'confirmation-dialogue-info-value',
  actionBtn: 'confirmation-dialogue-action-button',
};

interface EventFormProps {
  onClose: () => void;
  open: boolean;
  robotsInfo: any;
  eventFormOptions: any;
  closeEventForm: () => void;
  // children: any;
}

export const EventForm = (props: EventFormProps) => {
  const {
    // toolbar,
    onClose,
    robotsInfo,
    eventFormOptions,
    closeEventForm,
    ...otherProps
  } = props;
  console.log('EVENT FORM');

  const [startTime, setStartTime] = useState<Date>(eventFormOptions.data.startTime);
  const [endTime, setEndTime] = useState<Date>(eventFormOptions.data.endTime);
  const [openEndDateSelection, setOpenEndDateSelection] = useState<boolean>(false);
  const [selectedFleet, setSelectedFleet] = useState(eventFormOptions.data.selectedFleet);
  const [selectedRobot, setSelectedRobot] = useState(eventFormOptions.data.selectedRobot);
  const [selectedEventType, setSelectedEventType] = useState(
    eventFormOptions.data.selectedEventType,
  );
  const [zoneSequence, setZoneSequence] = useState(eventFormOptions.data.zoneSequence);
  const [daysCheckedArray, setDaysCheckedArray] = useState(eventFormOptions.data.daysCheckedArray);
  const [eventFormErrors, setEventFormErrors] = useState<EventFormDataErrors>({});
  const rmf = React.useContext(RmfAppContext);
  const { showAlert } = React.useContext(AppControllerContext);

  const getEventFormData = (): EventFormData => {
    return {
      startTime: startTime,
      endTime: endTime,
      selectedFleet: selectedFleet,
      selectedRobot: selectedRobot,
      selectedEventType: selectedEventType,
      daysCheckedArray: daysCheckedArray,
      zoneSequence: zoneSequence,
    };
  };

  const validateFormCallback = (): boolean => {
    const eventFormData = getEventFormData();
    const errors = validateEventFormData(eventFormData);
    setEventFormErrors(errors);
    if (Object.keys(errors).length === 0) return true;

    return false;
  };

  const onSubmitCallback = () => {
    console.log(`Submitting Event Form With Operation ${eventFormOptions.operation}`);

    const eventFormData: EventFormData = getEventFormData();
    // We determine what RESTFUL endpoints to call on submit
    // based on the operation.
    const request: any = {
      operation: eventFormOptions.operation,
      data: eventFormData,
    };

    if (eventFormOptions.editFormInfo) {
      request['eventId'] = eventFormOptions.editFormInfo.eventId;
      request['currentSchedule'] = eventFormOptions.editFormInfo.currentSchedule;
    }
    const submitFunction = getSubmitCallback(request);

    submitFunction && rmf ? submitFunction(rmf, showAlert) : console.log('Error submitting');
    closeEventForm();
  };

  console.log(`EVENT FORM OPTIONS IS ${JSON.stringify(eventFormOptions)}`);

  return (
    <StyledConfirmationDialog
      onClose={onClose}
      onSubmit={onSubmitCallback}
      validateForm={validateFormCallback}
      maxWidth="md"
      {...otherProps}
    >
      <EventFormSelection
        robotsInfo={robotsInfo}
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
        openEndDateSelection={openEndDateSelection}
        setOpenEndDateSelection={setOpenEndDateSelection}
        selectedFleet={selectedFleet}
        setSelectedFleet={setSelectedFleet}
        selectedRobot={selectedRobot}
        setSelectedRobot={setSelectedRobot}
        selectedEventType={selectedEventType}
        setSelectedEventType={setSelectedEventType}
        zoneSequence={zoneSequence}
        setZoneSequence={setZoneSequence}
        daysCheckedArray={daysCheckedArray}
        setDaysCheckedArray={setDaysCheckedArray}
        editFormInfo={eventFormOptions.editFormInfo ? eventFormOptions.editFormInfo : null}
        eventFormErrors={eventFormErrors}
      ></EventFormSelection>
    </StyledConfirmationDialog>
  );
};
