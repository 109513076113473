import { WorkspaceState } from '../workspace';

export const scheduleWorkspace: WorkspaceState = {
  layout: [
    { i: 'Calendar', x: 0, y: 0, w: 20, h: 12 },
    // { i: 'calendar', x: 0, y: 0, w: 20, h: 12 },
  ],
  windows: [
    { key: 'Calendar', appName: 'Calendar' },
    // { key: 'calendar', appName: 'Calendar' },
  ],
};
