var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
export function LinearProgressBar(props) {
    return (React.createElement(Box, { display: "flex", alignItems: "center" },
        React.createElement(Box, { width: "100%", mr: 1 },
            React.createElement(LinearProgress, __assign({ color: "secondary", variant: "determinate" }, props))),
        React.createElement(Box, { minWidth: 35 },
            React.createElement(Typography, { variant: "body2" }, Math.floor(props.value) + "%"))));
}
