"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskDescription = void 0;
var Time_1 = require("../../builtin_interfaces/msg/Time");
var Priority_1 = require("../../rmf_task_msgs/msg/Priority");
var TaskType_1 = require("../../rmf_task_msgs/msg/TaskType");
var Station_1 = require("../../rmf_task_msgs/msg/Station");
var Loop_1 = require("../../rmf_task_msgs/msg/Loop");
var Delivery_1 = require("../../rmf_task_msgs/msg/Delivery");
var Clean_1 = require("../../rmf_task_msgs/msg/Clean");
var TaskDescription = /** @class */ (function () {
    function TaskDescription(fields) {
        if (fields === void 0) { fields = {}; }
        this.start_time = fields.start_time || new Time_1.Time();
        this.priority = fields.priority || new Priority_1.Priority();
        this.task_type = fields.task_type || new TaskType_1.TaskType();
        this.station = fields.station || new Station_1.Station();
        this.loop = fields.loop || new Loop_1.Loop();
        this.delivery = fields.delivery || new Delivery_1.Delivery();
        this.clean = fields.clean || new Clean_1.Clean();
    }
    TaskDescription.validate = function (obj) {
        try {
            Time_1.Time.validate(obj['start_time']);
        }
        catch (e) {
            throw new Error('in "start_time":\n  ' + e.message);
        }
        try {
            Priority_1.Priority.validate(obj['priority']);
        }
        catch (e) {
            throw new Error('in "priority":\n  ' + e.message);
        }
        try {
            TaskType_1.TaskType.validate(obj['task_type']);
        }
        catch (e) {
            throw new Error('in "task_type":\n  ' + e.message);
        }
        try {
            Station_1.Station.validate(obj['station']);
        }
        catch (e) {
            throw new Error('in "station":\n  ' + e.message);
        }
        try {
            Loop_1.Loop.validate(obj['loop']);
        }
        catch (e) {
            throw new Error('in "loop":\n  ' + e.message);
        }
        try {
            Delivery_1.Delivery.validate(obj['delivery']);
        }
        catch (e) {
            throw new Error('in "delivery":\n  ' + e.message);
        }
        try {
            Clean_1.Clean.validate(obj['clean']);
        }
        catch (e) {
            throw new Error('in "clean":\n  ' + e.message);
        }
    };
    TaskDescription.FullTypeName = 'rmf_task_msgs/msg/TaskDescription';
    return TaskDescription;
}());
exports.TaskDescription = TaskDescription;
/*
# Desired start time of a task
builtin_interfaces/Time start_time

# Priority of the task
Priority priority

# Task type
TaskType task_type

# The corresponding field for the above TaskType should be populated
Station station
Loop loop
Delivery delivery
Clean clean

*/
