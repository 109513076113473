import './Calendar.css';
import { ScheduleForTaskScheduler, DeleteScheduleRequest, UpdateSeriesRequest } from 'api-client';
import React, { useState } from 'react';
import { Window } from 'react-components';
import { CAGRobotInfo } from 'api-client';
import { MicroAppProps } from '../micro-app';
import { RmfAppContext } from '../rmf-app';

import { CalendarApp as CAGCalendar } from './CalendarApp';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarToolbar } from './CalendarToolBar';
import { EventFormOptions } from './utils/event_form_utils';
import { EventForm } from './EventForm';

const queryClient = new QueryClient();

export const CalendarApp = React.memo(
  React.forwardRef(
    (
      { onClose, children, ...otherProps }: React.PropsWithChildren<MicroAppProps>,
      ref: React.Ref<HTMLDivElement>,
    ) => {
      console.log('FULL CALENDAR');
      const [eventFormOptions, setEventFormOptions] = useState<EventFormOptions | null>(null);
      const eventFormOpened = eventFormOptions ? true : false;
      const [robotsInfo, setRobotsInfo] = useState<any>([]);
      const rmf = React.useContext(RmfAppContext);

      // Opening event form callback
      const handleEventFormOpen = (options: EventFormOptions) => {
        setEventFormOptions(options);
      };

      // Close event form callabck
      const handleEventFormClose = () => {
        setEventFormOptions(null);
      };

      const renderEventForm = () => {
        if (!eventFormOpened || !eventFormOptions) return;
        return (
          <EventForm
            onClose={() => {
              handleEventFormClose();
            }}
            open={eventFormOpened}
            robotsInfo={robotsInfo}
            eventFormOptions={eventFormOptions}
            closeEventForm={handleEventFormClose}
          ></EventForm>
        );
      };

      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <QueryClientProvider client={queryClient}>
            <Window
              ref={ref}
              title="Calendar"
              onClose={onClose}
              toolbar={
                <CalendarToolbar handleEventFormOpen={handleEventFormOpen}></CalendarToolbar>
              }
              {...otherProps}
            >
              <div className="calendar-container">
                <CAGCalendar
                  eventFormOptions={eventFormOptions}
                  setEventFormOptions={setEventFormOptions}
                  handleEventFormOpen={handleEventFormOpen}
                  handleEventFormClose={handleEventFormClose}
                  robotsInfo={robotsInfo}
                  setRobotsInfo={setRobotsInfo}
                ></CAGCalendar>
              </div>
              {renderEventForm()}
              {children}
            </Window>
          </QueryClientProvider>
        </LocalizationProvider>
      );
    },
  ),
);
