import React from 'react';
import { TextField, Divider, styled } from '@mui/material';
var classes = {
    simpleFilter: 'simple-filter-root',
    filterBar: 'simple-filter-filterbar',
    divider: 'simple-filter-divider',
};
var StyledDiv = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&." + classes.simpleFilter] = {
            margin: '1rem',
            borderColor: theme.palette.success.main,
        },
        _b["& ." + classes.filterBar] = {
            width: '100%',
        },
        _b["& ." + classes.divider] = {
            margin: '1.5rem 0',
        },
        _b);
});
export var SimpleFilter = function (props) {
    var onChange = props.onChange, value = props.value;
    return (React.createElement(StyledDiv, { className: classes.simpleFilter },
        React.createElement(TextField, { label: "Filter", value: value, variant: "outlined", onChange: onChange, className: classes.filterBar, "aria-label": "text-input", "data-component": "simple-filter" }),
        React.createElement(Divider, { className: classes.divider })));
};
