var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Card, Divider, Grid, IconButton, Paper, Typography, styled, } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid } from 'react-window';
import { WorkcellTable } from './workcell-table';
var classes = {
    container: 'workcell-panel-container',
    buttonBar: 'workcell-buttonbar',
    cellContainer: 'workcell-cell-container',
    cellPaper: 'workcell-cell-paper',
    itemIcon: 'workcell-item-icon',
    panelHeader: 'workcell-panel-header',
    subPanelHeader: 'workcell-sub-panel-header',
    tableDiv: 'workcell-table-div',
    nameField: 'workcell-name-field',
    grid: 'workcell-grid',
};
var StyledCard = styled(function (props) { return React.createElement(Card, __assign({}, props)); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&." + classes.container] = {
            margin: theme.spacing(1),
        },
        _b["& ." + classes.buttonBar] = {
            display: 'flex',
            justifyContent: 'flex-end',
            borderRadius: 0,
            backgroundColor: theme.palette.primary.main,
        },
        _b["& ." + classes.cellContainer] = {
            padding: theme.spacing(1),
            maxHeight: '25vh',
            margin: theme.spacing(1),
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        _b["& ." + classes.cellPaper] = {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
            border: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.action.hover,
            },
            margin: theme.spacing(1),
            height: '60%',
        },
        _b["& ." + classes.grid] = {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(1),
        },
        _b["& ." + classes.itemIcon] = {
            color: theme.palette.primary.contrastText,
        },
        _b["& ." + classes.panelHeader] = {
            color: theme.palette.primary.contrastText,
            marginLeft: theme.spacing(2),
        },
        _b["& ." + classes.subPanelHeader] = {
            marginLeft: theme.spacing(2),
            color: theme.palette.primary.contrastText,
        },
        _b["& ." + classes.tableDiv] = {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
        _b["& ." + classes.nameField] = {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        _b.bottomTable = {
            marginTop: theme.spacing(2),
        },
        _b);
});
var WorkcellCell = React.memo(function (_a) {
    var workcell = _a.workcell, requestGuidQueue = _a.requestGuidQueue, secondsRemaining = _a.secondsRemaining;
    var labelId = "workcell-cell-" + workcell.guid;
    return (React.createElement(Paper, { className: classes.cellPaper, role: "region", "aria-labelledby": labelId }, requestGuidQueue !== undefined && secondsRemaining !== undefined ? (React.createElement(React.Fragment, null,
        React.createElement(Typography, { noWrap: true, id: labelId, align: "center", className: classes.nameField, title: workcell === null || workcell === void 0 ? void 0 : workcell.guid }, workcell === null || workcell === void 0 ? void 0 : workcell.guid),
        React.createElement(Grid, { container: true, direction: "row" },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { align: "center", variant: "body2" }, "Queue: " + requestGuidQueue.length)),
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Typography, { align: "center", variant: "body2" }, requestGuidQueue.length))),
        React.createElement(Typography, { align: "center" }, "Remaining: " + secondsRemaining + "s"))) : (React.createElement(Typography, { id: labelId, color: "error" }, workcell.guid + " not sending states"))));
});
var WorkcellGridRenderer = function (_a) {
    var data = _a.data, columnIndex = _a.columnIndex, rowIndex = _a.rowIndex, style = _a.style;
    var workcell;
    var workcellState;
    var columnCount = data.columnCount;
    var workcells = data.workcells, workcellStates = data.workcellStates;
    if (rowIndex * columnCount + columnIndex <= workcells.length - 1) {
        workcell = workcells[rowIndex * columnCount + columnIndex];
        workcellState = workcellStates[workcell.guid];
    }
    return workcell ? (React.createElement("div", { style: style },
        React.createElement(WorkcellCell, { workcell: workcell, requestGuidQueue: workcellState === null || workcellState === void 0 ? void 0 : workcellState.request_guid_queue, secondsRemaining: workcellState === null || workcellState === void 0 ? void 0 : workcellState.seconds_remaining }))) : null;
};
export function WorkcellPanel(_a) {
    var dispensers = _a.dispensers, ingestors = _a.ingestors, workcellStates = _a.workcellStates;
    var _b = React.useState(true), isCellView = _b[0], setIsCellView = _b[1];
    var columnWidth = 250;
    return (React.createElement(StyledCard, { variant: "outlined", className: classes.container },
        React.createElement(Paper, { className: classes.buttonBar },
            React.createElement(Grid, { container: true, direction: "row", justifyContent: "space-between", alignItems: "center" },
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(Typography, { variant: "h5", className: classes.panelHeader }, "Workcells")),
                React.createElement(Grid, { item: true },
                    React.createElement(IconButton, { "aria-label": "view mode", className: classes.itemIcon, onClick: function () { return setIsCellView(!isCellView); } }, isCellView ? React.createElement(ViewListIcon, null) : React.createElement(ViewModuleIcon, null))))),
        isCellView ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classes.cellContainer },
                React.createElement(Typography, { variant: "h6" }, "Dispensers"),
                React.createElement(Grid, { container: true, direction: "row", spacing: 1 },
                    React.createElement(AutoSizer, { disableHeight: true }, function (_a) {
                        var width = _a.width;
                        var columnCount = Math.floor(width / columnWidth);
                        return (React.createElement(FixedSizeGrid, { columnCount: columnCount, columnWidth: columnWidth, height: 250, rowCount: Math.ceil(dispensers.length / columnCount), rowHeight: 120, width: width, itemData: {
                                columnCount: columnCount,
                                workcells: dispensers,
                                workcellStates: workcellStates,
                            } }, WorkcellGridRenderer));
                    }))),
            React.createElement(Divider, null),
            React.createElement("div", { className: classes.cellContainer },
                React.createElement(Typography, { variant: "h6" }, "Ingestors"),
                React.createElement(Grid, { container: true, direction: "row", spacing: 1 },
                    React.createElement(AutoSizer, { disableHeight: true }, function (_a) {
                        var width = _a.width;
                        var columnCount = Math.floor(width / columnWidth);
                        return (React.createElement(FixedSizeGrid, { columnCount: columnCount, columnWidth: columnWidth, height: 250, rowCount: Math.ceil(ingestors.length / columnCount), rowHeight: 120, width: width, itemData: {
                                columnCount: columnCount,
                                workcells: ingestors,
                                workcellStates: workcellStates,
                            } }, WorkcellGridRenderer));
                    }))))) : (React.createElement(React.Fragment, null,
            dispensers.length > 0 ? (React.createElement("div", { className: classes.tableDiv },
                React.createElement(Typography, { variant: "h6" }, "Dispenser Table"),
                React.createElement(WorkcellTable, { workcells: dispensers, workcellStates: workcellStates }))) : null,
            ingestors.length > 0 ? (React.createElement("div", { className: classes.tableDiv },
                React.createElement(Typography, { variant: "h6" }, "Ingestor Table"),
                React.createElement(WorkcellTable, { workcells: ingestors, workcellStates: workcellStates }))) : null))));
}
