"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviveTask_Request = void 0;
var ReviveTask_Request = /** @class */ (function () {
    function ReviveTask_Request(fields) {
        if (fields === void 0) { fields = {}; }
        this.requester = fields.requester || '';
        this.task_id = fields.task_id || '';
    }
    ReviveTask_Request.validate = function (obj) {
        if (typeof obj['requester'] !== 'string') {
            throw new Error('expected "requester" to be "string"');
        }
        if (typeof obj['task_id'] !== 'string') {
            throw new Error('expected "task_id" to be "string"');
        }
    };
    ReviveTask_Request.FullTypeName = 'rmf_task_msgs/srv/ReviveTask_Request';
    return ReviveTask_Request;
}());
exports.ReviveTask_Request = ReviveTask_Request;
/*
# Revive a previously cancelled or failed task. This will reinitiate
# a bidding sequence to reassign this task.

# Identifier for who is requesting the service
string requester

# task that was previously cancelled or failed
string task_id


*/
