"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DestinationRequest = void 0;
var Location_1 = require("../../rmf_fleet_msgs/msg/Location");
var DestinationRequest = /** @class */ (function () {
    function DestinationRequest(fields) {
        if (fields === void 0) { fields = {}; }
        this.fleet_name = fields.fleet_name || '';
        this.robot_name = fields.robot_name || '';
        this.destination = fields.destination || new Location_1.Location();
        this.task_id = fields.task_id || '';
    }
    DestinationRequest.validate = function (obj) {
        if (typeof obj['fleet_name'] !== 'string') {
            throw new Error('expected "fleet_name" to be "string"');
        }
        if (typeof obj['robot_name'] !== 'string') {
            throw new Error('expected "robot_name" to be "string"');
        }
        try {
            Location_1.Location.validate(obj['destination']);
        }
        catch (e) {
            throw new Error('in "destination":\n  ' + e.message);
        }
        if (typeof obj['task_id'] !== 'string') {
            throw new Error('expected "task_id" to be "string"');
        }
    };
    DestinationRequest.FullTypeName = 'rmf_fleet_msgs/msg/DestinationRequest';
    return DestinationRequest;
}());
exports.DestinationRequest = DestinationRequest;
/*
string fleet_name
string robot_name
Location destination

# task_id must be copied into future RobotState messages
string task_id

*/
