"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AffineImage = void 0;
var AffineImage = /** @class */ (function () {
    function AffineImage(fields) {
        if (fields === void 0) { fields = {}; }
        this.name = fields.name || '';
        this.x_offset = fields.x_offset || 0;
        this.y_offset = fields.y_offset || 0;
        this.yaw = fields.yaw || 0;
        this.scale = fields.scale || 0;
        this.encoding = fields.encoding || '';
        this.data = fields.data || [];
    }
    AffineImage.validate = function (obj) {
        var e_1, _a;
        if (typeof obj['name'] !== 'string') {
            throw new Error('expected "name" to be "string"');
        }
        if (typeof obj['x_offset'] !== 'number') {
            throw new Error('expected "x_offset" to be "number"');
        }
        if (typeof obj['y_offset'] !== 'number') {
            throw new Error('expected "y_offset" to be "number"');
        }
        if (typeof obj['yaw'] !== 'number') {
            throw new Error('expected "yaw" to be "number"');
        }
        if (typeof obj['scale'] !== 'number') {
            throw new Error('expected "scale" to be "number"');
        }
        if (typeof obj['encoding'] !== 'string') {
            throw new Error('expected "encoding" to be "string"');
        }
        if (!(obj['data'] instanceof Uint8Array) && !Array.isArray(obj['data'])) {
            throw new Error('expected "data" to be "Uint8Array" or an array');
        }
        if (Array.isArray(obj['data'])) {
            try {
                for (var _b = __values(obj['data'].entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), i = _d[0], v = _d[1];
                    if (typeof v !== 'number') {
                        throw new Error("expected index " + i + " of \"data\" to be \"number\"");
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    AffineImage.FullTypeName = 'rmf_building_map_msgs/msg/AffineImage';
    return AffineImage;
}());
exports.AffineImage = AffineImage;
/*
string name
float32 x_offset
float32 y_offset
float32 yaw
float32 scale
string encoding
uint8[] data

*/
