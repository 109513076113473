var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Debug from 'debug';
import React from 'react';
import { trajectoryPath } from './trajectory';
import { FollowAnimationPath } from './trajectory-paths';
var debug = Debug('Map:TrajectoryMarker');
export var TrajectoryMarker = React.forwardRef(function (_a, ref) {
    var trajectory = _a.trajectory, color = _a.color, _b = _a.conflict, conflict = _b === void 0 ? false : _b, _c = _a.loopAnimation, loopAnimation = _c === void 0 ? false : _c, _d = _a.animationScale, animationScale = _d === void 0 ? 1 : _d, otherProps = __rest(_a, ["trajectory", "color", "conflict", "loopAnimation", "animationScale"]);
    debug("render " + trajectory.id);
    var footprint = trajectory.dimensions;
    var pathD = React.useMemo(function () {
        return trajectoryPath(trajectory.segments).d;
    }, [trajectory]);
    return (React.createElement("g", __assign({ ref: ref }, otherProps),
        React.createElement(FollowAnimationPath, { trajectory: trajectory, d: pathD, color: color, footprint: footprint, conflict: conflict, loopAnimation: loopAnimation, animationScale: animationScale })));
});
export default TrajectoryMarker;
