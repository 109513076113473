"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Clean = void 0;
var Clean = /** @class */ (function () {
    function Clean(fields) {
        if (fields === void 0) { fields = {}; }
        this.start_waypoint = fields.start_waypoint || '';
    }
    Clean.validate = function (obj) {
        if (typeof obj['start_waypoint'] !== 'string') {
            throw new Error('expected "start_waypoint" to be "string"');
        }
    };
    Clean.FullTypeName = 'rmf_task_msgs/msg/Clean';
    return Clean;
}());
exports.Clean = Clean;
/*
# The name of the waypoint where the robot should begin its pre-configured
# cleaning job.
string start_waypoint


*/
