var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import React from 'react';
var classes = {
    tabsContainer: 'navigation-bar-root',
};
var StyledTabs = styled(function (props) { return React.createElement(Tabs, __assign({}, props)); })(function () {
    var _a;
    return (_a = {},
        _a["&." + classes.tabsContainer] = {
            flexGrow: 4,
        },
        _a);
});
export var NavigationBar = function (props) {
    var value = props.value, onTabChange = props.onTabChange, children = props.children;
    return (React.createElement(StyledTabs, { variant: "scrollable", scrollButtons: "auto", value: value, onChange: onTabChange, className: classes.tabsContainer, TabIndicatorProps: { style: { backgroundColor: '#d32f2f' } } }, children));
};
