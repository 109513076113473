"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IngestorResult = void 0;
var Time_1 = require("../../builtin_interfaces/msg/Time");
var IngestorResult = /** @class */ (function () {
    function IngestorResult(fields) {
        if (fields === void 0) { fields = {}; }
        this.time = fields.time || new Time_1.Time();
        this.request_guid = fields.request_guid || '';
        this.source_guid = fields.source_guid || '';
        this.status = fields.status || 0;
    }
    IngestorResult.validate = function (obj) {
        try {
            Time_1.Time.validate(obj['time']);
        }
        catch (e) {
            throw new Error('in "time":\n  ' + e.message);
        }
        if (typeof obj['request_guid'] !== 'string') {
            throw new Error('expected "request_guid" to be "string"');
        }
        if (typeof obj['source_guid'] !== 'string') {
            throw new Error('expected "source_guid" to be "string"');
        }
        if (typeof obj['status'] !== 'number') {
            throw new Error('expected "status" to be "number"');
        }
    };
    IngestorResult.FullTypeName = 'rmf_ingestor_msgs/msg/IngestorResult';
    IngestorResult.ACKNOWLEDGED = 0;
    IngestorResult.SUCCESS = 1;
    IngestorResult.FAILED = 2;
    return IngestorResult;
}());
exports.IngestorResult = IngestorResult;
/*
builtin_interfaces/Time time

# A unique ID for the request which this result is for
string request_guid

# The unique ID of the workcell that this result was sent from
string source_guid

# Different basic result statuses
uint8 status
uint8 ACKNOWLEDGED=0
uint8 SUCCESS=1
uint8 FAILED=2

# below are custom workcell message fields

*/
