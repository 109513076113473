"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DispatchRequest = void 0;
var TaskProfile_1 = require("../../rmf_task_msgs/msg/TaskProfile");
var DispatchRequest = /** @class */ (function () {
    function DispatchRequest(fields) {
        if (fields === void 0) { fields = {}; }
        this.fleet_name = fields.fleet_name || '';
        this.task_profile = fields.task_profile || new TaskProfile_1.TaskProfile();
        this.method = fields.method || 0;
    }
    DispatchRequest.validate = function (obj) {
        if (typeof obj['fleet_name'] !== 'string') {
            throw new Error('expected "fleet_name" to be "string"');
        }
        try {
            TaskProfile_1.TaskProfile.validate(obj['task_profile']);
        }
        catch (e) {
            throw new Error('in "task_profile":\n  ' + e.message);
        }
        if (typeof obj['method'] !== 'number') {
            throw new Error('expected "method" to be "number"');
        }
    };
    DispatchRequest.FullTypeName = 'rmf_task_msgs/msg/DispatchRequest';
    DispatchRequest.ADD = 1;
    DispatchRequest.CANCEL = 2;
    return DispatchRequest;
}());
exports.DispatchRequest = DispatchRequest;
/*
# This message is published by Task Dispatcher Node to either award or cancel a
# task for a Fleet Adapter

# The selected Fleet Adapter to award/cancel the task
string fleet_name

# The details of the task to be awarded or cancelled. This should match the
# TaskProfile in the corresponding BidNotice message
TaskProfile task_profile

# Add or Cancel a task
uint8 method
uint8 ADD=1     # to add a task
uint8 CANCEL=2  # to cancel and remove a task

*/
