"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Location = void 0;
var Time_1 = require("../../builtin_interfaces/msg/Time");
var Location = /** @class */ (function () {
    function Location(fields) {
        if (fields === void 0) { fields = {}; }
        this.t = fields.t || new Time_1.Time();
        this.x = fields.x || 0;
        this.y = fields.y || 0;
        this.yaw = fields.yaw || 0;
        this.obey_approach_speed_limit = fields.obey_approach_speed_limit || false;
        this.approach_speed_limit = fields.approach_speed_limit || 0;
        this.level_name = fields.level_name || '';
        this.index = fields.index || 0;
    }
    Location.validate = function (obj) {
        try {
            Time_1.Time.validate(obj['t']);
        }
        catch (e) {
            throw new Error('in "t":\n  ' + e.message);
        }
        if (typeof obj['x'] !== 'number') {
            throw new Error('expected "x" to be "number"');
        }
        if (typeof obj['y'] !== 'number') {
            throw new Error('expected "y" to be "number"');
        }
        if (typeof obj['yaw'] !== 'number') {
            throw new Error('expected "yaw" to be "number"');
        }
        if (typeof obj['obey_approach_speed_limit'] !== 'boolean') {
            throw new Error('expected "obey_approach_speed_limit" to be "boolean"');
        }
        if (typeof obj['approach_speed_limit'] !== 'number') {
            throw new Error('expected "approach_speed_limit" to be "number"');
        }
        if (typeof obj['level_name'] !== 'string') {
            throw new Error('expected "level_name" to be "string"');
        }
        if (typeof obj['index'] !== 'number') {
            throw new Error('expected "index" to be "number"');
        }
    };
    Location.FullTypeName = 'rmf_fleet_msgs/msg/Location';
    return Location;
}());
exports.Location = Location;
/*
builtin_interfaces/Time t
float32 x
float32 y
float32 yaw

bool obey_approach_speed_limit false
# Speed limit of the lane leading to this waypoint in m/s
float32 approach_speed_limit

string level_name
uint64 index

*/
