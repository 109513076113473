var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useTheme } from '@mui/material';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { Map as LMap_, Pane, useLeaflet } from 'react-leaflet';
import { EntityManager, EntityManagerContext } from './entity-manager';
import { LabelsPortalContext } from './labels-overlay';
import { SVGOverlay } from './svg-overlay';
import { viewBoxFromLeafletBounds } from './utils';
export function calcMaxBounds(mapFloorLayers) {
    if (!mapFloorLayers.length) {
        return undefined;
    }
    var bounds = new L.LatLngBounds([0, 0], [0, 0]);
    Object.values(mapFloorLayers).forEach(function (x) { return bounds.extend(x.bounds); });
    return bounds.pad(0.2);
}
function EntityManagerProvider(_a) {
    var children = _a.children;
    var leaflet = useLeaflet();
    var entityManager = React.useRef(new EntityManager()).current;
    React.useEffect(function () {
        if (!leaflet.map)
            return;
        var listener = function () {
            // TODO: recalculate positions
        };
        leaflet.map.on('zoom', listener);
        return function () {
            leaflet.map && leaflet.map.off('zoom', listener);
        };
    }, [leaflet, leaflet.map]);
    return entityManager ? (React.createElement(EntityManagerContext.Provider, { value: entityManager }, children)) : null;
}
export var LMap = React.forwardRef(function (_a, ref) {
    var children = _a.children, bounds = _a.bounds, otherProps = __rest(_a, ["children", "bounds"]);
    var _b = React.useState(null), labelsPortal = _b[0], setLabelsPortal = _b[1];
    var viewBox = bounds ? viewBoxFromLeafletBounds(bounds) : undefined;
    var theme = useTheme();
    return (React.createElement(LMap_, __assign({ ref: ref, style: {
            height: '100%',
            width: '100%',
            margin: 0,
            padding: 0,
            backgroundColor: theme.palette.background.default,
        }, crs: L.CRS.Simple }, otherProps),
        React.createElement(EntityManagerProvider, null,
            React.createElement(LabelsPortalContext.Provider, { value: labelsPortal },
                children,
                bounds && (React.createElement(Pane, { name: "label", style: { zIndex: 1000 } },
                    React.createElement(SVGOverlay, { ref: function (current) {
                            setLabelsPortal((current === null || current === void 0 ? void 0 : current.container) || null);
                        }, viewBox: viewBox, bounds: bounds })))))));
});
