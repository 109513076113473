"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoorMode = void 0;
var DoorMode = /** @class */ (function () {
    function DoorMode(fields) {
        if (fields === void 0) { fields = {}; }
        this.value = fields.value || 0;
    }
    DoorMode.validate = function (obj) {
        if (typeof obj['value'] !== 'number') {
            throw new Error('expected "value" to be "number"');
        }
    };
    DoorMode.FullTypeName = 'rmf_door_msgs/msg/DoorMode';
    DoorMode.MODE_CLOSED = 0;
    DoorMode.MODE_MOVING = 1;
    DoorMode.MODE_OPEN = 2;
    DoorMode.MODE_OFFLINE = 3;
    DoorMode.MODE_UNKNOWN = 4;
    return DoorMode;
}());
exports.DoorMode = DoorMode;
/*
# The DoorMode message captures the "mode" of an automatic door controller.
# Most door controllers default to running in "closed" mode, and transition
# through some sort of "moving" mode until reaching the "open" mode.

uint32 value

# "value" must be one of the following enumerations:
uint32 MODE_CLOSED=0
uint32 MODE_MOVING=1
uint32 MODE_OPEN=2
uint32 MODE_OFFLINE=3
uint32 MODE_UNKNOWN=4

*/
