"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiftClearance = void 0;
var LiftClearance_Request_1 = require("./LiftClearance_Request");
var LiftClearance_Response_1 = require("./LiftClearance_Response");
var LiftClearance = /** @class */ (function () {
    function LiftClearance() {
    }
    LiftClearance.FullServiceName = 'rmf_fleet_msgs/srv/LiftClearance';
    LiftClearance.Request = LiftClearance_Request_1.LiftClearance_Request;
    LiftClearance.Response = LiftClearance_Response_1.LiftClearance_Response;
    return LiftClearance;
}());
exports.LiftClearance = LiftClearance;
