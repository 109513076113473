var commonTheme = {
    secondary: {
        // Smitten, Cyclamen, Pansy Purple
        main: '#BC477B',
        light: '#F178AA',
        dark: '#880E4F',
    },
    success: {
        // Apple Green, Inchworm, Avocado
        main: '#7FB800',
        light: '#B3EB49',
        dark: '#6dff6f',
    },
    error: {
        // Radical Red, Salmon Pink, Red NCS
        main: '#EF5264',
        light: '#FF8591',
        dark: '#B7153A',
    },
    warning: {
        // Honey Yellow, Minion Yellow,  Harvest Gold
        main: '#FFB400',
        light: '#FFE64C',
        dark: '#C68500',
    },
    info: {
        // Sky Blue Crayola, Electric Blue, Viridian Green
        // used in admin/information/indicators
        main: '#64D4EE',
        light: '#64E9EE',
        dark: '#009FAF',
    },
};
export default commonTheme;
