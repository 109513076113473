import { useContext, useState, useRef } from 'react';
import { TUICalendar } from './Calendar';
import { FilterSelector } from './FilterSelector';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { CalendarControl } from './CalendarControl';
import { eventToEventFormOptions, addEventFormOptions } from './utils/event_form_utils';
import { getWeekStart, getWeekEnd } from './utils/general_utils';
import { dataToRobotCalendarMap } from './utils/utils';
import { RmfAppContext } from '../rmf-app';

// interface CalendarAppProps {
//   eventFormOptions: EventFormOptions | null,
//   setEventFormOptions: React.Dispatch<React.SetStateAction<EventFormOptions>>
// }

export function CalendarApp(props) {
  const { eventFormOptions, setEventFormOptions, handleEventFormOpen, setRobotsInfo } = props;
  const calendarRef = useRef();
  const [filterStates, setFilterStates] = useState([]);
  const [robotsCalendarArray, setRobotsCalendarArray] = useState([]);
  const [calendarView, setCalendarView] = useState('week');
  const [calendarViewStart, setCalendarViewStart] = useState(getWeekStart(new Date()));
  const [calendarViewEnd, setCalendarViewEnd] = useState(getWeekEnd(new Date()));
  const rmf = useContext(RmfAppContext);

  console.log('CALENDAR APP');
  console.log(JSON.stringify(eventFormOptions));

  const { isPending, error, data } = useQuery({
    queryKey: ['robotZoneInfoData'],
    queryFn: async () => {
      const res = await rmf.schedulerApi.getCagRobotInfoSchedulerRobotZoneInfoGet();
      if (!res.status === 200) {
        const errorCode = res.statusText;
        throw new Error(`An error occurred: ${errorCode}`);
      }
      return res.data;
    },
    staleTime: 1000,
  });

  // https://github.com/TanStack/query/issues/1916
  useEffect(() => {
    console.log('USE EFFECT IN CALENDAR APP');
    // filter states and robotsCalendar array should be coupled
    if (data) {
      let calendarMap = dataToRobotCalendarMap(data);
      setRobotsInfo(data.info);
      setRobotsCalendarArray(Object.entries(calendarMap));
      setFilterStates(Object.entries(calendarMap).map(() => true));
    }
  }, [data, error]);

  const setEditEventFormOptionsCallback = (eventId, currentSchedule, operation) => {
    let options = eventToEventFormOptions(eventId, currentSchedule, operation);
    if (!options) {
      console.log(`Unable to generate options. Event of ID ${eventId} does not exist`);
      return;
    }
    console.log(`EVENT FORM OPTIONS ${JSON.stringify(options)}`);
    handleEventFormOpen(options);
  };

  const renderCalendarControls = () => {
    return <CalendarControl className="controls" calendarRef={calendarRef}></CalendarControl>;
  };

  const renderCalendar = () => {
    return (
      <div className="tuicalendar">
        <TUICalendar
          calendarRef={calendarRef}
          robotsCalendarArray={robotsCalendarArray}
          filterStates={filterStates}
          setEventFormOptions={setEventFormOptions}
          onEditCallback={setEditEventFormOptionsCallback}
        ></TUICalendar>
      </div>
    );
  };

  if (isPending) {
    console.log('Pending');
    return (
      <>
        {renderCalendar()}
        <div className="sidebar">
          <FilterSelector
            calendarRef={calendarRef}
            robotsCalendarArray={robotsCalendarArray}
            filterStates={filterStates}
            setFilterStates={setFilterStates}
          ></FilterSelector>
        </div>
      </>
    );
  }

  if (error) {
    console.log('Error Loading ROBOT INFO');
    // Create a default robotsCalendarMap containing only one for all robot tasks and another for flight schedule.

    return (
      <>
        {renderCalendar()}
        <div className="sidebar">
          <FilterSelector
            calendarRef={calendarRef}
            robotsCalendarArray={robotsCalendarArray}
            filterStates={filterStates}
            setFilterStates={setFilterStates}
          ></FilterSelector>
        </div>
      </>
    );
  }

  console.log('CALENDAR APP SUCCESFULLY RENDERED');

  return (
    <>
      {renderCalendarControls()}
      <div className="calendar-and-filters">
        <FilterSelector
          calendarRef={calendarRef}
          robotsCalendarArray={robotsCalendarArray}
          filterStates={filterStates}
          setFilterStates={setFilterStates}
        ></FilterSelector>
        {renderCalendar()}
      </div>
    </>
  );
}
