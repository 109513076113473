var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { LoginCard } from './login-card';
var prefix = 'login-page';
var classes = {
    container: prefix + "-container",
};
var StyledDiv = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&." + classes.container] = {
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        _b);
});
export var LoginPage = function (props) {
    return (_jsx(StyledDiv, __assign({ className: classes.container }, { children: _jsx(LoginCard, __assign({}, props), void 0) }), void 0));
};
export default LoginPage;
