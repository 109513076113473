import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
} from '@mui/material';
import { Loading } from './Loading';
import { EventFormSelection } from './EventFormSelections';

const dialogClasses = {
  title: 'confirmation-dialogue-info-value',
  actionBtn: 'confirmation-dialogue-action-button',
};

const StyledDialog = styled((props) => <Dialog {...props} />)(() => ({
  [`& .${dialogClasses.title}`]: {
    flex: '1 1 auto',
  },
  [`& .${dialogClasses.actionBtn}`]: {
    minWidth: 80,
  },
}));

export function StyledConfirmationDialog(props) {
  const {
    title = 'Edit Event',
    titleTextColor = '#FFFFFF',
    titleBackgroundColor = '#1976D2',
    confirmText = 'OK',
    cancelText = 'Cancel',
    submitting = false,
    classes,
    onSubmit,
    // toolbar,
    onClose,
    children,
    validateForm,
    ...otherProps
  } = props;

  return (
    <StyledDialog onClose={onClose} {...otherProps}>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          if (validateForm && !validateForm()) {
            // Form is not valid, do not submit
            return;
          }
          onSubmit && onSubmit(ev);
        }}
        aria-label={title}
      >
        <DialogTitle style={{ backgroundColor: titleBackgroundColor, color: titleTextColor }}>
          <Grid container wrap="nowrap">
            <Grid item className={dialogClasses.title}>
              {title}
            </Grid>
            {/* <Grid item>{toolbar}</Grid> */}
          </Grid>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={(ev) => onClose && onClose(ev, 'escapeKeyDown')}
            disabled={submitting}
            // className={clsx(dialogClasses.actionBtn, classes?.button)}
          >
            {cancelText}
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={submitting}
            // className={clsx(dialogClasses.actionBtn, classes?.button)}
          >
            <Loading hideChildren loading={submitting} size="1.5em" color="inherit">
              {confirmText}
            </Loading>
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
}
