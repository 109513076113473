import React, { useState } from 'react';
import {
  Alert,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';

interface ZoneSequenceSelectorProps {
  zones: Array<string>;
  zoneSequence: Array<string>;
  setZoneSequence: React.Dispatch<React.SetStateAction<Array<string>>>;
  setOpenZoneSequenceSelector: React.Dispatch<React.SetStateAction<Boolean>>;
}

interface Zone {
  id: string;
  content: string;
}

interface Column {
  name: string;
  items: Zone[];
}

interface Rows {
  [key: string]: Column;
}

const rowsFromBackend: Rows = {
  ZoneAvailable: {
    name: 'ZA',
    items: Array<Zone>(),
  },
};

function ZoneSequenceSelector(props: ZoneSequenceSelectorProps) {
  const { zones, zoneSequence, setZoneSequence, setOpenZoneSequenceSelector, ...other } = props;
  const [tempZoneSequence, setTempZoneSequence] = useState(zoneSequence);
  const [selectedZone, setSelectedZone] = useState('');
  console.log('ZONE SEQUENCE COMPONENT');

  const addZoneToSequence = () => {
    if (selectedZone == '') return;

    let newSquence = tempZoneSequence.slice();
    newSquence.push(selectedZone);
    setTempZoneSequence(newSquence);
    return;
  };

  const removeZoneFromSequence = () => {
    let newZoneSequence = tempZoneSequence.slice();
    newZoneSequence.pop();
    setTempZoneSequence(newZoneSequence);
    return;
  };

  const saveZoneSequence = () => {
    setZoneSequence(tempZoneSequence);
  };

  // a little function to help us with reordering the result
  const reorder = (arr, initialIndex, endIndex) => {
    let result = arr.slice();
    result[endIndex] = arr[initialIndex];
    result[initialIndex] = arr[endIndex];

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const reorderedSequence = reorder(
      tempZoneSequence,
      result.source.index,
      result.destination.index,
    );
    setTempZoneSequence(reorderedSequence);
    return;
  };

  return (
    <Grid container direction="row" wrap="nowrap" spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="zone-sequence">Zones Available</InputLabel>
          <Select
            labelId="select-clean-zone"
            id="select-clean-zone"
            value={selectedZone}
            label="Zone Sequence"
            onChange={(event: SelectChangeEvent) => setSelectedZone(event.target.value)}
          >
            {zones.map((zone) => (
              <MenuItem value={zone} key={zone}>
                {zone}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <React.StrictMode> */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey', // Adjust the background colors
                    padding: 6,
                    display: 'flex',
                    flexDirection: 'row',
                    overflowX: 'auto',
                    minWidth: '500px',
                    minHeight: '8rem',
                    alignContent: 'center',
                    border: '2px dashed #ccc', // Add a dashed border
                    borderRadius: '4px', // Add some border radius
                  }}
                >
                  {tempZoneSequence.map((zone, index) => {
                    return (
                      <React.Fragment key={zone}>
                        <Draggable key={zone} draggableId={zone} index={index}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  userSelect: 'none',
                                  padding: 16,
                                  margin: '0 8px 8px 8px',
                                  height: '20px',
                                  width: 'auto',
                                  fontSize: '12px',
                                  borderRadius: '20px 20px 20px 20px',
                                  backgroundColor: snapshot.isDragging ? '#263B4A' : '#faca69',
                                  color: 'black',
                                  position: 'relative',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',

                                  ...provided.draggableProps.style,
                                }}
                              >
                                {zone}
                              </div>
                            );
                          }}
                        </Draggable>
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        {/* </React.StrictMode> */}
        <Grid
          container
          spacing={4}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ paddingTop: 10 }}
        >
          <Grid item xs={6} md={4}>
            <Button variant="contained" onClick={addZoneToSequence} sx={{ width: 150 }}>
              Add Zone
            </Button>
          </Grid>
          <Grid item xs={6} md={4}>
            <Button variant="contained" onClick={removeZoneFromSequence} sx={{ width: 150 }}>
              Remove Zone
            </Button>
          </Grid>
          <Grid item xs={6} md={4}></Grid>
          <Grid item xs={6} md={4}>
            <Button onClick={() => saveZoneSequence()} sx={{ width: 150 }}>
              Save Zone
            </Button>
          </Grid>
          <Grid item xs={6} md={4}>
            <Button onClick={() => setOpenZoneSequenceSelector(false)} sx={{ width: 150 }}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ZoneSequenceSelector;
