"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBuildingMap = void 0;
var GetBuildingMap_Request_1 = require("./GetBuildingMap_Request");
var GetBuildingMap_Response_1 = require("./GetBuildingMap_Response");
var GetBuildingMap = /** @class */ (function () {
    function GetBuildingMap() {
    }
    GetBuildingMap.FullServiceName = 'rmf_building_map_msgs/srv/GetBuildingMap';
    GetBuildingMap.Request = GetBuildingMap_Request_1.GetBuildingMap_Request;
    GetBuildingMap.Response = GetBuildingMap_Response_1.GetBuildingMap_Response;
    return GetBuildingMap;
}());
exports.GetBuildingMap = GetBuildingMap;
