var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, MenuItem, styled, TextField, useTheme, Button, Select, InputLabel, Dialog, DialogContent, DialogTitle, Stack, } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, FormControl, FormControlLabel, FormGroup, FormLabel, FormHelperText, } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import { ConfirmationDialog } from '../confirmation-dialog';
import AddIcon from '@mui/icons-material/Add';
import { convertEditFormIntoSchedule, convertEventFormInfoToSchedule, checkEditFormValid, } from './utils';
import ZoneSequenceSelector from './zone-sequence';
import { isPast } from 'date-fns';
var classes = {
    selectedEvent: 'create-event-selected-event',
};
var StyledConfirmationDialog = styled(function (props) { return (React.createElement(ConfirmationDialog, __assign({}, props))); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& ." + classes.selectedEvent] = {
            background: theme.palette.action.focus,
        },
        _b);
});
// TODO: Should find a better way to store the list of robots
var robot_list = {
    LeoVac: ['LeoVac-01', 'LeoVac-02'],
    S75: ['S75-A'],
    V40: ['V40-A', 'V40-B'],
    '': [],
};
export var Operation;
(function (Operation) {
    Operation[Operation["ADD"] = 0] = "ADD";
    Operation[Operation["UPDATE"] = 1] = "UPDATE";
})(Operation || (Operation = {}));
export function CreateEventForm(_a) {
    var _this = this;
    var _b;
    var onClose = _a.onClose, submitSchedule = _a.submitSchedule, updateSchedule = _a.updateSchedule, deleteSchedule = _a.deleteSchedule, updateSeries = _a.updateSeries, onSuccess = _a.onSuccess, onFail = _a.onFail, currentSchedule = _a.currentSchedule, editEventFormInfo = _a.editEventFormInfo, otherProps = __rest(_a, ["onClose", "submitSchedule", "updateSchedule", "deleteSchedule", "updateSeries", "onSuccess", "onFail", "currentSchedule", "editEventFormInfo"]);
    var theme = useTheme();
    console.log('EDIT EVENT FORM');
    var _c = useState(''), eventRemarks = _c[0], setEventRemarks = _c[1];
    var _d = useState(''), selectedZonesError = _d[0], setSelectedZonesError = _d[1];
    var _e = useState({}), errors = _e[0], setErrors = _e[1]; // AMI
    var validateForm = function () {
        var isFormValid = true;
        var newErrors = {};
        if (!selectedEventType) {
            newErrors.selectedEventType = 'Event Category is required.';
            isFormValid = false;
        }
        if (!selectedFleet) {
            newErrors.selectedFleet = 'Fleet is required.';
            isFormValid = false;
        }
        if (!selectedRobot) {
            newErrors.selectedRobot = 'Robot is required.';
            isFormValid = false;
        }
        if (selectedEventType === 'Cleaning Task' && selectedZones.length === 0) {
            newErrors.selectedZones = 'At least one zone must be selected for Cleaning Task.';
            isFormValid = false;
        }
        setErrors(newErrors);
        return isFormValid;
    };
    var _f = useState(false), isFormValid = _f[0], setIsFormValid = _f[1];
    var _g = React.useState(false), submitting = _g[0], setSubmitting = _g[1];
    var _h = React.useState(editEventFormInfo.eventId), eventId = _h[0], setEventId = _h[1];
    var _j = React.useState(editEventFormInfo.robotName), selectedRobot = _j[0], setSelectedRobot = _j[1];
    var _k = React.useState(editEventFormInfo.fleetName), selectedFleet = _k[0], setSelectedFleet = _k[1];
    var _l = React.useState(editEventFormInfo.eventCategory), selectedEventType = _l[0], setSelectedEventType = _l[1];
    // React States for start time and end times
    var _m = React.useState(editEventFormInfo.startTime), startTime = _m[0], setStartTime = _m[1];
    var _o = React.useState(editEventFormInfo.endTime), endTime = _o[0], setEndTime = _o[1];
    var _p = React.useState(editEventFormInfo.endTime), estimatedEndTime = _p[0], setEstimatedEndTime = _p[1];
    var _q = React.useState(editEventFormInfo.zoneSequence ? editEventFormInfo.zoneSequence : Array()), selectedZones = _q[0], setSelectedZones = _q[1];
    var previousSelectedZonesRef = React.useRef([]);
    var _r = React.useState(editEventFormInfo.reccurrenceInfo ? editEventFormInfo.reccurrenceInfo : {}), reccurrenceInfo = _r[0], setRecurrenceInfo = _r[1];
    var eventTypes = ['Daily Maintenance', 'Vendor Maintenance', 'Cleaning Task'];
    var _s = useState(false), openZoneSequence = _s[0], setOpenZoneSequence = _s[1];
    var hoursInADay = [];
    for (var i = 0; i < 24; i++) {
        var hourStr = i < 10 ? '0' + i.toString() : i.toString();
        hoursInADay.push(hourStr);
    }
    var minutesInAnHour = [];
    for (var i = 0; i < 60; i++) {
        var minStr = i < 10 ? '0' + i.toString() : i.toString();
        minutesInAnHour.push(minStr);
    }
    var handleZoneSequenceOpen = function () {
        previousSelectedZonesRef.current = JSON.parse(JSON.stringify(selectedZones));
        setOpenZoneSequence(true);
    };
    var handleZoneSequenceClose = function () {
        setSelectedZones(previousSelectedZonesRef.current);
        setOpenZoneSequence(false);
    };
    var handleZoneSequenceSave = function () {
        setOpenZoneSequence(false);
    };
    // Conditional Display for Zone Sequence
    var renderCleanZoneSelection = function () {
        switch (selectedEventType) {
            case 'Cleaning Task':
                return (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { container: true, spacing: 2 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Button, { variant: "outlined", startIcon: React.createElement(AddIcon, null), onClick: handleZoneSequenceOpen }, "Zones")),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(InputLabel, { id: "zone-sequence" }, " Selected Zone Sequence"),
                            React.createElement(Box, { sx: { border: '1px solid grey', margin: 'flex' } },
                                React.createElement(Stack, { direction: "row" }, selectedZones.map(function (zone) {
                                    return (React.createElement(Box, { sx: {
                                            border: '2px solid black',
                                            margin: '8px',
                                            borderRadius: '8px',
                                            padding: '8px',
                                            backgroundColor: 'lightblue',
                                            fontSize: '10px',
                                        } }, zone));
                                }))),
                            errors.selectedZones && React.createElement("p", { style: { color: 'red' } }, errors.selectedZones))),
                    React.createElement(Dialog, { open: openZoneSequence, onClose: handleZoneSequenceClose },
                        React.createElement(DialogTitle, { style: { color: '#FFFFFF', backgroundColor: '#1976D2' } }, "Create Zone"),
                        React.createElement(DialogContent, null,
                            React.createElement(ZoneSequenceSelector, { selectedRobot: selectedFleet, currentZoneSequence: selectedZones, setZoneSequence: setSelectedZones }),
                            React.createElement(Button, { onClick: handleZoneSequenceClose }, "Close"),
                            React.createElement(Button, { onClick: handleZoneSequenceSave }, "Save")))));
            default:
                return null;
        }
    };
    var renderReccurrenceSelection = function (editSeries) {
        if (editSeries && editEventFormInfo.reccurrenceInfo) {
            var dayChangeCallbackArray_1 = new Array();
            var dayNames_1 = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ];
            // Callbacks for logic for reccurrence check boxes.
            // function to check if other days are still checked
            var checkOtherDays = function (checkedStates) {
                for (var _i = 0, checkedStates_1 = checkedStates; _i < checkedStates_1.length; _i++) {
                    var checkedState = checkedStates_1[_i];
                    if (checkedState) {
                        return true;
                    }
                }
                return false;
            };
            // Create callback for updating check boxes
            var createDayChangeCallback_1 = function (day) {
                var callback = function (event) {
                    var updatedDaysCheckedArray = reccurrenceInfo.daysCheckedArray.slice();
                    updatedDaysCheckedArray[day] = event.target.checked;
                    var weeklyChecked = updatedDaysCheckedArray.some(function (checked) { return checked; });
                    setRecurrenceInfo({
                        dailyChecked: event.target.checked ? false : reccurrenceInfo.dailyChecked,
                        weeklyChecked: weeklyChecked,
                        monthlyChecked: event.target.checked ? false : reccurrenceInfo.monthlyChecked,
                        daysCheckedArray: updatedDaysCheckedArray,
                        reccuranceEndDateChecked: reccurrenceInfo.reccuranceEndDateChecked,
                    });
                };
                return callback;
            };
            var handleDailyChange = function (event) {
                setRecurrenceInfo({
                    dailyChecked: event.target.checked,
                    weeklyChecked: event.target.checked ? false : reccurrenceInfo.weeklyChecked,
                    monthlyChecked: event.target.checked ? false : reccurrenceInfo.monthlyChecked,
                    daysCheckedArray: event.target.checked
                        ? Array(7).fill(false)
                        : reccurrenceInfo.daysCheckedArray,
                    reccuranceEndDateChecked: reccurrenceInfo.reccuranceEndDateChecked,
                });
            };
            var handleWeeklyChange = function (event) {
                setRecurrenceInfo({
                    dailyChecked: event.target.checked ? false : reccurrenceInfo.dailyChecked,
                    weeklyChecked: event.target.checked,
                    monthlyChecked: event.target.checked ? false : reccurrenceInfo.monthlyChecked,
                    daysCheckedArray: event.target.checked
                        ? Array(7).fill(false)
                        : reccurrenceInfo.daysCheckedArray,
                    reccuranceEndDateChecked: reccurrenceInfo.reccuranceEndDateChecked,
                });
            };
            var handleEndDateCheckedChange = function (event) {
                setRecurrenceInfo({
                    dailyChecked: reccurrenceInfo.dailyChecked,
                    weeklyChecked: reccurrenceInfo.weeklyChecked,
                    monthlyChecked: reccurrenceInfo.monthlyChecked,
                    daysCheckedArray: reccurrenceInfo.daysCheckedArray,
                    reccuranceEndDateChecked: event.target.checked,
                });
            };
            return (React.createElement(React.Fragment, null,
                React.createElement(FormControl, { component: "fieldset", variant: "outlined" },
                    React.createElement(FormLabel, { component: "legend", style: { color: 'black' } }, "Event Recurrence Pattern"),
                    React.createElement(FormHelperText, null, " Leave this section blank if there is no recurrence"),
                    React.createElement(FormGroup, null,
                        React.createElement(Grid, { container: true },
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: reccurrenceInfo.dailyChecked, onChange: handleDailyChange }), label: "All" }),
                            reccurrenceInfo.daysCheckedArray.map(function (check, idx) {
                                dayChangeCallbackArray_1.push(createDayChangeCallback_1(idx));
                                return (React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { size: "small", checked: reccurrenceInfo.daysCheckedArray[idx], onChange: dayChangeCallbackArray_1[idx] }), label: dayNames_1[idx] }));
                            })),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 12, style: { paddingBottom: 10 } },
                                React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { size: "small", checked: reccurrenceInfo.reccuranceEndDateChecked, onChange: handleEndDateCheckedChange }), label: "End Date" })),
                            React.createElement(Grid, { item: true, xs: 4 },
                                React.createElement(DatePicker, { inputFormat: 'dd/MM/yyyy', minDate: new Date(), value: reccurrenceInfo.reuccuranceEndDate
                                        ? reccurrenceInfo.reuccuranceEndDate
                                        : startTime, onChange: function (date) {
                                        if (!date) {
                                            return;
                                        }
                                        reccurrenceInfo.reuccuranceEndDate = date;
                                        setRecurrenceInfo(reccurrenceInfo);
                                    }, label: "Recurrence End Date", renderInput: function (props) { return React.createElement(TextField, __assign({}, props)); } })))))));
        }
        return React.createElement(React.Fragment, null);
    };
    // no memo because deps would likely change
    var handleSubmit = function (ev) { return __awaiter(_this, void 0, void 0, function () {
        var addSchedule, updateScheduleInfo;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    editEventFormInfo.startTime = startTime;
                    editEventFormInfo.endTime = endTime;
                    editEventFormInfo.estimatedEndTime = estimatedEndTime;
                    editEventFormInfo.zoneSequence = selectedZones;
                    editEventFormInfo.fleetName = selectedFleet;
                    editEventFormInfo.robotName = selectedRobot;
                    editEventFormInfo.eventCategory = selectedEventType;
                    editEventFormInfo.reccurrenceInfo = reccurrenceInfo;
                    editEventFormInfo.eventRemarks = eventRemarks;
                    console.log('Event Remarks:', eventRemarks);
                    if (!checkEditFormValid(editEventFormInfo)) {
                        console.log('EVENT FORM NOT VALID');
                        return [2 /*return*/];
                    }
                    if (!(editEventFormInfo.operation == Operation.ADD)) return [3 /*break*/, 1];
                    addSchedule = convertEventFormInfoToSchedule(editEventFormInfo);
                    if (!submitSchedule) {
                        console.debug('No add schedule callback provided');
                        return [2 /*return*/];
                    }
                    if (!addSchedule) {
                        console.debug('Not able to convert schedule into event form');
                        return [2 /*return*/];
                    }
                    submitSchedule(addSchedule);
                    return [3 /*break*/, 8];
                case 1:
                    if (!(editEventFormInfo.operation == Operation.UPDATE)) return [3 /*break*/, 8];
                    updateScheduleInfo = convertEditFormIntoSchedule(editEventFormInfo, currentSchedule);
                    console.log("CONVERTED SCHEDULE TO UPDATE " + JSON.stringify(updateScheduleInfo));
                    if (!updateScheduleInfo || !updateSchedule) {
                        console.debug('Update schedule callback does not exist or schedule is not valid');
                        return [2 /*return*/];
                    }
                    if (!updateScheduleInfo.deleteSchedule) return [3 /*break*/, 3];
                    if (!deleteSchedule) {
                        console.debug('There is a thigns to delete but no delete schedule callback!');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, deleteSchedule(updateScheduleInfo.deleteSchedule)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    if (!updateScheduleInfo.addSchedule) return [3 /*break*/, 5];
                    if (!submitSchedule) {
                        console.debug('There are things to add but no add schedule callback!');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, submitSchedule(updateScheduleInfo.addSchedule)];
                case 4:
                    _a.sent();
                    console.log('Sent additional schedule');
                    _a.label = 5;
                case 5:
                    if (!updateScheduleInfo.updateSeries) return [3 /*break*/, 7];
                    if (!updateSeries) {
                        console.debug('Series to updated but no update series callback');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, updateSeries(updateScheduleInfo.updateSeries)];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7:
                    updateSchedule(updateScheduleInfo.updateSchedule);
                    _a.label = 8;
                case 8:
                    onClose ? onClose() : console.log('No onClose callback set');
                    return [2 /*return*/];
            }
        });
    }); };
    var submitText = 'Submit';
    return (React.createElement(StyledConfirmationDialog, __assign({ title: "Edit Event", titleTextColor: "#FFFFFF", titleBackgroundColor: "#1976D2", onClose: onClose, submitting: submitting, confirmText: submitText, maxWidth: "md", fullWidth: false, onSubmit: handleSubmit, disableEnforceFocus: true, validateForm: validateForm }, otherProps),
        React.createElement(Grid, { container: true, direction: "row", wrap: "wrap" },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(FormControl, { fullWidth: true, margin: "normal" },
                        React.createElement(InputLabel, { id: "event-type" }, "Event Category"),
                        React.createElement(Select, { labelId: "select-event-type", id: "select-event-type", value: selectedEventType, label: "Event Category", onChange: function (event) { return setSelectedEventType(event.target.value); } }, eventTypes.map(function (item) {
                            return React.createElement(MenuItem, { value: item }, item);
                        })),
                        errors.selectedEventType && (React.createElement("p", { style: { color: 'red' } }, errors.selectedEventType)))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { fullWidth: true, label: "Event Remarks", variant: "outlined", value: eventRemarks, onChange: function (e) { return setEventRemarks(e.target.value); } })),
                React.createElement(Grid, { item: true, xs: 4 },
                    React.createElement(DatePicker, { inputFormat: 'dd/MM/yyyy', minDate: new Date(), value: startTime, onChange: function (date) {
                            if (!date) {
                                return;
                            }
                            setStartTime(date);
                        }, label: "Start Date", renderInput: function (props) { return React.createElement(TextField, __assign({}, props)); } })),
                React.createElement(Grid, { item: true, xs: 2 },
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(InputLabel, { id: "select-start-time-hh" }, "Start HH"),
                        React.createElement(Select, { labelId: "select-start-time-hh", id: "select-start-time-hh", value: startTime.toTimeString().slice(0, 2), label: "Start HH", onChange: function (event) {
                                var selectedHour = Number(event.target.value);
                                var selectedMinute = startTime.getMinutes();
                                // Create a new Date object with the selected hour and existing minute
                                var date = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), selectedHour, selectedMinute);
                                if (isPast(date)) {
                                }
                                else {
                                    setStartTime(date);
                                    if (endTime.getTime() <= date.getTime()) {
                                        // add 20 minutes.
                                        setEndTime(new Date(date.getTime() + 20 * 60 * 1000));
                                    }
                                    if (estimatedEndTime.getTime() <= date.getTime()) {
                                        // add 20 minutes.
                                        setEstimatedEndTime(new Date(date.getTime() + 20 * 60 * 1000));
                                    }
                                }
                                setErrors(errors);
                            } }, hoursInADay.map(function (item) {
                            return React.createElement(MenuItem, { value: item }, item);
                        })))),
                React.createElement(Grid, { item: true, xs: 2 },
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(InputLabel, { id: "select-start-time-mm" }, "Start MM"),
                        React.createElement(Select, { labelId: "select-start-time-mm", id: "select-start-time-mm", value: startTime.toTimeString().slice(3, 5), label: "Start MM", onChange: function (event) {
                                var selectedHour = startTime.getHours();
                                var selectedMinute = Number(event.target.value);
                                // Create a new Date object with the selected hour and minute
                                var date = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), selectedHour, selectedMinute);
                                // Check if the selected time is in the past
                                if (isPast(date)) {
                                }
                                else {
                                    setStartTime(date);
                                    if (endTime.getTime() <= date.getTime()) {
                                        // add 20 minutes.
                                        setEndTime(new Date(date.getTime() + 20 * 60 * 1000));
                                    }
                                    if (estimatedEndTime.getTime() <= date.getTime()) {
                                        // add 20 minutes.
                                        setEstimatedEndTime(new Date(date.getTime() + 20 * 60 * 1000));
                                    }
                                }
                                setErrors(errors);
                            } }, minutesInAnHour.map(function (item) {
                            return React.createElement(MenuItem, { value: item }, item);
                        })))),
                React.createElement(Grid, { item: true, xs: 2 },
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(InputLabel, { id: "select-end-time-hh" }, "End HH"),
                        React.createElement(Select, { labelId: "select-end-time-hh", id: "select-end-time-hh", value: endTime.toTimeString().slice(0, 2), label: "End HH", onChange: function (event) {
                                var date = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate(), Number(event.target.value), endTime.getMinutes());
                                setEndTime(date);
                            } }, hoursInADay.map(function (item) {
                            return React.createElement(MenuItem, { value: item }, item);
                        })))),
                React.createElement(Grid, { item: true, xs: 2 },
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(InputLabel, { id: "select-end-time-mm" }, "End MM"),
                        React.createElement(Select, { labelId: "select-end-time-mm", id: "select-end-time-mm", value: endTime.toTimeString().slice(3, 5), label: "End MM", onChange: function (event) {
                                var date = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate(), endTime.getHours(), Number(event.target.value));
                                setEndTime(date);
                            } }, minutesInAnHour.map(function (item) {
                            return React.createElement(MenuItem, { value: item }, item);
                        })))),
                React.createElement(Grid, { item: true, xs: 12 }, renderReccurrenceSelection(!((_b = editEventFormInfo.options) === null || _b === void 0 ? void 0 : _b.editSingleEvent))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(InputLabel, { id: "fleet-type" }, "Fleet"),
                        React.createElement(Select, { labelId: "select-fleet-type", id: "select-fleet-type", value: selectedFleet, label: "Fleet Name", onChange: function (event) {
                                setSelectedFleet(event.target.value);
                                setSelectedZones([]);
                            } }, Object.entries(robot_list).map(function (pair) {
                            return React.createElement(MenuItem, { value: pair[0] }, pair[0]);
                        })),
                        errors.selectedFleet && React.createElement("p", { style: { color: 'red' } }, errors.selectedFleet))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(InputLabel, { id: "robot-name" }, "Robot"),
                        React.createElement(Select, { labelId: "select-robot-name", id: "select-robot-name", value: selectedRobot, label: "Robot Name", onChange: function (event) { return setSelectedRobot(event.target.value); } }, robot_list[selectedFleet].map(function (item) {
                            return React.createElement(MenuItem, { value: item }, item);
                        })),
                        errors.selectedRobot && React.createElement("p", { style: { color: 'red' } }, errors.selectedRobot))),
                React.createElement(Grid, { item: true, xs: 12 }, renderCleanZoneSelection())))));
}
