"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathRequest = void 0;
var Location_1 = require("../../rmf_fleet_msgs/msg/Location");
var PathRequest = /** @class */ (function () {
    function PathRequest(fields) {
        if (fields === void 0) { fields = {}; }
        this.fleet_name = fields.fleet_name || '';
        this.robot_name = fields.robot_name || '';
        this.path = fields.path || [];
        this.task_id = fields.task_id || '';
    }
    PathRequest.validate = function (obj) {
        var e_1, _a;
        if (typeof obj['fleet_name'] !== 'string') {
            throw new Error('expected "fleet_name" to be "string"');
        }
        if (typeof obj['robot_name'] !== 'string') {
            throw new Error('expected "robot_name" to be "string"');
        }
        if (!Array.isArray(obj['path'])) {
            throw new Error('expected "path" to be an array');
        }
        try {
            for (var _b = __values(obj['path'].entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), i = _d[0], v = _d[1];
                try {
                    Location_1.Location.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"path\":\n  " + e.message);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (typeof obj['task_id'] !== 'string') {
            throw new Error('expected "task_id" to be "string"');
        }
    };
    PathRequest.FullTypeName = 'rmf_fleet_msgs/msg/PathRequest';
    return PathRequest;
}());
exports.PathRequest = PathRequest;
/*
string fleet_name
string robot_name
Location[] path

# task_id must be copied into future RobotState messages
string task_id

*/
