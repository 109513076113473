"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DispenserRequestItem = void 0;
var DispenserRequestItem = /** @class */ (function () {
    function DispenserRequestItem(fields) {
        if (fields === void 0) { fields = {}; }
        this.type_guid = fields.type_guid || '';
        this.quantity = fields.quantity || 0;
        this.compartment_name = fields.compartment_name || '';
    }
    DispenserRequestItem.validate = function (obj) {
        if (typeof obj['type_guid'] !== 'string') {
            throw new Error('expected "type_guid" to be "string"');
        }
        if (typeof obj['quantity'] !== 'number') {
            throw new Error('expected "quantity" to be "number"');
        }
        if (typeof obj['compartment_name'] !== 'string') {
            throw new Error('expected "compartment_name" to be "string"');
        }
    };
    DispenserRequestItem.FullTypeName = 'rmf_dispenser_msgs/msg/DispenserRequestItem';
    return DispenserRequestItem;
}());
exports.DispenserRequestItem = DispenserRequestItem;
/*
string type_guid
int32 quantity
string compartment_name

*/
