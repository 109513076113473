import { DataGrid, } from '@mui/x-data-grid';
import { Box, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { Status2 } from 'api-client';
import { robotStatusToUpperCase } from './utils';
export function RobotDataGridTable(_a) {
    var onRobotClick = _a.onRobotClick, robots = _a.robots;
    var handleEvent = function (params, event) {
        if (onRobotClick) {
            onRobotClick(event, params.row);
        }
    };
    var Status = function (params) {
        var theme = useTheme();
        var statusLabelStyle = (function () {
            var error = {
                color: theme.palette.error.main,
            };
            var charging = {
                color: theme.palette.info.main,
            };
            var working = {
                color: theme.palette.success.main,
            };
            var defaultColor = {
                color: theme.palette.warning.main,
            };
            switch (params.row.status) {
                case Status2.Error:
                    return error;
                case Status2.Charging:
                    return charging;
                case Status2.Working:
                    return working;
                default:
                    return defaultColor;
            }
        })();
        return (React.createElement(Box, { sx: statusLabelStyle },
            React.createElement(Typography, { "data-testid": "status", component: "p", sx: {
                    fontWeight: 'bold',
                    fontSize: 14,
                } }, robotStatusToUpperCase(params.row.status))));
    };
    var columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: false,
            valueGetter: function (params) { return params.row.name; },
            flex: 1,
            filterable: true,
        },
        {
            field: 'fleet',
            headerName: 'Fleet',
            width: 90,
            valueGetter: function (params) { return params.row.fleet; },
            flex: 1,
            filterable: true,
        },
        {
            field: 'estFinishTime',
            headerName: 'Est. Task Finish Time',
            width: 150,
            editable: false,
            valueGetter: function (params) {
                return params.row.estFinishTime ? new Date(params.row.estFinishTime).toLocaleString() : '-';
            },
            flex: 1,
            filterable: true,
        },
        {
            field: 'level',
            headerName: 'Level',
            width: 150,
            editable: false,
            valueGetter: function (params) { return params.row.level; },
            flex: 1,
            filterable: true,
        },
        {
            field: 'battery',
            headerName: 'Battery',
            width: 150,
            editable: false,
            valueGetter: function (params) { return params.row.battery * 100 + "%"; },
            flex: 1,
            filterable: true,
        },
        {
            field: 'lastUpdateTime',
            headerName: 'Last Updated',
            width: 150,
            editable: false,
            valueGetter: function (params) {
                return params.row.lastUpdateTime ? new Date(params.row.lastUpdateTime).toLocaleString() : '-';
            },
            flex: 1,
            filterable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            editable: false,
            flex: 1,
            renderCell: Status,
            filterable: true,
        },
    ];
    return (React.createElement(DataGrid, { autoHeight: true, getRowId: function (r) { return r.name; }, rows: robots, pageSize: 5, rowHeight: 38, columns: columns, rowsPerPageOptions: [5], onRowClick: handleEvent }));
}
