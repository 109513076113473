var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography, styled } from '@mui/material';
import React from 'react';
var prefix = 'login-card';
var classes = {
    container: prefix + "-container",
    title: prefix + "-title",
    logo: prefix + "-logo",
};
var StyledDiv = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&." + classes.container] = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderStyle: 'none',
            borderRadius: 20,
            borderColor: 'black',
            padding: '70px',
            width: 'fit-content',
            minWidth: 250,
            backgroundColor: 'snow',
            boxShadow: theme.shadows[12],
        },
        _b["& ." + classes.title] = {
            color: '#44497a',
        },
        _b["& ." + classes.logo] = {
            width: 100,
            margin: '25px 0px 50px 0px',
        },
        _b);
});
export var LoginCard = React.forwardRef(function (_a, ref) {
    var title = _a.title, logo = _a.logo, onLoginClick = _a.onLoginClick, children = _a.children;
    return (_jsxs(StyledDiv, __assign({ ref: ref, className: classes.container }, { children: [_jsx(Typography, __assign({ variant: "h4", className: classes.title }, { children: title }), void 0), _jsx("img", { src: logo, alt: "", className: classes.logo }, void 0), _jsx(Button, __assign({ variant: "contained", "aria-label": "Login", onClick: onLoginClick }, { children: "Login" }), void 0), children] }), void 0));
});
export default LoginCard;
