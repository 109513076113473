import * as parser from 'cron-parser';
import { v4 as uuidv4 } from 'uuid';
import { removeNullUndefined } from './general_utils';
import {
  AddSchedule,
  DeleteSchedule,
  UpdateSchedule,
  UpdateSeries,
  TogglePause,
  CancelTask,
} from './submit_api';
import {
  ScheduleForTaskScheduler,
  EventForTaskScheduler,
  DeleteScheduleRequest,
  UpdateSeriesRequest,
  EventsSeries,
  ErrorCode,
} from 'api-client';
import { RmfIngress } from '../../rmf-app';
import { AxiosResponse } from 'axios';

// Return a new Date object with the Hours and Minutes of the currentTime
//but with Year, Month and Date of newDate
export const getNewDateWithCurrentTime = (currentTime: Date, newDate: Date) => {
  let temp = new Date(currentTime);
  console.log(typeof newDate);
  console.log(newDate);
  console.log(temp);
  temp.setFullYear(newDate.getFullYear());
  temp.setMonth(newDate.getMonth());
  temp.setDate(newDate.getDate());
  return temp;
};

export const hourStringFromDate = (date: Date) => {
  let hour = date.getHours();
  return hour < 10 ? `0${hour}` : hour.toString();
};

export const minuteStringFromDate = (date: Date) => {
  let minute = date.getMinutes();
  return minute < 10 ? `0${minute}` : minute.toString();
};

// return true if start time is before end time
export const isStartBeforeEnd = (start: Date, end: Date, threshold: number = 0) => {
  if (end.valueOf() - start.valueOf() < threshold * 60 * 10 ** 3) return true;

  return false;
};

export const isStartDateBeforeEndDate = (start: Date, end: Date) => {
  if (start.getFullYear() > end.getFullYear()) return false;

  if (start.getFullYear() < end.getFullYear()) return true;

  // Start year is equal
  if (start.getMonth() > end.getMonth()) return false;

  if (start.getMonth() < end.getMonth()) return true;

  // Same month
  if (start.getDate() < end.getDate()) return true;

  return false;
};

const unixSecondsToDate = (seconds: number) => {
  return new Date(seconds * 10 ** 3);
};

const getFleetNameFromDetails = (details: any): string | null => {
  if (!('fleet' in details)) {
    return null;
  }
  return details['fleet'];
};

const getRobotNameFromDetails = (details: any): string | null => {
  if (!('robot' in details)) {
    return null;
  }
  return details['robot'];
};

const getZoneFromEventDetails = (details: any): string | null => {
  if (!('zone' in details)) {
    return null;
  }
  return details['zone'];
};

const getEventTypeFromEvent = (eventType: string) => {
  return eventType;
};

// Returns an array of zones given a DAG ID
// The caveat is that it only works when all nodes have only one successor.
const getDagEvents = (info: { dagId: string; currentSchedule: any }) => {
  if (!info.currentSchedule.dependencies) {
    return null;
  }
  if (!(info.dagId in info.currentSchedule.dependencies)) {
    return null;
  }

  let nodeEvents = Array<string>();
  // add all the events in the dag in the node Event list in order

  let entryNode;
  // NOTE: We are making change to the dag here so we need to make a copy
  let dag: any = JSON.parse(JSON.stringify(info.currentSchedule.dependencies[info.dagId]));
  for (let eventId in dag) {
    if (!Array.isArray(dag[eventId])) {
      return null;
    }
    if (dag[eventId].length == 0) {
      entryNode = eventId;

      // NOTE: This assumes that there is only one entry node.
      break;
    }
  }
  if (!entryNode) {
    console.debug('No entry node found');
    return null;
  }

  let nodeOfInterest = entryNode;
  nodeEvents.push(nodeOfInterest);
  // Assumes that the each non-entry node has only one dependency
  // the remaining item should be the entry node
  let run = true;
  while (run) {
    run = false;
    for (let eventId in dag) {
      if (dag[eventId].includes(nodeOfInterest)) {
        nodeOfInterest = eventId;
        nodeEvents.push(nodeOfInterest);
        // We found something so we continue running
        delete dag[eventId];
        run = true;
        break;
      }
    }
  }
  console.log(`NODE EVENTS: ${JSON.stringify(nodeEvents)}`);
  return nodeEvents.length > 0 ? nodeEvents : null;
};

const getZoneSequence = (info: { dagId: string; currentSchedule: any }): Array<string> | null => {
  let zoneSequenceEventIds = getDagEvents(info);
  let zoneSequence = Array<string>();
  if (!zoneSequenceEventIds) {
    return null;
  }
  for (let eventId of zoneSequenceEventIds) {
    if (!(eventId in info.currentSchedule.events)) {
      return null;
    }
    let eventDetails = info.currentSchedule.events[eventId].event_details;
    let cleanZone = getZoneFromEventDetails(eventDetails);
    if (!cleanZone) {
      return null;
    }
    zoneSequence.push(cleanZone);
  }
  return zoneSequence;
};

const convertToDaysCheckArray = (cronString: string): Array<boolean> | null => {
  let interval = parser.parseExpression(cronString);
  let fields = JSON.parse(JSON.stringify(interval.fields));
  if (fields.dayOfWeek.length >= 7 && fields.month.length === 12) {
    return new Array<boolean>(8).fill(true);
  } else if (fields.dayOfWeek.length < 7 && fields.month.length === 12) {
    let daysCheckedArray = new Array<boolean>(8).fill(false);
    for (let day of fields.dayOfWeek) {
      if (day === 7) {
        day = 0;
      }
      daysCheckedArray[day] = true;
    }
    return daysCheckedArray;
  }
  console.debug('Somehow a cron is created that is not supported by this event form');
  return null;
};

export enum Operation {
  AddSchedule = 0,
  EditEvent,
  EditEventOfDAG,
  EditOccurrenceOfEventSeries,
  EditOccurrenceOfDAGSeries,
  EditEventSeries,
  EditDAGSeries,
}

export enum EditOptions {
  Event = 0,
  EventOfDAG,
  OccurrenceOfEventSeries,
  OccurrenceOfDAGSeries,
  EventSeries,
  DAGSeries,
}

export enum EventType {
  Event = 0,
  EventOfDAG,
  OccurrenceOfDAGSeries,
  OccurrenceOfEventSeries,
}

export interface EventFormData {
  startTime: Date;
  endTime: Date;
  selectedFleet: string;
  selectedRobot: string;
  selectedEventType: string;
  daysCheckedArray: Array<boolean>;
  zoneSequence: Array<string>;
}

export interface EditFormInfo {
  eventId: string;
  currentSchedule: any;
  hideReccurrenceSelections: boolean;
  hideZoneSequenceSelections: boolean;
}

export interface EventFormOptions {
  operation: Operation;
  data: EventFormData;
  editFormInfo?: EditFormInfo;
}

export const getEventType = (eventId: string, currentSchedule: any) => {
  let event = currentSchedule.events[eventId];
  if (!event.series_id) {
    if (!event.dependency_id) return EventType.Event;
    return EventType.EventOfDAG;
  }
  // It is part of a series
  if (event.dependency_id) return EventType.OccurrenceOfDAGSeries;
  return EventType.OccurrenceOfEventSeries;
};

/////////////////////////////////// Create Event Form Options /////////////////////////////////////////////

// Converts information from RMF Scheduler Event to information to be displayed on the event form
export const eventToEventFormOptions = (
  eventId: string,
  currentSchedule: any,
  operation: Operation,
): EventFormOptions | null => {
  if (!(eventId in currentSchedule.events)) {
    return null;
  }

  const event = currentSchedule.events[eventId];
  let startTime = unixSecondsToDate(event.start_time);
  let endTime = unixSecondsToDate(event.start_time + event.duration);
  let category = getEventTypeFromEvent(event.type);
  let selectedFleet = getFleetNameFromDetails(event.event_details);
  let selectedRobot = getRobotNameFromDetails(event.event_details);
  let editFormInfo = {
    currentSchedule: currentSchedule,
    eventId: eventId,
    hideReccurrenceSelections: true,
    hideZoneSequenceSelections: false,
  };

  let zoneSequence;
  if (category === 'Cleaning Task') {
    if (event.dependency_id) {
      zoneSequence = getZoneSequence({
        dagId: event.dependency_id,
        currentSchedule: currentSchedule,
      });

      // We populate the start and end time according to the start time of the first event in the DAG,
      // and the end time of the last event in the DAG respectively.
      const dagEvents = getDagEvents({
        dagId: event.dependency_id,
        currentSchedule: currentSchedule,
      });
      if (dagEvents) {
        const dagStartEvent = currentSchedule.events[dagEvents[0]];
        const dagEndEvent = currentSchedule.events[dagEvents[dagEvents.length - 1]];
        startTime = unixSecondsToDate(dagStartEvent.start_time);
        endTime = unixSecondsToDate(dagEndEvent.start_time + dagEndEvent.duration);
      }
    } else {
      let zone = getZoneFromEventDetails(event.event_details);
      if (zone) {
        zoneSequence = new Array<string>(1).fill(zone);
      }
    }
  }
  if (!zoneSequence) zoneSequence = new Array<string>();

  let daysCheckedArray;

  if (operation === Operation.EditEventSeries || operation === Operation.EditDAGSeries) {
    editFormInfo.hideZoneSequenceSelections = true;
    editFormInfo.hideReccurrenceSelections = false;
  }

  if (event.series_id) {
    if (event.series_id in currentSchedule.series) {
      daysCheckedArray = convertToDaysCheckArray(currentSchedule.series[event.series_id].cron);
    }
    daysCheckedArray = new Array<boolean>(8).fill(false);
  } else {
    daysCheckedArray = new Array<boolean>(8).fill(false);
  }

  return {
    data: {
      startTime: startTime,
      endTime: endTime,
      selectedFleet: selectedFleet ? selectedFleet : '',
      selectedRobot: selectedRobot ? selectedRobot : '',
      selectedEventType: category,
      daysCheckedArray: daysCheckedArray,
      zoneSequence: zoneSequence,
    },
    operation: operation,
    editFormInfo: editFormInfo,
  };
};

// For Adding Schedule
export const addEventFormOptions = (): EventFormOptions => {
  const timeNow = new Date();
  return {
    data: {
      startTime: new Date(),
      endTime: new Date(timeNow.valueOf() + 30 * 60 * 10 ** 3),
      selectedFleet: '',
      selectedRobot: '',
      selectedEventType: '',
      zoneSequence: new Array<string>(),
      daysCheckedArray: new Array<boolean>(8).fill(false),
    },
    operation: Operation.AddSchedule,
  };
};

/////////////////////////////////// Validate Event Form /////////////////////////////////////////////
export interface EventFormDataErrors {
  startTime?: string;
  endTime?: string;
  selectedFleet?: string;
  selectedRobot?: string;
  selectedEventType?: string;
  daysCheckedArray?: string;
  zoneSequence?: string;
}

export const validateEventFormData = (data: EventFormData): EventFormDataErrors => {
  let errors = {} as EventFormDataErrors;
  if (data.selectedFleet === '') errors.selectedFleet = 'No Fleet Selected';

  if (data.selectedRobot === '') errors.selectedRobot = 'No Selected Robot';

  if (data.selectedEventType === '') errors.selectedEventType = 'No Selected Event Type';

  if (data.selectedEventType === 'Cleaning Task' && data.zoneSequence.length === 0)
    errors.zoneSequence = 'Cleaning Task Requires At Least One Zone';

  if (data.endTime.valueOf() <= data.startTime.valueOf())
    errors.endTime = 'End Time Has To Be After Start Time';

  if (data.startTime.valueOf() < new Date().valueOf() - 5 * 60 * 1000)
    errors.startTime = 'Start Time Has To Be The Current Time Or In The Future.';

  // If series selected
  if (data.daysCheckedArray.filter((isChecked) => isChecked).length != 0) {
    // If series is invalid
    if (!convertToCron({ startTime: data.startTime, daysCheckedArray: data.daysCheckedArray }))
      errors.daysCheckedArray = 'Invalid Cron Series';
  }
  return errors;
};

/////////////////////////////////// Event Form to Request /////////////////////////////////////////////

const convertToCron = (info: { startTime: Date; daysCheckedArray: Array<boolean> }): string => {
  let includeSeconds = true;

  // Include current day
  let daysToRun = new Array<number>();
  if (info.daysCheckedArray[info.daysCheckedArray.length - 1]) {
    for (let i = 0; i < 7; i++) {
      daysToRun.push(i);
    }
  } else {
    for (var i = 0; i < 7; i++) {
      // add all other unique days checked
      if (i === info.startTime.getDay()) {
        daysToRun.push(i);
      } else if (info.daysCheckedArray[i]) {
        daysToRun.push(i);
      }
    }
  }
  var interval = parser.parseExpression('* * * * * *');
  var fields = JSON.parse(JSON.stringify(interval.fields));
  fields.second = [info.startTime.getSeconds()];
  fields.minute = [info.startTime.getMinutes()];
  fields.hour = [info.startTime.getHours()];
  fields.dayOfWeek = daysToRun;
  return parser.fieldsToExpression(fields).stringify(includeSeconds);
};

const convertToSeries = (info: {
  occId: string;
  startTime: Date;
  daysCheckedArray: Array<boolean>;
  timeZone: string;
  idPrefix: string;
}): EventsSeries | null => {
  // If none of the boxes are checked means that this is not a series.
  if (info.daysCheckedArray.slice(0, 7).filter((item: boolean) => !item).length == 7) return null;

  const cronString = convertToCron({
    startTime: info.startTime,
    daysCheckedArray: info.daysCheckedArray,
  });

  return {
    cron: cronString,
    timezone: info.timeZone,
    id_prefix: info.idPrefix,
    occurrences: [info.occId],
  };
};

const isEventFormSeries = (data: EventFormData): boolean => {
  return !(data.daysCheckedArray.slice(0, 7).filter((item: boolean) => item).length === 0);
};

const isEventFormDAG = (data: EventFormData): boolean => {
  if (data.selectedEventType === 'Cleaning Task') return true;
  return false;
};

const getEventDescription = (
  fleet: string,
  robot: string,
  eventCategory: string,
  zone: string | null = null,
): string => {
  let description = `${robot} ${eventCategory} `;

  if (zone) {
    description += `at ${zone}`;
  }
  return description;
};

// Converts information from the event form to
// RMF scheduler definition of event
const eventFormToEvent = (info: {
  startTime: Date;
  endTime: Date;
  selectedFleet: string;
  selectedRobot: string;
  selectedEventType: string;
  zone?: string;
  seriesId?: string;
  dependencyId?: string;
}) => {
  const startTimeUnixSec = info.startTime.valueOf() / 1000;
  const durationUnixSec = info.endTime.valueOf() / 1000 - startTimeUnixSec;
  const details: any = { fleet: info.selectedFleet, robot: info.selectedRobot };
  if (info.zone) {
    details['zone'] = info.zone;
  }
  const description = getEventDescription(
    info.selectedFleet,
    info.selectedRobot,
    info.selectedEventType,
    info.zone,
  );

  const event = {
    event_details: details,
    description: description,
    type: info.selectedEventType,
    start_time: startTimeUnixSec,
    duration: durationUnixSec,
    series_id: info.seriesId,
    dependency_id: info.dependencyId,
  };
  console.log('EVENT FORM TO EVENT');
  console.log(JSON.stringify(event));

  removeNullUndefined(event);
  return event;
};

// Creates and returns DAG and the events within the DAG.
const convertToDAG = (info: {
  selectedFleet: string;
  selectedRobot: string;
  startTime: Date;
  endTime: Date;
  selectedEventType: string;
  zoneSequence: Array<string>;
  dagId: string;
  seriesId?: string;
}) => {
  const events: any = {};
  const dependencies: any = {};
  const dag: any = {};

  let prevEventId;
  const period = (info.endTime.valueOf() - info.startTime.valueOf()) / info.zoneSequence.length;
  for (let i = 0; i < info.zoneSequence.length; i++) {
    let zone = info.zoneSequence[i];
    const startTimeMillis = info.startTime.valueOf() + period * i;
    const endTimeUnixMillis = startTimeMillis + period;
    const startTime = new Date(startTimeMillis);
    const endTime = new Date(endTimeUnixMillis);
    const eventId = 'event-' + uuidv4();

    // Create event
    const event = eventFormToEvent({
      startTime: startTime,
      endTime: endTime,
      selectedFleet: info.selectedFleet,
      selectedRobot: info.selectedRobot,
      selectedEventType: info.selectedEventType,
      zone: zone,
      dependencyId: info.dagId,
      seriesId: info.seriesId,
    });

    // Add event to events
    events[eventId] = event;
    if (i === 0) {
      dag[eventId] = [];
    } else {
      dag[eventId] = [prevEventId];
    }

    prevEventId = eventId;
  }
  dependencies[info.dagId] = dag;

  return {
    events: events,
    dependencies: dependencies,
  };
};

const eventFormDataToAddScheduleRequest = (
  data: EventFormData,
): ScheduleForTaskScheduler | null => {
  let series;
  let dependencies;
  let events;

  const seriesIdPrefix = 'series-';
  const seriesTimeZone = 'Asia/Singapore';

  const isDAG = isEventFormDAG(data);
  const isSeries = isEventFormSeries(data);

  // Just a single event
  if (!isDAG && !isSeries) {
    console.log('Creating Request for Single Event');
    const event_id = 'event-' + uuidv4();
    const event = eventFormToEvent({
      startTime: data.startTime,
      endTime: data.endTime,
      selectedFleet: data.selectedFleet,
      selectedRobot: data.selectedRobot,
      selectedEventType: data.selectedEventType,
    });

    console.log(`EVENT INFO ${JSON.stringify(event)}`);

    events = { [event_id]: event };
  }
  // Series of events
  else if (!isDAG && isSeries) {
    const event_id = 'event-' + uuidv4();
    const seriesId = seriesIdPrefix + uuidv4();
    // The scheduler will populate the series id
    const event = eventFormToEvent({
      startTime: data.startTime,
      endTime: data.endTime,
      selectedFleet: data.selectedFleet,
      selectedRobot: data.selectedRobot,
      selectedEventType: data.selectedEventType,
      seriesId: seriesId,
    });

    // Set events
    events = { [event_id]: event };

    const seriesInfo = convertToSeries({
      occId: event_id,
      startTime: data.startTime,
      daysCheckedArray: data.daysCheckedArray,
      timeZone: seriesTimeZone,
      idPrefix: seriesIdPrefix,
    });

    if (seriesInfo) {
      // Set series
      series = { [seriesId]: seriesInfo };
    }
  }
  // Single DAG
  else if (isDAG && !isSeries) {
    const dagId = 'dag-' + uuidv4();

    const dagAndEvents = convertToDAG({
      selectedFleet: data.selectedFleet,
      selectedRobot: data.selectedRobot,
      startTime: data.startTime,
      endTime: data.endTime,
      selectedEventType: data.selectedEventType,
      zoneSequence: data.zoneSequence,
      dagId: dagId,
    });

    events = dagAndEvents.events;
    dependencies = dagAndEvents.dependencies;
  }
  // Series of DAGs
  else if (isDAG && isSeries) {
    const dagId = 'dag-' + uuidv4();
    const seriesId = seriesIdPrefix + uuidv4();

    const dagAndEvents = convertToDAG({
      selectedFleet: data.selectedFleet,
      selectedRobot: data.selectedRobot,
      startTime: data.startTime,
      endTime: data.endTime,
      selectedEventType: data.selectedEventType,
      zoneSequence: data.zoneSequence,
      dagId: dagId,
      seriesId: seriesId,
    });

    events = dagAndEvents.events;
    // Set dependencies
    dependencies = dagAndEvents.dependencies;

    const seriesInfo = convertToSeries({
      startTime: data.startTime,
      daysCheckedArray: data.daysCheckedArray,
      occId: dagId,
      timeZone: seriesTimeZone,
      idPrefix: seriesIdPrefix,
    });

    if (seriesInfo) {
      // Set series
      series = { [seriesId]: seriesInfo };
    }
  } else {
    // Should be unreachable
    console.error('UNREACHABLE');
    return null;
  }

  // const addRequest = {} as ScheduleForTaskScheduler;

  const addRequest: ScheduleForTaskScheduler = {
    events: events,
    series: series,
    dependencies: dependencies,
  };

  removeNullUndefined(addRequest);
  return addRequest;
};

// Strictly single events and not DAGs
const eventFormToUpdateEventRequest = (info: { data: EventFormData; eventId: string }) => {
  const event = eventFormToEvent({
    startTime: info.data.startTime,
    endTime: info.data.endTime,
    selectedFleet: info.data.selectedFleet,
    selectedRobot: info.data.selectedRobot,
    selectedEventType: info.data.selectedEventType,
  });

  const events = { [info.eventId]: event };

  return {
    events: events,
  };
};

// Update series of events
const eventFormToUpdateEventSeriesRequest = (info: {
  data: EventFormData;
  eventId: string;
  currentSchedule: any;
}): UpdateSeriesRequest | null => {
  if (!(info.eventId in info.currentSchedule.events)) {
    console.error(`Cannot find event ${info.eventId} in the current schedule events`);
    return null;
  }
  const event = info.currentSchedule.events[info.eventId];
  if (!event.series_id) {
    console.error(`Event ${info.eventId} has no series id`);
  }
  if (!(event.series_id in info.currentSchedule.series)) {
    console.error(`Cannot find series ${event.series_id}`);
  }
  const series = info.currentSchedule.series[event.series_id];

  const cronString = convertToCron({
    startTime: info.data.startTime,
    daysCheckedArray: info.data.daysCheckedArray,
  });
  return {
    updates: {
      [event.series_id]: {
        cron: cronString,
        timezone: series.timezone,
        old_occ_time: event.start_time,
        new_occ_time: info.data.startTime.valueOf() / 1000,
      },
    },
  };
};

const eventFormToUpdateDAGSeriesRequest = (info: {
  data: EventFormData;
  eventId: string;
  currentSchedule: any;
}) => {
  if (!(info.eventId in info.currentSchedule.events)) {
    console.error(`Cannot find event ${info.eventId} in the current schedule events`);
    return;
  }
  const event = info.currentSchedule.events[info.eventId];
  if (!event.series_id) {
    console.error(`Event ${info.eventId} has no series id`);
    return;
  }
  if (!(event.dependency_id in info.currentSchedule.dependencies)) {
    console.error(`Event ${info.eventId} has no dependency`);
    return;
  }
  const series = info.currentSchedule.series[event.series_id];

  const cronString = convertToCron({
    startTime: info.data.startTime,
    daysCheckedArray: info.data.daysCheckedArray,
  });

  // We need to get the start time of the DAG in order to get the start time of the DAG
  const dagEvents = getDagEvents({
    dagId: event.dependency_id,
    currentSchedule: info.currentSchedule,
  });
  let oldOccTime = event.start_time;
  if (dagEvents) {
    const firstDAGEvent = info.currentSchedule.events[dagEvents[0]];
    oldOccTime = firstDAGEvent.start_time;
  }

  return {
    updates: {
      [event.series_id]: {
        cron: cronString,
        timezone: series.timezone,
        old_occ_time: oldOccTime,
        new_occ_time: info.data.startTime.valueOf() / 1000,
      },
    },
  };
};

const eventFormToUpdateDAGRequest = (info: {
  data: EventFormData;
  eventId: string;
  currentSchedule: any;
}): [DeleteScheduleRequest, ScheduleForTaskScheduler] | undefined => {
  if (!(info.eventId in info.currentSchedule.events)) {
    console.error(`Cannot find event ${info.eventId} in the current schedule events`);
    return;
  }
  const event = info.currentSchedule.events[info.eventId];
  if (!(event.dependency_id in info.currentSchedule.dependencies)) {
    console.error(`Event ${info.eventId} has no dependency`);
    return;
  }

  const dagEvents = getDagEvents({
    dagId: event.dependency_id,
    currentSchedule: info.currentSchedule,
  });

  if (!dagEvents) {
    return;
  }

  // Delete zones all zones.
  // Add additional zones

  // Check if the current zones in the dag exist in
  // the new zone sequence in the event form
  const deleteScheduleRequest = {
    event_ids: dagEvents,
    dependency_ids: [event.dependency_id],
    series_ids: [],
  };

  const addScheduleRequest = eventFormDataToAddScheduleRequest(info.data);
  if (!addScheduleRequest) return;

  return [deleteScheduleRequest, addScheduleRequest];
};

const alertOnResponse = (
  promise: Promise<AxiosResponse<ErrorCode>>,
  messages: { successMsg: string; errorMsg: string },
  alertFunction: any,
) => {
  promise.then((response) => {
    if (response?.status !== undefined && response?.status < 400 && response?.data.value === 0) {
      alertFunction('success', messages.successMsg);
    } else {
      alertFunction('error', messages.errorMsg + response?.data.detail);
    }
  });
};

export const getSubmitCallback = (request: {
  operation: Operation;
  data: EventFormData;
  eventId?: string;
  currentSchedule?: any;
}) => {
  if (request.operation === Operation.AddSchedule) {
    const addRequest = eventFormDataToAddScheduleRequest(request.data);
    console.log(`ADD SCHEDULE REQEUST IS ${JSON.stringify(addRequest)}`);
    if (addRequest)
      return (rmf: RmfIngress, showAlert: any) => {
        const promise = AddSchedule(rmf, addRequest);
        alertOnResponse(
          promise,
          {
            successMsg: 'Successfully added new schedule',
            errorMsg: 'Error in adding new schedule: ',
          },
          showAlert,
        );
      };
  }
  if (
    request.operation === Operation.EditEvent ||
    request.operation === Operation.EditOccurrenceOfEventSeries
  ) {
    if (!request.eventId)
      return () => {
        console.log('Could not generate update function without event id');
      };
    const updateEventRequest = eventFormToUpdateEventRequest({
      data: request.data,
      eventId: request.eventId,
    });
    return (rmf: RmfIngress, showAlert: any) => {
      const promise = UpdateSchedule(rmf, updateEventRequest);
      alertOnResponse(
        promise,
        {
          successMsg: 'Successfully updated schedule',
          errorMsg: 'Error in updating schedule: ',
        },
        showAlert,
      );
    };
  }
  if (request.operation === Operation.EditEventOfDAG) {
    if (!request.eventId)
      return () => {
        console.log('Could not generate update function without event id');
      };
    if (!request.currentSchedule)
      return () => {
        console.log('Could not generate update function without current schedule');
      };

    const requests = eventFormToUpdateDAGRequest({
      data: request.data,
      eventId: request.eventId,
      currentSchedule: request.currentSchedule,
    });
    if (!requests) return;
    const [deleteRequest, addRequest] = requests;

    console.log(`Delete request ${JSON.stringify(deleteRequest)}`);
    console.log(`Add request ${JSON.stringify(addRequest)}`);

    return (rmf: RmfIngress, showAlert: any) => {
      const deletePromise = DeleteSchedule(rmf, deleteRequest);
      alertOnResponse(
        deletePromise,
        {
          successMsg: 'Successfully deleted schedule',
          errorMsg: 'Error in deleting schedule: ',
        },
        showAlert,
      );
      deletePromise.then((response) => {
        // We add the new DAG only after the previous has been deleted.
        if (
          response?.status !== undefined &&
          response?.status < 400 &&
          response?.data.value === 0
        ) {
          const addPromise = AddSchedule(rmf, addRequest);
          alertOnResponse(
            addPromise,
            {
              successMsg: 'Successfully added new schedule',
              errorMsg: 'Error in adding new schedule: ',
            },
            showAlert,
          );
        }
      });
    };
  }
  if (request.operation === Operation.EditEventSeries) {
    if (!request.eventId)
      return () => {
        console.debug('Could not generate update series function without event id');
      };

    if (!request.currentSchedule)
      return () => {
        console.log('Could not generate update series function without current schedule');
      };

    const updateEventScheduleRequest = eventFormToUpdateEventSeriesRequest({
      data: request.data,
      eventId: request.eventId,
      currentSchedule: request.currentSchedule,
    });

    if (updateEventScheduleRequest)
      return (rmf: RmfIngress, showAlert: any) => {
        const promise = UpdateSeries(rmf, updateEventScheduleRequest);
        alertOnResponse(
          promise,
          {
            successMsg: 'Successfully updated schedule',
            errorMsg: 'Error in updating schedule: ',
          },
          showAlert,
        );
      };

    return () => {
      console.log('Unable to generate function for update series');
    };
  }
  if (request.operation === Operation.EditOccurrenceOfDAGSeries) {
    // This is not allowed
    return () => {
      console.log('Edit Occurrence of DAG Series is not Allowed');
    };
  }
  if (request.operation === Operation.EditDAGSeries) {
    if (!request.eventId) return;
    if (!request.currentSchedule) return;
    const updateDAGSeriesRequest = eventFormToUpdateDAGSeriesRequest({
      data: request.data,
      eventId: request.eventId,
      currentSchedule: request.currentSchedule,
    });

    if (updateDAGSeriesRequest)
      return (rmf: RmfIngress, showAlert: any) => {
        const promise = UpdateSeries(rmf, updateDAGSeriesRequest);
        alertOnResponse(
          promise,
          {
            successMsg: 'Successfully updating DAG',
            errorMsg: 'Error in Updating DAG: ',
          },
          showAlert,
        );
      };
  }
};

export const DeleteCallback = (params: {
  rmf: RmfIngress;
  showAlert: any;
  messages: { successMsg: string; errorMsg: string };
  request: DeleteScheduleRequest;
}) => {
  const promise = DeleteSchedule(params.rmf, params.request);
  alertOnResponse(promise, params.messages, params.showAlert);
};

export const TogglePauseCallback = (params: { rmf: RmfIngress; eventId: string }) => {
  TogglePause(params.rmf, params.eventId);
};

export const CancelTaskCallback = (params: { rmf: RmfIngress; eventId: string }) => {
  CancelTask(params.rmf, params.eventId);
};
