"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskSummary = void 0;
var TaskProfile_1 = require("../../rmf_task_msgs/msg/TaskProfile");
var Time_1 = require("../../builtin_interfaces/msg/Time");
var TaskSummary = /** @class */ (function () {
    function TaskSummary(fields) {
        if (fields === void 0) { fields = {}; }
        this.fleet_name = fields.fleet_name || '';
        this.task_id = fields.task_id || '';
        this.task_profile = fields.task_profile || new TaskProfile_1.TaskProfile();
        this.state = fields.state || 0;
        this.status = fields.status || '';
        this.submission_time = fields.submission_time || new Time_1.Time();
        this.start_time = fields.start_time || new Time_1.Time();
        this.end_time = fields.end_time || new Time_1.Time();
        this.robot_name = fields.robot_name || '';
    }
    TaskSummary.validate = function (obj) {
        if (typeof obj['fleet_name'] !== 'string') {
            throw new Error('expected "fleet_name" to be "string"');
        }
        if (typeof obj['task_id'] !== 'string') {
            throw new Error('expected "task_id" to be "string"');
        }
        try {
            TaskProfile_1.TaskProfile.validate(obj['task_profile']);
        }
        catch (e) {
            throw new Error('in "task_profile":\n  ' + e.message);
        }
        if (typeof obj['state'] !== 'number') {
            throw new Error('expected "state" to be "number"');
        }
        if (typeof obj['status'] !== 'string') {
            throw new Error('expected "status" to be "string"');
        }
        try {
            Time_1.Time.validate(obj['submission_time']);
        }
        catch (e) {
            throw new Error('in "submission_time":\n  ' + e.message);
        }
        try {
            Time_1.Time.validate(obj['start_time']);
        }
        catch (e) {
            throw new Error('in "start_time":\n  ' + e.message);
        }
        try {
            Time_1.Time.validate(obj['end_time']);
        }
        catch (e) {
            throw new Error('in "end_time":\n  ' + e.message);
        }
        if (typeof obj['robot_name'] !== 'string') {
            throw new Error('expected "robot_name" to be "string"');
        }
    };
    TaskSummary.FullTypeName = 'rmf_task_msgs/msg/TaskSummary';
    TaskSummary.STATE_QUEUED = 0;
    TaskSummary.STATE_ACTIVE = 1;
    TaskSummary.STATE_COMPLETED = 2;
    TaskSummary.STATE_FAILED = 3;
    TaskSummary.STATE_CANCELED = 4;
    TaskSummary.STATE_PENDING = 5;
    return TaskSummary;
}());
exports.TaskSummary = TaskSummary;
/*
# Publish by Fleet Adapter (aka DispatchStatus)

# Fleet Adapter name
string fleet_name

# *optional and duplicated in TaskProfile
string task_id

TaskProfile task_profile

uint32 state
uint32 STATE_QUEUED=0
uint32 STATE_ACTIVE=1
uint32 STATE_COMPLETED=2  # hooray
uint32 STATE_FAILED=3     # oh no
uint32 STATE_CANCELED=4
uint32 STATE_PENDING=5

# a brief summary of the current status of the task, for UI's
# *optional
string status

# submission_time is when the task was submitted to rmf_core
# *optional and duplicated in TaskProfile
builtin_interfaces/Time submission_time

# when rmf_core actually began processing the task
builtin_interfaces/Time start_time

# When this message is a summary of an in-process task, the end_time field is
# an estimate. When this message is a summary of a completed or failed task,
# end_time is the actual time.
builtin_interfaces/Time end_time

# Allocated robot name
# *optional
string robot_name

*/
