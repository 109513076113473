import React, { useEffect, useState, useCallback } from 'react';
import { RmfAppContext } from '../rmf-app';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { GaussianS75MaintenanceForm } from './GaussianS75MaintenanceForm';
import { LionsbotLeoVacMaintenanceForm } from './LionsbotLeoVacMaintenanceForm';
import { GaussianV40MaintenanceForm } from './GaussianV40MaintenanceForm';
import { CenobotL50MaintenanceForm } from './CenobotL50MaintenanceForm';
import { GlobotixFlexaMaintenanceForm } from './GlobotixFlexaMaintenanceForm';
import { DefaultMaintenanceForm } from './DefaultMaintenanceForm';

interface MaintenanceData {
  fleet: string;
  robot: string;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  overflow: 'hidden', //prevent scrollbar
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#ffffff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  maxHeight: 'none', //allow content to expand
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const MaintenanceApp: React.FC = () => {
  const rmf = React.useContext(RmfAppContext);

  const [data, setData] = useState<MaintenanceData[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const maintenanceDataRef = React.useRef<MaintenanceData>({ fleet: '', robot: '' });

  const handleSubmit = (user: MaintenanceData) => {
    maintenanceDataRef.current = user;
    setShowDialog(true);
  };

  const handleClose = () => {
    //Reset dialog state after user click
    setShowDialog(false);
  };

  // returns a list of events based on the schedule received
  function getMaintenanceInfo() {
    if (!rmf) {
      console.debug('RMF Context does not exist!');
      return;
    }
    // Request for events through api-client
    const maintenance_info = rmf.schedulerApi.getMaintenanceSchedulerMaintenanceGet();

    maintenance_info
      .then(function (response: any) {
        setData(response.data);
      })
      .catch((error) => console.error('Error fetching maintenance alerts', error));
  }

  // adding use callback for maintenance api callback to memoize the function and not to recreate during rerender
  const getMaintenanceCallback = useCallback(getMaintenanceInfo, [rmf]);

  useEffect(() => {
    const id = setInterval(() => {
      getMaintenanceCallback();
    }, 5000);

    getMaintenanceCallback();

    return () => {
      clearInterval(id);
    };
  }, [getMaintenanceCallback]);

  // Logic handling (submit form) when maintenance is finished
  const handleFinishMaintenance = async () => {
    if (!rmf) {
      console.debug('RMF Context does not exist!');
      return;
    }
    setLoading(true);
    const current_maintenance_info: MaintenanceData = maintenanceDataRef.current;

    const finish_maintenance = rmf.schedulerApi.finishMaintenanceSchedulerMaintenanceFinishPost({
      fleet: current_maintenance_info.fleet,
      robot: current_maintenance_info.robot,
    });

    finish_maintenance
      .then(function (response: any) {
        console.debug('FINISH MAINTENANCE, wait 2s for new info');
        setTimeout(() => {
          getMaintenanceInfo();
          handleClose();
          setLoading(false);
        }, 2000);
      })
      .catch((error) => console.error('Error finishing maintenance', error));
  };

  const renderAccordionContent = (user: MaintenanceData) => {
    switch (true) {
      case user.fleet.startsWith('s75'):
        return <GaussianS75MaintenanceForm onSubmit={() => handleSubmit(user)} />;
      case user.fleet.startsWith('leovac'):
        return <LionsbotLeoVacMaintenanceForm onSubmit={() => handleSubmit(user)} />;
      case user.fleet.startsWith('v40'):
        return <GaussianV40MaintenanceForm onSubmit={() => handleSubmit(user)} />;
      case user.fleet.startsWith('flexa'):
        return <GlobotixFlexaMaintenanceForm onSubmit={() => handleSubmit(user)} />;
      case user.fleet.startsWith('l50'):
        return <CenobotL50MaintenanceForm onSubmit={() => handleSubmit(user)} />;
      default:
        return <DefaultMaintenanceForm onSubmit={() => handleSubmit(user)} />;
    }
  };

  return (
    <div>
      <Grid container style={{ padding: 20 }} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card style={{ borderRadius: 16 }}>
            <CardHeader
              title="Maintenance"
              titleTypographyProps={{ fontWeight: 500, fontSize: '1.25 rem' }}
              style={{ backgroundColor: '#1976D2', color: '#FFFFFF' }}
            ></CardHeader>
            <CardContent>
              {data.length !== 0 ? (
                data.map((user) => (
                  <Accordion key={user.fleet}>
                    <AccordionSummary
                      style={{ fontWeight: 1000 }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`fleet-details-${user.fleet}`}
                    >
                      {user.robot}
                    </AccordionSummary>
                    <AccordionDetails>{renderAccordionContent(user)}</AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Grid container justifyContent="center">
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Typography variant="h6" gutterBottom>
                      No maintenance schedule found.
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Dialog open={showDialog} onClose={handleClose}>
                <DialogTitle style={{ backgroundColor: '#1976D2', color: '#FFFFFF' }}>
                  Submission Successful!
                </DialogTitle>
                <DialogContent style={{ paddingTop: 20, paddingBottom: 10 }}>
                  The maintenance for the robot is completed. Please return the robot back to RMF.
                </DialogContent>
                <DialogActions style={{ paddingBottom: 20 }}>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <LoadingButton
                        onClick={async () => {
                          await handleFinishMaintenance();
                        }}
                        endIcon={<SendIcon />}
                        loading={loading}
                        sx={{ width: 230 }}
                        loadingPosition="end"
                        variant="contained"
                      >
                        Return Robot to RMF
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Dialog>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default MaintenanceApp;
