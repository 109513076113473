"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelTask_Request = void 0;
var CancelTask_Request = /** @class */ (function () {
    function CancelTask_Request(fields) {
        if (fields === void 0) { fields = {}; }
        this.requester = fields.requester || '';
        this.task_id = fields.task_id || '';
    }
    CancelTask_Request.validate = function (obj) {
        if (typeof obj['requester'] !== 'string') {
            throw new Error('expected "requester" to be "string"');
        }
        if (typeof obj['task_id'] !== 'string') {
            throw new Error('expected "task_id" to be "string"');
        }
    };
    CancelTask_Request.FullTypeName = 'rmf_task_msgs/srv/CancelTask_Request';
    return CancelTask_Request;
}());
exports.CancelTask_Request = CancelTask_Request;
/*
# Cancel Task | "Delete" service call

# Identifier for who is requesting the service
string requester

# generated task ID by dispatcher node
string task_id


*/
