"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBuildingMap_Response = void 0;
var BuildingMap_1 = require("../../rmf_building_map_msgs/msg/BuildingMap");
var GetBuildingMap_Response = /** @class */ (function () {
    function GetBuildingMap_Response(fields) {
        if (fields === void 0) { fields = {}; }
        this.building_map = fields.building_map || new BuildingMap_1.BuildingMap();
    }
    GetBuildingMap_Response.validate = function (obj) {
        try {
            BuildingMap_1.BuildingMap.validate(obj['building_map']);
        }
        catch (e) {
            throw new Error('in "building_map":\n  ' + e.message);
        }
    };
    GetBuildingMap_Response.FullTypeName = 'rmf_building_map_msgs/srv/GetBuildingMap_Response';
    return GetBuildingMap_Response;
}());
exports.GetBuildingMap_Response = GetBuildingMap_Response;
/*

BuildingMap building_map

*/
