import React from 'react';
import { IconButton, Tooltip, Button } from '@mui/material';
import { RmfAppContext } from '../rmf-app';

export default function ExportDataJSON() {
  const rmf = React.useContext(RmfAppContext);

  const handleExportClick = async () => {
    try {
      if (!rmf) {
        console.error('RmfAppContext not provided.');
        return;
      }

      // Simulate fetching data (replace this with your actual API call)
      const response = await rmf.schedulerApi.getScheduleSchedulerScheduleGet(0, 2147483647);
      const responseData = response.data;

      if (!responseData || !responseData.schedule) {
        console.error('No data to export.');
        return;
      }
      const scheduleData = responseData.schedule;

      const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(scheduleData),
      )}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = 'Events.json';
      link.click();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <Tooltip title="Download as json" color="inherit" placement="top">
      <IconButton onClick={handleExportClick}>
        <Button variant="contained">JSON</Button>
      </IconButton>
    </Tooltip>
  );
}
