"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviveTask_Response = void 0;
var ReviveTask_Response = /** @class */ (function () {
    function ReviveTask_Response(fields) {
        if (fields === void 0) { fields = {}; }
        this.success = fields.success || false;
    }
    ReviveTask_Response.validate = function (obj) {
        if (typeof obj['success'] !== 'boolean') {
            throw new Error('expected "success" to be "boolean"');
        }
    };
    ReviveTask_Response.FullTypeName = 'rmf_task_msgs/srv/ReviveTask_Response';
    return ReviveTask_Response;
}());
exports.ReviveTask_Response = ReviveTask_Response;
/*


# Confirmation that this service call is processed
bool success


*/
