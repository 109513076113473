var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ButtonBase, styled } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
var classes = {
    logoBtn: 'logo-button-root',
    logoImg: 'logo-button-image',
};
var StyledButtonBase = styled(function (props) { return React.createElement(ButtonBase, __assign({}, props)); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&." + classes.logoBtn] = {
            padding: theme.spacing(1) + " " + theme.spacing(2),
            boxSizing: 'border-box',
        },
        _b["& ." + classes.logoImg] = {
            width: '100%',
            height: '100%',
        },
        _b);
});
export var LogoButton = function (_a) {
    var src = _a.src, alt = _a.alt, className = _a.className, otherProps = __rest(_a, ["src", "alt", "className"]);
    return (React.createElement(StyledButtonBase, __assign({ className: clsx(classes.logoBtn, className), disableRipple: true }, otherProps),
        React.createElement("img", { src: src, alt: alt, className: classes.logoImg })));
};
