"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Session = void 0;
var Time_1 = require("../../builtin_interfaces/msg/Time");
var Session = /** @class */ (function () {
    function Session(fields) {
        if (fields === void 0) { fields = {}; }
        this.request_time = fields.request_time || new Time_1.Time();
        this.requester_id = fields.requester_id || '';
    }
    Session.validate = function (obj) {
        try {
            Time_1.Time.validate(obj['request_time']);
        }
        catch (e) {
            throw new Error('in "request_time":\n  ' + e.message);
        }
        if (typeof obj['requester_id'] !== 'string') {
            throw new Error('expected "requester_id" to be "string"');
        }
    };
    Session.FullTypeName = 'rmf_door_msgs/msg/Session';
    return Session;
}());
exports.Session = Session;
/*

builtin_interfaces/Time request_time
string requester_id

*/
