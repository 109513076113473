/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck
import {
  Grid,
  MenuItem,
  TextField,
  Select,
  InputLabel,
  SelectChangeEvent,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from '@mui/material';
import React from 'react';
import ZoneSequenceSelector from './ZoneSequenceSelector';
import {
  getNewDateWithCurrentTime,
  hourStringFromDate,
  minuteStringFromDate,
  isStartBeforeEnd,
  isStartDateBeforeEndDate,
  EventFormDataErrors,
} from './utils/event_form_utils';
import AddIcon from '@mui/icons-material/Add';

const eventTypes = ['Daily Maintenance', 'Vendor Maintenance', 'Cleaning Task'];
const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'All Day',
];

interface EventFormSelectionProps {
  robotsInfo: any;
  startTime: Date;
  setStartTime: React.Dispatch<React.SetStateAction<Date>>;
  endTime: Date;
  setEndTime: React.Dispatch<React.SetStateAction<Date>>;
  openEndDateSelection: boolean;
  setOpenEndDateSelection: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFleet: string;
  setSelectedFleet: React.Dispatch<React.SetStateAction<string>>;
  selectedRobot: string;
  setSelectedRobot: React.Dispatch<React.SetStateAction<string>>;
  selectedEventType: string;
  setSelectedEventType: React.Dispatch<React.SetStateAction<string>>;
  zoneSequence: Array<string>;
  setZoneSequence: React.Dispatch<React.SetStateAction<Array<string>>>;
  daysCheckedArray: Array<boolean>;
  setDaysCheckedArray: React.Dispatch<React.SetStateAction<Array<boolean>>>;
  editFormInfo: any;
  eventFormErrors: EventFormDataErrors;
}

export function EventFormSelection(props: EventFormSelectionProps) {
  const {
    robotsInfo,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    openEndDateSelection,
    setOpenEndDateSelection,
    selectedFleet,
    setSelectedFleet,
    selectedRobot,
    setSelectedRobot,
    selectedEventType,
    setSelectedEventType,
    zoneSequence,
    setZoneSequence,
    daysCheckedArray,
    setDaysCheckedArray,
    editFormInfo,
    eventFormErrors,
  } = props;
  const [openZoneSequenceSelector, setOpenZoneSequenceSelector] = useState(false);
  console.log('EVENTFORMSELECTION');
  console.log(`DAYS CHECKED ARRAY ${daysCheckedArray}`);

  // get and array of strings to be displayed as menu item for start and end hours
  const getHoursStringArray = () => {
    let arr = new Array<string>();
    for (let i = 0; i < 24; i++) {
      arr.push(i < 10 ? `0${i}` : i.toString());
    }
    return arr;
  };

  // get and array of strings to be displayed as menute items for start and end minutes
  const getMinutesStringArray = () => {
    let arr = new Array<string>();
    for (let i = 0; i < 60; i++) {
      arr.push(i < 10 ? `0${i}` : i.toString());
    }
    return arr;
  };

  const menuItemsFromArray = (arr: Array<any>) => {
    return arr.map((item) => (
      <MenuItem value={item} key={item}>
        {item}
      </MenuItem>
    ));
  };

  // Return the list of robots based on selected fleet
  const getRobotOptions = () => {
    let fleet_info = robotsInfo.find((el: any) => el.ui_fleet_name === selectedFleet);
    if (!fleet_info) {
      return;
    }
    return menuItemsFromArray(fleet_info.ui_robot_names);
  };

  const changeStartDate = (newStartDate: Date | null) => {
    if (!newStartDate) return;

    let newStartTime = getNewDateWithCurrentTime(startTime, newStartDate);
    // since we changed the start date hear we adjust the end time after
    if (isStartBeforeEnd(newStartDate, endTime, 30)) {
      let newEndTime = new Date(newStartTime.valueOf() + 30 * 60 * 10 ** 3);
      setEndTime(newEndTime);
    }
    setStartTime(newStartTime);
  };

  const handleStartHourChange = (newHourString: string) => {
    let newStartHour = Number(newHourString);
    let newStartTime = new Date(startTime);
    newStartTime.setHours(newStartHour);
    if (isStartBeforeEnd(newStartTime, endTime, 30)) {
      let newEndTime = new Date(newStartTime.valueOf() + 30 * 60 * 10 ** 3);
      setEndTime(newEndTime);
    }
    setStartTime(newStartTime);
  };

  const handleStartMinuteChange = (newMinuteString: string) => {
    let newStartMinute = Number(newMinuteString);
    console.log(newStartMinute);
    let newStartTime = new Date(startTime);
    newStartTime.setMinutes(newStartMinute);
    if (isStartBeforeEnd(newStartTime, endTime, 30)) {
      let newEndTime = new Date(newStartTime.valueOf() + 30 * 60 * 10 ** 3);
      setEndTime(newEndTime);
    }
    setStartTime(newStartTime);
  };

  const handleChangeEndMinute = (newMinuteString: string) => {
    let newEndMinute = Number(newMinuteString);
    let newEndTime = new Date(endTime);
    newEndTime.setMinutes(newEndMinute);

    if (isStartBeforeEnd(startTime, newEndTime)) {
      let newStartTime = new Date(newEndTime.valueOf() - 30 * 60 * 10 ** 3);
      setStartTime(newStartTime);
    }

    setEndTime(newEndTime);
  };

  const handleChangeEndHour = (newHourString: string) => {
    let newEndHour = Number(newHourString);
    let newEndTime = new Date(endTime);
    newEndTime.setHours(newEndHour);

    if (isStartBeforeEnd(startTime, newEndTime)) {
      let newStartTime = new Date(newEndTime.valueOf() - 30 * 60 * 10 ** 3);
      setStartTime(newStartTime);
    }

    setEndTime(newEndTime);
  };

  const handleChangeEndDate = (newEndDate: Date | null) => {
    if (!newEndDate) return;

    let newEndTime = getNewDateWithCurrentTime(endTime, newEndDate);
    if (isStartBeforeEnd(startTime, newEndDate)) {
      let newStartTime = new Date(newEndTime.valueOf() - 30 * 60 * 10 ** 3);
      setStartTime(newStartTime);
    }
    setEndTime(newEndTime);
  };

  const handleDayCheckedChange = (isChecked: boolean, index: number) => {
    if (index == daysCheckedArray.length - 1 && isChecked) {
      // all day selected
      setDaysCheckedArray(new Array(dayNames.length).fill(true));
      return;
    }
    let newDaysCheckedArray = daysCheckedArray.slice();
    newDaysCheckedArray[index] = isChecked;
    if (
      newDaysCheckedArray.slice(0, 7).filter((item: boolean) => {
        return item;
      }).length < 7
    ) {
      newDaysCheckedArray[newDaysCheckedArray.length - 1] = false;
    }
    setDaysCheckedArray(newDaysCheckedArray);
  };

  // return react components for series selection
  const renderSeriesSelection = () => {
    if (editFormInfo && editFormInfo.hideReccurrenceSelections) return;
    return (
      <Grid item xs={12}>
        <FormControl>
          <FormLabel>Event Recurrence Pattern</FormLabel>
          <FormGroup>
            <Grid container>
              {daysCheckedArray.map((isChecked: boolean, index: number) => {
                return (
                  <FormControlLabel
                    key={dayNames[index]}
                    control={
                      <Checkbox
                        key={dayNames[index]}
                        size="small"
                        checked={isChecked}
                        onChange={(event) => handleDayCheckedChange(event.target.checked, index)}
                      />
                    }
                    label={dayNames[index]}
                  />
                );
              })}
            </Grid>
          </FormGroup>
        </FormControl>
      </Grid>
    );
  };

  const openEndDateSelectionCheckBox = () => {
    return (
      <FormControlLabel
        key={'openEndDateSelection'}
        label={'Show End Date'}
        control={
          <Checkbox
            key={'openEndDateSelection'}
            size="small"
            checked={openEndDateSelection}
            onChange={(event: any) => setOpenEndDateSelection(event.target.checked)}
          />
        }
      ></FormControlLabel>
    );
  };

  // return react components for zone selection when event type is 'Cleaning Task'
  const renderZoneSelectionPopUp = () => {
    if (editFormInfo && editFormInfo.hideZoneSequenceSelections) return;

    if (selectedEventType === 'Cleaning Task' && openZoneSequenceSelector) {
      let zones = [];
      let fleet_info = robotsInfo.find((fleet: any) => fleet.ui_fleet_name === selectedFleet);
      if (fleet_info) {
        zones = fleet_info.zones.map((zone: any) => zone.zone_name);
        console.log(zones);
      }
      return (
        <Dialog open={openZoneSequenceSelector} onClose={() => setOpenZoneSequenceSelector(false)}>
          <DialogTitle style={{ color: '#FFFFFF', backgroundColor: '#1976D2' }}>
            Create Zone
          </DialogTitle>
          <DialogContent>
            <ZoneSequenceSelector
              zones={zones}
              zoneSequence={zoneSequence}
              setZoneSequence={setZoneSequence}
              setOpenZoneSequenceSelector={setOpenZoneSequenceSelector}
            ></ZoneSequenceSelector>
          </DialogContent>
        </Dialog>
      );
    }
    return;
  };

  const renderZoneSelection = () => {
    if (editFormInfo && editFormInfo.hideZoneSequenceSelections) return;

    if (selectedEventType !== 'Cleaning Task') return;

    return (
      <>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setOpenZoneSequenceSelector(true)}
          >
            Zones
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ border: '1px solid grey', margin: 'flex' }}>
            <Stack direction="row">
              {zoneSequence.map((zone) => {
                return (
                  <Box
                    sx={{
                      border: '2px solid black',
                      margin: '8px',
                      borderRadius: '8px',
                      padding: '8px',
                      backgroundColor: 'lightblue',
                      fontSize: '10px',
                    }}
                  >
                    {zone}
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Grid>
      </>
    );
  };

  const renderEndDateSelection = () => {
    if (isStartDateBeforeEndDate(startTime, endTime) || openEndDateSelection) {
      return (
        <Grid item xs={4}>
          <DatePicker
            inputFormat={'dd/MM/yyyy'}
            // minDate={new Date()}
            value={endTime}
            onChange={(newDate) => {
              handleChangeEndDate(newDate);
            }}
            label="End Date"
            renderInput={(props) => <TextField {...props} />}
          />
        </Grid>
      );
    }
    // else its the same day so we return;
    return;
  };

  return (
    <Grid container direction="row" wrap="wrap">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="event-type">Event Category</InputLabel>
            <Select
              labelId="select-event-type"
              id="select-event-type"
              value={selectedEventType}
              label="Event Category"
              onChange={(event: SelectChangeEvent) => {
                setSelectedEventType(event.target.value);
              }}
              inputProps={{ readOnly: editFormInfo ? true : false }}
            >
              {menuItemsFromArray(eventTypes)}
            </Select>
            {eventFormErrors.selectedEventType && (
              <p style={{ color: 'red' }}>{eventFormErrors.selectedEventType}</p>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Event Remarks"
            variant="outlined"
            value={''} // Bind the value to the eventRemarks state
            onChange={(e) => {
              console.log('');
            }} // Update eventRemarks when the input changes
          />
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="select-start-time-hh">Start HH</InputLabel>
            <Select
              labelId="select-start-time-hh"
              id="select-start-time-hh"
              value={hourStringFromDate(startTime)}
              label="Start HH"
              onChange={(event: SelectChangeEvent) => handleStartHourChange(event.target.value)}
            >
              {menuItemsFromArray(getHoursStringArray())}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="select-start-time-mm">Start MM</InputLabel>
            <Select
              labelId="select-start-time-mm"
              id="select-start-time-mm"
              value={minuteStringFromDate(startTime)}
              label="Start MM"
              onChange={(event: SelectChangeEvent) => handleStartMinuteChange(event.target.value)}
            >
              {menuItemsFromArray(getMinutesStringArray())}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <DatePicker<Date>
            inputFormat={'dd/MM/yyyy'}
            // minDate={new Date()}
            value={startTime}
            onChange={(value: Date | null) => {
              changeStartDate(value);
            }}
            label="Start Date"
            renderInput={(props) => <TextField {...props} />}
          />
          {eventFormErrors.startTime && <p style={{ color: 'red' }}>{eventFormErrors.startTime}</p>}
        </Grid>

        <Grid item xs={4}></Grid>

        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="select-end-time-hh">End HH</InputLabel>
            <Select
              labelId="select-end-time-hh"
              id="select-end-time-hh"
              value={hourStringFromDate(endTime)}
              label="End HH"
              onChange={(event: SelectChangeEvent) => handleChangeEndHour(event.target.value)}
            >
              {menuItemsFromArray(getHoursStringArray())}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="select-end-time-mm">End MM</InputLabel>
            <Select
              labelId="select-end-time-mm"
              id="select-end-time-mm"
              value={minuteStringFromDate(endTime)}
              label="End MM"
              onChange={(event: SelectChangeEvent) => handleChangeEndMinute(event.target.value)}
            >
              {menuItemsFromArray(getMinutesStringArray())}
            </Select>
          </FormControl>
        </Grid>
        {renderEndDateSelection()}
        <Grid item xs={4}>
          {openEndDateSelectionCheckBox()}
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="fleet-type">Fleet</InputLabel>
            <Select
              labelId="select-fleet-type"
              id="select-fleet-type"
              value={selectedFleet}
              label="Fleet Name"
              onChange={(event: SelectChangeEvent) => {
                // We clear the zone sequnce when fleet changes
                // as zones may differ accross fleets.
                setZoneSequence([]);
                setSelectedFleet(event.target.value);
              }}
            >
              {robotsInfo.map((fleet: any) => {
                return (
                  <MenuItem value={fleet.ui_fleet_name} key={fleet.ui_fleet_name}>
                    {fleet.ui_fleet_name}
                  </MenuItem>
                );
              })}
            </Select>
            {eventFormErrors.selectedFleet && (
              <p style={{ color: 'red' }}>{eventFormErrors.selectedFleet}</p>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="robot-name">Robot</InputLabel>
            <Select
              labelId="select-robot-name"
              id="select-robot-name"
              value={selectedRobot}
              label="Robot Name"
              onChange={(event: SelectChangeEvent) => {
                setSelectedRobot(event.target.value);
              }}
            >
              {getRobotOptions()}
            </Select>
            {eventFormErrors.selectedRobot && (
              <p style={{ color: 'red' }}>{eventFormErrors.selectedRobot}</p>
            )}
          </FormControl>
        </Grid>

        {renderSeriesSelection()}
        {renderZoneSelection()}
        {renderZoneSelectionPopUp()}
      </Grid>
    </Grid>
  );
}
