var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CircularProgress, styled } from '@mui/material';
import React from 'react';
var classes = {
    root: 'loading-root',
    container: 'loading-container',
    loadingProgress: 'loading-progress',
    loadingOverlay: 'loading-overlay',
};
var StyledDiv = styled('div')(function () {
    var _a;
    return (_a = {},
        _a["&." + classes.root] = {
            position: 'relative',
            height: '100%',
            flex: '1 1 auto',
        },
        _a["& ." + classes.container] = {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
        },
        _a["& ." + classes.loadingProgress] = {
            position: 'absolute',
            flex: '0 0 auto',
        },
        _a["& ." + classes.loadingOverlay] = {
            filter: 'blur(2px)',
            opacity: 0.6,
            pointerEvents: 'none',
            userSelect: 'none',
        },
        _a);
});
export function Loading(_a) {
    var children = _a.children, _b = _a.loading, loading = _b === void 0 ? false : _b, _c = _a.hideChildren, hideChildren = _c === void 0 ? false : _c, _d = _a.loadingClassName, loadingClassName = _d === void 0 ? '' : _d, _e = _a.className, className = _e === void 0 ? '' : _e, _f = _a.style, style = _f === void 0 ? {} : _f, otherProps = __rest(_a, ["children", "loading", "hideChildren", "loadingClassName", "className", "style"]);
    return loading ? (React.createElement(StyledDiv, { className: classes.root },
        React.createElement("div", { className: classes.loadingOverlay + " " + loadingClassName, style: { visibility: hideChildren ? 'hidden' : 'visible' } }, children),
        React.createElement("div", { className: classes.container },
            React.createElement(CircularProgress, __assign({ "aria-label": "loading", className: classes.loadingProgress + " " + className, style: __assign({ visibility: loading ? 'visible' : 'hidden' }, style) }, otherProps))))) : (React.createElement(React.Fragment, null, children));
}
