import React, { useEffect, useState, useCallback } from 'react';
import { RmfAppContext } from '../rmf-app';
import { Grid } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { adminPageClasses, AdminPageContainer } from './page-css';
import { TelegramOTPCard } from './telegram-otp-card';

interface TelegramOTP {
  id: number;
  code: string;
  counter: string;
  unix_millis_request_time: number;
}

export const TelegramOTPPage: React.FC = () => {
  const rmf = React.useContext(RmfAppContext);
  const [data, setData] = useState<TelegramOTP[]>([]);

  const fabStyle = {
    position: 'absolute',
    bottom: 24,
    right: 24,
    background: '#1976D2',
    color: '#FFFFFF',
  };

  const getTelegramOTP = async () => {
    if (!rmf) {
      console.debug('RMF Context does not exist!');
      return;
    }

    const get_telegram_otp = rmf.notificationApi.getOtpNotificationOtpGet();

    get_telegram_otp
      .then(function (response: any) {
        setData(response.data);
      })
      .catch((error) => console.error('Error fetching telegram otp', error));
  };

  const postTelegramOTP = async () => {
    if (!rmf) {
      console.debug('RMF Context does not exist!');
      return;
    }

    const post_telegram_otp = rmf.notificationApi.addOtpNotificationOtpPost();

    post_telegram_otp
      .then(function (response: any) {
        getTelegramOTP();
        console.log('new otp added');
      })
      .catch((error) => console.error('Error adding telegram otp', error));
  };

  const getMaintenanceOTPCallback = useCallback(getTelegramOTP, [rmf]);
  const postMaintenanceOTPCallback = useCallback(postTelegramOTP, [rmf]);

  useEffect(() => {
    const id = setInterval(() => {
      getMaintenanceOTPCallback();
    }, 5000);

    getMaintenanceOTPCallback();

    return () => {
      clearInterval(id);
    };
  }, [getMaintenanceOTPCallback]);

  return (
    <AdminPageContainer className={adminPageClasses.pageRoot}>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={item.id}>
            <TelegramOTPCard
              otp={Number(item.code)}
              timeLeftToExpire={
                180 - (Math.round(new Date().getTime() / 1000) - item.unix_millis_request_time)
              }
            />
          </Grid>
        ))}
      </Grid>
      <Fab sx={fabStyle} onClick={postMaintenanceOTPCallback}>
        <AddIcon />
      </Fab>
    </AdminPageContainer>
  );
};

export default TelegramOTPPage;
