"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiftClearance_Response = void 0;
var LiftClearance_Response = /** @class */ (function () {
    function LiftClearance_Response(fields) {
        if (fields === void 0) { fields = {}; }
        this.decision = fields.decision || 0;
    }
    LiftClearance_Response.validate = function (obj) {
        if (typeof obj['decision'] !== 'number') {
            throw new Error('expected "decision" to be "number"');
        }
    };
    LiftClearance_Response.FullTypeName = 'rmf_fleet_msgs/srv/LiftClearance_Response';
    LiftClearance_Response.DECISION_CLEAR = 1;
    LiftClearance_Response.DECISION_CROWDED = 2;
    return LiftClearance_Response;
}());
exports.LiftClearance_Response = LiftClearance_Response;
/*


uint32 decision
uint32 DECISION_CLEAR = 1
uint32 DECISION_CROWDED = 2

*/
