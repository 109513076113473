import React, { useState, ChangeEvent } from 'react';
import { ScheduleForTaskScheduler } from 'api-client';
import { RmfAppContext } from '../rmf-app';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

interface UploadProps {
  children?: React.ReactNode;
}

export function UploadFile({ children }: UploadProps) {
  const [files, setFiles] = useState<ScheduleForTaskScheduler | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const [dialogMessage, setDialogMessage] = useState<string | null>(null);

  const rmf = React.useContext(RmfAppContext);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    const file = e.target.files?.[0];
    if (file) {
      fileReader.readAsText(file, 'UTF-8');
      fileReader.onload = (e) => {
        if (e.target?.result) {
          const fileContent = e.target.result as string;
          try {
            const parsedJSON = JSON.parse(fileContent);
            console.log('parsedJSON', parsedJSON);
            setFiles(parsedJSON);
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        }
      };
    }
  };

  const handleSubmit = async () => {
    if (!files) {
      console.error('No valid data to submit.');
      return;
    }

    setIsSubmitting(true);
    try {
      console.log('Submitting schedule...');
      const response = await rmf?.schedulerApi.addScheduleSchedulerScheduleAddPost(files);

      // Check the API response for success
      if (response && response.data && response.data.value === 0 && response.data.detail === '') {
        console.log('Schedule submitted successfully');
        setDialogMessage('Schedule submitted successfully');
      } else {
        console.error('Error submitting schedule. Unexpected API response:', response);

        // Handle specific error cases based on the response value or detail
        if (response && response.data) {
          const responseData = response.data;
          if (responseData.value === 65793) {
            setDialogMessage('Event with the same ID already exists. Please use a different ID.');
          } else {
            setDialogMessage('An error occurred while submitting the schedule. Please try again.');
          }
        } else {
          setDialogMessage('An error occurred while submitting the schedule. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error submitting schedule:', error);
      setDialogMessage('An error occurred while submitting the schedule. Please try again.');
    } finally {
      setIsSubmitting(false);
      handleOpenDialog();
    }
  };

  const handleClear = () => {
    setFiles(null);
  };

  return (
    <>
      <input type="file" onChange={handleChange} />
      <br />
      {files && (
        <>
          <pre>{JSON.stringify(files, null, 2)}</pre>
          <Button onClick={handleClear}>Clear</Button>
        </>
      )}

      <Button onClick={handleSubmit} disabled={isSubmitting || !files}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </Button>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Form Submission</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
