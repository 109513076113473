var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, styled, } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { Loading } from './loading';
var dialogClasses = {
    title: 'confirmation-dialogue-info-value',
    actionBtn: 'confirmation-dialogue-action-button',
};
var StyledDialog = styled(function (props) { return React.createElement(Dialog, __assign({}, props)); })(function () {
    var _a;
    return (_a = {},
        _a["& ." + dialogClasses.title] = {
            flex: '1 1 auto',
        },
        _a["& ." + dialogClasses.actionBtn] = {
            minWidth: 80,
        },
        _a);
});
export function ConfirmationDialog(_a) {
    var _b = _a.title, title = _b === void 0 ? 'Confirm' : _b, _c = _a.titleTextColor, titleTextColor = _c === void 0 ? '#000000' : _c, _d = _a.titleBackgroundColor, titleBackgroundColor = _d === void 0 ? '#FFFFFF' : _d, _e = _a.confirmText, confirmText = _e === void 0 ? 'OK' : _e, _f = _a.cancelText, cancelText = _f === void 0 ? 'Cancel' : _f, _g = _a.submitting, submitting = _g === void 0 ? false : _g, classes = _a.classes, onSubmit = _a.onSubmit, toolbar = _a.toolbar, onClose = _a.onClose, children = _a.children, validateForm = _a.validateForm, otherProps = __rest(_a, ["title", "titleTextColor", "titleBackgroundColor", "confirmText", "cancelText", "submitting", "classes", "onSubmit", "toolbar", "onClose", "children", "validateForm"]);
    return (React.createElement(StyledDialog, __assign({ onClose: onClose }, otherProps),
        React.createElement("form", { onSubmit: function (ev) {
                ev.preventDefault();
                if (validateForm && !validateForm()) {
                    // Form is not valid, do not submit
                    return;
                }
                onSubmit && onSubmit(ev);
            }, "aria-label": title },
            React.createElement(DialogTitle, { style: { backgroundColor: titleBackgroundColor, color: titleTextColor } },
                React.createElement(Grid, { container: true, wrap: "nowrap" },
                    React.createElement(Grid, { item: true, className: dialogClasses.title }, title),
                    React.createElement(Grid, { item: true }, toolbar))),
            React.createElement(DialogContent, null, children),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: "outlined", onClick: function (ev) { return onClose && onClose(ev, 'escapeKeyDown'); }, disabled: submitting, className: clsx(dialogClasses.actionBtn, classes === null || classes === void 0 ? void 0 : classes.button) }, cancelText),
                React.createElement(Button, { variant: "contained", type: "submit", color: "primary", disabled: submitting, className: clsx(dialogClasses.actionBtn, classes === null || classes === void 0 ? void 0 : classes.button) },
                    React.createElement(Loading, { hideChildren: true, loading: submitting, size: "1.5em", color: "inherit" }, confirmText))))));
}
