"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskType = void 0;
var TaskType = /** @class */ (function () {
    function TaskType(fields) {
        if (fields === void 0) { fields = {}; }
        this.type = fields.type || 0;
    }
    TaskType.validate = function (obj) {
        if (typeof obj['type'] !== 'number') {
            throw new Error('expected "type" to be "number"');
        }
    };
    TaskType.FullTypeName = 'rmf_task_msgs/msg/TaskType';
    TaskType.TYPE_STATION = 0;
    TaskType.TYPE_LOOP = 1;
    TaskType.TYPE_DELIVERY = 2;
    TaskType.TYPE_CHARGE_BATTERY = 3;
    TaskType.TYPE_CLEAN = 4;
    TaskType.TYPE_PATROL = 5;
    return TaskType;
}());
exports.TaskType = TaskType;
/*
uint32 type
uint32 TYPE_STATION=0
uint32 TYPE_LOOP=1
uint32 TYPE_DELIVERY=2
uint32 TYPE_CHARGE_BATTERY=3
uint32 TYPE_CLEAN=4
uint32 TYPE_PATROL=5


*/
