import React from 'react';
import { IconButton, Tooltip, Button } from '@mui/material';
import { RmfAppContext } from '../rmf-app';

type JSONValue = Record<string, any>;

interface EventData {
  description: string;
  type: string;
  start_time: string;
  duration?: number;
  series_id?: string | null;
  event_details: JSONValue;
}

export default function ExportDataCSV() {
  const rmf = React.useContext(RmfAppContext);

  const handleExportClick = async () => {
    try {
      if (!rmf) {
        console.error('RmfAppContext not provided.');
        return;
      }

      const response = await rmf.schedulerApi.getScheduleSchedulerScheduleGet(0, 2147483647);
      const responseData = response.data;

      if (!responseData || !responseData.schedule || !responseData.schedule.events) {
        console.error('No data to export.');
        return;
      }

      const eventsData = responseData.schedule.events;

      const extractedData: EventData[] = Object.values(eventsData).map((event) => {
        // Convert Unix timestamps to readable date and 24-hour clock time
        const startTime = new Date(event.start_time * 1000);
        const endTime = event.duration
          ? new Date((event.start_time + event.duration) * 1000)
          : new Date((event.start_time + 1) * 1000);

        return {
          description: event.description,
          type: event.type,
          series_id: event.series_id || null,
          event_details: event.event_details,
          start_time:
            startTime.toLocaleDateString('en-US') +
            ' ' +
            startTime.toLocaleTimeString('en-US', { hour12: false }),
          end_time:
            endTime.toLocaleDateString('en-US') +
            ' ' +
            endTime.toLocaleTimeString('en-US', { hour12: false }),
          duration: event.duration || undefined,
        };
      });

      const csvData = extractedData.map((item) => {
        const eventDetailsString = JSON.stringify(item.event_details).replace(/\n/g, ' ');
        return `"${item.description}","${item.type}",${item.start_time},${item.duration || ''},"${
          item.series_id || ''
        }","${eventDetailsString}"`;
      });

      const csvHeader = 'Description,Type,Start Time,Duration,Series ID,Event Details';

      const csvContent = `${csvHeader}\n${csvData.join('\n')}`;
      const csvBlob = new Blob([csvContent], { type: 'text/csv' });
      const csvUrl = window.URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = 'Events.csv';
      link.click();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <Tooltip title="Download as csv" color="inherit" placement="top">
      <IconButton onClick={handleExportClick}>
        <Button variant="contained">CSV</Button>
      </IconButton>
    </Tooltip>
  );
}
