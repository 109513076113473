"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RobotState = void 0;
var RobotMode_1 = require("../../rmf_fleet_msgs/msg/RobotMode");
var Location_1 = require("../../rmf_fleet_msgs/msg/Location");
var RobotState = /** @class */ (function () {
    function RobotState(fields) {
        if (fields === void 0) { fields = {}; }
        this.name = fields.name || '';
        this.model = fields.model || '';
        this.task_id = fields.task_id || '';
        this.seq = fields.seq || 0;
        this.mode = fields.mode || new RobotMode_1.RobotMode();
        this.battery_percent = fields.battery_percent || 0;
        this.location = fields.location || new Location_1.Location();
        this.path = fields.path || [];
    }
    RobotState.validate = function (obj) {
        var e_1, _a;
        if (typeof obj['name'] !== 'string') {
            throw new Error('expected "name" to be "string"');
        }
        if (typeof obj['model'] !== 'string') {
            throw new Error('expected "model" to be "string"');
        }
        if (typeof obj['task_id'] !== 'string') {
            throw new Error('expected "task_id" to be "string"');
        }
        if (typeof obj['seq'] !== 'number') {
            throw new Error('expected "seq" to be "number"');
        }
        try {
            RobotMode_1.RobotMode.validate(obj['mode']);
        }
        catch (e) {
            throw new Error('in "mode":\n  ' + e.message);
        }
        if (typeof obj['battery_percent'] !== 'number') {
            throw new Error('expected "battery_percent" to be "number"');
        }
        try {
            Location_1.Location.validate(obj['location']);
        }
        catch (e) {
            throw new Error('in "location":\n  ' + e.message);
        }
        if (!Array.isArray(obj['path'])) {
            throw new Error('expected "path" to be an array');
        }
        try {
            for (var _b = __values(obj['path'].entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), i = _d[0], v = _d[1];
                try {
                    Location_1.Location.validate(v);
                }
                catch (e) {
                    throw new Error("in index " + i + " of \"path\":\n  " + e.message);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    RobotState.FullTypeName = 'rmf_fleet_msgs/msg/RobotState';
    return RobotState;
}());
exports.RobotState = RobotState;
/*
string name
string model

# task_id is copied in from the most recent Request message,
# such as ModeRequest, DestinationRequest, or PathRequest
string task_id

# The sequence number of this message. Every new message should increment the
# sequence number by 1.
uint64 seq

RobotMode mode
float32 battery_percent
Location location
Location[] path

*/
