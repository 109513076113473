var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createTheme } from '@mui/material/styles';
import commonTheme from './common-theme';
export var base = createTheme({
    //Light Grey, Cultured, Silver Sand
    palette: __assign(__assign({}, commonTheme), { mode: 'light', primary: {
            main: '#1FD8DC ',
            light: '#ECEFF1',
            dark: '#B0BEC5',
        }, background: {
            //Cultured, White
            default: '#EEEEEE',
            paper: '#FFFFFF',
        } }),
});
export var rmfLight = createTheme(__assign(__assign({}, base), { components: {
        MuiTableCell: {
            styleOverrides: {
                stickyHeader: {
                    backgroundColor: base.palette.primary.main,
                },
            },
        },
    } }));
