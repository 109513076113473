// Converts unix millis time to toast date time string
function unixMillisToDateTime(unix_millis) {
  const date = new Date(unix_millis);
  let toast_time = new String();
  let year = date.getFullYear().toString();
  let month =
    date.getMonth() + 1 < 10
      ? `0${(date.getMonth() + 1).toString()}`
      : (date.getMonth() + 1).toString();
  let day = date.getDate() < 10 ? `0${date.getDate().toString()}` : date.getDate().toString();
  let time = date.toString().slice(16, 15 + 9);
  toast_time = `${year}-${month}-${day}T${time}`;
  return toast_time;
}

export const dataToRobotCalendarMap = (data) => {
  let robotsCalendarMap = {};

  if (!data.hasOwnProperty('info')) return robotsCalendarMap;

  for (let info of data.info) {
    info.robots.map((robot, index) => {
      let ui_robot_name;
      if (!info.ui_robot_names || index > info.ui_robot_names.length) {
        ui_robot_name = robot;
      } else {
        ui_robot_name = info.ui_robot_names[index];
      }
      robotsCalendarMap[ui_robot_name] = {
        ui_robot_name: ui_robot_name,
        calendars: {
          completed: `${robot}_completed`,
          clean: `${robot}_clean`,
          maintenance: `${robot}_maintenance`,
          error: `${robot}_error`,
        },
      };
    });
  }
  const flightSchedulePrefix = 'flight-schedule';
  robotsCalendarMap[flightSchedulePrefix] = {
    ui_robot_name: flightSchedulePrefix,
    calendars: {
      completed: `${flightSchedulePrefix}_completed`,
      ongoing: `${flightSchedulePrefix}`,
    },
  };
  return robotsCalendarMap;
};

// Converts the toast rmf schedule events to Toast events
export function eventToToastEvent(event_id, scheduler_event, calendar_id, color) {
  // Hard code numbers as there are is no duration in the events as of now
  let start_time = unixMillisToDateTime(scheduler_event['start_time'] * 1000);
  let end_time;
  if (scheduler_event['duration'] !== null) {
    end_time = unixMillisToDateTime(
      scheduler_event['duration'] * 1000 + scheduler_event['start_time'] * 1000,
    );
  } else {
    // Hard code a default duration of 1 hour
    end_time = unixMillisToDateTime(scheduler_event['start_time'] * 1000 + 3600000);
  }
  return {
    id: event_id,
    calendarId: calendar_id,
    category: 'time',
    title: scheduler_event['description'],
    start: start_time,
    end: end_time,
    backgroundColor: color,
    borderColor: color,
  };
}

function isCleanEvent(event) {
  if (event.type === 'default/robot_task' || event.type === 'Cleaning Task') {
    return true;
  }
  return false;
}

function isMaintenanceEvent(event) {
  if (event.type === 'Daily Maintenance' || event.type === 'Vendor Maintenance') {
    return true;
  }
  return false;
}

function isFlightScheduleBlockOff(event) {
  if (event.type === 'flight-schedule') {
    return true;
  }
  return false;
}

const isFailed = (details) => {
  // if there is not "success" it has not completed
  if (!('success' in details)) return false;
  return !details['success'];
};

function eventCompleted(event) {
  // First check based on eventDetails
  let eventDetails = event.event_details ? event.event_details : null;
  // Check if eventDetail success is false
  if ('success' in eventDetails) {
    return true;
  }

  // Second check if the end time is smaller than current time
  const now = new Date().getTime() / 1000;
  const event_end = event.duration ? event.start_time + event.duration : event.start_time;
  if (event_end <= now) {
    return true;
  }
  return false;
}

export function getCalendarIdAndColor(rmfEvent, robotsCalendarArray) {
  // Check if event is flight schedule
  if (isFlightScheduleBlockOff(rmfEvent)) {
    const flightScheduleCalendarInfo = robotsCalendarArray.find(
      (item) => 'flight-schedule' === item[0],
    );
    if (eventCompleted(rmfEvent)) {
      const colour = '#d8d8d8';
      if (flightScheduleCalendarInfo)
        return [flightScheduleCalendarInfo[1].calendars.completed, colour];
      return ['flight-schedule_completed', colour];
    } else {
      const colour = '#ff8989';
      if (flightScheduleCalendarInfo)
        return [flightScheduleCalendarInfo[1].calendars.ongoing, colour];
      return ['flight-schedule', colour];
    }
  }

  // It should be a robot task

  // Event is a robot task
  const robot = rmfEvent.event_details.robot;
  let calendars;
  robotsCalendarArray.every((item) => {
    if (robot === item[0]) {
      calendars = item[1].calendars;
      return false;
    }
    return true;
  });

  // not in any category that we planned to handle
  if (!calendars) return ['something_else', '#ffffff'];

  if (isFailed(rmfEvent.event_details)) {
    return [calendars.error, '#ff4d4d'];
  }

  if (eventCompleted(rmfEvent)) {
    return [calendars.completed, '#d8d8d8'];
  }

  if (isCleanEvent(rmfEvent)) {
    return [calendars.clean, '#a1b56c'];
  }

  if (isMaintenanceEvent(rmfEvent)) {
    return [calendars.maintenance, '#FFD18B'];
  }
}

export function getCalendars(robotsCalendarArray) {
  let calendars = [];
  robotsCalendarArray.forEach((item) => {
    for (let calendar in item[1].calendars) {
      let name = item[1].calendars[calendar];
      calendars.push({ id: name, name: name });
    }
  });
  return calendars;
}

const getZoneSequence = (rmfEvent) => {
  let details = rmfEvent.event_details;
  let cleanZones = [];

  if ('zone' in details) {
    cleanZones.push(details['zone']);
  }
  return cleanZones.length > 0 ? cleanZones : null;
};

// extract out information from the event to be displayed on the Event Dialog
export const convertToDisplay = (eventId, currentSchedule) => {
  if (!(eventId in currentSchedule.events)) {
    console.debug('No such event exists in the schedule currently');
    return null;
  }
  const rmfEvent = currentSchedule.events[eventId];
  const eventCategory = rmfEvent.type;
  const startTime = new Date(rmfEvent.start_time * 1000);
  const startTimeString = startTime.toString().slice(0, 24);
  const unixEndTimeSec = rmfEvent.duration
    ? (rmfEvent.start_time + rmfEvent.duration) * 1000
    : rmfEvent.start_time + 1;
  const endTime = new Date(unixEndTimeSec);
  const endTimeString = endTime.toString().slice(0, 24);
  let fleetName;
  let robotName;

  const zoneSequence =
    eventCategory === 'Cleaning Task' || eventCategory === 'flight-schedule'
      ? getZoneSequence(rmfEvent)
      : null;
  let eventDetails = rmfEvent.event_details ? rmfEvent.event_details : null;
  if ('fleet' in eventDetails) {
    fleetName = eventDetails['fleet'];
  }
  if ('robot' in eventDetails) {
    robotName = eventDetails['robot'];
  }

  const seriesId = rmfEvent.series_id ? rmfEvent.series_id : '';

  let recurrenceEndDate = null;
  if (seriesId) {
    if (currentSchedule.series) {
      if (seriesId in currentSchedule.series) {
        recurrenceEndDate = currentSchedule.series[seriesId].until
          ? currentSchedule.series[seriesId].until
          : null;
      }
    }
  }

  // Consolidate the event info
  let result = {
    Category: eventCategory,
    'Start Time': startTimeString,
    'End Time': endTimeString,
    Fleet: fleetName ? fleetName : null,
    Robot: robotName ? robotName : null,
    Zones: zoneSequence ? zoneSequence : undefined,
  };
  // Check if there are execution information
  if (!('success' in eventDetails)) {
    // Not executed yet
    // Skip adding completion details

    return result;
  }

  // Check if execution is successful
  if (eventDetails['success']) {
    result.completionDetails = 'Success';
  } else {
    result.completionDetails = 'Failure';
  }

  // Append delay information if any
  if ('completion_delay' in eventDetails) {
    result.completionDetails += '\nDelays: ' + eventDetails['completion_delay'].toString() + 's';
  }

  // Append details if any
  if ('completion_details' in eventDetails) {
    result.completionDetails += '\nDetails: ' + eventDetails['completion_details'];
  }

  return result;
};

// Event Form Utils
