import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
} from '@mui/material';

interface FormState {
  clearHopper: boolean;
  cleanSideBrushes: boolean;
  cleanMainBrush: boolean;
  checkDustBag: boolean;
}

interface GaussianV40MaintenanceFormProps {
  onSubmit: () => void;
}

export function GaussianV40MaintenanceForm({ onSubmit }: GaussianV40MaintenanceFormProps) {
  const [formState, setFormState] = useState<FormState>({
    clearHopper: false,
    cleanSideBrushes: false,
    cleanMainBrush: false,
    checkDustBag: false,
  });

  const [formValid, setFormValid] = useState<boolean>(false);

  // Validation Logic
  useEffect(() => {
    // Check if all checkboxes are checked
    const isFormValid = Object.values(formState).every((value) => value);
    setFormValid(isFormValid);
  }, [formState]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <div>
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="clearHopper"
                checked={formState.clearHopper}
                onChange={handleCheckboxChange}
              />
            }
            label="Clear Hopper"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="cleanSideBrushes"
                checked={formState.cleanSideBrushes}
                onChange={handleCheckboxChange}
              />
            }
            label="Clean Side Brushes"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="cleanMainBrush"
                checked={formState.cleanMainBrush}
                onChange={handleCheckboxChange}
              />
            }
            label="Clean Main Brush"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="checkDustBag"
                checked={formState.checkDustBag}
                onChange={handleCheckboxChange}
              />
            }
            label="Check Dust Bag"
          />
          <Button
            style={{ marginTop: 10 }}
            variant="contained"
            onClick={onSubmit}
            disabled={!formValid} // Disable the button if the form is not valid
          >
            Submit
          </Button>
        </FormGroup>
        {!formValid && (
          <FormHelperText error>You must complete all steps before submission!</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}
