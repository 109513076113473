export const removeNullUndefined = (obj: any) => {
  if (!obj) return obj;
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  }
};

export const getWeekStart = (date: Date): Date => {
  const currentDay = date.getDay();
  const difference = currentDay - 0;
  return new Date(date.valueOf() - difference * 24 * 60 * 1000);
};

export const getWeekEnd = (date: Date): Date => {
  const currentDay = date.getDay();
  const difference = 7 - currentDay;
  return new Date(date.valueOf() + difference * 24 * 60 * 1000);
};
