"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LaneRequest = void 0;
var LaneRequest = /** @class */ (function () {
    function LaneRequest(fields) {
        if (fields === void 0) { fields = {}; }
        this.fleet_name = fields.fleet_name || '';
        this.open_lanes = fields.open_lanes || [];
        this.close_lanes = fields.close_lanes || [];
    }
    LaneRequest.validate = function (obj) {
        var e_1, _a, e_2, _b;
        if (typeof obj['fleet_name'] !== 'string') {
            throw new Error('expected "fleet_name" to be "string"');
        }
        if (!(obj['open_lanes'] instanceof BigUint64Array) && !Array.isArray(obj['open_lanes'])) {
            throw new Error('expected "open_lanes" to be "BigUint64Array" or an array');
        }
        if (Array.isArray(obj['open_lanes'])) {
            try {
                for (var _c = __values(obj['open_lanes'].entries()), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var _e = __read(_d.value, 2), i = _e[0], v = _e[1];
                    if (typeof v !== 'number') {
                        throw new Error("expected index " + i + " of \"open_lanes\" to be \"number\"");
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        if (!(obj['close_lanes'] instanceof BigUint64Array) && !Array.isArray(obj['close_lanes'])) {
            throw new Error('expected "close_lanes" to be "BigUint64Array" or an array');
        }
        if (Array.isArray(obj['close_lanes'])) {
            try {
                for (var _f = __values(obj['close_lanes'].entries()), _g = _f.next(); !_g.done; _g = _f.next()) {
                    var _h = __read(_g.value, 2), i = _h[0], v = _h[1];
                    if (typeof v !== 'number') {
                        throw new Error("expected index " + i + " of \"close_lanes\" to be \"number\"");
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    };
    LaneRequest.FullTypeName = 'rmf_fleet_msgs/msg/LaneRequest';
    return LaneRequest;
}());
exports.LaneRequest = LaneRequest;
/*

string fleet_name
uint64[] open_lanes
uint64[] close_lanes

*/
