var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from '@mui/material';
import React from 'react';
import { LiftRequestDialog } from './lift-request-dialog';
import { requestDoorModes, requestModes } from './lift-utils';
export function LiftControls(_a) {
    var currentLevel = _a.currentLevel, onClose = _a.onClose, otherProps = __rest(_a, ["currentLevel", "onClose"]);
    var _b = React.useState(false), showDialog = _b[0], setShowDialog = _b[1];
    // Doing `{showDialog && <Form .../>}` will unomunt it before the animations are done.
    // Instead we give a `key` to the form to make react spawn a new instance.
    var _c = React.useState(0), resetForm = _c[0], setResetForm = _c[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "contained", color: "primary", size: "small", onClick: function () {
                setResetForm(function (prev) { return prev + 1; });
                setShowDialog(true);
            } }, "Request"),
        React.createElement(LiftRequestDialog, __assign({ key: resetForm, showFormDialog: showDialog, currentLevel: currentLevel ? currentLevel : 'Unknown', availableDoorModes: requestDoorModes, availableRequestTypes: requestModes, onClose: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                setShowDialog(false);
                onClose && onClose.apply(void 0, args);
            } }, otherProps))));
}
