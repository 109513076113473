"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiftState = void 0;
var Time_1 = require("../../builtin_interfaces/msg/Time");
var LiftState = /** @class */ (function () {
    function LiftState(fields) {
        if (fields === void 0) { fields = {}; }
        this.lift_time = fields.lift_time || new Time_1.Time();
        this.lift_name = fields.lift_name || '';
        this.available_floors = fields.available_floors || [];
        this.current_floor = fields.current_floor || '';
        this.destination_floor = fields.destination_floor || '';
        this.door_state = fields.door_state || 0;
        this.motion_state = fields.motion_state || 0;
        this.available_modes = fields.available_modes || [];
        this.current_mode = fields.current_mode || 0;
        this.session_id = fields.session_id || '';
    }
    LiftState.validate = function (obj) {
        var e_1, _a, e_2, _b;
        try {
            Time_1.Time.validate(obj['lift_time']);
        }
        catch (e) {
            throw new Error('in "lift_time":\n  ' + e.message);
        }
        if (typeof obj['lift_name'] !== 'string') {
            throw new Error('expected "lift_name" to be "string"');
        }
        if (!Array.isArray(obj['available_floors'])) {
            throw new Error('expected "available_floors" to be an array');
        }
        try {
            for (var _c = __values(obj['available_floors'].entries()), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), i = _e[0], v = _e[1];
                if (typeof v !== 'string') {
                    throw new Error("expected index " + i + " of \"available_floors\" to be \"string\"");
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (typeof obj['current_floor'] !== 'string') {
            throw new Error('expected "current_floor" to be "string"');
        }
        if (typeof obj['destination_floor'] !== 'string') {
            throw new Error('expected "destination_floor" to be "string"');
        }
        if (typeof obj['door_state'] !== 'number') {
            throw new Error('expected "door_state" to be "number"');
        }
        if (typeof obj['motion_state'] !== 'number') {
            throw new Error('expected "motion_state" to be "number"');
        }
        if (!(obj['available_modes'] instanceof Uint8Array) && !Array.isArray(obj['available_modes'])) {
            throw new Error('expected "available_modes" to be "Uint8Array" or an array');
        }
        if (Array.isArray(obj['available_modes'])) {
            try {
                for (var _f = __values(obj['available_modes'].entries()), _g = _f.next(); !_g.done; _g = _f.next()) {
                    var _h = __read(_g.value, 2), i = _h[0], v = _h[1];
                    if (typeof v !== 'number') {
                        throw new Error("expected index " + i + " of \"available_modes\" to be \"number\"");
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        if (typeof obj['current_mode'] !== 'number') {
            throw new Error('expected "current_mode" to be "number"');
        }
        if (typeof obj['session_id'] !== 'string') {
            throw new Error('expected "session_id" to be "string"');
        }
    };
    LiftState.FullTypeName = 'rmf_lift_msgs/msg/LiftState';
    LiftState.DOOR_CLOSED = 0;
    LiftState.DOOR_MOVING = 1;
    LiftState.DOOR_OPEN = 2;
    LiftState.MOTION_STOPPED = 0;
    LiftState.MOTION_UP = 1;
    LiftState.MOTION_DOWN = 2;
    LiftState.MOTION_UNKNOWN = 3;
    LiftState.MODE_UNKNOWN = 0;
    LiftState.MODE_HUMAN = 1;
    LiftState.MODE_AGV = 2;
    LiftState.MODE_FIRE = 3;
    LiftState.MODE_OFFLINE = 4;
    LiftState.MODE_EMERGENCY = 5;
    return LiftState;
}());
exports.LiftState = LiftState;
/*
# lift_time records when the information in this message was generated
builtin_interfaces/Time lift_time

string lift_name

string[] available_floors
string current_floor
string destination_floor

uint8 door_state
uint8 DOOR_CLOSED=0
uint8 DOOR_MOVING=1
uint8 DOOR_OPEN=2

uint8 motion_state
uint8 MOTION_STOPPED=0
uint8 MOTION_UP=1
uint8 MOTION_DOWN=2
uint8 MOTION_UNKNOWN=3

# We can only set human or agv mode, but we can read other modes: fire, etc.
uint8[] available_modes
uint8 current_mode
uint8 MODE_UNKNOWN=0
uint8 MODE_HUMAN=1
uint8 MODE_AGV=2
uint8 MODE_FIRE=3
uint8 MODE_OFFLINE=4
uint8 MODE_EMERGENCY=5
# we can add more "read-only" modes as we come across more of them.

# this field records the session_id that has been granted control of the lift
# until it sends a request with a request_type of REQUEST_END_SESSION
string session_id

*/
