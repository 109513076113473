var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import RBush from 'rbush';
import React from 'react';
var EntityRBush = /** @class */ (function (_super) {
    __extends(EntityRBush, _super);
    function EntityRBush() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EntityRBush.prototype.toBBox = function (entity) {
        return entity.bbox;
    };
    return EntityRBush;
}(RBush));
var EntityManager = /** @class */ (function () {
    function EntityManager() {
        this._entities = new EntityRBush();
    }
    EntityManager.prototype.add = function (entity) {
        this._entities.insert(entity);
        return entity;
    };
    EntityManager.prototype.remove = function (entity) {
        this._entities.remove(entity);
    };
    /**
     * Get a bounding box that does not collides with any other entities.
     * @param bbox Preferred location.
     * @param searchDepth
     * @returns
     */
    EntityManager.prototype.getNonColliding = function (bbox, _a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.searchDepth, searchDepth = _c === void 0 ? 4 : _c, _d = _b.distLimit, distLimit = _d === void 0 ? 200 : _d;
        var distLimitSq = Math.pow(distLimit, 2);
        var candidates = [];
        var stack = [{ bbox: bbox, depth: 0 }];
        var _loop_1 = function (top_1) {
            var search = top_1.bbox, depth = top_1.depth;
            var collidingEntites = this_1._entities.search(search);
            var distSq = Math.pow((search.minX - bbox.minX), 2) + Math.pow((search.minY - bbox.minY), 2);
            if (collidingEntites.length === 0 && distSq <= distLimitSq) {
                candidates.push(search);
            }
            else if (depth < searchDepth) {
                collidingEntites.forEach(function (colliding) {
                    // left
                    stack.push({
                        bbox: {
                            minX: bbox.minX - (bbox.maxX - colliding.bbox.minX + 0.001),
                            minY: bbox.minY,
                            maxX: colliding.bbox.minX - 0.001,
                            maxY: bbox.maxY,
                        },
                        depth: depth + 1,
                    });
                    // right
                    stack.push({
                        bbox: {
                            minX: colliding.bbox.maxX + 0.001,
                            minY: bbox.minY,
                            maxX: bbox.maxX + (colliding.bbox.maxX - bbox.minX + 0.001),
                            maxY: bbox.maxY,
                        },
                        depth: depth + 1,
                    });
                    // top
                    stack.push({
                        bbox: {
                            minX: bbox.minX,
                            minY: bbox.minY - (bbox.maxY - colliding.bbox.minY + 0.001),
                            maxX: bbox.maxX,
                            maxY: colliding.bbox.minY - 0.001,
                        },
                        depth: depth + 1,
                    });
                    // bottom
                    stack.push({
                        bbox: {
                            minX: bbox.minX,
                            minY: colliding.bbox.maxY + 0.001,
                            maxX: bbox.maxX,
                            maxY: bbox.maxY + (colliding.bbox.maxY - bbox.minY + 0.001),
                        },
                        depth: depth + 1,
                    });
                });
            }
        };
        var this_1 = this;
        for (var top_1 = stack.pop(); top_1 !== undefined; top_1 = stack.pop()) {
            _loop_1(top_1);
        }
        if (candidates.length === 0)
            return null;
        var preferredCenterX = bbox.minX + (bbox.maxX - bbox.minX) / 2;
        var preferredCenterY = bbox.minY + (bbox.maxY - bbox.minY) / 2;
        var distances = candidates.map(function (candidate) {
            var centerX = candidate.minX + (candidate.maxX - candidate.minX) / 2;
            var centerY = candidate.minY + (candidate.maxY - candidate.minY) / 2;
            return Math.pow((preferredCenterY - centerY), 2) + Math.pow((preferredCenterX - centerX), 2);
        });
        var closestIdx = 0;
        var closestDistance = distances[0];
        distances.forEach(function (d, i) {
            if (d < closestDistance) {
                closestDistance = d;
                closestIdx = i;
            }
        });
        return candidates[closestIdx];
    };
    /**
     * Returns true if the bounding box collidies with any other entities.
     * @param bbox
     * @returns
     */
    EntityManager.prototype.collides = function (bbox) {
        return this._entities.collides(bbox);
    };
    return EntityManager;
}());
export { EntityManager };
export var EntityManagerContext = React.createContext(new EntityManager());
