var _a, _b;
import { LiftRequest as RmfLiftRequest, LiftState as RmfLiftState } from 'rmf-models';
export function liftModeToString(liftMode) {
    if (liftMode === undefined) {
        return "Unknown (" + liftMode + ")";
    }
    switch (liftMode) {
        case RmfLiftState.MODE_AGV:
            return 'AGV';
        case RmfLiftState.MODE_EMERGENCY:
            return 'Emergency';
        case RmfLiftState.MODE_FIRE:
            return 'Fire';
        case RmfLiftState.MODE_HUMAN:
            return 'Human';
        case RmfLiftState.MODE_OFFLINE:
            return 'Offline';
        default:
            return "Unknown (" + liftMode + ")";
    }
}
export function doorStateToString(doorState) {
    if (doorState === undefined)
        return 'Unknown';
    switch (doorState) {
        case RmfLiftState.DOOR_OPEN:
            return 'OPEN';
        case RmfLiftState.DOOR_CLOSED:
            return 'CLOSED';
        case RmfLiftState.DOOR_MOVING:
            return 'MOVING';
        default:
            return "UNKNOWN (" + doorState + ")";
    }
}
export function motionStateToString(motionState) {
    if (motionState === undefined)
        return 'Unknown';
    switch (motionState) {
        case RmfLiftState.MOTION_DOWN:
            return 'Down';
        case RmfLiftState.MOTION_STOPPED:
            return 'Stopped';
        case RmfLiftState.MOTION_UP:
            return 'Up';
        default:
            return "Unknown (" + motionState + ")";
    }
}
export var requestModes = [
    RmfLiftRequest.REQUEST_AGV_MODE,
    RmfLiftRequest.REQUEST_HUMAN_MODE,
    RmfLiftRequest.REQUEST_END_SESSION,
];
export var requestModeStrings = (_a = {},
    _a[RmfLiftRequest.REQUEST_END_SESSION] = 'End Session',
    _a[RmfLiftRequest.REQUEST_AGV_MODE] = 'AGV',
    _a[RmfLiftRequest.REQUEST_HUMAN_MODE] = 'Human',
    _a);
export function requestModeToString(requestMode) {
    return requestModeStrings[requestMode] || "Unknown (" + requestMode + ")";
}
export var requestDoorModes = [RmfLiftRequest.DOOR_OPEN, RmfLiftRequest.DOOR_CLOSED];
export var requestDoorModeStrings = (_b = {},
    _b[RmfLiftRequest.DOOR_OPEN] = 'Open',
    _b[RmfLiftRequest.DOOR_CLOSED] = 'Closed',
    _b);
export function requestDoorModeToString(requestDoorMode) {
    return requestDoorModeStrings[requestDoorMode] || 'Unknown';
}
