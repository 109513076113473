"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskProfile = void 0;
var Time_1 = require("../../builtin_interfaces/msg/Time");
var TaskDescription_1 = require("../../rmf_task_msgs/msg/TaskDescription");
var TaskProfile = /** @class */ (function () {
    function TaskProfile(fields) {
        if (fields === void 0) { fields = {}; }
        this.task_id = fields.task_id || '';
        this.submission_time = fields.submission_time || new Time_1.Time();
        this.description = fields.description || new TaskDescription_1.TaskDescription();
    }
    TaskProfile.validate = function (obj) {
        if (typeof obj['task_id'] !== 'string') {
            throw new Error('expected "task_id" to be "string"');
        }
        try {
            Time_1.Time.validate(obj['submission_time']);
        }
        catch (e) {
            throw new Error('in "submission_time":\n  ' + e.message);
        }
        try {
            TaskDescription_1.TaskDescription.validate(obj['description']);
        }
        catch (e) {
            throw new Error('in "description":\n  ' + e.message);
        }
    };
    TaskProfile.FullTypeName = 'rmf_task_msgs/msg/TaskProfile';
    return TaskProfile;
}());
exports.TaskProfile = TaskProfile;
/*
# Unique ID assigned to this task
string task_id

# Task submission time
builtin_interfaces/Time submission_time

# Details of the task
TaskDescription description

*/
