"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiftClearance_Request = void 0;
var LiftClearance_Request = /** @class */ (function () {
    function LiftClearance_Request(fields) {
        if (fields === void 0) { fields = {}; }
        this.robot_name = fields.robot_name || '';
        this.lift_name = fields.lift_name || '';
    }
    LiftClearance_Request.validate = function (obj) {
        if (typeof obj['robot_name'] !== 'string') {
            throw new Error('expected "robot_name" to be "string"');
        }
        if (typeof obj['lift_name'] !== 'string') {
            throw new Error('expected "lift_name" to be "string"');
        }
    };
    LiftClearance_Request.FullTypeName = 'rmf_fleet_msgs/srv/LiftClearance_Request';
    return LiftClearance_Request;
}());
exports.LiftClearance_Request = LiftClearance_Request;
/*

# Name of the robot that wants to enter a lift
string robot_name

# Name of the lift that the robot wants to enter
string lift_name


*/
