var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, Button, } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getCleanZonesFromRobot } from './utils';
var rowsFromBackend = {
    ZoneAvailable: {
        name: 'ZA',
        items: Array(),
    },
};
function ZoneSequenceSelector(props) {
    var selectedRobot = props.selectedRobot, currentZoneSequence = props.currentZoneSequence, setZoneSequence = props.setZoneSequence, other = __rest(props, ["selectedRobot", "currentZoneSequence", "setZoneSequence"]);
    var zoneObjArray = Array();
    currentZoneSequence === null || currentZoneSequence === void 0 ? void 0 : currentZoneSequence.map(function (zone) {
        zoneObjArray.push({ id: zone, content: zone });
    });
    rowsFromBackend['ZoneAvailable'].items = zoneObjArray;
    var _a = useState(rowsFromBackend), rows = _a[0], setRows = _a[1];
    var _b = React.useState(''), selectedZone = _b[0], setSelectedZone = _b[1];
    console.log('ZONE SEQUENCE COMPONENT');
    console.log(JSON.stringify(rows));
    console.log(JSON.stringify(currentZoneSequence));
    var addZoneToSequence = function () {
        // Make sure zone is not empty or if it is valid
        if (!getCleanZonesFromRobot(selectedRobot).includes(selectedZone)) {
            console.log('Invalid Zone!');
            return;
        }
        var newZoneSequence = currentZoneSequence ? currentZoneSequence : new Array();
        if (newZoneSequence.includes(selectedZone)) {
            console.log("Zone " + selectedZone + " already in zone sequence");
            return;
        }
        // Add newly selected zone to the end of the sequence
        newZoneSequence.push(selectedZone);
        var row_string = 'ZoneAvailable';
        rows[row_string].items.push({ id: selectedZone, content: selectedZone });
        setRows(rows);
        setZoneSequence(newZoneSequence);
    };
    var removeZoneFromSequence = function () {
        if (!currentZoneSequence) {
            // nothing to remove
            return;
        }
        if (!currentZoneSequence.includes(selectedZone)) {
            // Selected zone not in current zone sequence
            return;
        }
        // Get index of selected zone sequence and remove it
        var index = currentZoneSequence.indexOf(selectedZone);
        currentZoneSequence.splice(index, 1);
        setZoneSequence(currentZoneSequence);
        var row_string = 'ZoneAvailable';
        // get index of deleted zone
        var rowIndex;
        rows[row_string].items.map(function (zone, index) {
            if (zone.content == selectedZone) {
                rowIndex = index;
            }
        });
        if (rowIndex == undefined) {
            // this shouldnt happen
            console.debug('Could not find selected zone in zones');
            return;
        }
        // Remove roles
        rows[row_string].items.splice(rowIndex, 1);
        setRows(rows);
    };
    var onDragEnd = function (result, rows, setRows) {
        if (!result.destination) {
            return;
        }
        var source = result.source, destination = result.destination;
        // Swap around source and destination droppable items
        var column = rows[source.droppableId];
        var copiedItems = __spreadArray([], column.items, true);
        var removed = copiedItems.splice(source.index, 1)[0];
        copiedItems.splice(destination.index, 0, removed);
        rows[source.droppableId].items = copiedItems;
        setRows(rows);
        var newZoneSequence = Array();
        copiedItems.map(function (zone) {
            newZoneSequence.push(zone.content);
        });
        setZoneSequence(newZoneSequence);
    };
    return (React.createElement(Grid, { container: true, direction: "row", wrap: "nowrap", spacing: 2 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(FormControl, { fullWidth: true, margin: "normal" },
                React.createElement(InputLabel, { id: "zone-sequence" }, "Zones Available"),
                React.createElement(Select, { labelId: "select-clean-zone", id: "select-clean-zone", value: selectedZone, label: "Zone Sequence", onChange: function (event) { return setSelectedZone(event.target.value); } }, getCleanZonesFromRobot(selectedRobot).map(function (zone) {
                    return React.createElement(MenuItem, { value: zone }, zone);
                }))),
            React.createElement(DragDropContext, { onDragEnd: function (result) { return onDragEnd(result, rows, setRows); } }, Object.entries(rows).map(function (_a, index) {
                var columnId = _a[0], column = _a[1];
                return (React.createElement(Grid, { container: true, spacing: 4 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Droppable, { droppableId: columnId, key: columnId, direction: "horizontal" }, function (provided, snapshot) {
                            return (React.createElement("div", __assign({}, provided.droppableProps, { ref: provided.innerRef, style: {
                                    background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                                    padding: 6,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflowX: 'auto',
                                    minWidth: '500px',
                                    minHeight: '8rem',
                                    alignContent: 'center',
                                    border: '2px dashed #ccc',
                                    borderRadius: '4px', // Add some border radius
                                } }),
                                column.items.map(function (item, index) {
                                    return (React.createElement(React.Fragment, { key: item.id },
                                        React.createElement(Draggable, { key: item.id, draggableId: item.id, index: index }, function (provided, snapshot) {
                                            return (React.createElement("div", __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: __assign({ userSelect: 'none', padding: 16, margin: '0 8px 8px 8px', height: '20px', width: 'auto', fontSize: '12px', borderRadius: '20px 20px 20px 20px', backgroundColor: snapshot.isDragging
                                                        ? '#263B4A'
                                                        : '#faca69', color: 'black', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }, provided.draggableProps.style) }), item.content));
                                        }),
                                        index < column.items.length - 1 && (React.createElement("div", { style: {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                margin: '12px 0',
                                            } },
                                            React.createElement("div", { style: {
                                                    border: '2px dashed #000',
                                                    height: '0',
                                                    width: '50%',
                                                } })))));
                                }),
                                provided.placeholder));
                        }))));
            })),
            React.createElement(Grid, { container: true, spacing: 4, columnSpacing: { xs: 1, sm: 2, md: 3 }, style: { paddingTop: 10 } },
                React.createElement(Grid, { item: true, xs: 6, md: 4 },
                    React.createElement(Button, { variant: "contained", onClick: addZoneToSequence, sx: { width: 150 } }, "Add Zone")),
                React.createElement(Grid, { item: true, xs: 6, md: 4 },
                    React.createElement(Button, { variant: "contained", onClick: removeZoneFromSequence, sx: { width: 150 } }, "Remove Zone"))))));
}
export default ZoneSequenceSelector;
