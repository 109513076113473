import React, { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Tabs, Tab } from '@mui/material';
import Calendar from '@toast-ui/react-calendar';

interface CalendarControlProps {
  calendarRef: React.RefObject<typeof Calendar>;
}

export function CalendarControl(props: CalendarControlProps) {
  const { calendarRef } = props;

  // TODO(KW): Decide on how to set the current month value when the tab
  const [currMonth, setCurrMonth] = React.useState<string>(new Date().toString().slice(4, 7));
  let current_date_string =
    new Date().getDate().toString() + ' ' + new Date().toString().slice(4, 7);
  const [currRange, setCurrRange] = React.useState<string>(
    current_date_string + ' - ' + current_date_string,
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const getCalInstance = () => {
    if (!calendarRef.current) return null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return calendarRef.current.getInstance();
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const [value, setValue] = React.useState('0');

  const changeMonth = () => {
    const calendarInstance = getCalInstance();
    if (!calendarInstance) return;
    let curr_month = calendarInstance.getDate().toString().slice(4, 7);
    setCurrMonth(curr_month);
  };

  const changeRange = () => {
    let curr_range_start = getCalInstance().getDateRangeStart();
    let curr_range_end = getCalInstance().getDateRangeEnd();
    let curr_range_start_string =
      curr_range_start.getDate().toString() + ' ' + curr_range_start.toString().slice(4, 7);
    let curr_range_end_string =
      curr_range_end.getDate().toString() + ' ' + curr_range_end.toString().slice(4, 7);
    setCurrRange(curr_range_start_string + ' - ' + curr_range_end_string);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    let view;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const inst = getCalInstance();
    console.log(inst);
    if (!inst) {
      console.error('This should not happen');
      return;
    }
    if (newValue === '0') {
      view = 'day';
    } else if (newValue === '1') {
      view = 'week';
    } else if (newValue === '2') {
      view = 'month';
    } else if (newValue === '3') {
      getCalInstance().prev();
      changeMonth();
      changeRange();
      changeYear();
      return;
    } else if (newValue === '4') {
      getCalInstance().next();
      changeMonth();
      changeRange();
      changeYear();
      return;
    } else {
      return;
    }

    getCalInstance().changeView(view);
    changeRange();
    changeYear();
    console.debug(`VIEW: ${view}`);
    setValue(newValue);
    console.debug(`NEW VALUE: ${newValue}`);
  };

  const [currYear, setCurrYear] = React.useState<string>(new Date().getFullYear().toString());

  const changeYear = () => {
    const currentDate = new Date();
    const calInstance = getCalInstance(); // Store the instance once for consistency.

    // Check if the current date is greater than December 31 of the current year
    if (currentDate > new Date(currentDate.getFullYear(), 11, 31)) {
      // Increment the year and update the state
      const nextYear = (calInstance.getFullYear() + 1).toString();
      setCurrYear(nextYear);
      console.log(setCurrYear);
    }
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="basic tabs example"
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      textColor="primary"
    >
      <Tab label="DAILY" value="0" />
      <Tab label="WEEKLY" value="1" />
      <Tab label="MONTHLY" value="2" />
      <Tab icon={<ArrowBackIosIcon />} value="3" />
      <Tab icon={currRange} value="6" />
      <Tab icon={<ArrowForwardIosIcon />} value="4" />
      <Tab label={currMonth} value="5" />
      <Tab label={currYear} value="7" />
    </Tabs>
  );
}
