"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitTask_Response = void 0;
var SubmitTask_Response = /** @class */ (function () {
    function SubmitTask_Response(fields) {
        if (fields === void 0) { fields = {}; }
        this.success = fields.success || false;
        this.task_id = fields.task_id || '';
        this.message = fields.message || '';
    }
    SubmitTask_Response.validate = function (obj) {
        if (typeof obj['success'] !== 'boolean') {
            throw new Error('expected "success" to be "boolean"');
        }
        if (typeof obj['task_id'] !== 'string') {
            throw new Error('expected "task_id" to be "string"');
        }
        if (typeof obj['message'] !== 'string') {
            throw new Error('expected "message" to be "string"');
        }
    };
    SubmitTask_Response.FullTypeName = 'rmf_task_msgs/srv/SubmitTask_Response';
    return SubmitTask_Response;
}());
exports.SubmitTask_Response = SubmitTask_Response;
/*


# Confirmation that this service call is processed
bool success

# generated task ID by dispatcher node
string task_id

# This will provide a verbose message regarding task submission
string message


*/
