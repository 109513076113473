var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
/**
 * A reducer hook that helps manage a stack of views.
 * @param initialState
 * @param homeView
 */
export function useStackNavigator(initialState, homeView) {
    var _a = React.useState(initialState), stack = _a[0], setStack = _a[1];
    var dispatch = React.useMemo(function () { return ({
        push: function (viewId) { return setStack(function (prev) { return __spreadArray(__spreadArray([], prev, true), [viewId], false); }); },
        pop: function () { return setStack(function (prev) { return (prev.length > 1 ? prev.slice(0, prev.length - 1) : prev); }); },
        home: function () { return setStack(function (prev) { return __spreadArray(__spreadArray([], prev, true), [homeView], false); }); },
        reset: function () { return setStack(initialState); },
    }); }, [homeView, initialState]);
    return [stack, dispatch];
}
export default useStackNavigator;
