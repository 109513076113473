var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Tab, styled } from '@mui/material';
import React from 'react';
var StyledTab = styled(function (props) { return React.createElement(Tab, __assign({}, props)); })(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.primary.contrastText,
        opacity: 0.6,
        '&.Mui-selected': {
            color: theme.palette.primary.contrastText,
            opacity: 1,
        },
    });
});
export function AppBarTab(props) {
    var label = props.label, value = props.value, onTabClick = props.onTabClick, otherProps = __rest(props, ["label", "value", "onTabClick"]);
    return (React.createElement(StyledTab, __assign({ label: label, value: value, onClick: function () { return onTabClick && onTabClick(); } }, otherProps)));
}
