"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Priority = void 0;
var Priority = /** @class */ (function () {
    function Priority(fields) {
        if (fields === void 0) { fields = {}; }
        this.value = fields.value || 0;
    }
    Priority.validate = function (obj) {
        if (typeof obj['value'] !== 'number') {
            throw new Error('expected "value" to be "number"');
        }
    };
    Priority.FullTypeName = 'rmf_task_msgs/msg/Priority';
    return Priority;
}());
exports.Priority = Priority;
/*
uint64 value 0
*/
