"use strict";
/* This is a generated file, do not edit */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DispenserState = void 0;
var Time_1 = require("../../builtin_interfaces/msg/Time");
var DispenserState = /** @class */ (function () {
    function DispenserState(fields) {
        if (fields === void 0) { fields = {}; }
        this.time = fields.time || new Time_1.Time();
        this.guid = fields.guid || '';
        this.mode = fields.mode || 0;
        this.request_guid_queue = fields.request_guid_queue || [];
        this.seconds_remaining = fields.seconds_remaining || 0;
    }
    DispenserState.validate = function (obj) {
        var e_1, _a;
        try {
            Time_1.Time.validate(obj['time']);
        }
        catch (e) {
            throw new Error('in "time":\n  ' + e.message);
        }
        if (typeof obj['guid'] !== 'string') {
            throw new Error('expected "guid" to be "string"');
        }
        if (typeof obj['mode'] !== 'number') {
            throw new Error('expected "mode" to be "number"');
        }
        if (!Array.isArray(obj['request_guid_queue'])) {
            throw new Error('expected "request_guid_queue" to be an array');
        }
        try {
            for (var _b = __values(obj['request_guid_queue'].entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), i = _d[0], v = _d[1];
                if (typeof v !== 'string') {
                    throw new Error("expected index " + i + " of \"request_guid_queue\" to be \"string\"");
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (typeof obj['seconds_remaining'] !== 'number') {
            throw new Error('expected "seconds_remaining" to be "number"');
        }
    };
    DispenserState.FullTypeName = 'rmf_dispenser_msgs/msg/DispenserState';
    DispenserState.IDLE = 0;
    DispenserState.BUSY = 1;
    DispenserState.OFFLINE = 2;
    return DispenserState;
}());
exports.DispenserState = DispenserState;
/*
builtin_interfaces/Time time

# A unique ID for this workcell
string guid

# Different basic modes that the workcell could be in
int32 mode
int32 IDLE=0
int32 BUSY=1
int32 OFFLINE=2

# Queued up requests that are being handled by this workcell
string[] request_guid_queue

# below are custom workcell message fields
float32 seconds_remaining

*/
