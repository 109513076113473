"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Duration = void 0;
var Duration = /** @class */ (function () {
    function Duration(fields) {
        if (fields === void 0) { fields = {}; }
        this.sec = fields.sec || 0;
        this.nanosec = fields.nanosec || 0;
    }
    Duration.validate = function (obj) {
        if (typeof obj['sec'] !== 'number') {
            throw new Error('expected "sec" to be "number"');
        }
        if (typeof obj['nanosec'] !== 'number') {
            throw new Error('expected "nanosec" to be "number"');
        }
    };
    Duration.FullTypeName = 'builtin_interfaces/msg/Duration';
    return Duration;
}());
exports.Duration = Duration;
/*
# Duration defines a period between two time points. It is comprised of a
# seconds component and a nanoseconds component.

# Seconds component, range is valid over any possible int32 value.
int32 sec

# Nanoseconds component in the range of [0, 10e9).
uint32 nanosec

*/
