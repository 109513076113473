import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { DoorMode } from 'rmf-models';
import { doorModeToString, doorTypeToString } from './door-utils';
import { HealthStatus, healthStatusToOpMode } from '../utils';
export function DoorDataGridTable(_a) {
    var doors = _a.doors;
    var theme = useTheme();
    var OpModeState = function (params) {
        var opModeStateLabelStyle = (function () {
            var unknown = {
                color: theme.palette.action.disabledBackground,
            };
            var online = {
                color: theme.palette.success.main,
            };
            var unstable = {
                color: theme.palette.warning.main,
            };
            var offline = {
                color: theme.palette.error.main,
            };
            switch (params.row.opMode) {
                case HealthStatus.Healthy:
                    return online;
                case HealthStatus.Unhealthy:
                    return unstable;
                case HealthStatus.Dead:
                    return offline;
                default:
                    return unknown;
            }
        })();
        return (React.createElement(Box, { sx: opModeStateLabelStyle },
            React.createElement(Typography, { "data-testid": "op-mode-state", component: "p", sx: {
                    fontWeight: 'bold',
                    fontSize: 14,
                } }, healthStatusToOpMode(params.row.opMode))));
    };
    var DoorState = function (params) {
        var labelStyle = React.useMemo(function () {
            var disabled = {
                color: theme.palette.action.disabledBackground,
            };
            var open = {
                color: theme.palette.success.main,
            };
            var closed = {
                color: theme.palette.error.main,
            };
            var moving = {
                color: theme.palette.warning.main,
            };
            switch (params.row.doorState.current_mode.value) {
                case DoorMode.MODE_OPEN:
                    return open;
                case DoorMode.MODE_CLOSED:
                    return closed;
                case DoorMode.MODE_MOVING:
                    return moving;
                default:
                    return disabled;
            }
        }, [params.row.doorState.current_mode.value]);
        return (React.createElement(Box, { sx: labelStyle },
            React.createElement(Typography, { "data-testid": "door-state", component: "p", sx: {
                    fontWeight: 'bold',
                    fontSize: 14,
                } }, params.row.doorState ? doorModeToString(params.row.doorState.current_mode.value) : -1)));
    };
    var OpenCloseButtons = function (params) {
        return (React.createElement(Box, { sx: { margin: 0, padding: 0, paddingRight: 1 } },
            React.createElement(Button, { variant: "contained", size: "small", "aria-label": "open", sx: { marginRight: 2 }, onClick: params.row.onClickOpen }, "Open"),
            React.createElement(Button, { variant: "contained", size: "small", "aria-label": "close", onClick: params.row.onClickClose }, "Close")));
    };
    var columns = [
        {
            field: 'doorName',
            headerName: 'Name',
            width: 90,
            valueGetter: function (params) { return params.row.doorName; },
            flex: 1,
            filterable: true,
        },
        {
            field: 'opMode',
            headerName: 'Op. Mode',
            width: 150,
            editable: false,
            flex: 1,
            renderCell: OpModeState,
            filterable: true,
        },
        {
            field: 'levelName',
            headerName: 'Current Floor',
            width: 150,
            editable: false,
            valueGetter: function (params) { return params.row.levelName; },
            flex: 1,
            filterable: true,
        },
        {
            field: 'doorType',
            headerName: 'Type',
            width: 150,
            editable: false,
            valueGetter: function (params) { return doorTypeToString(params.row.doorType); },
            flex: 1,
            filterable: true,
        },
        {
            field: 'doorState',
            headerName: 'Door State',
            width: 150,
            editable: false,
            flex: 1,
            renderCell: DoorState,
            filterable: true,
        },
        {
            field: '-',
            headerName: '',
            width: 150,
            editable: false,
            renderCell: OpenCloseButtons,
            flex: 1,
            filterable: true,
        },
    ];
    return (React.createElement("div", { style: { height: '100%', width: '100%' } },
        React.createElement(DataGrid, { autoHeight: true, getRowId: function (l) { return l.index; }, rows: doors, pageSize: 5, rowHeight: 38, columns: columns, rowsPerPageOptions: [5], localeText: {
                noRowsLabel: 'No doors available.',
            } })));
}
