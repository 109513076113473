"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RobotMode = void 0;
var RobotMode = /** @class */ (function () {
    function RobotMode(fields) {
        if (fields === void 0) { fields = {}; }
        this.mode = fields.mode || 0;
        this.mode_request_id = fields.mode_request_id || 0;
    }
    RobotMode.validate = function (obj) {
        if (typeof obj['mode'] !== 'number') {
            throw new Error('expected "mode" to be "number"');
        }
        if (typeof obj['mode_request_id'] !== 'number') {
            throw new Error('expected "mode_request_id" to be "number"');
        }
    };
    RobotMode.FullTypeName = 'rmf_fleet_msgs/msg/RobotMode';
    RobotMode.MODE_IDLE = 0;
    RobotMode.MODE_CHARGING = 1;
    RobotMode.MODE_MOVING = 2;
    RobotMode.MODE_PAUSED = 3;
    RobotMode.MODE_WAITING = 4;
    RobotMode.MODE_EMERGENCY = 5;
    RobotMode.MODE_GOING_HOME = 6;
    RobotMode.MODE_DOCKING = 7;
    RobotMode.MODE_ADAPTER_ERROR = 8;
    RobotMode.MODE_CLEANING = 9;
    return RobotMode;
}());
exports.RobotMode = RobotMode;
/*
uint32 mode
uint32 MODE_IDLE=0
uint32 MODE_CHARGING=1
uint32 MODE_MOVING=2
uint32 MODE_PAUSED=3
uint32 MODE_WAITING=4
uint32 MODE_EMERGENCY=5
uint32 MODE_GOING_HOME=6
uint32 MODE_DOCKING=7

# Use this when a command received from the fleet adapter
# has a problem and needs to be recomputed.
uint32 MODE_ADAPTER_ERROR=8

uint32 MODE_CLEANING=9

uint64 mode_request_id

*/
