var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Divider, Grid, Paper, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
var prefix = 'task-logs';
var classes = {
    root: prefix + "-root",
};
var StyledPaper = styled(function (props) { return React.createElement(Paper, __assign({ variant: "outlined" }, props)); })(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&." + classes.root] = {
            padding: theme.spacing(1),
            width: '100%',
            flex: '0 0 auto',
            maxHeight: '100%',
            overflow: 'auto',
        },
        _b);
});
export var TaskLogs = function (_a) {
    var taskLog = _a.taskLog, eventName = _a.eventName, eventStatus = _a.eventStatus;
    var theme = useTheme();
    function mapEventColor(eventStatus) {
        // TODO(MXG): We should make this color selection consistent with the color
        // selection that's done for task states.
        if (eventStatus == null)
            return theme.palette.warning.light;
        switch (eventStatus) {
            case 'uninitialized':
            case 'blocked':
            case 'error':
            case 'failed':
                return theme.palette.error.dark;
            case 'queued':
            case 'standby':
                return theme.palette.info.light;
            case 'underway':
                return theme.palette.success.light;
            case 'delayed':
                return theme.palette.warning.main;
            case 'skipped':
            case 'canceled':
            case 'killed':
                return theme.palette.error.light;
            case 'completed':
                return theme.palette.info.light;
            default:
                return theme.palette.error.dark;
        }
    }
    return (React.createElement(StyledPaper, { className: classes.root },
        React.createElement(Typography, { variant: "h5", style: { textAlign: 'center' }, gutterBottom: true }, taskLog.task_id),
        React.createElement(Divider, null),
        taskLog.phases ? (Object.entries(taskLog.phases).map(function (_a) {
            var phaseId = _a[0], phase = _a[1];
            return (React.createElement(Paper, { sx: { padding: theme.spacing(1) }, variant: "outlined", key: phaseId },
                React.createElement(Typography, { variant: "h6", fontWeight: "bold", marginTop: 3 },
                    "Phase - ",
                    phaseId),
                React.createElement(Divider, null),
                phase.events ? (Object.entries(phase.events).map(function (_a) {
                    var eventId = _a[0], event = _a[1];
                    return (React.createElement("div", { style: {
                            marginTop: theme.spacing(1),
                            backgroundColor: mapEventColor(eventStatus(phaseId, eventId)),
                            padding: theme.spacing(1),
                            borderRadius: theme.spacing(1),
                        }, key: eventId },
                        React.createElement(Typography, { variant: "body1", fontWeight: "bold" }, eventName(phaseId, eventId)),
                        event.length > 0 ? (event.map(function (log, idx) { return (React.createElement(Grid, { container: true, key: idx, direction: "row", justifyItems: "center", sx: {
                                backgroundColor: 'white',
                                marginTop: theme.spacing(1),
                                borderRadius: '8px',
                            } },
                            React.createElement(Grid, { item: true, xs: 4, sx: {
                                    padding: theme.spacing(1),
                                } },
                                React.createElement(Typography, { variant: "body1" }, new Date(log.unix_millis_time).toLocaleString())),
                            React.createElement(Grid, { item: true, xs: 8, sx: {
                                    padding: theme.spacing(1),
                                } },
                                React.createElement(Typography, { variant: "body1" }, log.text)))); })) : (React.createElement(Typography, { align: "center", sx: { padding: theme.spacing(1) }, fontWeight: "bold" }, "No Logs"))));
                })) : (React.createElement(Typography, { align: "center", sx: { padding: theme.spacing(1) }, fontWeight: "bold" }, "No Event Logs"))));
        })) : (React.createElement("div", null,
            React.createElement(Typography, { align: "center", sx: { padding: theme.spacing(1) }, fontWeight: "bold" }, "No logs to be shown")))));
};
