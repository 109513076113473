import { RmfIngress } from '../../rmf-app';
import { ScheduleForTaskScheduler, DeleteScheduleRequest, UpdateSeriesRequest } from 'api-client';

export const DeleteSchedule = (rmf: RmfIngress, request: DeleteScheduleRequest) => {
  return rmf.schedulerApi.deleteScheduleSchedulerScheduleDeleteDelete(request);
};

export const AddSchedule = (rmf: RmfIngress, request: ScheduleForTaskScheduler) => {
  return rmf.schedulerApi.addScheduleSchedulerScheduleAddPost(request);
};

export const UpdateSchedule = (rmf: RmfIngress, request: ScheduleForTaskScheduler) => {
  return rmf.schedulerApi.updateScheduleSchedulerScheduleUpdatePut(request);
};

export const UpdateSeries = (rmf: RmfIngress, request: UpdateSeriesRequest) => {
  return rmf.schedulerApi.updateSeriesSchedulerSeriesUpdateSeriesPut(request);
};

export const TogglePause = (rmf: RmfIngress, id: string) => {
  return rmf.schedulerApi.togglePauseSchedulerTogglePausePost(id);
};

export const CancelTask = (rmf: RmfIngress, id: string) => {
  return rmf.schedulerApi.cancelTaskSchedulerCancelPost(id);
};
