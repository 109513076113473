"use strict";
/* This is a generated file, do not edit */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelTask = void 0;
var CancelTask_Request_1 = require("./CancelTask_Request");
var CancelTask_Response_1 = require("./CancelTask_Response");
var CancelTask = /** @class */ (function () {
    function CancelTask() {
    }
    CancelTask.FullServiceName = 'rmf_task_msgs/srv/CancelTask';
    CancelTask.Request = CancelTask_Request_1.CancelTask_Request;
    CancelTask.Response = CancelTask_Response_1.CancelTask_Response;
    return CancelTask;
}());
exports.CancelTask = CancelTask;
