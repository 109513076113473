import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Subscription } from 'rxjs';
import {
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { ScheduleForTaskScheduler, DeleteScheduleRequest, UpdateSeriesRequest } from 'api-client';
import React, { useState } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { UploadFile } from './upload';
import ExportDataJSON from './export-file';
import ExportDataCSV from './export-file-csv';
import { v4 as uuidv4 } from 'uuid';
import DownloadIcon from '@mui/icons-material/Download';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import RectangleIcon from '@mui/icons-material/Rectangle';
import { EventFormOptions, addEventFormOptions } from './utils/event_form_utils';

interface CalendarToolbarProps {
  handleEventFormOpen: (options: EventFormOptions) => void;
}

const ITEM_HEIGHT = 48;

export const CalendarToolbar = (props: CalendarToolbarProps) => {
  const { handleEventFormOpen } = props;
  const [downloadopen, setDownloadOpen] = useState<boolean>(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const legendopen = Boolean(anchorEl);

  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const handleDownloadClickOpen = () => {
    setDownloadOpen(true);
  };
  const handleDownloadClickClose = () => {
    setDownloadOpen(false);
  };
  const legendHandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const legendhandleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar variant="dense">
      <Tooltip title="Download Events" color="inherit" placement="top">
        <IconButton onClick={handleDownloadClickOpen}>
          <DownloadIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Dialog onClose={handleDownloadClickClose} open={downloadopen}>
        <DialogTitle>Click on File Format</DialogTitle>
        <ExportDataJSON />
        <ExportDataCSV />
        <DialogContent></DialogContent>
      </Dialog>
      <Tooltip title="Upload Schedule" color="inherit" placement="top">
        <IconButton onClick={handleClickOpenUpload}>
          <UploadFileIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Dialog onClose={handleCloseUpload} open={openUpload}>
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <UploadFile></UploadFile>
        </DialogContent>
      </Dialog>

      <Tooltip title="Create Event" color="inherit" placement="top">
        <IconButton
          onClick={() => handleEventFormOpen(addEventFormOptions())}
          aria-label="Create Task"
        >
          <AddOutlinedIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Legend" color="inherit" placement="top">
        <IconButton onClick={legendHandleClick}>
          <InfoOutlinedIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={legendopen}
        onClose={legendhandleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '40ch',
          },
        }}
      >
        <div style={{ marginLeft: '20px' }}>
          <Typography variant="h6" color="textPrimary">
            Legend for Calendar Events
          </Typography>
        </div>
        <MenuItem onClick={legendhandleClose}>
          <ListItemIcon>
            <RectangleIcon style={{ color: '#FFD18B' }} fontSize="small" />
          </ListItemIcon>
          <ListItemText>Daily / Vendor Maintenance</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <RectangleIcon style={{ color: '#a1b56c' }} fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cleaning Task</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <RectangleIcon style={{ color: '#d8d8d8' }} fontSize="small" />
          </ListItemIcon>
          <ListItemText>Successfully Completed Events</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <RectangleIcon style={{ color: '#ff4d4d' }} fontSize="small" />
          </ListItemIcon>
          <ListItemText>Failed / Conflicting Events</ListItemText>
        </MenuItem>
      </Menu>
    </Toolbar>
  );
};
