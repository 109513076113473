var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { Door as RmfDoor, DoorMode as RmfDoorMode } from 'rmf-models';
var classes = {
    marker: 'door-marker-base-marker',
    base: 'door-marker-base-door',
    open: 'door-marker-open',
    close: 'door-marker-close',
    moving: 'door-marker-moving',
    unknown: 'door-marker-unknown',
    transparent: 'door-marker-transparent',
};
var StyledG = styled('g')(function () {
    var _a;
    return (_a = {},
        _a["& ." + classes.marker] = {
            cursor: 'pointer',
            pointerEvents: 'auto',
        },
        _a["& ." + classes.base] = {
            strokeWidth: 0.2,
        },
        _a["& ." + classes.open] = {
            width: 200,
            stroke: '#AFDDAE',
            strokeDasharray: 0.1,
        },
        _a["& ." + classes.close] = {
            stroke: '#BC4812',
        },
        _a["& ." + classes.moving] = {
            stroke: '#E9CE9F',
            strokeDasharray: 0.3,
        },
        _a["& ." + classes.unknown] = {
            stroke: 'grey',
        },
        _a["& ." + classes.transparent] = {
            stroke: 'transparent',
        },
        _a);
});
function useDoorStyle(doorMode) {
    if (doorMode === undefined) {
        return classes.unknown;
    }
    switch (doorMode) {
        case RmfDoorMode.MODE_OPEN:
            return classes.open;
        case RmfDoorMode.MODE_MOVING:
            return classes.moving;
        case RmfDoorMode.MODE_CLOSED:
            return classes.close;
        default:
            return classes.unknown;
    }
}
var BaseDoor = function (_a) {
    var className = _a.className, otherProps = __rest(_a, ["className"]);
    return React.createElement("line", __assign({ className: clsx(classes.base, className) }, otherProps));
};
/**
 * Because we are using stroke-dash in some of the classes, it makes it such that only
 * the rendered line will be considered for click detection. To workaround it, we use
 * a transparent door on top of the marker, this dummy door will be used to allow the
 * full door to be clickable.
 */
var DummyDoor = function (_a) {
    var className = _a.className, otherProps = __rest(_a, ["className"]);
    return React.createElement("line", __assign({ className: clsx(classes.base, classes.transparent, className) }, otherProps));
};
var SingleSwingDoor = function (_a) {
    var x1 = _a.x1, x2 = _a.x2, y1 = _a.y1, y2 = _a.y2, doorMode = _a.doorMode;
    var doorStyle = useDoorStyle(doorMode);
    return (React.createElement(React.Fragment, null,
        React.createElement(BaseDoor, { x1: x1, y1: y1, x2: x2, y2: y2, className: doorStyle }),
        React.createElement(DummyDoor, { x1: x1, y1: y1, x2: x2, y2: y2 })));
};
var SingleSlidingDoor = SingleSwingDoor;
var SingleTelescopeDoor = SingleSlidingDoor;
var DoubleSwingDoor = function (_a) {
    var x1 = _a.x1, y1 = _a.y1, x2 = _a.x2, y2 = _a.y2, doorMode = _a.doorMode;
    var separatorX = (x2 - x1) * 0.002;
    var separatorY = (y2 - y1) * 0.002;
    var centerX = x1 + (x2 - x1) / 2;
    var centerY = y1 + (y2 - y1) / 2;
    var doorStyle = useDoorStyle(doorMode);
    return (React.createElement(React.Fragment, null,
        React.createElement(BaseDoor, { x1: x1, y1: y1, x2: centerX - separatorX, y2: centerY - separatorY, className: doorStyle }),
        React.createElement(BaseDoor, { x1: centerX + separatorX, y1: centerY + separatorY, x2: x2, y2: y2, className: doorStyle }),
        React.createElement(DummyDoor, { x1: x1, y1: y1, x2: x2, y2: y2 })));
};
var DoubleSlidingDoor = DoubleSwingDoor;
var DoubleTelescopeDoor = DoubleSlidingDoor;
export var DoorMarker = React.forwardRef(function (_a, ref) {
    var x1 = _a.x1, y1 = _a.y1, x2 = _a.x2, y2 = _a.y2, doorType = _a.doorType, doorMode = _a.doorMode, otherProps = __rest(_a, ["x1", "y1", "x2", "y2", "doorType", "doorMode"]);
    var doorProps = { x1: x1, y1: y1, x2: x2, y2: y2, doorType: doorType, doorMode: doorMode };
    var renderDoor = function () {
        switch (doorType) {
            case RmfDoor.DOOR_TYPE_SINGLE_SWING:
                return React.createElement(SingleSwingDoor, __assign({}, doorProps));
            case RmfDoor.DOOR_TYPE_SINGLE_SLIDING:
                return React.createElement(SingleSlidingDoor, __assign({}, doorProps));
            case RmfDoor.DOOR_TYPE_SINGLE_TELESCOPE:
                return React.createElement(SingleTelescopeDoor, __assign({}, doorProps));
            case RmfDoor.DOOR_TYPE_DOUBLE_SWING:
                return React.createElement(DoubleSwingDoor, __assign({}, doorProps));
            case RmfDoor.DOOR_TYPE_DOUBLE_SLIDING:
                return React.createElement(DoubleSlidingDoor, __assign({}, doorProps));
            case RmfDoor.DOOR_TYPE_DOUBLE_TELESCOPE:
                return React.createElement(DoubleTelescopeDoor, __assign({}, doorProps));
            default:
                return null;
        }
    };
    try {
        return (React.createElement(StyledG, __assign({ ref: ref }, otherProps),
            React.createElement("g", { className: otherProps.onClick ? classes.marker : undefined }, renderDoor())));
    }
    catch (e) {
        console.error(e.message);
        return null;
    }
});
export default DoorMarker;
